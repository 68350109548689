import { Dropdown, DropdownAction, DropdownActions, option } from '@monorepo/base/src/components/dropdown/dropdown';
import { FormControl, FormTitle, FormDesc } from '@monorepo/base/src/components/form/form-control/form-control';
import { InputTitle, InputDesc, InputInfo, InputItem } from '@monorepo/base/src/components/form/input-item/input-item';
import { Input } from '@monorepo/base/src/components/form/input/input';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { observer } from 'mobx-react';
import { useEffect, useRef, useState } from 'react';
import { CampaignDomIds } from '../../../../hooks/toc/campaign.toc';
import { AdvertiserModel } from '../../../../models/advertiser.model';
import { IMFPStore } from '../../../../stores';
import styles from '../campaign-form.module.scss';
import { SpaceBetweenFormControls, SpaceBetweenInputControls, toolTipDisabledContent } from '../campaign.form';
import { Icon } from '@monorepo/base/src/components/icon/icon';
import { getTheme } from '@monorepo/tools/src/lib/get-config';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';
import { StatusEnum } from '../../../../enums/status';
import routes from '../../../../routes/index.routes';
import { Tooltip } from '@monorepo/base/src/components/tooltip/tooltip';
import { Flex } from '@monorepo/base/src/components/flex/flex';
import { Toggle } from '@monorepo/base/src/components/form/toggle/toggle';
import { Directions } from '@monorepo/base/src/components/buttons/buttons';
import { useParams } from 'react-router-dom';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';

const landingPageMacros = [
	{
		text: 'Click ID',
		value: '{{Click ID}}',
	},
	// {
	// 	text: 'Bid',
	// 	value: '{{BID}}',
	// },
	{
		text: 'Device',
		value: '{{Device}}',
	},
	{
		text: 'Source',
		value: '{{Source}}',
	},
	// {
	// 	text: 'Browser',
	// 	value: '{{Browser}}',
	// },
	// {
	// 	text: 'Carrier',
	// 	value: '{{Carrier}}',
	// },
	// {
	// 	text: 'OS',
	// 	value: '{{OS}}',
	// },
	{
		text: 'Geo',
		value: '{{Geo}}',
	},
	// {
	// 	text: 'Campaign Keyword',
	// 	value: '{{Campaign - Keyword}}',
	// },
];

export interface ICampaignProfile {
	onCreateAdvertiser?: (e: React.MouseEvent<HTMLElement>) => void;
	onAfterAdvertiserSelect?: () => void;
}

export const CampaignProfile = observer((props: ICampaignProfile) => {
	const { onCreateAdvertiser, onAfterAdvertiserSelect } = props;
	const { campaignStore, advertiserStore } = useStores<IMFPStore>();
	const [activatedMacros, setActivatedMacros] = useState<Set<string>>(new Set());

	const routesObj = useRoute();
	const params = useParams();
	const isEditPage = routesObj.currentRoute.includes('edit');
	const isNestedAdvertiser = routesObj.currentRoute.includes('advertisers');
	const campaignCrud = campaignStore.getCrud();
	const campaign = campaignCrud.getData();
	const isDraftStatus = campaign.getStatus() === StatusEnum.DRAFT;
	const formStore = campaignCrud.getFormStore();
	const advertisers = advertiserStore.getListStore().getList();
	const isLoadingAdvertisers = advertiserStore.getListStore().getIsLoading();
	const advertisersNames = advertisers.map(_advertiser => _advertiser.getName() || '');
	const advertiser = campaign.getAdvertiser();
	const accountId = advertiser?.getAccount()?.getId();
	const landingPage = campaign.getLandingPage();
	const inputElement = useRef<HTMLInputElement>(null);

	useEffect(() => {
		const matchedMacros = landingPage?.match(/{{(.*?)}}/g);
		if (Array.isArray(matchedMacros) && matchedMacros?.length > 0) {
			setActivatedMacros(new Set([...matchedMacros]));
		} else {
			setActivatedMacros(new Set());
		}
	}, [landingPage]);

	useEffect(() => {
		if (isNestedAdvertiser && params.advertiserId) {
			const _advertiser = advertisers.find(_advertiser => _advertiser.getId() == params.advertiserId);
			if (_advertiser) {
				onAdvertiserSelect(_advertiser.getName() || '');
			}
		}
	}, [advertisers]);

	const onAdvertiserSelect = (advertiserName: option) => {
		const _advertiser = advertisers.find(_advertiser => _advertiser.getName() === advertiserName);
		if (_advertiser) {
			const advertiser = new AdvertiserModel(_advertiser);
			campaign.setAdvertiser(advertiser);
			if (advertiser?.id) {
				advertiserStore.getCrud().get(advertiser.id);
				campaign.setConversionActions([]);
				onAfterAdvertiserSelect && onAfterAdvertiserSelect();
			}
		}
	};

	const onMacroClick = (macroValue: string) => {
		if (inputElement.current) {
			inputElement.current.focus();
		}
		campaign.setLandingPage(`${landingPage || ''}${macroValue}`);
	};

	return (
		<div>
			<FormControl>
				<FormTitle id={CampaignDomIds.Profile}>Campaign Profile</FormTitle>
				<FormDesc>Provide details regarding the campaign you are creating</FormDesc>
			</FormControl>

			<SpaceBetweenFormControls />

			<InputItem id={CampaignDomIds.Name}>
				<InputInfo className={styles.inputInfo}>
					<InputTitle>Campaign Name</InputTitle>
					<InputDesc>Enter a descriptive name for your campaign</InputDesc>
				</InputInfo>
				<Input
					className={styles.inputWrapper}
					value={campaign.getName()}
					onValue={value => {
						campaign.setName(value);
						formStore.clearError(CampaignDomIds.Name);
					}}
					required={true}
					requiredAsterisk={false}
					error={formStore.getErrors().get(CampaignDomIds.Name)?.getMsg()}
					debugProps={{ dataAttrs: [new DataAttribute('id', 'name')] }}
				/>
			</InputItem>

			<SpaceBetweenInputControls />

			<InputItem id={CampaignDomIds.Advertiser}>
				<InputInfo className={styles.inputInfo}>
					<InputTitle>Advertiser</InputTitle>
					<InputDesc>Select advertiser</InputDesc>
				</InputInfo>

				<Tooltip is={isEditPage && !isDraftStatus} content={toolTipDisabledContent}>
					<Dropdown
						required={true}
						defaultOption={advertiser?.getName()}
						isLoading={isLoadingAdvertisers}
						requiredAsterisk={false}
						label={'Select advertiser'}
						autocomplete={true}
						autocompletePlaceholder={'Search Advertiser'}
						disabled={(isEditPage && !isDraftStatus) || isNestedAdvertiser}
						options={advertisersNames}
						onSelect={(advertiserName: Set<option> | undefined) => {
							onAdvertiserSelect(advertiserName?.values().next().value);
							formStore.clearError(CampaignDomIds.Advertiser);
						}}
						error={formStore.getErrors().get(CampaignDomIds.Advertiser)?.getMsg()}
						actions={
							advertisersNames.length > 1 ? (
								<DropdownActions>
									<DropdownAction
										to={accountId ? routes.accounts.createAdvertiser(accountId) : routes.advertisers.create()}
										onClick={onCreateAdvertiser}>
										Add a new advertiser
									</DropdownAction>
								</DropdownActions>
							) : null
						}
						debugProps={{ dataAttrs: [new DataAttribute('id', 'advertiser')] }}
					/>
				</Tooltip>
			</InputItem>

			<SpaceBetweenInputControls />

			<InputItem id={CampaignDomIds.LandingPage} maxWidth={'800px'}>
				<InputInfo className={`${styles.inputInfo} ${styles.stickToTop}`}>
					<InputTitle>Landing Page</InputTitle>
					<InputDesc>Choose the URL of the page on your website where you want users to land</InputDesc>
				</InputInfo>
				<Flex flexDirection={'column'} gap={'20px'}>
					<Flex gap={'20px'}>
						<Input
							ref={inputElement}
							before={
								<Icon isMFP={true} color={getTheme().subtitleColor} size={'22px'}>
									link-02
								</Icon>
							}
							placeholder={'https://example.com'}
							className={styles.inputWrapper}
							value={landingPage?.toString()}
							onValue={value => {
								campaign.setLandingPage(value);
								formStore.clearError(CampaignDomIds.LandingPage);
							}}
							required={true}
							requiredAsterisk={false}
							error={formStore.getErrors().get(CampaignDomIds.LandingPage)?.getMsg()}
							debugProps={{ dataAttrs: [new DataAttribute('id', 'landing_page')] }}
						/>
						<Toggle
							label={'Deep Link'}
							labelDirection={Directions.FromRight}
							onChange={status => campaign.setIsDeepLink(status)}
							defaultValue={campaign.getIsDeepLink()}
							debugProps={{ dataAttrs: [new DataAttribute('id', 'deep_link')] }}
						/>
					</Flex>
					<div className={styles.macrosWrapper}>
						{landingPageMacros.map((macro, i) => {
							return (
								<div
									onClick={() => onMacroClick(macro.value)}
									key={i}
									className={`${styles.macroItem} ${activatedMacros.has(macro.value) ? styles.active : ''}`}>
									<Icon isMFP={true} className={styles.icon}>
										{activatedMacros.has(macro.value) ? 'check-circle-broken' : 'plus-circle'}
									</Icon>
									<span className={styles.text}>{macro.text}</span>
								</div>
							);
						})}
					</div>
				</Flex>
			</InputItem>

			<SpaceBetweenInputControls />
		</div>
	);
});
