import { observer } from 'mobx-react';
import { stringAction, stringFuncs } from '@monorepo/tools/src/lib/utils/string';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { FilterModel, FilterOptionsEnums, IFilterComponentProps, IFilterProto } from '@monorepo/controlled/src/models/filter.model';
import { FilterStringMenu } from '@monorepo/base/src/components/filters/filter-string-menu/filter-string-menu';
import { IMFPStore } from '../../stores';
import { ChannelFiltersStoreKeys } from '../../stores/channels/channel.store';
import { ChannelModel } from '../../models/channel.model';

// TODO - change to add filter and edit filter from props
const ChannelNameFilter = observer((props: IFilterComponentProps) => {
	const { filter, onCancel, onApply } = props;
	const { channelStore } = useStores<IMFPStore>();
	const { currentRouteWithoutSpecialChars } = useRoute();

	const onAddChannelNameFilter = (_action: stringAction, _value: string) => {
		const filterLabel = `Channel Name ${_action} ${_value}`;
		const channelNameFilter = new FilterModel({
			label: filterLabel,
			action: _action,
			value: _value,
			prototype: ChannelNameFilterPrototype,
		});
		const filterStore = channelStore.getFiltersStore(currentRouteWithoutSpecialChars as ChannelFiltersStoreKeys);
		if (filter?.index && filterStore?.getCurrentFilters().has(filter?.index)) {
			filterStore.editFilter(filter.index, channelNameFilter);
		} else {
			filterStore?.addFilter(channelNameFilter);
		}
		onApply();
	};

	return (
		<FilterStringMenu
			title={'Channel Name'}
			defaultValue={typeof filter?.value === 'string' ? filter?.value : ''}
			defaultAction={filter?.action}
			onApply={(_action: stringAction, _value: string) => onAddChannelNameFilter(_action, _value)}
			onCancel={onCancel}
		/>
	);
});

export const ChannelNameFilterPrototype: IFilterProto = {
	MenuComponent: 'Channel Name',
	FilterComponent: ChannelNameFilter,
	prop: FilterOptionsEnums.ChannelName,
	deletable: true,
	multi: true,
	filterFunc<T extends ChannelModel>(models: Array<T>, filter: FilterModel): Array<T> {
		const { value, action } = filter;
		return models.filter(model => {
			if (!action) {
				return true;
			}
			const actionFunc = stringFuncs[action];
			if (!actionFunc) {
				return true;
			}
			return actionFunc(model?.channel || '', typeof value === 'string' ? value : '');
		});
	},
};
