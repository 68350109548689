import { ReactNode } from 'react';
import styles from './pagebar.module.scss';

interface IPagebar {
	children: ReactNode;
	classes?: {
		pagebar?: string;
	};
}

interface IPagebarContent {
	children: ReactNode;
	classes?: {
		content?: string;
	};
}

interface IPagebarTitle {
	children: ReactNode;
	unstyled?: boolean;
	classes?: {
		title?: string;
	};
}

interface IPagebarSubtitle {
	children: ReactNode;
	unstyled?: boolean;
	classes?: {
		subtitle?: string;
	};
}

interface IPagebarActions {
	children: ReactNode;
}

export const Pagebar = (props: IPagebar) => {
	const { children, classes } = props;
	return <div className={`${styles.pagebar} ${classes?.pagebar}`}>{children}</div>;
};

export const PagebarContent = (props: IPagebarContent) => {
	const { children, classes } = props;
	return <div className={`${styles.content} ${classes?.content}`}>{children}</div>;
};

export const PagebarTitle = (props: IPagebarTitle) => {
	const { children, unstyled = false, classes = {} } = props;
	let internalClasses = '';
	if (!unstyled) {
		internalClasses = styles.title;
	}
	return <div className={`${internalClasses} ${styles.pagebarTitle} ${classes.title}`}>{children}</div>;
};

export const PagebarSubtitle = (props: IPagebarSubtitle) => {
	const { children, unstyled = false, classes = {} } = props;
	let internalClasses = '';
	if (!unstyled) {
		internalClasses = styles.pagebarSubtitle;
	}
	return <div className={`${internalClasses} ${styles.pagebarSubtitle} ${classes.subtitle}`}>{children}</div>;
};

export const PagebarActions = (props: IPagebarActions) => {
	const { children } = props;
	return <div className={styles.actions}>{children}</div>;
};
