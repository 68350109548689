import { CampaignsRoutes } from './campaigns.routes';
import { AuthRoutes } from './auth.routes';
import { GeneralRoutes } from './general.routes';
import { AdvertisersRoutes } from './advertisers.routes';
import { AccountsRoutes } from './accounts.routes';

const routes = {
	auth: new AuthRoutes(),
	general: new GeneralRoutes(),
	accounts: new AccountsRoutes(),
	advertisers: new AdvertisersRoutes(),
	campaigns: new CampaignsRoutes(),
};

export default routes;
