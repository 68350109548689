import { FilterOptionsEnums, IFilterProto } from '@monorepo/controlled/src/models/filter.model';
import { AdvertiserNameFilterPrototype } from './advertiser-name.filter';
import { ChannelNameFilterPrototype } from './channel-name.filter';
import { AccountNameFilterPrototype } from './account-name.filter';
import { StatusFilterPrototype } from './status.filter';

export {
	StatusFilterPrototype as StatusFilter,
	AdvertiserNameFilterPrototype as AdvertiserNameFilter,
	ChannelNameFilterPrototype as ChannelNameFilter,
	AccountNameFilterPrototype as AccountNameFilter,
};
export const filterPrototypesByProp: Record<string, IFilterProto> = {
	[FilterOptionsEnums.Status]: StatusFilterPrototype,
	[FilterOptionsEnums.AdvertiserName]: AdvertiserNameFilterPrototype,
	[FilterOptionsEnums.ChannelName]: ChannelNameFilterPrototype,
	[FilterOptionsEnums.AccountName]: AccountNameFilterPrototype,
};
