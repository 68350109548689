import { IThirdPartyIcon } from './google.icon';

export const MicrosoftIcon = (props: IThirdPartyIcon) => {
	const { classes } = props;
	return (
		<svg
			className={classes}
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			width="24px"
			height="24px"
			viewBox="0 0 24 23"
			version="1.1">
			<g id="surface1">
				<path
					stroke="none"
					fillRule="nonzero"
					fill="rgb(94.509804%,31.764706%,10.588235%)"
					fillOpacity="1"
					d="M 11.40625 10.929688 L 0 10.929688 L 0 0 L 11.40625 0 Z M 11.40625 10.929688 "
				/>
				<path
					stroke="none"
					fillRule="nonzero"
					fill="rgb(50.196078%,80%,15.686275%)"
					fillOpacity="1"
					d="M 24 10.929688 L 12.59375 10.929688 L 12.59375 0 L 23.996094 0 L 23.996094 10.929688 Z M 24 10.929688 "
				/>
				<path
					stroke="none"
					fillRule="nonzero"
					fill="rgb(0%,67.843137%,93.72549%)"
					fillOpacity="1"
					d="M 11.40625 23 L 0 23 L 0 12.070312 L 11.40625 12.070312 Z M 11.40625 23 "
				/>
				<path
					stroke="none"
					fillRule="nonzero"
					fill="rgb(98.431373%,73.72549%,3.529412%)"
					fillOpacity="1"
					d="M 24 23 L 12.59375 23 L 12.59375 12.070312 L 23.996094 12.070312 L 23.996094 23 Z M 24 23 "
				/>
			</g>
		</svg>
	);
};
