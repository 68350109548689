import { sentenceCase } from 'change-case';
import { ReactNode, useRef, useState } from 'react';
import { useOnClickOutside } from '@monorepo/tools/src/lib/hooks/utils/use-on-click-outside';
import { Icon, IconFonts } from '@monorepo/base/src/components/icon/icon';
import { Menu } from '@monorepo/base/src/components/menu/menu';
import { Tooltip } from '@monorepo/base/src/components/tooltip/tooltip';
import styles from './quick-actions-menu.module.scss';

interface ModelStatus {
	status: string;
}

interface IEntityActionsMenu<T> {
	children: ReactNode;
	entity: T;
	isStatus?: boolean;
	toggler?: ReactNode;
}

export { QuickActionButton } from './quick-action-button';
export function QuickActionsMenu<T>(props: IEntityActionsMenu<T>): JSX.Element;
export function QuickActionsMenu<T extends ModelStatus>(props: IEntityActionsMenu<T>): JSX.Element {
	const { children, entity, isStatus = false, toggler } = props;
	const [isQuickActionsMenuOpen, setEntityActionsMenuOpen] = useState<boolean>(false);
	const entityActionsRef = useRef(null);

	let status = null;
	if (isStatus && entity.status) {
		// TODO - should send here isActiveStatus and not check status === 'active'
		status = entity.status.toLowerCase();
	}

	useOnClickOutside(entityActionsRef, () => {
		setEntityActionsMenuOpen(false);
	});

	return (
		<div ref={entityActionsRef} className={styles.wrapper}>
			<div onClick={() => setEntityActionsMenuOpen(!isQuickActionsMenuOpen)} className={styles.quickActionsTrigger}>
				{isStatus && status ? (
					<Tooltip content={sentenceCase(status)} delay={500} className={styles.tooltipPosition}>
						<Icon size={'14px'} isMFP={true} color={status === 'active' ? '#0b8043' : undefined} font={IconFonts.Filled}>
							{status === 'active' ? 'circle' : 'pause-circle'}
						</Icon>
					</Tooltip>
				) : null}
				{toggler || (
					<Icon className={'tableQuickActions'} isMFP={true} size={'22px'}>
						arrow-dropdown
					</Icon>
				)}
			</div>
			<Menu isOpen={isQuickActionsMenuOpen} unstyled={true}>
				<div className={styles.actionsMenu}>{children}</div>
			</Menu>
		</div>
	);
}
