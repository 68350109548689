import { observer } from 'mobx-react';
import { stringAction, stringFuncs } from '@monorepo/tools/src/lib/utils/string';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { CampaignModel } from '../../models/campaign.model';
import { FilterModel, FilterOptionsEnums, IFilterComponentProps, IFilterProto } from '@monorepo/controlled/src/models/filter.model';
import { CampaignFiltersStoreKeys } from '../../stores/campaigns/campaign.store';
import { FilterStringMenu } from '@monorepo/base/src/components/filters/filter-string-menu/filter-string-menu';
import { IMFPStore } from '../../stores';

// TODO - change to add filter and edit filter from props
const AdvertiserNameFilter = observer((props: IFilterComponentProps) => {
	const { filter, onCancel, onApply } = props;
	const { campaignStore } = useStores<IMFPStore>();
	const { currentRouteWithoutSpecialChars } = useRoute();

	const onAddAdvertiserNameFilter = (_action: stringAction, _value: string) => {
		const filterLabel = `Advetiser Name ${_action} ${_value}`;
		const advertiserNameFilter = new FilterModel({
			label: filterLabel,
			action: _action,
			value: _value,
			prototype: AdvertiserNameFilterPrototype,
		});
		const filterStore = campaignStore.getFiltersStore(currentRouteWithoutSpecialChars as CampaignFiltersStoreKeys);
		if (filter?.index && filterStore?.getCurrentFilters().has(filter?.index)) {
			filterStore.editFilter(filter.index, advertiserNameFilter);
		} else {
			filterStore?.addFilter(advertiserNameFilter);
		}
		onApply();
	};

	return (
		<FilterStringMenu
			title={'Advertiser Name'}
			defaultValue={typeof filter?.value === 'string' ? filter?.value : ''}
			defaultAction={filter?.action}
			onApply={(_action: stringAction, _value: string) => onAddAdvertiserNameFilter(_action, _value)}
			onCancel={onCancel}
		/>
	);
});

export const AdvertiserNameFilterPrototype: IFilterProto = {
	MenuComponent: 'Advertiser Name',
	FilterComponent: AdvertiserNameFilter,
	prop: FilterOptionsEnums.AdvertiserName,
	deletable: true,
	multi: true,
	filterFunc<T extends CampaignModel>(models: Array<T>, filter: FilterModel): Array<T> {
		const { value, action } = filter;
		return models.filter(model => {
			if (!action) {
				return true;
			}
			const actionFunc = stringFuncs[action];
			if (!actionFunc) {
				return true;
			}
			return actionFunc(model?.advertiser?.name || '', typeof value === 'string' ? value : '');
		});
	},
};
