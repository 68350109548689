import { Page, Pagebar } from '@monorepo/base/src/components/page/page';
import { Soon } from '@monorepo/base/src/components/soon/soon';
import { observer } from 'mobx-react';

export const EditAccount = observer(() => {
	return (
		<Page>
			<Pagebar>
				<Soon />
			</Pagebar>
		</Page>
	);
});
