import { DecimalData, PerformanceMetricsSymbols } from '@monorepo/tools/src/lib/enums/performance-labels';
import { Skeleton } from '../../skeleton/skeleton';
import { Tooltip } from '../../tooltip/tooltip';
import styles from './donut-legends.module.scss';

interface IDonutLegends {
	legends?: {
		label: string;
		value: number;
		color: string;
		entityName: string;
	}[];
	isLoading?: boolean;
}

export const DonutLegends = (props: IDonutLegends) => {
	const { legends = [], isLoading } = props;
	const dummyArray = [1, 1, 1, 1, 1];

	return (
		<div className={styles.wrapper}>
			{!isLoading
				? legends.map(legend => {
						const symbol = PerformanceMetricsSymbols.get(legend.label as any);
						return (
							<div className={styles.legend} key={legend.entityName}>
								<span className={styles.tooltipWrapper}>
									<Tooltip content={legend.entityName}>
										<span className={styles.title}>{legend.entityName}</span>
									</Tooltip>
								</span>
								<div className={styles.valueWrapper}>
									<div className={styles.color} style={{ backgroundColor: legend.color }}></div>
									<span className={styles.value}>
										{symbol === '$' ? symbol : ''}
										{DecimalData.includes(legend.label) ? legend.value : legend.value.toLocaleString()}
										{symbol === '%' ? symbol : ''}
									</span>
								</div>
							</div>
						);
				  })
				: dummyArray.map((d, index) => {
						return (
							<div className={styles.legend} key={index}>
								<span className={styles.tooltipWrapper}>
									<span className={styles.title}>
										<Skeleton is fullWidth />
									</span>
								</span>
								<div className={styles.valueWrapper}>
									<span className={`${styles.value} ${styles.skeleton}`}>
										<Skeleton is fullWidth />
									</span>
								</div>
							</div>
						);
				  })}
		</div>
	);
};
