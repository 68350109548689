import styles from './signin.module.scss';
import { PrimaryButton } from '@monorepo/base/src/components/buttons/buttons';
import { Input } from '@monorepo/base/src/components/form/input/input';
import { observer } from 'mobx-react-lite';
import { Checkbox } from '@monorepo/base/src/components/form/checkbox/checkbox';
import { FC, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import routes from '../../../modules/routes/index.routes';
import { ThirdPartyAuth } from '../../../modules/components/third-party-auth/third-party-auth';
import { Icon } from '@monorepo/base/src/components/icon/icon';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { IMFPStore } from '../../../modules/stores';
import { sessionStorageHelper } from '@monorepo/tools/src/lib/tools/storage';
import { URL_TO_RETURN_AFTER_LOGOUT } from '../../../lib/consts/storage';
import { UserModel } from '../../../modules/models/user.model';
import { HttpHeaders } from '@monorepo/tools/src/lib/enums/headers';
import { ErrorMsg } from '@monorepo/base/src/components/error-msg/error-msg';
import { FullResponse } from '@monorepo/tools/src/lib/interfaces/url';
import { IUserRes } from '../../../modules/stores/users/user-crud.store';

export const SignIn: FC = observer(() => {
	const { userStore, authStore } = useStores<IMFPStore>();
	const [showPassword, setShowPassword] = useState<boolean>(false);
	const crudStore = userStore.getCrud();
	const user = crudStore.getData();
	const formStore = crudStore.getFormStore();
	const error = crudStore.getHttpError();
	const isLoading = crudStore.getIsLoading();
	const navigate = useNavigate();

	useEffect(() => {
		crudStore.reset();
	}, []);

	const onSignIn = async () => {
		const signInProps = await crudStore.signIn();
		if (!signInProps) {
			return;
		}
		const { body, headers } = signInProps as FullResponse<IUserRes>;
		if (!body?.payload?.user) {
			return;
		}
		const userAuthToken = headers?.find(h => h[0] === HttpHeaders.USER_TOKEN)?.[1];
		if (!userAuthToken) {
			return;
		}
		const user = new UserModel(body.payload.user);
		userStore.setUser(user);
		authStore.setToken(userAuthToken);

		if (!user.hasAccounts()) {
			return navigate(routes.accounts.createAccount());
		}

		if (!user.isVerified()) {
			return navigate(routes.auth.verify());
		}

		const urlToReturnTo = sessionStorageHelper.getItem(URL_TO_RETURN_AFTER_LOGOUT);
		navigate(urlToReturnTo || routes.general.home());
	};

	return (
		<div className={styles.signinContent}>
			<div className={styles.title}>Login</div>
			<div className={styles.subtitle}>Welcome back! Please enter your details.</div>
			<ThirdPartyAuth />
			<div className={styles.lineSeperator}>
				<span className={styles.lineText}>or Sign in with Email</span>
			</div>

			<div className={styles.formWrapper}>
				<div className={styles.inputWrapper}>
					<div className={styles.formText}>Email</div>
					<Input
						className={styles.formInput}
						placeholder={'Enter your email'}
						onValue={v => user.setEmail(v)}
						value={user.getEmail()}
						error={formStore.getErrors().get('email')?.getMsg()}
					/>
				</div>
				<div className={styles.inputWrapper}>
					<div className={styles.formText}>Password</div>
					<Input
						className={styles.formInput}
						value={user.getPassword()}
						placeholder={'Enter your Password'}
						onValue={v => user.setPassword(v)}
						requiredAsterisk={false}
						type={!showPassword ? 'password' : 'text'}
						after={
							<Icon onClick={() => setShowPassword(!showPassword)} className={styles.passwordVisibilityIcon} isMFP={true}>
								{!showPassword ? 'eye' : 'eye-off'}
							</Icon>
						}
						error={formStore.getErrors().get('password')?.getMsg()}
					/>
				</div>
				<div className={styles.formOptions}>
					<div className={styles.rememberMe}>
						<Checkbox onChange={() => user.setRememberMe(!user.getRememberMe())} />
						<div>Keep me signed in</div>
					</div>
					<Link to={''} className={styles.forgotPassword}>
						Forgot Password
					</Link>
				</div>
				<PrimaryButton
					isRotateIcon={isLoading}
					iconSize={'20px'}
					icon={isLoading ? 'loading-01' : ''}
					className={styles.signInBtn}
					onClick={() => onSignIn()}>
					Sign in
				</PrimaryButton>
				{error ? <ErrorMsg error={error} isIcon={false} classes={{ error: styles.error }} /> : null}
				<div className={styles.signUpWrapper}>
					<div>Don't have an account?&nbsp;</div>
					<Link to={routes.auth.signUp()} className={styles.signUp}>
						Sign up
					</Link>
				</div>
			</div>
		</div>
	);
});
