import { IThirdPartyIcon } from './google.icon';

export const FacebookIcon = (props: IThirdPartyIcon) => {
	const { classes } = props;

	return (
		<svg className={classes} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_1619_41399)">
				<path
					d="M24 12.0003C23.9989 9.70744 23.341 7.46289 22.1041 5.53229C20.8672 3.60169 19.1031 2.06587 17.0205 1.10659C14.938 0.14731 12.6242 -0.19526 10.3531 0.119418C8.08194 0.434096 5.94847 1.39285 4.2052 2.88221C2.46194 4.37157 1.18186 6.32918 0.516449 8.52335C-0.148958 10.7175 -0.17184 13.0564 0.450535 15.2632C1.07291 17.4699 2.31448 19.4522 4.02829 20.9754C5.74209 22.4985 7.8564 23.4988 10.121 23.8578V15.487H7.07518V12.0139H10.121V9.38186C10.121 6.36999 11.9186 4.70804 14.6591 4.70804C15.5682 4.71972 16.4752 4.79908 17.3725 4.94546V7.89628H15.8598C15.6015 7.86287 15.3389 7.88779 15.0915 7.96919C14.8441 8.05059 14.618 8.1864 14.4299 8.36665C14.2419 8.5469 14.0966 8.76699 14.0048 9.01077C13.913 9.25454 13.877 9.51578 13.8994 9.77531V12.0206H17.2301L16.6738 15.487H13.8722V23.8714C16.6978 23.4237 19.2707 21.9818 21.1275 19.8054C22.9843 17.629 24.0029 14.8611 24 12.0003V12.0003Z"
					fill="#337FFF"
				/>
				<path
					d="M16.6739 15.4869L17.203 12.0138H13.8723V9.76844C13.8499 9.50892 13.8859 9.24767 13.9777 9.0039C14.0695 8.76013 14.2148 8.54003 14.4028 8.35979C14.5909 8.17954 14.817 8.04373 15.0644 7.96233C15.3119 7.88093 15.5744 7.85602 15.8327 7.88942H17.3455V4.93859C16.4481 4.79222 15.5412 4.71286 14.6321 4.70117C11.9187 4.70117 10.0939 6.36313 10.0939 9.375V12.0138H7.0481V15.4869H10.0939V23.8713C10.7157 23.9667 11.3439 24.0143 11.9729 24.0138C12.5997 24.0146 13.2257 23.967 13.8452 23.8713V15.4869H16.6739Z"
					fill="white"
				/>
			</g>
			<defs>
				<clipPath id="clip0_1619_41399">
					<rect width="24" height="24" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};
