import { TableLink } from '@monorepo/controlled/src/components/table/table-link/table-link';
import routes from '../../routes/index.routes';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { AdvertiserModel, IAdvertiser } from '../../models/advertiser.model';
import { IEntityHeaders, IMergedHeadersReturn, renderSubRow } from './index.headers';
import { BetweenPagesStateProps } from '@monorepo/tools/src/lib/interfaces/global';
import { selectionColumn } from '@monorepo/controlled/src/components/table-wrapper/selection-column/selection-column';
import { isSubRow } from '@monorepo/controlled/src/components/table/table';
import { IPerformanceItem } from '../../models/performance.model';
import { createColumnHelper } from '@tanstack/react-table';
import { IMFPStore } from '../../stores';
import { useWithPerformanceHeaders } from './with-performance.headers';
import { Tag } from '@monorepo/base/src/components/tag/tag';
import { mapToIcon, StatusType } from '../../enums/status';
import { Icon, IconFonts } from '@monorepo/base/src/components/icon/icon';
import { sentenceCase } from 'change-case';
import { mapToTagStatus } from '../../enums/status';
import styles from './headers.module.scss';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';

const columnHelper = createColumnHelper<IAdvertiser & IPerformanceItem>();

export const useAdvertiserHeaders = (props: IEntityHeaders = {}): IMergedHeadersReturn<AdvertiserModel> => {
	const { EntityActions, onEdit, summary, includeColumns = {} } = props;
	const routeObj = useRoute();
	const isNestedAccount = routeObj.currentRoute.includes('accounts');
	const { sidebarStore } = useStores<IMFPStore>();
	const { account = true } = includeColumns;
	const mergedPerforamnceHeaders = useWithPerformanceHeaders({ summaryItem: summary }).columns;

	const renderHierachyColumns = () => {
		const columns = [];

		if (account) {
			columns.push(
				columnHelper.accessor(row => `${row?.account?.name}`, {
					id: 'accountName',
					header: 'Account',
					cell: info => {
						const { row } = info;
						const advertiser = new AdvertiserModel(row.original); // TODO - check if need the constructor
						const accountId = advertiser.getAccount()?.getId();
						if (!accountId || isSubRow(row)) {
							// will enter here when use segments
							return <span></span>;
						}
						return (
							<TableLink
								to={routes.accounts.advertisers(accountId)}
								onClick={() => {
									sidebarStore.setSidebarOpen(false);
								}}>
								{row.getValue('accountName')}
							</TableLink>
						);
					},
				})
			);
		}

		return columns;
	};

	return {
		columns: [
			selectionColumn<AdvertiserModel>({ model: AdvertiserModel, EntityActions }),
			columnHelper.accessor('name', {
				header: 'Name',
				cell: info => {
					const { row } = info;
					if (isSubRow(row)) {
						return renderSubRow({ row });
					}
					const toEdit = (() => {
						if (onEdit) {
							return onEdit(row.getValue('id'));
						}
						return routes.advertisers.edit(row.getValue('id'));
					})();
					return (
						<TableLink
							to={routes.advertisers.campaigns(row.getValue('id'))}
							toEdit={toEdit}
							toEditState={{ isBlockRequest: true } as BetweenPagesStateProps}
							onClick={() => {
								if (!toEdit) {
									sidebarStore.setSidebarOpen(false);
								}
							}}>
							{row.getValue('name')}
						</TableLink>
					);
				},
			}),
			columnHelper.accessor('status', {
				header: 'Status',
				cell: info => {
					const { row } = info;
					if (isSubRow(row)) {
						return <span></span>;
					}
					const status = row.getValue('status') as string;
					const mappedStatus = mapToIcon(status?.toUpperCase() as StatusType);
					return (
						<Tag type={mapToTagStatus(status?.toUpperCase() as StatusType)}>
							<Icon
								className={styles[mappedStatus.className]}
								color={mappedStatus.color}
								size={'13px'}
								font={IconFonts.Outlined}
								isMFP={true}>
								{mappedStatus.icon}
							</Icon>
							{`${sentenceCase(row.getValue('status'))}`}
						</Tag>
					);
				},
			}),
			columnHelper.accessor('id', {
				header: 'ID',
			}),
			...mergedPerforamnceHeaders,
			...renderHierachyColumns(),
		],
		columnVisibility: { id: false, impressions: false, accountName: !isNestedAccount },
	};
};
