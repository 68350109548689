import { HttpError } from '@monorepo/tools/src/lib/models/http-error.model';
import { List, ListItem } from '@monorepo/base/src/components/list/list';
import styles from './http-error-modal.module.scss';

interface IHttpErrorModal {
	error: HttpError;
	message?: string;
}

export const HttpErrorModal = (props: IHttpErrorModal) => {
	const { error, message } = props;

	return (
		<div className={styles.wrapper}>
			<div className={styles.title}>Error</div>
			<List className={styles.list}>
				{message ? (
					<ListItem key={message}>{message}</ListItem>
				) : (
					error.getMessages().map(message => {
						return <ListItem key={message}>{message}</ListItem>;
					})
				)}
			</List>
		</div>
	);
};
