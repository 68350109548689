import { observer } from 'mobx-react';
import { stringFuncs, stringAction } from '@monorepo/tools/src/lib/utils/string';
import { useRoute } from '../../../hooks/tools/use-route';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { CampaignModel } from '../../../models/campaign.model';
import { FilterModel, FilterOptionsEnums, IFilterComponentProps, IFilterProto } from '@monorepo/controlled/src/models/filter.model';
import { CampaignFiltersStoreKeys } from '../../../stores/campaigns/campaign.store';
import { FilterStringMenu } from '@monorepo/base/src/components/filters/filter-string-menu/filter-string-menu';
import { IAdminxStore } from '../../../stores';

// TODO - change to add filter and edit filter from props
const CampaignGroupNameFilter = observer((props: IFilterComponentProps) => {
	const { filter, onCancel, onApply } = props;
	const { campaignStore } = useStores<IAdminxStore>();
	const { currentRouteWithoutSpecialChars } = useRoute();

	const onAddCampaignGroupNameFilter = (_action: stringAction, _value: string) => {
		const filterLabel = `Campaign Group Name ${_action} ${_value}`;
		const campaignGroupNameFilter = new FilterModel({
			label: filterLabel,
			action: _action,
			value: _value,
			prototype: CampaignGroupNameFilterPrototype,
		});

		const filterStore = campaignStore.getFiltersStore(currentRouteWithoutSpecialChars as CampaignFiltersStoreKeys);
		if (filter?.index && filterStore?.getCurrentFilters().has(filter?.index)) {
			filterStore.editFilter(filter.index, campaignGroupNameFilter);
		} else {
			filterStore?.addFilter(campaignGroupNameFilter);
		}
		onApply();
	};

	return (
		<FilterStringMenu
			title={'Campaign Group Name'}
			defaultValue={typeof filter?.value === 'string' ? filter?.value : ''}
			defaultAction={filter?.action}
			onApply={(_action: stringAction, _value: string) => onAddCampaignGroupNameFilter(_action, _value)}
			onCancel={onCancel}
		/>
	);
});

export const CampaignGroupNameFilterPrototype: IFilterProto = {
	MenuComponent: 'Campaign Group Name',
	FilterComponent: CampaignGroupNameFilter,
	prop: FilterOptionsEnums.CampaignName,
	multi: true,
	deletable: true,
	filterFunc<T extends CampaignModel>(models: Array<T>, filter: FilterModel): Array<T> {
		const { value, action } = filter;
		return models.filter(model => {
			if (!action) {
				return true;
			}
			const actionFunc = stringFuncs[action];
			if (!actionFunc) {
				return true;
			}
			return actionFunc(model?.campaign_group?.name || '', typeof value === 'string' ? value : '');
		});
	},
};
