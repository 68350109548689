import { sentenceCase } from 'change-case';
import { Fragment, ReactNode } from 'react';
import { Icon, IconFonts } from '@monorepo/base/src/components/icon/icon';
import styles from './tag.module.scss';

interface ITag {
	type?: string;
	children: string | number | ReactNode;
	withIcon?: boolean;
	textColor?: string;
	backgroundColor?: string;
	fontWeight?: string;
}

export enum TagTypes {
	Success = 'success',
	Error = 'error',
	New = 'new',
	Info = 'info',
	Warning = 'warning',
	Pending = 'pending',
	Paused = 'paused',
	Deleted = 'deleted',
	Draft = 'draft',
}

enum TypeIcons {
	Success = 'check',
	Error = 'minus-circle',
	New = 'clock-plus',
	Info = 'check',
	Pending = 'hourglass-03',
}

const mapTypeToIcons = (type: string) => {
	if (type === 'success') {
		return TypeIcons.Success;
	}
	if (type === 'error') {
		return TypeIcons.Error;
	}
	if (type === 'new') {
		return TypeIcons.New;
	}
	if (type === 'info') {
		return TypeIcons.Info;
	}
	if (type === 'pending') {
		return TypeIcons.Pending;
	}

	return 'check_icon';
};

export type TagStatus = TagTypes.Success | TagTypes.Error | TagTypes.New | TagTypes.Info | TagTypes.Pending;

export const Tag = (props: ITag) => {
	const { children, type, withIcon, textColor, backgroundColor, fontWeight } = props;

	if (!children) {
		return null;
	}

	let classType = null;
	if (type) {
		classType = styles[type];
	}

	return (
		<span
			className={`${classType} ${styles.tag} ${withIcon ? styles.extraSize : ''}`}
			style={{ color: textColor, backgroundColor, fontWeight }}>
			{typeof children === 'string' ? (
				<Fragment>
					{withIcon && type && (
						<Icon
							font={type === 'new' ? IconFonts.Outlined : IconFonts.Filled}
							className={`${styles.icon} ${classType}`}
							isMFP={true}>
							{mapTypeToIcons(type)}
						</Icon>
					)}
					{sentenceCase(children)}
				</Fragment>
			) : (
				<Fragment>
					{withIcon && type && (
						<Icon className={styles.icon} isMFP={true}>
							{mapTypeToIcons(type)}
						</Icon>
					)}
					{children}
				</Fragment>
			)}
		</span>
	);
};
