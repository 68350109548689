import { memo } from 'react';
import { Helmet } from 'react-helmet-async';
import { hotjarScript } from '@monorepo/tools/src/lib/scripts/hotjar';
import { Envs } from '@monorepo/tools/src/lib/enums/env';
import { getConfig } from '@monorepo/tools/src/lib/get-config';

const _Head = () => {
	const config = getConfig();
	return (
		<Helmet>
			{config.env.projectEnv !== Envs.Local ? (
				<script type="text/javascript">{hotjarScript(config.scripts.hotjar.id, config.scripts.hotjar.version)}</script>
			) : null}
		</Helmet>
	);
};
export const Head = memo(_Head);
