import { Fragment } from 'react';
import { PrimaryLink, SecondaryText } from '@monorepo/base/src/components/buttons/buttons';
import { SelectedLinerButton } from '@monorepo/controlled/src/components/table/action-liner/liner-buttons';
import { QuickActionButton } from '@monorepo/controlled/src/components/table/quick-actions-menu/quick-actions-menu';
import { BetweenPagesStateProps } from '@monorepo/tools/src/lib/interfaces/global';
import { AdvertiserModel } from '../../models/advertiser.model';
import routes from '../../routes/index.routes';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { IEntityActions, ITableActions, IUseEntityActions, useDefaultActions } from './default.actions';
import { getTheme } from '@monorepo/tools/src/lib/get-config';
import { IMFPStore } from '../../stores';
import { ITableFiltersActions, TableFilters } from '@monorepo/controlled/src/components/table/table-filters/table-filters';
import { AccountNameFilter, StatusFilter } from '../../components/filters';
import { AdvertiserFiltersStoreKeys } from '../../stores/advertisers/advertiser.store';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';
import { useSegments } from '../tools/use-segments';
import { Segments } from '@monorepo/base/src/components/segments/segments';
import { PerformanceModel } from '../../models/performance.model';
import { PerformancesColumns } from '../apis/use-reports-list';
import { IconFonts } from '@monorepo/base/src/components/icon/icon';
import { ActionModal, ModalTypes } from '@monorepo/controlled/src/components/modal/modals/action-modal/action-modal';
import { EnablePauseAdvertisersModal } from '../../components/modals/enable-pause-advertisers';
import { useNavigate } from 'react-router-dom';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { ISegmentActions } from '@monorepo/controlled/src/components/table/table';

const theme = getTheme();

export function useAdvertiserActions(props: IUseEntityActions<AdvertiserModel> = {}) {
	const { createRoute, onEdit, availableFilters, currentSegments } = props;
	const { advertiserStore, modalsStore, toastsStore } = useStores<IMFPStore>();
	const advertisersCrudStore = advertiserStore.getCrud();
	const { currentRouteWithoutSpecialChars } = useRoute();

	const filterStore = advertiserStore.getFiltersStore(
		currentRouteWithoutSpecialChars === 'advertiserscreate' || currentRouteWithoutSpecialChars === 'advertisersedit'
			? ('advertisers' as AdvertiserFiltersStoreKeys)
			: (currentRouteWithoutSpecialChars as AdvertiserFiltersStoreKeys)
	);
	const currentFilters = filterStore?.getCurrentFilters();
	const DefaultAvailableFilters = [AccountNameFilter, StatusFilter];
	const navigate = useNavigate();

	const onEditRoute = (advertiserId: id) => (onEdit ? onEdit(advertiserId) : routes.advertisers.edit(advertiserId));
	const onDelete = (id: id) => advertiserStore.getCrud().delete(id);

	const funcActions = {
		onEditRoute,
		onDelete,
	};

	const SelectedActions = (props: ITableActions<AdvertiserModel>) => {
		const { rows } = props;
		const entities = rows.map(row => new AdvertiserModel(row.original));
		const { EditAction, DeleteAction } = useDefaultActions({ entities, ...funcActions });
		const textColor = theme.actionLinerIconTextColor;
		const iconColor = theme.actionLinerIconColor;
		const entitiesIds: id[] = [];
		entities.forEach(e => {
			const id = e.getId();
			if (id) {
				entitiesIds.push(id);
			}
		});

		return (
			<Fragment>
				<SelectedLinerButton>
					<SecondaryText
						icon={'play'}
						onClick={() => enableAdvertisers(entitiesIds)}
						textColor={textColor}
						iconColor={iconColor}
						font={IconFonts.Outlined}>
						Enable
					</SecondaryText>
				</SelectedLinerButton>
				<SelectedLinerButton>
					<SecondaryText
						icon={'pause-square'}
						onClick={() => pauseAdvertisers(entitiesIds)}
						textColor={textColor}
						iconColor={iconColor}
						font={IconFonts.Outlined}>
						Pause
					</SecondaryText>
				</SelectedLinerButton>
				<SelectedLinerButton>
					<EditAction state={{ isBlockRequest: true } as BetweenPagesStateProps} />
				</SelectedLinerButton>
				<SelectedLinerButton>
					<DeleteAction />
				</SelectedLinerButton>
			</Fragment>
		);
	};

	const EntityActions = (props: IEntityActions<AdvertiserModel>) => {
		const { entity } = props;
		const { EditAction, DeleteAction, buttonClassName } = useDefaultActions({ entities: [entity], ...funcActions });
		const entityId = entity.getId();
		if (!entityId) {
			// log error
			return null;
		}

		return (
			<Fragment>
				<QuickActionButton>
					<SecondaryText
						icon={'play'}
						onClick={() => enableAdvertisers([entityId])}
						className={buttonClassName}
						textColor={theme.titleColor}
						iconColor={theme.titleColor}
						font={IconFonts.Outlined}>
						Enable
					</SecondaryText>
				</QuickActionButton>
				<QuickActionButton>
					<SecondaryText
						icon={'pause-square'}
						onClick={() => pauseAdvertisers([entityId])}
						textColor={theme.titleColor}
						className={buttonClassName}
						iconColor={theme.titleColor}
						font={IconFonts.Outlined}>
						Pause
					</SecondaryText>
				</QuickActionButton>
				<QuickActionButton>
					<EditAction
						className={buttonClassName}
						iconColor={theme.titleColor}
						textColor={theme.titleColor}
						state={{ isBlockRequest: true } as BetweenPagesStateProps}
					/>
				</QuickActionButton>
				<QuickActionButton>
					<DeleteAction className={buttonClassName} iconColor={theme.titleColor} textColor={theme.titleColor} />
				</QuickActionButton>
			</Fragment>
		);
	};

	const IndexActions = () => {
		return (
			<PrimaryLink
				icon={'plus'}
				debugProps={{ dataAttrs: [new DataAttribute('id', 'create_advertiser')] }}
				to={`${createRoute || routes.advertisers.create()}${location.search}`}
				width={'100px'}
				state={{ isBlockRequest: true } as BetweenPagesStateProps}>
				Create
			</PrimaryLink>
		);
	};

	const FiltersActions = (props: ITableFiltersActions) => {
		if (!currentFilters) {
			return null;
		}

		return (
			<TableFilters
				{...props}
				availableFilters={availableFilters || DefaultAvailableFilters}
				currentFilters={currentFilters}
				clearAll={() => filterStore?.clearAll()}
				clearFilter={index => filterStore?.clearFilter(index)}
				addFilter={filter => filterStore?.addFilter(filter)}
				editFilter={(index, filter) => filterStore?.editFilter(index, filter)}
			/>
		);
	};

	const SegmentActions = (props: ISegmentActions) => {
		const { segments, onSegment } = useSegments<AdvertiserModel>({ setSegments: advertiserStore.setSegments });

		return (
			<Segments<AdvertiserModel, PerformanceModel, PerformancesColumns>
				currentSegments={currentSegments}
				isComingSoon={segments}
				availableSegments={segments}
				onClick={segment => onSegment(segment)}
				{...props}
			/>
		);
	};

	const enableAdvertisers = (advertiserIds: id[]) => {
		modalsStore.addModal({
			children: (
				<ActionModal
					type={ModalTypes.Info}
					primaryBtnTitle={'Enable'}
					onAction={() => {
						advertisersCrudStore.enableAdvertisers(advertiserIds).then(() => {
							toastsStore.addToastOnNav({ msg: `Advertisers ${advertiserIds} Enabled`, type: 'success' });
							navigate(0);
						});
					}}>
					<EnablePauseAdvertisersModal action={'Enable'} advertiserIds={advertiserIds} />
				</ActionModal>
			),
		});
	};

	const pauseAdvertisers = (advertiserIds: id[]) => {
		modalsStore.addModal({
			children: (
				<ActionModal
					type={ModalTypes.Info}
					primaryBtnTitle={'Paused'}
					onAction={() => {
						advertisersCrudStore.pauseAdvertisers(advertiserIds).then(() => {
							toastsStore.addToastOnNav({ msg: `Advertisers ${advertiserIds} Paused`, type: 'success' });
							navigate(0);
						});
					}}>
					<EnablePauseAdvertisersModal action={'Paused'} advertiserIds={advertiserIds} />
				</ActionModal>
			),
		});
	};

	return {
		SelectedActions,
		EntityActions,
		IndexActions,
		FiltersActions,
		SegmentActions,
	};
}
