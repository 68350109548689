import { observer } from 'mobx-react';
import styles from './sub-user.module.scss';
import { SecondaryButton } from '@monorepo/base/src/components/buttons/buttons';
import { generateStoreName } from '@monorepo/controlled/src/stores/helper';

export const SubUser = observer(() => {
	// const { userStore } = useStores<IMFPStore>();
	// const { accountStore } = useStores<IMFPStore>();
	// const accounts = accountStore.getListStore().getList();
	// const isLoadingAccounts = accountStore.getListStore().getIsLoading();
	//
	// const onListItemAccount = (accountName: string, e: React.MouseEvent<HTMLElement>) => {
	// 	e.stopPropagation();
	//
	// 	const clickedAccount = accounts.find(_account => _account.getName() === accountName);
	// 	if (!clickedAccount || !clickedAccount.getId()) {
	// 		// TODO - log
	// 		return null;
	// 	}
	// 	alert(`${clickedAccount.getId()}-${clickedAccount.getName()}`);
	// };
	//
	// return (
	// 	<div className={styles.wrapper}>
	// 		<Select
	// 			placeholder={'Select Account'}
	// 			listItemClassName={styles.selectItem}
	// 			values={accounts.length > 0 ? accounts.map(account => account.getName()) : []}
	// 			onClick={onListItemAccount}
	// 			disabled={isLoadingAccounts}
	// 			multiple={false}
	// 			// defaultValue={accountName}
	// 		/>
	// 	</div>
	// );

	const onSubUserClick = () => {
		const sprf = 'aa33';
		const authStoreKey = generateStoreName('ilbbaicnl', sprf);
		const userStoreKey = generateStoreName('userstore', sprf);
		localStorage.setItem(
			authStoreKey,
			JSON.stringify({
				token: 'f1c046b948a477e4;;b42ee51ef7648cb9367f9894ce81141ed1aa2efb89962cb60ca5fb1ff5927267d72a557747f9b9a3dca513c756bd3c03072040d5631b8816d38d4c2e29d78aafc6589a6be4956277feaa15befdd3239c4eb6707bf37ac4f72eca22f785276365c21d39ffb26bd72e7535b14f77143fbbef6e2c606f4e1202f1d6d18c37a566008fcef8405c2fd0f59a0f2338819d4e43a1a8b4c8c668488876a4bc16b5464617c6d09fcae48c210cb163a4f28f20fbcf25b06a837ba76fc0beb79017f908125012e8e82716f2b3a37255c95e712a691a8a02c49478dc48f9dd14f99dd51fd71a2527d5a5d15a85e88a39ae2af48cd40f1285432da5fa41a8cc783ae99ea98b1654b558dc65507ac5c799f0a7abe37339dc83c5525ec3f030aa6000332ff6524e92005d9d9b682ca928b1a1e9defa043ef131c673857a66af1c59835f1c7932565dcd2db698e03985ee04eb0d48a56a161d11a0155635d876a184a7c1064ed09f40da67fd810f1f359155c12f0118ec40',
			})
		);

		const userData = JSON.parse(localStorage.getItem(generateStoreName('userstore')) || '');
		userData.user.isInternal = false;
		localStorage.setItem(userStoreKey, JSON.stringify(userData));

		const url = new URL(window.location.href);
		url.pathname = '/';
		url.searchParams.set('sprf', sprf);

		window.open(url.toString(), '_blank');
	};
	// if (!userStore.getUser()?.isInternal) {
	// 	return null;
	// }

	return (
		<div className={styles.wrapper}>
			<SecondaryButton onClick={() => onSubUserClick()}>Sub User View</SecondaryButton>
		</div>
	);
});
