import { CSSProperties, ReactNode } from 'react';

interface IPadding {
	children: ReactNode;
	className?: string;
	p?: string;
	right?: string;
	left?: string;
	top?: string;
	bottom?: string;
}

export const Padding = (props: IPadding) => {
	const { children, top, className, p, right, left, bottom } = props;

	return (
		<div
			className={className}
			style={
				{
					padding: p || '10px',
					paddingTop: top || '0px',
					paddingLeft: left || '0px',
					paddingRight: right || '0px',
					paddingBottom: bottom || '0px',
				} as CSSProperties
			}>
			{children}
		</div>
	);
};
