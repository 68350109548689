import { JobModel } from '../../models/job.model';
import { JobsApi } from '../../apis/job.api';
import { makeAutoObservable } from 'mobx';
import { BaseListStore } from '@monorepo/controlled/src/stores/base-list.store';
import { JobCrudStore } from './job-crud.store';

export class JobStore {
	private crud = new JobCrudStore();
	private list = new BaseListStore<JobModel>({
		listFunc: JobsApi.list,
		model: JobModel,
	});

	constructor() {
		makeAutoObservable(this);
	}

	getCrud() {
		return this.crud;
	}

	getListStore() {
		return this.list;
	}
}
