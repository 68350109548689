import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import { useDates } from '@monorepo/controlled/src/hooks/use-dates';
import { BetweenPagesStateProps } from '@monorepo/tools/src/lib/interfaces/global';
import { useDidMount } from '@monorepo/tools/src/lib/hooks/utils/use-didmount';
import { IPerformancePayload, PerformanceModel } from '../../models/performance.model';
import { ISegment } from '@monorepo/base/src/components/segments/segments';
import { HttpStore } from '@monorepo/controlled/src/stores/http.store';
import { hash } from '@monorepo/tools/src/lib/tools/hash';
import { IQueryParams } from '@monorepo/tools/src/lib/interfaces/url';
import { useDebug } from '@monorepo/tools/src/lib/hooks/tools/use-debug';

export enum SideEffectKeys {
	DealId = 'deal_id',
	Id = 'id',
}

export enum Entities {
	Campagins = 'campaigns',
	Channels = 'channels',
	Chart = 'chart',
	Advertisers = 'advertisers',
	Accounts = 'accounts',
}

export enum PerformancesColumns {
	Domain = 'domain',
	Page = 'page',
	BidKeys = 'bid_key',
	CampaignId = 'campaign_id',
	CampaignName = 'campaign_name',
	CampaignGroupId = 'campaign_group_id',
	AdvertiserId = 'advertiser_id',
	AccountId = 'account_id',
	CreativeId = 'creative_id',
	CreativeGroupId = 'creative_group_id',
	TestId = 'test_id',
	Date = 'date',
	PublisherId = 'publisher_id',
	BrowserName = 'browser_name',
	OsName = 'os_name',
	Conversion = 'conversion',
	Geo = 'geo',
	State = 'state',
	City = 'city',
	AuctionType = 'auction_type',
	PreferredDealId = 'preferred_deal_id',
	CreativeSize = 'creative_size',
	TestGroupName = 'test_group_name',
	PreferredDeaName = 'preferred_deal_name',
	BidIsEditable = 'bid_is_editable',
	CreativeBrandName = 'creative_brand_name',
	CreativeGroupDesign = 'creative_group_design',
	StrategyId = 'strategy_id',
	CreativeImageUrl = 'creative_image_url',
	TestName = 'test_name',
	Bid = 'bid',
	Channel = 'channel',
}

interface IPerformanceOptionsPeriod {
	periodStart: string; // YYYY-MM-DD
	periodEnd: string; // YYYY-MM-DD
}

export enum IPerformanceOptionsFilterType {
	Include = 'INCLUDE',
	AutocompleteInclue = 'AUTOCOMPLETE_INCLUDE',
}

interface IPerformanceOptionsFilter {
	column: string;
	filterType: string;
	value: string[];
	inverted?: boolean;
}

enum IPerformanceOptionsDateSegments {
	Daily = 'DAILY',
	None = 'NONE',
}

export enum IPerformanceOptionsSortBy {
	Cost = 'cost',
	ROAS = 'roas',
	Date = 'date',
	Clicks = 'clicks',
	Conversions = 'conversions',
	Impressions = 'impressions',
	Revenue = 'revenue',
	CVR = 'cvr',
	CPA = 'cpa',
	CPC = 'cpc',
	CPM = 'cpm',
}

export type PerformanceOptionsSortByStrings = keyof typeof IPerformanceOptionsSortBy;

export interface IPerformanceOptions {
	entity?: string;
	// start?: number;
	periodStart?: string; // YYYY-MM-DD
	periodEnd?: string; // YYYY-MM-DD
	multipleTimePeriods?: IPerformanceOptionsPeriod[];
	groupBys?: (PerformancesColumns | null)[];
	filters?: IPerformanceOptionsFilter[];
	dateSegment?: IPerformanceOptionsDateSegments;
	sortBys?: [{ column: IPerformanceOptionsSortBy; order: 'asc' | 'desc' }];
	page?: number;
	limit?: number;
	offset?: number;
}

export const preparePerformanceRequest = (options: IPerformanceOptions): IPerformanceOptions => {
	const { entity, limit, periodStart, periodEnd, multipleTimePeriods, groupBys, filters, sortBys, page, offset } = options;

	const externalOptions: IPerformanceOptions = {
		entity,
		limit: limit || 100000,
		page, // not implemented in backend
		offset,
		periodStart,
		periodEnd,
		multipleTimePeriods: multipleTimePeriods || [],
		groupBys: (() => {
			if (!groupBys) {
				return [];
			}
			// Default is by date, if we add date it ruins the results so remove it for now until fixed in reporting service (probably never blat)
			return groupBys.filter((value, index, self) => self.indexOf(value) === index); // remove duplicates
		})(),
		filters: filters || [],
		sortBys: sortBys || [{ column: IPerformanceOptionsSortBy.Revenue, order: 'desc' }],
	};

	return externalOptions;
};

//TODO - add aggergateDataBySegment from use-merge-with-performance
export const useReportsList = <T,>(
	httpStore: HttpStore<IPerformanceOptions, IPerformancePayload>,
	options: IPerformanceOptions = {},
	hookOptions: BetweenPagesStateProps = {},
	segments?: Set<ISegment<T, PerformanceModel, PerformancesColumns>> // TODO remove after implementation
) => {
	const isBlockRequest = hookOptions.isBlockRequest || false;
	const location = useLocation();
	const isDebug = useDebug();
	const { startDate, endDate } = useDates();
	const primaryFromDate = dayjs(startDate).format('YYYY-MM-DD');
	const primaryToDate = dayjs(endDate).format('YYYY-MM-DD');
	const didMount = useDidMount(); // fetch data after did mount, solve problem of navigating between performance pages before fetch is resolved

	useEffect(() => {
		fetchReports();
		return () => {
			if (!isBlockRequest) {
				// in case of block request no request happend so no need to abort
				httpStore.abort();
			}
		};
	}, [location, primaryFromDate, primaryToDate, didMount, segments]); // options

	const fetchReports = () => {
		const { periodStart, periodEnd } = options;
		if (isBlockRequest || !didMount) {
			return;
		}

		const params = preparePerformanceRequest({
			...options,
			periodStart: periodStart || primaryFromDate,
			periodEnd: periodEnd || primaryToDate,
			groupBys: [
				...(options?.groupBys ? options.groupBys : []),
				...(segments ? Array.from(segments).map(s => s.performanceGroupBy) : []),
			],
		});

		const extraQueryParams: IQueryParams = {
			k: hash.makeId(6),
		};
		if (isDebug) {
			extraQueryParams.m = JSON.stringify(params);
		}

		httpStore.reset();
		httpStore.fetch(params, { queryParams: extraQueryParams });
		// TODO - need to add here abort in return func? like useMetricsGet
	};

	return { error: httpStore.getHttpError(), isLoading: httpStore.getIsLoading(), fetchReports };
};
