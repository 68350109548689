import { Fragment, ReactNode } from 'react';
import styles from './empty-state.module.scss';
import { getTheme } from '@monorepo/tools/src/lib/get-config';
import { Text } from '@monorepo/base/src/components/text/text';
import { Image } from '../img/img';
import { url } from '@monorepo/tools/src/lib/types/url';
import { Icon } from '../icon/icon';
import { SpacerY } from '../spacer/spacer';

interface IEmptyState {
	children: ReactNode;
	classes?: {
		wrapper?: string;
		title?: string;
		description?: string;
		image?: string;
	};
}

export const EmptyState = (props: IEmptyState) => {
	const { children, classes } = props;
	return <div className={classes?.wrapper || styles.wrapper}>{children}</div>;
};

interface IEmptyStateImage {
	source?: url;
	classes?: {
		image: string;
	};
}

interface IEmptyStateIcon {
	children: string | JSX.Element;
}

export const EmptyStateIcon = (props: IEmptyStateIcon) => {
	const { children } = props;
	return (
		<Fragment>
			<SpacerY y={0.5} />
			<Icon size={'40px'} isMFP={true} className={styles.icon}>
				{children}
			</Icon>
			<SpacerY y={0.5} />
		</Fragment>
	);
};

export const EmptyStateImage = (props: IEmptyStateImage) => {
	const { source, classes } = props;
	return <Image className={classes?.image || styles.image} source={source} />;
};

export const EmptyStateTitle = (props: IEmptyState) => {
	const { children, classes } = props;
	return (
		<Text className={classes?.title || styles.title} color={getTheme().titleColor}>
			{children}
		</Text>
	);
};

export const EmptyStateDescription = (props: IEmptyState) => {
	const { children } = props;
	return (
		<Text className={styles.desc} color={getTheme().subtitleColor}>
			{children}
		</Text>
	);
};

export const EmptyStateActions = (props: IEmptyState) => {
	const { children } = props;
	return <div className={styles.actions}>{children}</div>;
};
