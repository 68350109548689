import { IRequestOptions } from '@monorepo/tools/src/lib/interfaces/url';
import { observable, makeObservable, action, runInAction } from 'mobx';
import { ask } from '@monorepo/tools/src/lib/tools/ask/ask';
import { Constructable } from '@monorepo/tools/src/lib/interfaces/class';
import { isAbortError } from '@monorepo/tools/src/lib/tools/ask/guards';

// type Parameter<T> = T extends (arg: infer T) => any ? T : never;

// function call<F extends (arg: any) => any>(func: F, arg: Parameter<F>): ReturnType<F> {
// 	return func(arg);
// }

// function call<F extends (arg1?: A, arg2?: B) => void, A = void, B = void>(f: F, arg1?: A, arg2?: B) {
// 	return f(arg1, arg2);
// }

// const fn = (input: number): number => input * 2;
// const result = call(fn, 2); // Valid
// const result2 = call(fn, '2'); // Argument of type '"2"' is not assignable to parameter of type 'number'.(2345)

export interface IHttpStoreOptions<TRequestParams, TResponse> {
	httpFunc: (params: TRequestParams, options?: IRequestOptions) => Promise<TResponse>;
	model?: Constructable<TResponse>;
}

export class HttpStore<TRequestParams, TResponse> {
	isLoading = false;
	isSuccess = false;
	httpError: Error | null = null;
	isLocalCache = false;
	httpFunc: (params: TRequestParams, options?: IRequestOptions) => Promise<TResponse>;
	model?: Constructable<TResponse>;
	data: TResponse | null;
	abortController = new AbortController();
	isAborted = false;

	constructor(props: IHttpStoreOptions<TRequestParams, TResponse>) {
		const { httpFunc, model } = props;
		this.httpFunc = httpFunc;
		if (model) {
			this.model = model;
		}
		this.data = null;

		makeObservable(this, {
			isLoading: observable,
			isSuccess: observable,
			httpError: observable,
			data: observable,
			isLocalCache: observable,
			setIsLoading: action,
			setIsSuccess: action,
			setIsLocalCache: action,
			setHttpError: action,
			setData: action,
		});
	}

	fetch(params: TRequestParams, options?: IRequestOptions): Promise<TResponse | null> {
		if (this.getIsLocalCache()) {
			return Promise.resolve(this.getData());
		}

		this.setIsLocalCache(true);
		this.setIsLoading(true);

		this.abortController = new AbortController();

		ask.addSignal(this.abortController.signal);

		return this.httpFunc(params, options)
			.then(res => {
				const data = this.model ? (new this.model(res) as TResponse) : (res as TResponse);
				runInAction(() => {
					this.setIsLocalCache(false);
					this.setIsAborted(false);
					this.setData(data);
					this.setIsLoading(false);
					this.setIsSuccess(true);
				});
				return data;
			})
			.catch((error: Error) => {
				// TODO - log
				runInAction(() => {
					this.setHttpError(error);
					this.setIsLocalCache(false);
					this.setIsAborted(false);
					this.setIsSuccess(false);
					if (!isAbortError(error)) {
						// in case of abort error the user changed page, so the loading of the next page should be true
						this.setIsLoading(false);
					}
				});
				return null;
			});
	}

	public abort(reason?: string) {
		this.abortController.abort(reason || 'external abort');
		this.setIsLocalCache(false);
		this.setIsAborted(true);
	}

	public reset() {
		this.setData(null);
	}

	public getIsLoading(): boolean {
		return this.isLoading;
	}

	public setIsLoading(isLoading: boolean) {
		this.isLoading = isLoading;
	}

	public setIsSuccess(isSuccess: boolean) {
		this.isSuccess = isSuccess;
	}

	public getIsSuccess(): boolean {
		return this.isSuccess;
	}

	public setIsLocalCache(isLocalCache: boolean) {
		this.isLocalCache = isLocalCache;
	}

	public getIsLocalCache(): boolean {
		return this.isLocalCache;
	}

	public getHttpError(): Error | null {
		return this.httpError;
	}

	public setHttpError(httpError: Error | null) {
		this.httpError = httpError;
	}

	public setData(data: TResponse | null) {
		this.data = data;
	}

	public getData(): TResponse | null {
		return this.data;
	}

	public setIsAborted(isAborted: boolean) {
		this.isAborted = isAborted;
	}

	public getIsAborted(): boolean {
		return this.isAborted;
	}
}
