import { observer } from 'mobx-react';
import { Fragment, ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import routes from '../../routes/index.routes';
import { IMFPStore } from '../../stores';
import { ask, IRequestInterceptor } from '@monorepo/tools/src/lib/tools/ask/ask';

interface IRequireAuth {
	children: ReactNode;
}

/**
 * Redirect users to login page in case of user not authenticated
 */
export const RequireAuth = observer((props: IRequireAuth) => {
	const { children } = props;
	const { authStore, userStore } = useStores<IMFPStore>();
	const location = useLocation();
	const authToken = authStore.getToken();
	const user = userStore.getUser();

	const authInterceptor: IRequestInterceptor = {
		call: (url, requestOptions) => {
			if (authStore.getToken()) {
				requestOptions.headers = {
					Authorization: `Bearer ${authStore.getToken()}`,
					...requestOptions.headers,
				};
			}
		},
	};

	ask.interceptors.requests.set('Auth', authInterceptor);

	if (!authToken || !user?.id || !user?.isVerified() || !user.hasAccounts()) {
		userStore.removeUser();
		authStore.removeToken();
		// Redirect them to the /login page, but save the current location they were
		// trying to go to when they were redirected. This allows us to send them
		// along to that page after they login, which is a nicer user experience
		// than dropping them off on the home page.
		return <Navigate to={routes.auth.login()} state={{ from: location }} replace />;
	}

	return <Fragment>{children}</Fragment>; // The fragment is just for not typescript yelling
});
