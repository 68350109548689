import { observer } from 'mobx-react';
import { useNavigate, useParams } from 'react-router-dom';
import { ControlledDater } from '@monorepo/controlled/src/components/controlled-dater/controlled-dater';
import { Page, Pagebar, PagebarActions, PagebarTitle } from '@monorepo/base/src/components/page/page';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { IMFPStore } from '../../../../modules/stores';
import {
	Entities,
	IPerformanceOptionsFilterType,
	PerformancesColumns,
	useReportsList,
} from '../../../../modules/hooks/apis/use-reports-list';
import { ControlledTable } from '@monorepo/controlled/src/components/table-wrapper/table-wrapper';
import { useMetricsGet } from '../../../../modules/hooks/tools/use-metrics-get';
import { useMetrics } from '../../../../modules/hooks/tools/use-metrics';
import { useChannelHeaders } from '../../../../modules/hooks/theaders/channel.headers';
import { availablePerformanceToMetrics } from '../../../../modules/models/performance.model';
import routes from '../../../../modules/routes/index.routes';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';
import { ChannelFiltersStoreKeys } from '../../../../modules/stores/channels/channel.store';
import { useCampaignActions } from '../../../../modules/hooks/actions/campaign.actions';
import { EntityActionsMenu } from '../../../../modules/components/entity-actions-menu/entity-actions-menu';
import { useChannelActions } from '../../../../modules/hooks/actions/channel.actions';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { ManifestoChart } from '../../../../modules/components/chart/manifesto-chart';
import styles from './../../../../styles/pages.module.scss';

export const CampaignOverview = observer(() => {
	const { channelStore, campaignStore } = useStores<IMFPStore>();
	const { currentRouteWithoutSpecialChars } = useRoute();
	const reportsStore = channelStore.getReportsStore();
	const reportData = reportsStore.getData();
	const metricsStore = channelStore.getMetricsStore();
	const campaign = campaignStore.getCrud().getData(); // This campaign will be stored from breadcrumbs
	const { campaignId } = useParams();
	const navigate = useNavigate();

	if (!campaignId) {
		navigate(`${routes.campaigns.index()}${location.search}`, { replace: true });
		return null;
	}

	useReportsList(
		reportsStore,
		{
			entity: Entities.Channels,
			groupBys: [PerformancesColumns.Channel],
			filters: [
				{
					column: PerformancesColumns.CampaignId,
					filterType: IPerformanceOptionsFilterType.Include,
					value: [campaignId],
				},
			],
		},
		undefined,
		channelStore.getSegments()
	);

	useMetricsGet(metricsStore, {
		entity: Entities.Chart,
		groupBys: [PerformancesColumns.Date],
		filters: [
			{
				column: PerformancesColumns.CampaignId,
				filterType: IPerformanceOptionsFilterType.Include,
				value: [campaignId],
			},
		],
	});
	const { columns, columnVisibility } = useChannelHeaders({ summary: reportData?.getSummary() });
	const { metrics, xLabels } = useMetrics(metricsStore.getData());

	const { EntityActions } = useCampaignActions();
	const { FiltersActions, SegmentActions } = useChannelActions();
	const currentFilters = channelStore.getFiltersStore(currentRouteWithoutSpecialChars as ChannelFiltersStoreKeys)?.getCurrentFilters();

	return (
		<Page unstyled={true} className={styles.article}>
			<Pagebar classes={{ pagebar: styles.padding }}>
				<PagebarTitle>Overview</PagebarTitle>
				<PagebarActions>
					<ControlledDater />
					<EntityActionsMenu title={'Campaign Actions'}>
						<EntityActions entity={campaign} />
					</EntityActionsMenu>
				</PagebarActions>
			</Pagebar>
			<ManifestoChart
				isLoading={metricsStore.getIsLoading()}
				metrics={metrics}
				labels={xLabels}
				legendOptions={Object.keys(availablePerformanceToMetrics)}
			/>
			<ControlledTable
				totalRows={reportData?.getTotalRows() ? reportData?.getTotalRows()?.toLocaleString() : undefined}
				debugProps={{ dataAttrs: [new DataAttribute('id', 'overview_campaign_table')] }}
				classes={{ wrapper: styles.padding }}
				columns={columns}
				columnVisibility={columnVisibility}
				data={reportData?.getData() || []}
				isLoading={reportsStore.getIsLoading()}
				filters={currentFilters}
				isWithPerformance={true}
				isLoadingPerformance={reportsStore.getIsLoading()}
				defaultSortBy={[{ id: 'revenue', desc: true }]}>
				{() => {
					return {
						FiltersActions,
						SegmentActions,
					};
				}}
			</ControlledTable>
		</Page>
	);
});
