import { GButton, IGButtonInject } from '@monorepo/base/src/components/buttons/group/button/g-button';
import { Icon, IconFonts } from '@monorepo/base/src/components/icon/icon';
import { Radio } from '@monorepo/base/src/components/form/radio/radio';
import { getTheme } from '@monorepo/tools/src/lib/get-config';
import styles from './g-button.module.scss';

export interface IManifestoGButton {
	value: string;
	icon?: string | JSX.Element;
	title?: string;
	description?: string;
	mini?: boolean;
	multi?: boolean;
	badge?: string | JSX.Element;
	disabledGroup?: boolean;
	disabled?: boolean;
}
export const ManifestoGButton = (props: IManifestoGButton) => {
	// The rest is all props that come from ButtonGroup clone element
	const { value, title, description, icon, mini, multi, badge, disabled, disabledGroup, ...rest } = props;

	const ButtonContent = (gButtonProps: IGButtonInject) => {
		const { isActive } = gButtonProps;
		return (
			<div className={styles.wrapper}>
				<div className={styles.radio}>
					<Radio disabled={disabled || disabledGroup} iconColor={getTheme().primary700} multi={multi} isActive={isActive} />
				</div>
				{badge ? <div className={styles.badge}>{badge}</div> : null}
				{icon ? (
					<Icon
						isMFP={true}
						font={IconFonts.Outlined}
						className={`${styles.icon} ${disabled || disabledGroup ? styles.disabled : ''}`}>
						{icon}
					</Icon>
				) : null}
				<div className={styles.info}>
					<div className={`${styles.title} ${disabled || disabledGroup ? styles.disabled : ''}`}>{title}</div>
					<div className={`${styles.description} ${disabled || disabledGroup ? styles.disabled : ''}`}>{description}</div>
				</div>
			</div>
		);
	};

	return (
		<GButton
			disabled={disabled || disabledGroup}
			className={`${styles.gbutton} ${mini ? styles.mini : ''}`}
			value={value}
			multi={multi}
			{...rest}>
			{gbuttonProps => <ButtonContent {...gbuttonProps} />}
		</GButton>
	);
};
ManifestoGButton.displayName = 'GButton'; // for use in children.map loop
