import { IHead, IInjectHeadItem, TableOfContents } from '@monorepo/base/src/components/table-of-contents/table-of-contents';
import { ReactNode } from 'react';
import styles from './toc-item.module.scss';
import { Tooltip } from '@monorepo/base/src/components/tooltip/tooltip';

interface ITocItem extends IInjectHeadItem {
	title: string;
	description?: string;
	steps?: IHead[];
	isError?: boolean;
}

export const TocItem = (props: ITocItem) => {
	const { title, steps = [], description, isActive, onClick } = props;

	return (
		<div className={`${styles.itemWrapper} ${isActive ? styles.active : ''}`}>
			<div className={styles.line}></div>
			<div className={styles.circle}>
				<div className={styles.dot}></div>
			</div>
			<div className={styles.info}>
				<div onClick={e => onClick(e)} className={styles.title}>
					{title}
				</div>
				{description ? <div className={styles.desc}>{description}</div> : null}
				<TableOfContents heads={steps} offset={50} />
			</div>
		</div>
	);
};

interface ISubTocItem extends IInjectHeadItem {
	children: ReactNode;
	isError?: boolean;
	isDisabled?: boolean;
}

export const SubTocItem = (props: ISubTocItem) => {
	const { children, isError, isDisabled } = props;

	return (
		<Tooltip content={'This property can not be changed while a campaign has been published'} is={Boolean(isDisabled)}>
			<div className={`${styles.subWrapper} ${isError ? styles.error : ''} ${isDisabled ? styles.disabled : ''}`}>
				{isError ? <div className={styles.errorCircle}></div> : null}
				<span className={styles.label}>{children}</span>
			</div>
		</Tooltip>
	);
};
