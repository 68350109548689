import { PrimaryLink } from '@monorepo/base/src/components/buttons/buttons';
import { Fragment, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Image } from '@monorepo/base/src/components/img/img';
import styles from './verified-user.module.scss';
import { Navigate, useLocation } from 'react-router-dom';
import routes from '../../modules/routes/index.routes';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { IMFPStore } from '../../modules/stores';
import { useSearchParams } from 'react-router-dom';
import { ManifestoLogoSVG } from '../../modules/components/logo/logo';
import { Flex } from '@monorepo/base/src/components/flex/flex';
import { useDidMount } from '@monorepo/tools/src/lib/hooks/utils/use-didmount';
import { HttpHeaders } from '@monorepo/tools/src/lib/enums/headers';
import { UserModel } from '../../modules/models/user.model';
import { capitalCase } from 'change-case';
import { Spinner } from '@monorepo/base/src/components/spinner/spinner';
import { FullResponse } from '@monorepo/tools/src/lib/interfaces/url';
import { IUserRes } from '../../modules/stores/users/user-crud.store';

export const VerifiedUser = observer(() => {
	const { userStore, authStore } = useStores<IMFPStore>();
	const location = useLocation();
	const [searchParams] = useSearchParams();
	const userStoreCrud = userStore.getCrud();
	const isLoading = userStoreCrud.getIsLoading();
	const isSuccess = userStoreCrud.getIsSuccess();
	const error = userStoreCrud.getHttpError();
	const user = userStore.getUser();
	const isDidMount = useDidMount();
	const verifyTokenParam = searchParams.get('token');

	useEffect(() => {
		const verifyUser = async () => {
			if (!verifyTokenParam) {
				return;
			}
			const verifyProps = await userStore.getCrud().verify(verifyTokenParam);
			const { body, headers } = verifyProps as FullResponse<IUserRes>;
			if (!body?.payload?.user) {
				return;
			}
			const userAuthToken = headers.find(h => h[0] === HttpHeaders.USER_TOKEN)?.[1];
			if (!userAuthToken) {
				return;
			}
			const user = new UserModel(body?.payload?.user);
			userStore.setUser(user);
			authStore.setToken(userAuthToken);
		};
		if (!isDidMount) {
			return;
		}

		if (!verifyTokenParam) {
			return;
		}

		userStore.removeUser();
		authStore.removeToken();
		verifyUser();
	}, [isDidMount]);

	if (!verifyTokenParam) {
		userStore.removeUser();
		authStore.removeToken();
		return <Navigate to={`${routes.auth.login()}${location.search}`} state={{ from: location }} replace />;
	}

	const renderError = () => {
		return (
			<Fragment>
				<div className={styles.imageWrapper}>
					<Image className={styles.img} source={'/assets/verify-email.png'} alt={'email photo'} />
				</div>
				<div className={styles.welcome}>Something went wrong</div>
				<div className={styles.text}>Please try again later</div>
				<div className={styles.actions}>
					<PrimaryLink to={routes.auth.login()} className={styles.btn}>
						Login
					</PrimaryLink>
				</div>
			</Fragment>
		);
	};

	const renderContent = () => {
		if (!isSuccess && !isLoading && error) {
			return renderError();
		}
		return (
			<Fragment>
				<div className={styles.imageWrapper}>
					<Image className={styles.img} source={'/assets/verify-email.png'} alt={'email photo'} />
				</div>
				<div className={styles.heading}>Hello {capitalCase(user?.getFirstName() || '')}</div>
				<div className={styles.welcome}>Welcome To Manifesto Platfrom</div>
				<div className={styles.text}>Thank you for choosing our platform</div>
				<div className={styles.text}>We look forward to helping you achieve your goals!</div>
				<div className={styles.actions}>
					<PrimaryLink to={user?.hasAccounts() ? routes.general.home() : routes.accounts.createAccount()} className={styles.btn}>
						Get Started
					</PrimaryLink>
				</div>
			</Fragment>
		);
	};

	return (
		<div className={styles.wrapper}>
			<ManifestoLogoSVG width={'173'} height={'36'} />
			<div className={styles.contentWrapper}>
				{isSuccess || error ? (
					renderContent()
				) : (
					<Flex flexDirection={'column'}>
						<Spinner size={'30px'} />
					</Flex>
				)}
			</div>
		</div>
	);
});
