import { ISegment } from '@monorepo/base/src/components/segments/segments';
import { FilterStore } from '@monorepo/controlled/src/stores/filter.store';
import { HttpStore } from '@monorepo/controlled/src/stores/http.store';
import { action, makeAutoObservable, observable } from 'mobx';
import { ChannelsApi } from '../../apis/channels.api';
import { filterPrototypesByProp } from '../../components/filters';
import { IPerformanceOptions, PerformancesColumns } from '../../hooks/apis/use-reports-list';
import { ChannelModel } from '../../models/channel.model';
import { PerformancePayload, PerformanceModel } from '../../models/performance.model';

// every enum here is the path withtout / and ids
// for example, advertiser/1/ will transform to ChannelFiltersStoreKeys.Advertiser
export enum ChannelFiltersStoreKeys {
	Campaign = 'campaignschannels',
	Advertiser = 'advertiserschannels',
	Account = 'accountschannels',
}

export class ChannelStore {
	// list = new BaseListStore<ChannelModel>({
	// 	listFunc: ChannelsApi.list,
	// 	model: ChannelModel,
	// });
	reports = new HttpStore<IPerformanceOptions, PerformancePayload>({
		httpFunc: ChannelsApi.reports,
		model: PerformancePayload,
	});
	metrics = new HttpStore<IPerformanceOptions, PerformancePayload>({
		httpFunc: ChannelsApi.metrics,
		model: PerformancePayload,
	});

	insights = new HttpStore<IPerformanceOptions, PerformancePayload>({
		httpFunc: ChannelsApi.insights,
		model: PerformancePayload,
	});

	filters: Map<string, FilterStore>;

	segments: Set<ISegment<ChannelModel, PerformanceModel, PerformancesColumns>>;

	constructor() {
		makeAutoObservable(this, {
			// list: observable,
			filters: observable,
			metrics: observable,
			insights: observable,
			segments: observable,
			setSegments: action.bound, // https://mobx.js.org/actions.html#actionbound prevents cannot set property of undefined
		});
		this.segments = new Set<ISegment<ChannelModel, PerformanceModel, PerformancesColumns>>([]);
		this.filters = this.createDefaultFilters();
	}

	createDefaultFilters() {
		const campaignChannelsPageFilterStore = new FilterStore({
			key: ChannelFiltersStoreKeys.Campaign,
			currentFilters: undefined,
			filterPrototypesByProp,
		});

		const advertiserChannelsPageFilterStore = new FilterStore({
			key: ChannelFiltersStoreKeys.Advertiser,
			currentFilters: undefined,
			filterPrototypesByProp,
		});

		const accountChannelsPageFilterStore = new FilterStore({
			key: ChannelFiltersStoreKeys.Account,
			currentFilters: undefined,
			filterPrototypesByProp,
		});

		return new Map([
			[ChannelFiltersStoreKeys.Campaign, campaignChannelsPageFilterStore],
			[ChannelFiltersStoreKeys.Advertiser, advertiserChannelsPageFilterStore],
			[ChannelFiltersStoreKeys.Account, accountChannelsPageFilterStore],
		]);
	}

	getFiltersStore(key: ChannelFiltersStoreKeys) {
		return this.filters.get(key);
	}

	// getListStore() {
	// 	return this.list;
	// }

	getReportsStore() {
		return this.reports;
	}

	getMetricsStore() {
		return this.metrics;
	}
	getInsightsStore() {
		return this.insights;
	}

	setSegments(segments: Set<ISegment<ChannelModel, PerformanceModel, PerformancesColumns>>): void {
		this.segments = segments;
	}

	getSegments(): Set<ISegment<ChannelModel, PerformanceModel, PerformancesColumns>> {
		return this.segments;
	}
}
