export enum StringActions {
	Contains = 'contains',
	DoesNotContain = 'does not contain',
	BeginsWith = 'begins with',
	EndsWith = 'ends with',
	Equals = 'equals',
	DoesNotEqual = 'does not equal',
}
export type stringAction =
	| StringActions.Contains
	| StringActions.DoesNotContain
	| StringActions.BeginsWith
	| StringActions.EndsWith
	| StringActions.DoesNotEqual;

export const toLowerCaseAndTrim = (word: string | undefined) => {
	if (!word) {
		return '';
	}

	// TODO
	word = word.replaceAll('-', '').replace(/\s+/g, '-').toLowerCase().trim();
	return word;
};

export const randomString = (length: number) => {
	let result = '';
	const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	const charactersLength = characters.length;
	for (let i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
};

export const getFirstStringUrl = () => {
	return window.location.pathname.replace(/^\/([^/]*).*$/, '$1');
};
export const validateEmail = (email: string): boolean => {
	const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	return regex.test(email);
};

export const passwordValidation = (password: string) => {
	return {
		lengthError: password.length < 8,
		integerError: !/[0-9]/.test(password),
		letterCaseError: !/[a-z]/.test(password) || !/[A-Z]/.test(password),
	};
};

export const stringFuncs = {
	[StringActions.Contains]: (string: string, searchValue: string) => string.toLowerCase().includes(searchValue.toLowerCase()),
	[StringActions.BeginsWith]: (string: string, searchValue: string) => string.toLowerCase().startsWith(searchValue.toLowerCase()),
	[StringActions.DoesNotContain]: (string: string, searchValue: string) => !string.toLowerCase().includes(searchValue.toLowerCase()),
	[StringActions.DoesNotEqual]: (string: string, searchValue: string) => string.toLowerCase() !== searchValue.toLowerCase(),
	[StringActions.EndsWith]: (string: string, searchValue: string) => string.toLowerCase().endsWith(searchValue.toLowerCase()),
	[StringActions.Equals]: (string: string, searchValue: string) => string.toLowerCase() === searchValue.toLowerCase(),
};
