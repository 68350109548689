import { Fragment } from 'react';
import { id } from '@monorepo/tools/src/lib/types/primitives';

type IToggleAccountStatuses = {
	accountIds: id[];
	action: string;
};
export const EnablePauseAccountsModal = (props: IToggleAccountStatuses) => {
	const { accountIds, action } = props;
	return (
		<Fragment>
			<div>{action} the following accounts:</div>
			<span style={{ color: '#4f46e5' }}>
				{accountIds.length < 6
					? accountIds.map((accountId, index) => `${index !== accountIds.length - 1 ? accountId + ', ' : accountId + '.'}`)
					: `${accountIds.length} accounts`}
			</span>
		</Fragment>
	);
};
