import { makePersistable } from 'mobx-persist-store';
import { UserModel } from '../models/user.model';
import { makeAutoObservable } from 'mobx';
import { generateStoreName } from '@monorepo/controlled/src/stores/helper';

export class UserStore {
	user?: UserModel;

	constructor() {
		makeAutoObservable(this);
		// TODO - save it in somewhere else
		makePersistable(this, {
			name: generateStoreName('userstore'),
			properties: ['user'],
			storage: {
				setItem: (key, data) => window.localStorage.setItem(key, data),
				removeItem: key => window.localStorage.removeItem(key),
				getItem: key => {
					const data = JSON.parse(window.localStorage.getItem(key) || '{}');

					return {
						...data,
						user: data.user ? new UserModel(data.user) : undefined,
					};
				},
			},
		});
	}

	setUser(user: UserModel) {
		this.user = user;
	}

	getUser(): UserModel | undefined {
		return this.user;
	}

	removeUser() {
		this.user = undefined;
	}
}
