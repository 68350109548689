import { Fragment, ReactElement, useEffect } from 'react';
import { IControlledStore, useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { IToastItem } from '../../../stores/toasts.store';
import { Icon } from '@monorepo/base/src/components/icon/icon';
import styles from './toast.module.scss';

// TODO - should be in base?
export const Toast = (props: IToastItem) => {
	const { children, onClose = null, type = 'success', msg = '', id = 0 } = props;
	const { toastsStore } = useStores<IControlledStore>();

	const closeToast = () => {
		toastsStore.removeToast(id);
		if (onClose) {
			onClose();
		}
	};

	useEffect(() => {
		const timer = setTimeout(() => {
			closeToast();
		}, 10000);
		return () => {
			clearTimeout(timer);
		};
	}, [id]);

	const iconType: { [key: string]: ReactElement } = {
		success: (
			<Icon isMFP={true} color={'#0e7b02'}>
				check
			</Icon>
		),
		info: (
			<Icon isMFP={true} color={'#003378'}>
				info-circle
			</Icon>
		),
		error: (
			<Icon isMFP={true} color={'#cb0000'}>
				alert-circle
			</Icon>
		),
	};

	const msgsType: { [key: string]: string } = {
		success: 'Success!',
		info: 'Info',
		error: 'Something went wrong',
	};

	return (
		<div className={`${styles.content} ${styles}`}>
			<div className={styles.type}>{iconType[type]}</div>
			<div className={styles.msg}>
				{msg ? (
					<Fragment>
						<div>{msgsType[type]}</div>
						<div>{msg}</div>{' '}
					</Fragment>
				) : (
					children
				)}
			</div>
			<button className={styles.close} onClick={closeToast}>
				<Icon isMFP={true}>x-close</Icon>
			</button>
		</div>
	);
};
