import { StatusEnum } from './../../enums/status';
import { ISegment } from '@monorepo/base/src/components/segments/segments';
import { FilterModel } from '@monorepo/controlled/src/models/filter.model';
import { BaseListStore } from '@monorepo/controlled/src/stores/base-list.store';
import { FilterStore } from '@monorepo/controlled/src/stores/filter.store';
import { HttpStore } from '@monorepo/controlled/src/stores/http.store';
import { capitalCase } from 'change-case';
import { action, makeAutoObservable, observable } from 'mobx';
import { CampaignsApi } from '../../apis/campaigns.api';
import { filterPrototypesByProp, StatusFilter } from '../../components/filters';
import { IPerformanceOptions, PerformancesColumns } from '../../hooks/apis/use-reports-list';
import { CampaignModel } from '../../models/campaign.model';
import { PerformancePayload, PerformanceModel } from '../../models/performance.model';
import { CampaignCrudStore } from './campaign-crud.store';

// every enum here is the path withtout / and ids
// for example, advertiser/1/campaigns will transform to CampaignFiltersStoreKeys.Advertiser
export enum CampaignFiltersStoreKeys {
	Campaigns = 'campaigns',
	Advertiser = 'advertiserscampaigns',
	Account = 'accountscampaigns',
}

export class CampaignStore {
	crud = new CampaignCrudStore();
	list = new BaseListStore<CampaignModel>({
		listFunc: CampaignsApi.list,
		model: CampaignModel,
	});
	reports = new HttpStore<IPerformanceOptions, PerformancePayload>({
		httpFunc: CampaignsApi.reports,
		model: PerformancePayload,
	});
	metrics = new HttpStore<IPerformanceOptions, PerformancePayload>({
		httpFunc: CampaignsApi.metrics,
		model: PerformancePayload,
	});

	insights = new HttpStore<IPerformanceOptions, PerformancePayload>({
		httpFunc: CampaignsApi.insights,
		model: PerformancePayload,
	});

	filters: Map<string, FilterStore>;

	segments: Set<ISegment<CampaignModel, PerformanceModel, PerformancesColumns>>;

	constructor() {
		makeAutoObservable(this, {
			crud: observable,
			list: observable,
			filters: observable,
			metrics: observable,
			insights: observable,
			segments: observable,
			setSegments: action.bound, // https://mobx.js.org/actions.html#actionbound prevents cannot set property of undefined
		});
		this.segments = new Set<ISegment<CampaignModel, PerformanceModel, PerformancesColumns>>([]);
		this.filters = this.createDefaultFilters();
	}

	createDefaultFilters() {
		// set filters for all campaigns pages
		const defaultFilters = new Map([
			[
				1,
				new FilterModel({
					index: 1,
					label: `Status is ${capitalCase(StatusEnum.ACTIVE)}`,
					value: StatusEnum.ACTIVE,
					prototype: StatusFilter,
				}),
			],
		]);
		const campaignsPageFilterStore = new FilterStore({
			key: CampaignFiltersStoreKeys.Campaigns,
			currentFilters: defaultFilters,
			filterPrototypesByProp,
		});
		const campaignsAdvertiserPageFilterStore = new FilterStore({
			key: CampaignFiltersStoreKeys.Advertiser,
			currentFilters: defaultFilters,
			filterPrototypesByProp,
		});
		const campaignsAccountPageFilterStore = new FilterStore({
			key: CampaignFiltersStoreKeys.Account,
			currentFilters: defaultFilters,
			filterPrototypesByProp,
		});

		return new Map([
			[CampaignFiltersStoreKeys.Campaigns, campaignsPageFilterStore],
			[CampaignFiltersStoreKeys.Advertiser, campaignsAdvertiserPageFilterStore],
			[CampaignFiltersStoreKeys.Account, campaignsAccountPageFilterStore],
		]);
		//
	}

	getFiltersStore(key: CampaignFiltersStoreKeys) {
		return this.filters.get(key);
	}

	getCrud() {
		return this.crud;
	}

	getListStore() {
		return this.list;
	}

	getReportsStore() {
		return this.reports;
	}

	getMetricsStore() {
		return this.metrics;
	}
	getInsightsStore() {
		return this.insights;
	}

	setSegments(segments: Set<ISegment<CampaignModel, PerformanceModel, PerformancesColumns>>): void {
		this.segments = segments;
	}

	getSegments(): Set<ISegment<CampaignModel, PerformanceModel, PerformancesColumns>> {
		return this.segments;
	}
}
