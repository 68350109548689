import { url } from '@monorepo/tools/src/lib/types/url';
import { email } from '@monorepo/tools/src/lib/types/email';
import { makeAutoObservable } from 'mobx';
import { UserStatusEnum } from '../enums/user.enum';
import { capitalCase } from 'change-case';

export interface IUser {
	id?: string;
	firstName?: string;
	lastName?: string;
	company?: string;
	picture?: string;
	email?: string;
	isInternal?: boolean;
	password?: string;
	status?: UserStatusEnum;
	rememberMe?: boolean;
}

export interface IUserCreateForm {
	id?: string;
	firstName?: string;
	lastName?: string;
	email?: string;
	password?: string;
	company?: string;
}

export interface IUserEditForm {
	id?: string;
	firstName?: string;
	lastName?: string;
	email?: string;
	password?: string;
	company?: string;
}

export class UserModel implements IUser {
	id?: string;
	firstName?: string;
	lastName?: string;
	company?: string;
	picture?: url;
	email?: email;
	isInternal?: boolean;
	password?: string;
	status?: UserStatusEnum;
	rememberMe?: boolean;

	constructor(user?: IUser);
	constructor(user: IUser) {
		this.id = user?.id;
		this.firstName = capitalCase(user?.firstName || '');
		this.lastName = capitalCase(user?.lastName || '');
		this.company = user?.company;
		this.picture = user?.picture;
		this.email = user?.email;
		this.isInternal = Boolean(user?.isInternal);
		this.status = user?.status;
		this.rememberMe = user?.rememberMe;

		makeAutoObservable(this);
	}

	getId(): string | undefined {
		return this.id;
	}

	setEmail(email: email | undefined): void {
		this.email = email;
	}

	getEmail(): email | undefined {
		return this.email;
	}

	getFullName(): string | undefined {
		if (!this.lastName) {
			return this.firstName;
		} else if (!this.firstName) {
			return this.lastName;
		}
		return `${this.firstName} ${this.lastName}`;
	}

	getCompany(): string | undefined {
		return this.company;
	}

	setCompany(company: string): void {
		this.company = company;
	}

	getPassword(): string | undefined {
		return this.password;
	}

	setPassword(password: string): void {
		this.password = password;
	}

	setFirstName(firstName: string): void {
		this.firstName = firstName;
	}

	getFirstName(): string | undefined {
		return this.firstName;
	}

	setLastName(lastName: string): void {
		this.lastName = lastName;
	}

	getLastName(): string | undefined {
		return this.lastName;
	}

	setStatus(status: UserStatusEnum): void {
		this.status = status;
	}

	getStatus(): UserStatusEnum | undefined {
		return this.status;
	}

	getPicture(): url | undefined {
		return this.picture;
	}

	hasAccounts(): boolean {
		return !this.email?.includes('+1');
	}

	isVerified(): boolean {
		return this.status === UserStatusEnum.ENABLED;
	}

	setRememberMe(rememberMe: boolean) {
		this.rememberMe = rememberMe;
	}

	getRememberMe(): boolean | undefined {
		return this.rememberMe;
	}
}
