import { createColumnHelper } from '@tanstack/react-table';
import { ChannelModel, IChannel } from '../../models/channel.model';
import { IEntityHeaders } from './index.headers';
import { IMergedHeadersReturn } from './index.headers';
import { useWithPerformanceHeaders } from './with-performance.headers';

const columnHelper = createColumnHelper<IChannel>();

export const useChannelHeaders = (props: IEntityHeaders = {}): IMergedHeadersReturn<ChannelModel> => {
	const { summary } = props;
	const mergedPerforamnceHeaders = useWithPerformanceHeaders({ summaryItem: summary }).columns;

	return {
		columns: [
			//TODO remove empty_column_layout it's an ugly and quick solution... fack eat
			columnHelper.accessor('empty_column_layout', {
				header: '',
			}),
			columnHelper.accessor('channel', {
				header: 'Channel',
			}),
			columnHelper.accessor('id', {
				header: 'ID',
			}),
			...mergedPerforamnceHeaders,
		],
		columnVisibility: { impressions: false, cpm: false, cvr: false, id: false },
	};
};
