import { PrimaryLink } from '@monorepo/base/src/components/buttons/buttons';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import {
	EmptyState,
	EmptyStateActions,
	EmptyStateDescription,
	EmptyStateImage,
	EmptyStateTitle,
} from '@monorepo/base/src/components/empty-state/empty-state';
import { Padding } from '@monorepo/base/src/components/padding/padding';

export const EmptyCampaignsState = ({ to }: { to: string }) => {
	return (
		<Padding p={'40px'}>
			<EmptyState>
				<EmptyStateImage source={'campaign-empty-state.png'} />
				<EmptyStateTitle>No Campaigns yet</EmptyStateTitle>
				<EmptyStateDescription>
					Nothing to see here! take the first step towards success and create a campaign
				</EmptyStateDescription>
				<EmptyStateActions>
					<PrimaryLink
						icon={'add'}
						debugProps={{ dataAttrs: [new DataAttribute('id', 'create_campaign_cta')] }}
						to={to}
						width={'min-content'}>
						Create your first campaign now!
					</PrimaryLink>
				</EmptyStateActions>
			</EmptyState>
		</Padding>
	);
};
