import { Strategies } from '../../enums/strategies';
import { ChannelModel } from '../../models/channel.model';
import { mapToObject } from '@monorepo/tools/src/lib/utils/map';
import { CampaignsApi } from '../../apis/campaigns.api';
import { runInAction } from 'mobx';
import { BaseCrudStore } from '@monorepo/controlled/src/stores/base-crud.store';
import { FormError } from '@monorepo/tools/src/lib/models/form-error.model';
import { HttpError, IHttpError } from '@monorepo/tools/src/lib/models/http-error.model';
import { CampaignModel, ICampaignCreateForm, ICampaignEditForm } from '../../models/campaign.model';
import { setToArray } from '@monorepo/tools/src/lib/utils/set';
import { ChannelsEnums } from '../../models/channel.model';
import { isValidUrl } from '@monorepo/tools/src/lib/utils/url';
import dayjs from 'dayjs';
import { CampaignDomIds } from '../../hooks/toc/campaign.toc';
import { id } from '@monorepo/tools/src/lib/types/primitives';

export class CampaignCrudStore extends BaseCrudStore<CampaignModel, ICampaignCreateForm, ICampaignEditForm> {
	constructor() {
		super({
			apiLayer: CampaignsApi,
			model: CampaignModel,
		});

		// makePersistable(this, {
		// 	name: generateStoreName('mfp-cc-p'),
		// 	properties: [
		// 		{
		// 			key: 'data',
		// 			serialize: () => {
		// 				this.setIsSaved(false);
		// 				setTimeout(() => {
		// 					this.setIsSaved(true);
		// 				}, 500);
		// 				const createData = this.getCreateFormData();
		// 				createData.landingPage = createData.landingPage?.replace('https://', '');
		// 				return createData;
		// 			},
		// 			deserialize: value => {
		// 				if (value?.strategy?.channels) {
		// 					// In case of AdvancedStrategyModel, we need to deserialize the object to map
		// 					const channels = objectToMap(value.strategy.channels) as Map<ChannelsEnums, ChannelModel>;
		// 					value.strategy.channels = new Map();
		// 					channels.forEach(channel => {
		// 						if (channel.name) {
		// 							value.strategy.channels.set(channel.name, new ChannelModel(channel));
		// 						}
		// 					});
		// 				}
		// 				this.setData(this.createInstance(value));
		// 				return this.getData();
		// 			},
		// 		},
		// 	],
		// 	storage: window.localStorage,
		// });
	}

	createInstance(value = {}) {
		return new CampaignModel(value);
	}

	saveDraft() {
		this.formStore.reset();

		// Draft validation
		const campaign = this.getData();

		const campaignName = campaign.getName();
		if (!campaignName) {
			this.formStore.addError(new FormError(CampaignDomIds.Name, 'Please provide a campaign name'));
		}
		const isValid = this.formStore.getIsValid();
		//

		this.setHttpError(null);
		if (!isValid && !this.isLoading) {
			return Promise.resolve(null);
		}
		this.setIsLoading(true);
		this.setIsEdited(false);
		return CampaignsApi.saveDraft(this.getEditFormData())
			.then(response => {
				const data = new this.model(response);
				runInAction(() => {
					this.setData(data);
					this.setIsEdited(true);
					this.setIsLoading(false);
					this.setIsSuccess(true);
				});
				return data;
			})
			.catch((error: IHttpError) => {
				// TODO - log
				runInAction(() => {
					this.setHttpError(new HttpError(error));
					this.setIsEdited(true);
					this.setIsLoading(false);
					this.setIsSuccess(true);
				});
				return error;
			});
	}

	enableCampaigns(campaignsIds: id[]): Promise<void> {
		return CampaignsApi.enableCampaigns(campaignsIds)
			.then(() => {
				this.setIsLoading(false);
				this.setIsSuccess(true);
			})
			.catch(() => {
				this.setIsLoading(false);
				this.setIsSuccess(false);
			});
	}

	pauseCampaigns(campaignsIds: id[]): Promise<void> {
		return CampaignsApi.pauseCampaigns(campaignsIds)
			.then(() => {
				this.setIsLoading(false);
				this.setIsSuccess(true);
			})
			.catch(() => {
				this.setIsLoading(false);
				this.setIsSuccess(false);
			});
	}

	/**
	 * Must call isValidCampaign before calling this function
	 * @returns
	 */
	public getCreateFormData(): ICampaignCreateForm {
		const campaign = this.getData();
		const devices = campaign.getDevices();
		// const languages = campaign.getLanguages();
		const advertiser = campaign.getAdvertiser();
		const advertiserId = advertiser?.getId();
		const advertiserName = advertiser?.getName();
		const geo = campaign.getGeo();
		const channels = campaign.getChannels();
		const endTime = campaign.getEndTime();

		const conversions = campaign.getConversionActions()?.filter(c => c.id !== null || c.name === null);

		return {
			name: campaign.getName(),
			landingPage: this.replaceToAts(campaign.getLandingPage() || '') || '',
			advertiser: advertiserId ? { id: advertiserId, name: advertiserName } : undefined,
			startTime: dayjs(campaign.getStartTime()).format('YYYY-MM-DD HH:MM:ss'),
			endTime: endTime ? dayjs(campaign.getEndTime()).format('YYYY-MM-DD HH:MM:ss') : undefined,
			devices: devices ? setToArray(devices) : [],
			geo,
			// languages: languages ? setToArray(languages) : [],
			totalBudget: campaign.getTotalBudget(),
			strategyType: campaign.getStrategyType(),
			channelsByName: channels ? mapToObject<ChannelsEnums, ChannelModel>(channels) : null,
			dailyBudget: campaign.dailyBudget,
			optimizationMode: campaign.optimizationMode,
			defaultBid: campaign.defaultBid,
			conversionActions: conversions?.length ? conversions : undefined,
			isDeepLink: campaign.getIsDeepLink(),
		};
	}

	/**
	 * Must call isValidCampaign before calling this function
	 * @returns
	 */
	public getEditFormData(): ICampaignEditForm {
		const campaign = this.getData();
		const devices = campaign.getDevices();
		// const languages = campaign.getLanguages();
		const advertiser = campaign.getAdvertiser();
		const advertiserId = advertiser?.getId();
		const advertiserName = advertiser?.getName();
		const geo = campaign.getGeo();
		const channels = campaign.getChannels();
		const endTime = campaign.getEndTime();
		const conversions = campaign.getConversionActions()?.filter(c => c.id !== null);

		return {
			id: campaign.getId(),
			name: campaign.getName(),
			status: campaign.getStatus(),
			landingPage: this.replaceToAts(campaign.getLandingPage() || '') || '',
			advertiser: advertiserId ? { id: advertiserId, name: advertiserName } : undefined,
			startTime: dayjs(campaign.getStartTime()).format('YYYY-MM-DD HH:MM:ss'),
			endTime: endTime ? dayjs(campaign.getEndTime()).format('YYYY-MM-DD HH:MM:ss') : undefined,
			devices: devices ? setToArray(devices) : [],
			geo,
			// languages: languages ? setToArray(languages) : [],
			totalBudget: campaign.getTotalBudget(),
			strategyType: campaign.getStrategyType(),
			channelsByName: channels ? mapToObject<ChannelsEnums, ChannelModel>(channels) : null,
			dailyBudget: campaign.dailyBudget,
			optimizationMode: campaign.optimizationMode,
			defaultBid: campaign.defaultBid,
			conversionActions: conversions?.length ? conversions : undefined,
			isDeepLink: campaign.getIsDeepLink(),
		};
	}

	public isValid(): boolean {
		this.formStore.reset();
		const campaign = this.getData();

		const campaignName = campaign.getName();
		if (!campaignName) {
			this.formStore.addError(new FormError(CampaignDomIds.Name, 'Please provide a campaign name'));
		}

		if (campaignName && campaignName?.length > 65) {
			this.formStore.addError(new FormError(CampaignDomIds.Name, 'Campaign name must be lower then 65 characters'));
		}

		const advertiser = campaign.getAdvertiser();
		if (!advertiser || !advertiser?.id) {
			this.formStore.addError(new FormError(CampaignDomIds.Advertiser, 'Please provide an advertiser'));
		}

		const landingPageUrl = campaign.getLandingPage();
		if (!landingPageUrl) {
			this.formStore.addError(new FormError(CampaignDomIds.LandingPage, 'Please provide a landing page url'));
		}
		if (landingPageUrl && !isValidUrl(landingPageUrl)) {
			this.formStore.addError(new FormError(CampaignDomIds.LandingPage, 'Please provide a valid url'));
		}

		const startDate = campaign.getStartTime();
		if (!startDate) {
			this.formStore.addError(new FormError(CampaignDomIds.Dates, 'Please provide start date'));
		}

		const devices = campaign.getDevices();
		if (!devices || devices.size === 0) {
			this.formStore.addError(new FormError(CampaignDomIds.Devices, 'Please provide at least one device type'));
		}

		const geo = campaign.getGeo();
		if (!geo) {
			this.formStore.addError(new FormError(CampaignDomIds.Geos, 'Please provide country'));
		}

		// const languages = campaign.getLanguages();
		// if (!languages) {
		// 	this.formStore.addError(new FormError(CampaignDomIds.BrowserLanguages, 'Please provide at least one browser language'));
		// }

		if (campaign.strategyType === Strategies.Basic) {
			const defaultBid = campaign.getDefaultBid();
			if (!defaultBid) {
				this.formStore.addError(new FormError(CampaignDomIds.BasicDefaultBid, 'Please provide default bid'));
			}

			if (campaign?.dailyBudget && campaign?.totalBudget && campaign?.dailyBudget > campaign?.totalBudget) {
				this.formStore.addError(new FormError(CampaignDomIds.TotalBudget, 'Total budget must be bigger then daily budget'));
			}
		}

		if (campaign.strategyType === Strategies.Advanced) {
			if (campaign.getChannels()?.size === 0) {
				this.formStore.addError(new FormError(CampaignDomIds.Channels, 'Please add at least one channel'));
			}
			let sumChannelsDailyBudgets = 0;
			campaign.getChannels()?.forEach(channel => {
				if (channel.dailyCap) {
					sumChannelsDailyBudgets += channel.dailyCap;
				}
				if (!channel?.getDefaultBid()) {
					this.formStore.addError(
						new FormError(`advanced_strategy_channel_${channel.getName()?.toLowerCase()}_bid`, 'Please provide default bid')
					);
				}
			});
			if (sumChannelsDailyBudgets && campaign?.totalBudget && sumChannelsDailyBudgets > campaign?.totalBudget) {
				this.formStore.addError(new FormError(CampaignDomIds.TotalBudget, 'Total buget must be bigger then Channels daily budget'));
			}
		}

		if (campaign.strategyType === Strategies.Smart) {
			if (!campaign.optimizationMode) {
				this.formStore.addError(new FormError(CampaignDomIds.OptimizationMode, 'Please provide optimization mode'));
			}
			const defaultBid = campaign.getDefaultBid();
			if (!defaultBid) {
				this.formStore.addError(new FormError(CampaignDomIds.OptimizationBid, 'Please provide default bid'));
			}
			const conversionActions = campaign.getConversionActions();
			if (!conversionActions) {
				this.formStore.addError(new FormError(CampaignDomIds.ConversionType, 'Please Select a conversion type'));
			}
		}

		return this.formStore.getIsValid();
	}
	private replaceToAts(url: string) {
		return url.replace(/{{/g, '@@').replace(/}}/g, '@@');
	}
}
