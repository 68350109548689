import { sentenceCase } from 'change-case';
import { ElementType } from 'react';
import { Row, ColumnDef } from '@tanstack/react-table';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import { IPerformanceItem } from '../../models/performance.model';

export interface IEntityHeaders {
	includeColumns?: {
		advertiser?: boolean;
		account?: boolean;
	};
	EntityActions?: ElementType;
	onEdit?: (id: id) => string;
	summary?: IPerformanceItem;
}

// TODO - add T when we fix this any, the any should be replaced with T
// eslint-disable-next-line
export interface IMergedHeadersReturn<T> {
	// TODO - fix this any - probably bug in new table v8 with ColumnDef
	columns: ColumnDef<any, any>[];
	accessors?: (string | undefined)[];
	columnVisibility?: Record<string, boolean>;
}

// TODO - Think where to put it
export function renderSubRow<T>({ row }: { row: Row<T> }) {
	const name = row.getValue('name');
	if (typeof name === 'string') {
		return sentenceCase(name);
	} else {
		return name;
	}
}
