import { IAccountCreateForm, IAccountEditForm } from '../models/account.model';
import { AccountModel } from '../models/account.model';
import { ask } from '@monorepo/tools/src/lib/tools/ask/ask';
import endpoints from './endpoints/index.endpoints';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import { IPerformanceOptions } from '../hooks/apis/use-reports-list';
import { PerformancePayload } from '../models/performance.model';
import { queryParamsToSearchParams } from '@monorepo/tools/src/lib/utils/url';
import { IRequestOptions } from '@monorepo/tools/src/lib/interfaces/url';

export const AccountsApi = {
	list: (params?: URLSearchParams): Promise<AccountModel[]> => {
		return ask.get(endpoints.accounts.list(), params).then(res => res?.payload?.list);
	},
	create: (account: IAccountCreateForm, options?: IRequestOptions): Promise<AccountModel> => {
		return ask.post<IAccountCreateForm>(endpoints.accounts.create(), account, options).then(res => res?.payload?.item);
	},
	edit: (accountId: id, account: IAccountEditForm, options?: IRequestOptions): Promise<AccountModel> => {
		return ask.patch<IAccountEditForm>(endpoints.accounts.edit(accountId), account, options).then(res => res?.payload?.item);
	},
	get: (accountId: id, options?: URLSearchParams): Promise<AccountModel> => {
		return ask.get(endpoints.accounts.get(accountId), options).then(res => res?.payload?.item);
	},
	delete: (accountId: id) => {
		return ask.delete(endpoints.accounts.delete(accountId)).then(res => res?.payload?.ids);
	},
	reports: (params?: IPerformanceOptions, options?: IRequestOptions): Promise<PerformancePayload> => {
		const hashParams = btoa(JSON.stringify(params));
		return ask
			.get(endpoints.accounts.reports(), queryParamsToSearchParams({ p: hashParams, ...(options?.queryParams || {}) }))
			.then(res => res?.payload?.report);
	},
	metrics: (params?: IPerformanceOptions): Promise<PerformancePayload> => {
		const hashParams = btoa(JSON.stringify(params));
		return ask.get(endpoints.campaigns.reports(), queryParamsToSearchParams({ p: hashParams })).then(res => res?.payload?.report);
	},
	enableAccounts(accountIds: id[]): Promise<null> {
		return ask.patch(endpoints.accounts.enableAccounts(), accountIds).then(res => res?.payload?.ids);
	},
	pauseAccounts(accountIds: id[]): Promise<null> {
		return ask.patch(endpoints.accounts.pauseAccounts(), accountIds).then(res => res?.payload?.ids);
	},
	// login: (email: string, password: string) => {
	// 	return ask.post(endpoints.accounts.baseUrl + '/login', { email, password });
	// },
};
