import { primitives } from '../types/primitives';

// Swap key to value and value to key
export const objectFlip = (obj: { [key: string]: string }) => {
	return Object.keys(obj).reduce((ret: { [key: string]: string }, key: string) => {
		ret[obj[key]] = key;
		return ret;
	}, {});
};

export const isExists = (obj: primitives): boolean => {
	return obj !== null && obj !== undefined;
};

export function shallowEqualObjects(objA: Record<string, unknown>, objB: Record<string, unknown>) {
	if (objA === objB) {
		return true;
	}

	if (!objA || !objB) {
		return false;
	}

	const aKeys = Object.keys(objA);
	const bKeys = Object.keys(objB);
	const len = aKeys.length;

	if (bKeys.length !== len) {
		return false;
	}

	for (let i = 0; i < len; i++) {
		const key = aKeys[i];

		if (objA[key] !== objB[key] || !Object.prototype.hasOwnProperty.call(objB, key)) {
			return false;
		}
	}

	return true;
}

export const getKeyByValue = (object: any, value: string | number) => {
	return Object.keys(object).find(key => object[key] === value);
};
