import { SidebarMode } from '@monorepo/base/src/components/sidebar/sidebar';
import { action, makeAutoObservable } from 'mobx';

export class SidebarStore {
	isSidebarVisible = false;
	isSidebarOpen = true;
	sidebarMode = SidebarMode.LOCK;
	isSidebarAnimate = false;

	constructor() {
		makeAutoObservable(this, {
			setSidebarVisibility: action,
			setSidebarOpen: action,
			setSidebarMode: action,
		});
		// makePersistable(this, {
		// 	name: generateStoreName('sidebarstore'),
		// 	properties: ['isSidebarVisible', 'isSidebarOpen', 'sidebarMode'],
		// 	storage: window.localStorage,
		// });
	}

	setSidebarMode(sidebarMode: SidebarMode): void {
		this.sidebarMode = sidebarMode;
	}

	getSidebarMode() {
		return this.sidebarMode;
	}

	setSidebarVisibility(isSidebarVisible: boolean): void {
		this.isSidebarVisible = isSidebarVisible;
	}

	setSidebarAnimate(isSidebarAnimate: boolean): void {
		this.isSidebarAnimate = isSidebarAnimate;
	}

	setSidebarOpen(isSidebarOpen: boolean): void {
		this.isSidebarOpen = isSidebarOpen;
	}

	getIsSideBarOpen(): boolean {
		return this.isSidebarOpen;
	}

	getIsSideBarVisible(): boolean {
		return this.isSidebarVisible;
	}

	getIsSideBarAnimate(): boolean {
		return this.isSidebarAnimate;
	}
}
