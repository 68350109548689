import { InputDesc, InputInfo, InputTitle } from '@monorepo/base/src/components/form/input-item/input-item';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { observer } from 'mobx-react';
import { IMFPStore } from '../../../../stores';
import { SpaceBetweenInputControls } from '../campaign.form';
import { Channel } from '../campaign-common.form';
import { ChannelIds, ChannelModel, ChannelsEnums } from '../../../../models/channel.model';
import { useCallback } from 'react';
import { SpacerY } from '@monorepo/base/src/components/spacer/spacer';
import { FormError } from '@monorepo/base/src/components/form/form-error/form-error';
import { Flex } from '@monorepo/base/src/components/flex/flex';
import { CampaignDomIds } from '../../../../hooks/toc/campaign.toc';
import { randomNumber } from '@monorepo/tools/src/lib/utils/number';

export const ChannelSuggestedBids = {
	[ChannelsEnums.Coupons]: parseFloat(randomNumber(0.05, 0.5)),
	[ChannelsEnums.BNPL]: parseFloat(randomNumber(0.05, 0.5)),
	[ChannelsEnums.Contextual]: parseFloat(randomNumber(0.05, 0.5)),
	[ChannelsEnums.Push]: parseFloat(randomNumber(0.05, 0.5)),
	[ChannelsEnums.Tiles]: parseFloat(randomNumber(0.05, 0.5)),
};

export const AdvancedStrategy = observer(() => {
	const { campaignStore } = useStores<IMFPStore>();
	const campaignCrudStore = campaignStore.getCrud();
	const campaign = campaignCrudStore.getData();
	const formStore = campaignCrudStore.getFormStore();

	const couponsChannel = campaign.getChannel(ChannelsEnums.Coupons);
	const bnplChannel = campaign.getChannel(ChannelsEnums.BNPL);
	const contextualChannel = campaign.getChannel(ChannelsEnums.Contextual);
	const pushChannel = campaign.getChannel(ChannelsEnums.Push);
	const tilesChannel = campaign.getChannel(ChannelsEnums.Tiles);

	const onAddCallback = useCallback((channelName: ChannelsEnums, isActive: boolean) => {
		if (isActive) {
			formStore.clearError(CampaignDomIds.Channels);
			campaign.addChannel(
				channelName,
				new ChannelModel({ channelId: ChannelIds[channelName], name: channelName, suggestedBid: ChannelSuggestedBids[channelName] })
			);
		} else {
			campaign.removeChannel(channelName);
		}
	}, []);

	const channelsErrorMsg = formStore.getErrors().get(CampaignDomIds.Channels)?.getMsg();

	return (
		<div id={CampaignDomIds.Channels}>
			<Flex justifyContent={'flex-start'}>
				<InputInfo>
					<InputTitle>Channels</InputTitle>
					<InputDesc>Choose the channels you want the campaign to run on</InputDesc>
				</InputInfo>
				{channelsErrorMsg ? <FormError msg={channelsErrorMsg} /> : null}
			</Flex>
			<SpacerY />
			<Channel
				name={ChannelsEnums.Coupons}
				channel={couponsChannel}
				hasChannel={Boolean(campaign.hasChannel(ChannelsEnums.Coupons))}
				onAdd={onAddCallback}
				bidErrorMsg={formStore.getErrors().get('advanced_strategy_channel_coupons_bid')?.getMsg()}
				onValue={() => formStore.clearError('advanced_strategy_channel_coupons_bid')}
				suggestedBid={ChannelSuggestedBids[ChannelsEnums.Coupons]}
			/>

			<SpacerY y={2} />

			<Channel
				name={ChannelsEnums.BNPL}
				channel={bnplChannel}
				hasChannel={Boolean(campaign.hasChannel(ChannelsEnums.BNPL))}
				onAdd={onAddCallback}
				bidErrorMsg={formStore.getErrors().get('advanced_strategy_channel_bnpl_bid')?.getMsg()}
				onValue={() => formStore.clearError('advanced_strategy_channel_bnpl_bid')}
				suggestedBid={ChannelSuggestedBids[ChannelsEnums.BNPL]}
			/>

			<SpacerY y={2} />

			<Channel
				name={ChannelsEnums.Contextual}
				channel={contextualChannel}
				hasChannel={Boolean(campaign.hasChannel(ChannelsEnums.Contextual))}
				onAdd={onAddCallback}
				bidErrorMsg={formStore.getErrors().get('advanced_strategy_channel_contextual_bid')?.getMsg()}
				onValue={() => formStore.clearError('advanced_strategy_channel_contextual_bid')}
				suggestedBid={ChannelSuggestedBids[ChannelsEnums.Contextual]}
			/>

			<SpacerY y={2} />

			<Channel
				name={ChannelsEnums.Push}
				channel={pushChannel}
				hasChannel={Boolean(campaign.hasChannel(ChannelsEnums.Push))}
				onAdd={onAddCallback}
				bidErrorMsg={formStore.getErrors().get('advanced_strategy_channel_push_bid')?.getMsg()}
				onValue={() => formStore.clearError('advanced_strategy_channel_push_bid')}
				suggestedBid={ChannelSuggestedBids[ChannelsEnums.Push]}
			/>

			<SpacerY y={2} />
			<Channel
				name={ChannelsEnums.Tiles}
				channel={tilesChannel}
				hasChannel={Boolean(campaign.hasChannel(ChannelsEnums.Tiles))}
				onAdd={onAddCallback}
				bidErrorMsg={formStore.getErrors().get('advanced_strategy_channel_tiles_bid')?.getMsg()}
				onValue={() => formStore.clearError('advanced_strategy_channel_tiles_bid')}
				suggestedBid={ChannelSuggestedBids[ChannelsEnums.Tiles]}
			/>

			<SpaceBetweenInputControls />
		</div>
	);
});
