import routes from '../routes/index.routes';
import { Soon } from '@monorepo/base/src/components/soon/soon';
import { Advertisers } from '../../pages/advertisers/index/advertisers.page';
import { FullLayout } from '../components/layouts/full-layout/full-layout';
import { PageLayout } from '../components/layouts/page-layout/page-layout';
import { AdvertiserView } from '../../pages/advertisers/view/advertiser-view.page';
import { AdvertiserCampaigns } from '../../pages/advertisers/view/campaigns/campaigns.advertiser';
import { AdvertiserChannels } from '../../pages/advertisers/view/channels/channels.advertiser';
import { CreateAdvertiser } from '../../pages/advertisers/create/create-advertiser.page';
import { CreateCampaign } from '../../pages/campaigns/create/create-campaign.page';
import { EditAdvertiser } from '../../pages/advertisers/edit/edit-advertiser.page';

export const AdvertisersRouter = [
	{
		element: <FullLayout />,
		children: [
			{
				path: routes.advertisers.createCampaign(':advertiserId'),
				element: <CreateCampaign parentRoute={routes.advertisers.index()} />,
			},
		],
	},
	{
		element: <PageLayout />,
		children: [
			{
				path: routes.advertisers.index(),
				element: <Advertisers />,
				children: [
					{
						path: routes.advertisers.create(),
						element: <CreateAdvertiser />,
					},
					{
						path: routes.advertisers.edit(':advertiserId'),
						element: <EditAdvertiser />,
					},
				],
			},
			{
				element: <AdvertiserView />,
				children: [
					{
						path: routes.advertisers.view(':advertiserId'),
						element: <Soon />,
					},
					{
						path: routes.advertisers.campaigns(':advertiserId'),
						element: <AdvertiserCampaigns />,
					},
					{
						path: routes.advertisers.channels(':advertiserId'),
						element: <AdvertiserChannels />,
					},
				],
			},
		],
	},
];
