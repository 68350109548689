import { Icon } from '@monorepo/base/src/components/icon/icon';
import styles from './sidebar.module.scss';
import { Logo } from '@monorepo/base/src/components/logo/logo';
import { Link } from 'react-router-dom';
import { Sidebar, SidebarMode } from '@monorepo/base/src/components/sidebar/sidebar';
import { IControlledStore, useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { useEffect, useState } from 'react';
import { route } from '@monorepo/tools/src/lib/types/url';
import routes from '../../routes/index.routes'; // TODO - need to be dynamic
import { observer } from 'mobx-react';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';
import { IMFPStore } from '../../stores';
import { ManifestoLogoSVG, ManifestoMiniLogoSvg } from '../logo/logo';

interface IItemList {
	mini?: boolean;
	title?: JSX.Element | string;
	route: string; // TODO - one of the routes
	icon?: string;
	color?: string;
}

// TODO - @MFP remove it from controlled
export const ControlledSidebar = observer(() => {
	const { sidebarStore } = useStores<IControlledStore>();
	const { userStore } = useStores<IMFPStore>();
	const { indexRoute, currentRoute } = useRoute();
	const isIndexRoute = indexRoute === currentRoute;
	const [activeRoute, setActiveRoute] = useState<route>(indexRoute || routes.general.home());

	useEffect(() => {
		if (indexRoute) {
			setActiveRoute(indexRoute);
		}
	}, [indexRoute]);

	const ItemList = ({ mini = false, title, route, icon, color }: IItemList) => {
		return (
			<li className={`${styles.itemWrapper} ${activeRoute === route && isIndexRoute ? styles.activeItem : ''}`}>
				<Link to={route + window.location.search} onClick={() => setActiveRoute(route)} className={styles.itemLink}>
					<div className={styles.item}>
						{icon ? (
							<Icon
								className={styles.icon}
								isMFP={true}
								color={color}
								size={'22px'}
								// font={activeRoute === route ? IconFonts.Filled : IconFonts.Outlined}
							>
								{icon}
							</Icon>
						) : null}

						{mini ? null : <span className={styles.itemTitle}>{title}</span>}
					</div>
				</Link>
			</li>
		);
	};

	const isMiniSidebar = (isHover: boolean) => {
		return (
			(!isHover && sidebarStore.getSidebarMode() === SidebarMode.HOVER) ||
			(!sidebarStore.getIsSideBarOpen() && sidebarStore.getSidebarMode() === SidebarMode.LOCK)
		);
	};

	return (
		<Sidebar
			isOpen={sidebarStore.getIsSideBarOpen()}
			isAnimate={sidebarStore.getIsSideBarAnimate() || sidebarStore.getSidebarMode() === SidebarMode.HOVER}
			mode={sidebarStore.getSidebarMode()}>
			{props => {
				const { isHover } = props;
				const _isMiniSidebar = isMiniSidebar(isHover);
				return (
					<div className={styles.wrapper}>
						{!_isMiniSidebar ? (
							<div className={styles.logoWrapper}>
								<Logo>
									<ManifestoLogoSVG />
								</Logo>
							</div>
						) : (
							<Logo>
								<ManifestoMiniLogoSvg className={styles.miniLogo} />
							</Logo>
						)}
						<ul className={`${styles.list} ${styles.fullList}`}>
							<ItemList icon={'bar-chart-square-02'} route={routes.general.home()} title={'Overview'} mini={_isMiniSidebar} />
							{!userStore?.getUser()?.isInternal ? null : (
								<ItemList icon={'building-07'} route={routes.accounts.index()} title={'Accounts'} mini={_isMiniSidebar} />
							)}
							<ItemList icon={'dataflow-04'} route={routes.advertisers.index()} title={'Advertisers'} mini={_isMiniSidebar} />
							<ItemList icon={'announcement-01'} route={routes.campaigns.index()} title={'Campaigns'} mini={_isMiniSidebar} />
						</ul>
					</div>
				);
			}}
		</Sidebar>
	);
});
