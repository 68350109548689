import { CreativeGroupModel } from '../../models/creative-group.model';
import { makeAutoObservable, observable } from 'mobx';
import { action } from 'mobx';
import { CreativeGroupsCrudStore } from './creative-groups-crud.store';
import { BaseListStore } from '@monorepo/controlled/src/stores/base-list.store';
import { CreativeGroupsApi } from '../../apis/creative-groups.api';
import { PerformancesColumns } from '../../hooks/apis/use-performance';
import { PerformanceStore } from '../performance.store';

export class CreativeGroupsStore {
	list = new BaseListStore<CreativeGroupModel>({
		listFunc: CreativeGroupsApi.list,
		model: CreativeGroupModel,
	});
	crud = new CreativeGroupsCrudStore();
	performance = new PerformanceStore<CreativeGroupModel>({
		keys: new Set([PerformancesColumns.CreativeGroupId]),
	});

	constructor() {
		makeAutoObservable(this, {
			crud: observable,
			list: observable,
			resetCrud: action, // TODO - remove
			performance: observable,
		});
	}

	getCrud() {
		return this.crud;
	}

	resetCrud() {
		this.crud = new CreativeGroupsCrudStore();
	}

	getListStore() {
		return this.list;
	}

	getPerformance() {
		return this.performance;
	}
}
