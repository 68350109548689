import { observer } from 'mobx-react';
import { FC } from 'react';
import { ControlledTable } from '@monorepo/controlled/src/components/table-wrapper/table-wrapper';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import routes from '../../../modules/routes/index.routes';
import { ControlledDater } from '@monorepo/controlled/src/components/controlled-dater/controlled-dater';
import { Page, Pagebar, PagebarTitle, PagebarActions } from '@monorepo/base/src/components/page/page';
import { IMFPStore } from '../../../modules/stores';
import { useCampaignHeaders } from '../../../modules/hooks/theaders/campaigns.headers';
import { useCampaignActions } from '../../../modules/hooks/actions/campaign.actions';
import { Entities, PerformancesColumns, useReportsList } from '../../../modules/hooks/apis/use-reports-list';
import { PrimaryLink } from '@monorepo/base/src/components/buttons/buttons';
import styles from './../../../styles/pages.module.scss';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';
import { CampaignFiltersStoreKeys } from '../../../modules/stores/campaigns/campaign.store';
import { SpacerY } from '@monorepo/base/src/components/spacer/spacer';
import { useMetrics } from '../../../modules/hooks/tools/use-metrics';
import { useMetricsGet } from '../../../modules/hooks/tools/use-metrics-get';
import { availablePerformanceToMetrics } from '../../../modules/models/performance.model';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { EmptyCampaignsState } from '../../../modules/components/empty-campaigns-state/empty-campaigns-state';
import { ManifestoChart } from '../../../modules/components/chart/manifesto-chart';

export const Campaigns: FC = observer(() => {
	const { campaignStore } = useStores<IMFPStore>();
	const reportsStore = campaignStore.getReportsStore();
	const metricsStore = campaignStore.getMetricsStore();
	const reportData = reportsStore.getData();
	const { currentRouteWithoutSpecialChars } = useRoute();

	useReportsList(
		reportsStore,
		{
			entity: Entities.Campagins,
			groupBys: [PerformancesColumns.CampaignId],
		},
		undefined,
		campaignStore.getSegments()
	);

	useMetricsGet(metricsStore, {
		entity: Entities.Chart,
		groupBys: [PerformancesColumns.Date],
	});
	const { columns, columnVisibility } = useCampaignHeaders({ summary: reportData?.getSummary() });
	const { SelectedActions, FiltersActions, SegmentActions } = useCampaignActions({ currentSegments: campaignStore.getSegments() });

	const currentFilters = campaignStore.getFiltersStore(currentRouteWithoutSpecialChars as CampaignFiltersStoreKeys)?.getCurrentFilters();

	const { metrics, xLabels } = useMetrics(metricsStore.getData());

	return (
		<Page unstyled={true} className={styles.article}>
			<Pagebar classes={{ pagebar: styles.padding }}>
				<PagebarTitle>Campaigns</PagebarTitle>
				<PagebarActions>
					<ControlledDater />
					<PrimaryLink
						icon={'plus'}
						debugProps={{ dataAttrs: [new DataAttribute('id', 'create_campaign')] }}
						to={routes.campaigns.create()}
						width={'148px'}>
						New Campaign
					</PrimaryLink>
				</PagebarActions>
			</Pagebar>
			<ManifestoChart
				isLoading={metricsStore.getIsLoading()}
				metrics={metrics}
				labels={xLabels}
				legendOptions={Object.keys(availablePerformanceToMetrics)}
			/>
			<SpacerY y={2} />
			<ControlledTable
				classes={{ wrapper: styles.padding }}
				label={'Campaigns'}
				totalRows={reportData?.getTotalRows() ? reportData?.getTotalRows()?.toLocaleString() : undefined}
				debugProps={{ dataAttrs: [new DataAttribute('id', 'campaigns_table')] }}
				columns={columns}
				emptyState={<EmptyCampaignsState to={routes.campaigns.create()} />}
				columnVisibility={columnVisibility}
				data={reportData?.getData() || []}
				isLoading={reportsStore.getIsLoading()}
				isWithPerformance={true}
				isLoadingPerformance={reportsStore.getIsLoading()}
				filters={currentFilters}
				defaultSortBy={[{ id: 'revenue', desc: true }]}>
				{() => {
					return {
						SelectedActions,
						FiltersActions,
						SegmentActions,
					};
				}}
			</ControlledTable>
		</Page>
	);
});
