import { ask } from '@monorepo/tools/src/lib/tools/ask/ask';
import endpoints from './endpoints/index.endpoints';
import { URLSearchParams } from 'url';
import { ChannelModel } from '../models/channel.model';
import { PerformancePayload } from '../models/performance.model';
import { IPerformanceOptions } from '../hooks/apis/use-reports-list';
import { queryParamsToSearchParams } from '@monorepo/tools/src/lib/utils/url';
import { IRequestOptions } from '@monorepo/tools/src/lib/interfaces/url';

export const ChannelsApi = {
	// TODO - check if using, maybe DEPRECATED
	list: (params?: URLSearchParams): Promise<ChannelModel[]> => {
		return ask.get(endpoints.channels.list(), params).then(res => res?.payload?.list);
	},
	reports: (params?: IPerformanceOptions, options?: IRequestOptions): Promise<PerformancePayload> => {
		const hashParams = btoa(JSON.stringify(params));
		return ask
			.get(endpoints.channels.reports(), queryParamsToSearchParams({ p: hashParams, ...(options?.queryParams || {}) }))
			.then(res => res?.payload?.report);
	},
	metrics: (params?: IPerformanceOptions): Promise<PerformancePayload> => {
		const hashParams = btoa(JSON.stringify(params));
		return ask.get(endpoints.channels.reports(), queryParamsToSearchParams({ p: hashParams })).then(res => res?.payload?.report);
	},
	insights: (params?: IPerformanceOptions): Promise<PerformancePayload> => {
		const hashParams = btoa(JSON.stringify(params));
		return ask.get(endpoints.channels.reports(), queryParamsToSearchParams({ p: hashParams })).then(res => res?.payload?.report);
	},
};
