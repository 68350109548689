import { ColumnDef, createColumnHelper, RowData, sortingFns } from '@tanstack/react-table';
import { IPerformanceItem, PerformanceModel } from '../../models/performance.model';
import { IMergedHeadersReturn } from './index.headers';
import { currencyFormat, isStringInfinityOrNaN, percents } from '@monorepo/tools/src/lib/utils/number';

// TODO - think maybe in meta object should be column type and not the isPerformance
// TODO - meta interface with total line

interface IuseWithPerformanceHeaders {
	summaryItem?: IPerformanceItem;
}

const columnHelper = createColumnHelper<PerformanceModel>();

declare module '@tanstack/table-core' {
	// eslint-disable-next-line
	interface ColumnMeta<TData extends RowData, TValue> {
		isPerformance: boolean;
		// TODO add total line here
	}
}
export const useWithPerformanceHeaders = (props: IuseWithPerformanceHeaders = {}): IMergedHeadersReturn<PerformanceModel> => {
	const { summaryItem = {} } = props;

	const columns = [
		columnHelper.accessor('impressions', {
			header: 'Impressions',
			cell: ({ row }) => <span>{row.getValue('impressions')?.toLocaleString() || '-'}</span>,
			footer: () => <span>{summaryItem?.impressions?.toLocaleString() || '-'}</span>,
			meta: {
				isPerformance: true,
			},
			sortingFn: (rowA, rowB, columnId) => sortingFns.alphanumeric(rowA, rowB, columnId),
		}),
		columnHelper.accessor('clicks', {
			header: 'Clicks',
			cell: ({ row }) => <span>{row.getValue('clicks')?.toLocaleString() || '-'}</span>,
			footer: () => <span>{summaryItem?.clicks?.toLocaleString() || '-'}</span>,
			meta: {
				isPerformance: true,
			},
			sortingFn: (rowA, rowB, columnId) => sortingFns.alphanumeric(rowA, rowB, columnId),
		}),
		columnHelper.accessor('conversions', {
			header: 'Conversions',
			cell: ({ row }) => <span>{row.getValue('conversions')?.toLocaleString() || '-'}</span>,
			footer: () => <span>{summaryItem?.conversions?.toLocaleString() || '-'}</span>,
			meta: {
				isPerformance: true,
			},
			sortingFn: (rowA, rowB, columnId) => sortingFns.alphanumeric(rowA, rowB, columnId),
		}),
		columnHelper.accessor('cost', {
			header: 'Cost',
			cell: ({ row }) => <span>{currencyFormat(row.getValue('cost')) ?? '-'}</span>,
			footer: () => <span>{currencyFormat(summaryItem?.cost) || '-'}</span>,
			meta: {
				isPerformance: true,
			},
			sortingFn: (rowA, rowB, columnId) => sortingFns.alphanumeric(rowA, rowB, columnId),
		}),
		columnHelper.accessor('revenue', {
			header: 'Revenue',
			cell: ({ row }) => <span>{currencyFormat(row.getValue('revenue')) ?? '-'}</span>,
			footer: () => <span>{currencyFormat(summaryItem?.revenue) || '-'}</span>,
			meta: {
				isPerformance: true,
			},
			sortingFn: (rowA, rowB, columnId) => sortingFns.alphanumeric(rowA, rowB, columnId),
		}),
		columnHelper.accessor('roas', {
			header: 'ROAS',
			cell: ({ row }) => <span>{!isStringInfinityOrNaN(row.getValue('roas')) ? percents(row.getValue('roas')) ?? '0%' : '0%'}</span>,
			footer: () => <span>{!isStringInfinityOrNaN(summaryItem?.roas || 0) ? percents(summaryItem?.roas) || '0%' : '0%'}</span>,
			meta: {
				isPerformance: true,
			},
			sortingFn: (rowA, rowB, columnId) => sortingFns.alphanumeric(rowA, rowB, columnId),
		}),
		columnHelper.accessor('cpc', {
			header: 'CPC',
			cell: ({ row }) => (
				<span>{!isStringInfinityOrNaN(row.getValue('cpc')) ? currencyFormat(row.getValue('cpc'), true) ?? '-' : '-'}</span>
			),
			footer: () => <span>{!isStringInfinityOrNaN(summaryItem?.cpc || 0) ? currencyFormat(summaryItem?.cpc) || '-' : '-'}</span>,
			meta: {
				isPerformance: true,
			},
			sortingFn: (rowA, rowB, columnId) => sortingFns.alphanumeric(rowA, rowB, columnId),
		}),
		columnHelper.accessor('cpa', {
			header: 'CPA',
			cell: ({ row }) => (
				<span>{!isStringInfinityOrNaN(row.getValue('cpa')) ? currencyFormat(row.getValue('cpa'), true) || '-' : '-'}</span>
			),
			footer: () => <span>{!isStringInfinityOrNaN(summaryItem?.cpa || 0) ? currencyFormat(summaryItem?.cpa) || '-' : '-'}</span>,
			meta: {
				isPerformance: true,
			},
			sortingFn: (rowA, rowB, columnId) => sortingFns.alphanumeric(rowA, rowB, columnId),
		}),
		columnHelper.accessor('cpm', {
			header: 'CPM',
			cell: ({ row }) => (
				<span>{!isStringInfinityOrNaN(row.getValue('cpm')) ? currencyFormat(row.getValue('cpm'), true) ?? '-' : '-'}</span>
			),
			footer: () => <span>{!isStringInfinityOrNaN(summaryItem?.cpm || 0) ? currencyFormat(summaryItem?.cpm) || '-' : '-'}</span>,
			meta: {
				isPerformance: true,
			},
			sortingFn: (rowA, rowB, columnId) => sortingFns.alphanumeric(rowA, rowB, columnId),
		}),
		columnHelper.accessor('cvr', {
			header: 'CVR',
			cell: ({ row }) => <span>{!isStringInfinityOrNaN(row.getValue('cvr')) ? percents(row.getValue('cvr')) ?? '0%' : '0%'}</span>,
			footer: () => <span>{!isStringInfinityOrNaN(summaryItem?.cvr || 0) ? percents(summaryItem?.cvr) || '0%' : '0%'}</span>,
			meta: {
				isPerformance: true,
			},
			sortingFn: (rowA, rowB, columnId) => sortingFns.alphanumeric(rowA, rowB, columnId),
		}),
	] as ColumnDef<PerformanceModel>[];

	return {
		columns,
	};
};
