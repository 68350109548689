import { BaseCrudStore } from '@monorepo/controlled/src/stores/base-crud.store';
import { FormError } from '@monorepo/tools/src/lib/models/form-error.model';
import { PreferencesApi } from '../../apis/preferences.api';
import { PreferenceModel, IPreferenceCreateForm, IPreferenceEditForm } from '../../models/preference.model';

export class PreferenceCrudStore extends BaseCrudStore<PreferenceModel, IPreferenceCreateForm, IPreferenceEditForm> {
	constructor() {
		super({
			apiLayer: PreferencesApi,
			model: PreferenceModel,
		});
	}

	/**
	 * Must call isValidPreference before calling this function
	 * @returns
	 */
	public getCreateFormData(): IPreferenceCreateForm {
		return {
			name: this.getData().getName(),
			value: this.getData().getValue(),
			comment: this.getData().getComment(),
		};
	}

	/**
	 * Must call isValidPreference before calling this function
	 * @returns
	 */
	public getEditFormData(): IPreferenceEditForm {
		return {
			id: this.getData().getId(),
			name: this.getData().getName(),
			value: this.getData().getValue(),
			comment: this.getData().getComment(),
		};
	}

	public isValid(): boolean {
		const preferenceName = this.getData().getName();
		if (!preferenceName) {
			this.formStore.addError(new FormError('name', 'Please provide a preference name'));
		}

		const value = this.getData().getValue();
		if (!value) {
			this.formStore.addError(new FormError('value', 'Please provide a value'));
		}

		return this.formStore.getIsValid();
	}
}
