import { NotificationsStore } from '@monorepo/controlled/src/stores/notifications.store';
import { AuthStore } from '@monorepo/controlled/src/stores/auth.store';
import { FormStore } from '@monorepo/controlled/src/stores/form.store';
import { DateStore } from '@monorepo/controlled/src/stores/date.store';
import { ModalsStore } from '@monorepo/controlled/src/stores/modals.store';
import { ToastsStore } from '@monorepo/controlled/src/stores/toasts.store';
import { SidebarStore } from '@monorepo/controlled/src/stores/sidebar.store';
import { TableStore } from '@monorepo/controlled/src/stores/table.store';
import { CampaignStore } from './campaigns/campaign.store';
import { AdvertiserStore } from './advertisers/advertiser.store';
import { AccountStore } from './accounts/account.store';
import { UserStore } from './users/user.store';
import { ChannelStore } from './channels/channel.store';

export interface IMFPStore {
	campaignStore: CampaignStore;
	advertiserStore: AdvertiserStore;
	accountStore: AccountStore;
	channelStore: ChannelStore;
	sidebarStore: SidebarStore;
	toastsStore: ToastsStore;
	tableStore: TableStore;
	modalsStore: ModalsStore;
	authStore: AuthStore;
	formStore: FormStore;
	notificationsStore: NotificationsStore;
	dateStore: DateStore;
	userStore: UserStore;
}

export class MFPStore implements IMFPStore {
	public accountStore: AccountStore;
	public advertiserStore: AdvertiserStore;
	public authStore: AuthStore;
	public campaignStore: CampaignStore;
	public channelStore: ChannelStore;
	public dateStore: DateStore;
	public formStore: FormStore;
	public modalsStore: ModalsStore;
	public notificationsStore: NotificationsStore;
	public sidebarStore: SidebarStore;
	public tableStore: TableStore;
	public toastsStore: ToastsStore;
	public userStore: UserStore;

	constructor() {
		this.formStore = new FormStore();
		this.authStore = new AuthStore();
		this.userStore = new UserStore();
		this.notificationsStore = new NotificationsStore();
		this.sidebarStore = new SidebarStore();
		this.toastsStore = new ToastsStore();
		this.modalsStore = new ModalsStore();
		this.tableStore = new TableStore();
		this.dateStore = new DateStore();
		this.campaignStore = new CampaignStore();
		this.advertiserStore = new AdvertiserStore();
		this.accountStore = new AccountStore();
		this.channelStore = new ChannelStore();
	}
}
