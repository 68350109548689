import { action, makeObservable, observable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import { ReactElement } from 'react';
import { generateStoreName } from './helper';

interface INotificationAdd {
	date: Date;
	onClose?: () => void;
	children?: ReactElement | string;
	msg?: string;
	type?: string;
}

export interface INotificationItem extends INotificationAdd {
	id: number;
}

interface INotifications {
	notifications: INotificationAdd[];
}

export class NotificationsStore implements INotifications {
	notifications: INotificationItem[] = [];
	id = 1;

	constructor() {
		makeObservable(this, {
			notifications: observable,
			setNotifications: action,
			removeNotification: action,
		});
		makePersistable(this, {
			name: generateStoreName('notificationsstore'),
			properties: ['notifications'],
			storage: {
				setItem: (key, data) => {
					window.sessionStorage.setItem(key, data);
				},
				removeItem: key => window.sessionStorage.removeItem(key),
				getItem: key => {
					const data = JSON.parse(window.sessionStorage.getItem(key) || '{}');
					return {
						...data,
						notifications: data.notifications || [],
					};
				},
			},
		});
	}

	getNotifications(): INotificationItem[] {
		return this.notifications;
	}

	addNotification(notification: INotificationAdd) {
		if (!notification) {
			throw new Error('No notification added to addNotification function');
		}
		// Render notifications in the next render cycle
		setTimeout(() => {
			const notifications = [
				{
					id: this.id++,
					...notification,
				},
				...this.notifications,
			];
			this.setNotifications(notifications);
		});
	}

	setNotifications(notifications: INotificationItem[]) {
		this.notifications = notifications;
	}

	removeNotification(id: number) {
		this.notifications = this.notifications.filter(notification => notification.id !== id);
	}

	getId(): number {
		return this.id;
	}
}
