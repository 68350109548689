interface IImageProps {
	source?: string;
	alt?: string;
	className?: string;
}

export const Image = (props: IImageProps) => {
	const { source, alt, className } = props;
	// const [isLoaded, setIsLoaded] = useState<boolean>(false);
	// console.log(`TCL ~ isLoaded`, isLoaded);

	// if (!isLoaded) {
	// 	return <div>moshe</div>;
	// }

	return <img src={source} alt={alt} width={'100%'} className={className} />;
};
