import { createColumnHelper } from '@tanstack/react-table';
import { selectionColumn } from '@monorepo/controlled/src/components/table-wrapper/selection-column/selection-column';
import { TableLink } from '@monorepo/controlled/src/components/table/table-link/table-link';
import { isSubRow } from '@monorepo/controlled/src/components/table/table';
import { CampaignModel, ICampaign } from '../../models/campaign.model';
import routes from '../../routes/index.routes';
import { useCampaignActions } from '../actions/campaign.actions';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { IMFPStore } from '../../stores';
import { IEntityHeaders, renderSubRow } from './index.headers';
import { IMergedHeadersReturn } from './index.headers';
import { useWithPerformanceHeaders } from './with-performance.headers';
import { Tag } from '@monorepo/base/src/components/tag/tag';
import { mapToIcon, StatusType, mapToTagStatus } from '../../enums/status';
import { Icon, IconFonts } from '@monorepo/base/src/components/icon/icon';
import styles from './headers.module.scss';
import { sentenceCase } from 'change-case';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';

const columnHelper = createColumnHelper<ICampaign>();

export const useCampaignHeaders = (props: IEntityHeaders = {}): IMergedHeadersReturn<CampaignModel> => {
	const { includeColumns = {}, summary } = props;
	const routeObj = useRoute();
	const isNestedAdvertiser = routeObj.currentRoute.includes('advertisers');
	const mergedPerforamnceHeaders = useWithPerformanceHeaders({ summaryItem: summary }).columns;
	const { advertiser = true } = includeColumns;
	const { sidebarStore } = useStores<IMFPStore>();
	const { EntityActions } = useCampaignActions();

	const renderHierachyColumns = () => {
		const columns = [];

		if (advertiser) {
			columns.push(
				columnHelper.accessor(row => `${row?.advertiser?.name}`, {
					id: 'advertiserName',
					header: 'Advertiser',
					cell: info => {
						const { row } = info;
						const campaign = new CampaignModel(row.original); // TODO - check if need the constructor
						const advertiserId = campaign.getAdvertiser()?.getId();
						if (!advertiserId || isSubRow(row)) {
							// will enter here when use segments
							return <span></span>;
						}
						return (
							<TableLink
								to={routes.advertisers.campaigns(advertiserId)}
								onClick={() => {
									sidebarStore.setSidebarOpen(false);
								}}>
								{row.getValue('advertiserName')}
							</TableLink>
						);
					},
				})
			);
		}

		return columns;
	};

	return {
		columns: [
			selectionColumn<CampaignModel>({ model: CampaignModel, EntityActions }),
			columnHelper.accessor('name', {
				header: 'Name',
				cell: info => {
					const { row } = info;
					if (isSubRow(row)) {
						return renderSubRow({ row });
					}
					return (
						<TableLink
							to={routes.campaigns.channels(row.getValue('id'))}
							toEdit={routes.campaigns.edit(row.getValue('id'))}
							onClick={() => {
								sidebarStore.setSidebarOpen(false);
							}}>
							{row.getValue('name')}
						</TableLink>
					);
				},
			}),
			columnHelper.accessor('status', {
				header: 'Status',
				cell: info => {
					const { row } = info;
					if (isSubRow(row)) {
						return <span></span>;
					}
					const status = row.getValue('status') as string;
					const mappedStatus = mapToIcon(status?.toUpperCase() as StatusType);
					return (
						<Tag type={mapToTagStatus(status?.toUpperCase() as StatusType)}>
							<Icon
								className={styles[mappedStatus.className]}
								size={'13px'}
								color={mappedStatus.color}
								font={IconFonts.Outlined}
								isMFP={true}>
								{mappedStatus.icon}
							</Icon>
							{`${sentenceCase(row.getValue('status'))}`}
						</Tag>
					);
				},
			}),
			columnHelper.accessor('id', {
				header: 'ID',
			}),
			columnHelper.accessor('daily_cap', {
				header: 'Daily Budget',
			}),
			columnHelper.accessor('geo', {
				header: 'Country',
				cell: info => {
					const { row } = info;
					let country = row.getValue('geo') as string; //TODO revert this shitty hotfix

					if (country === 'United States') {
						country = 'US';
					}

					if (country === null) {
						country = 'N/A';
					}
					// TODO - image component
					return (
						<div className={styles.countries}>
							<img src={`/assets/country_logos/${country === 'N/A' ? 'unknown' : country}.svg`} alt={country} />
							<span>{country}</span>
						</div>
					);
				},
			}),
			...mergedPerforamnceHeaders,
			...renderHierachyColumns(),
		],
		columnVisibility: { impressions: false, cpm: false, cvr: false, advertiserName: !isNestedAdvertiser },
	};
};
