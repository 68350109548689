import { observer } from 'mobx-react';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { Input } from '@monorepo/base/src/components/form/input/input';
import { IMFPStore } from '../../../stores';
import styles from './advertiser-form.module.scss';
import { Dropdown, option } from '@monorepo/base/src/components/dropdown/dropdown';
import { useEffect } from 'react';
import { InputDesc, InputInfo, InputItem, InputTitle } from '@monorepo/base/src/components/form/input-item/input-item';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';
import { getTheme } from '@monorepo/tools/src/lib/get-config';
import { Icon } from '@monorepo/base/src/components/icon/icon';
import { useDidMount } from '@monorepo/tools/src/lib/hooks/utils/use-didmount';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';

export const AdvertiserForm = observer(() => {
	const routes = useRoute();
	const isEditPage = routes.currentRoute.includes('edit');
	const isNestedAccount = routes.currentRoute.includes('accounts');
	const { advertiserStore, accountStore } = useStores<IMFPStore>();
	const advertiserCrudStore = advertiserStore.getCrud();
	const formStore = advertiserCrudStore.getFormStore();
	const advertiser = advertiserCrudStore.getData();
	const categoriesStore = advertiserStore.getCategoriesStore();
	const categories = advertiserStore.getCategoriesStore().getData();
	const accounts = accountStore.getListStore().getList();
	const accountCrudStore = accountStore.getCrud();
	const currentAccount = accountCrudStore.getData();
	const accountName = currentAccount.getName();
	const accountsNameList = accounts.map(account => account?.getName() || '');
	const isDidMount = useDidMount();

	useEffect(() => {
		// In case of coming from account view then complete the advertiser dropdown
		if (accountName) {
			onAccountSelect(accountName);
		}
	}, [accountName, accounts]);

	useEffect(() => {
		if (isDidMount) {
			categoriesStore.fetch({});
		}
	}, [isDidMount]);

	const onCategorySelect = (categoryName: option) => {
		const _category = categories?.find(category => category === categoryName);
		if (_category) {
			advertiser.setCategory(_category);
		}
	};

	const onAccountSelect = (accountName: option) => {
		const _account = accounts?.find(account => account.getName() === accountName);
		if (_account) {
			advertiser.setAccount(_account);
		}
	};

	return (
		<div className={styles.wrapper}>
			<InputItem flexDirection={'column'} gap={'16px'}>
				<InputInfo className={styles.inputInfo}>
					<InputTitle>Advertiser Name</InputTitle>
					<InputDesc>Enter the name of the advertiser</InputDesc>
				</InputInfo>
				<Input
					className={styles.inputWrapper}
					value={advertiser?.getName()}
					onValue={value => {
						advertiser.setName(value);
						formStore.clearError('name');
					}}
					required={true}
					requiredAsterisk={false}
					error={formStore.getErrors().get('name')?.getMsg()}
					debugProps={{ dataAttrs: [new DataAttribute('id', 'name')] }}
				/>
			</InputItem>

			<InputItem flexDirection={'column'} gap={'16px'}>
				<InputInfo className={styles.inputInfo}>
					<InputTitle>Domain</InputTitle>
					<InputDesc>Enter the Domain</InputDesc>
				</InputInfo>
				<Input
					before={
						<Icon isMFP={true} color={getTheme().subtitleColor} size={'22px'}>
							link-02
						</Icon>
					}
					placeholder={'www.example.com'}
					className={styles.inputWrapper}
					value={advertiser.getMainDomain() || ''}
					onValue={value => {
						advertiser.setMainDomain(value);
						formStore.clearError('domain');
					}}
					requiredAsterisk={false}
					error={formStore.getErrors().get('domain')?.getMsg()}
					debugProps={{ dataAttrs: [new DataAttribute('id', 'domain')] }}
				/>
			</InputItem>

			<InputItem flexDirection="column" maxWidth={'622px'} gap={'16px'}>
				<InputInfo className={styles.inputInfo}>
					<InputTitle>Advertiser Category</InputTitle>
					<InputDesc>Select the advertiser's category</InputDesc>
				</InputInfo>
				<Dropdown
					isExtraHeight={true}
					defaultOption={advertiser?.getCategory()}
					label={'Select Category'}
					required={true}
					requiredAsterisk={false}
					autocomplete={true}
					autocompletePlaceholder={'Search Category'}
					options={categories && categories?.length > 0 ? categories : []}
					onSelect={(category: Set<option> | undefined) => onCategorySelect(category?.values().next().value)}
					error={formStore.getErrors().get('category')?.getMsg()}
					debugProps={{ dataAttrs: [new DataAttribute('id', 'category')] }}
				/>
			</InputItem>

			<InputItem flexDirection="column" maxWidth={'622px'} gap={'16px'}>
				<InputInfo className={styles.inputInfo}>
					<InputTitle>Advertiser's Account</InputTitle>
					<InputDesc>Select the advertiser's account</InputDesc>
				</InputInfo>
				<Dropdown
					label={'Select Account'}
					isExtraHeight={true}
					defaultOption={advertiser?.getAccount()?.getName()}
					required={true}
					requiredAsterisk={false}
					autocomplete={true}
					autocompletePlaceholder={'Search Account'}
					options={accountsNameList}
					onSelect={(category: Set<option> | undefined) => onAccountSelect(category?.values().next().value)}
					error={formStore.getErrors().get('account')?.getMsg()}
					disabled={isEditPage || (isNestedAccount && Boolean(advertiser?.getAccount()?.getName()))}
					debugProps={{ dataAttrs: [new DataAttribute('id', 'account')] }}
				/>
			</InputItem>
		</div>
	);
});
