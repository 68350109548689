export enum OptimizationModes {
	TargetRoas = 'ROI',
	TargetCPA = 'CPA',
	ManualCPC = 'CPC',
}

export enum OptimizationModesLabels {
	TargetRoas = 'Target ROAS',
	TargetCPA = 'Target CPA',
}
