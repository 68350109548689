import { getConfig } from '@monorepo/tools/src/lib/get-config';

let STORE_PREFIX = getConfig().env.projectName === 'adminx' ? 'barry' : 'arad';
const SUB_USER_STORE_PREFIX = 'sprf';

let subUserPrefix = sessionStorage.getItem(SUB_USER_STORE_PREFIX);
if (!subUserPrefix) {
	const currentUrl = new URL(window.location.href);
	subUserPrefix = currentUrl.searchParams.get(SUB_USER_STORE_PREFIX);

	if (subUserPrefix) {
		let storePrefixList;
		try {
			storePrefixList = JSON.parse(localStorage.getItem('sprf-list') || '{}');
		} catch (err) {
			// Todo: log the issue
			storePrefixList = {};
		}
		storePrefixList[subUserPrefix] = { createdAt: new Date() };
		localStorage.setItem('sprf-list', JSON.stringify(storePrefixList));
		sessionStorage.setItem(SUB_USER_STORE_PREFIX, subUserPrefix);
	}
}

export const generateStoreName = (name: string, subPrefix?: string) => {
	if (subUserPrefix || subPrefix) {
		return `${STORE_PREFIX}|${subUserPrefix || subPrefix}|${name}`;
	}
	return `${STORE_PREFIX}|${name}`;
};

export const removeOldPerssistableStores = () => {
	window.localStorage.removeItem('advertiserscampaignsFilterStore');
	window.localStorage.removeItem('campaignscreativesFilterStore');
	window.localStorage.removeItem('testsFilterStore');
	window.localStorage.removeItem('accountsFilterStore');
	window.localStorage.removeItem('accountsadvertisersFilterStore');
	window.localStorage.removeItem('advertisersFilterStore');
	window.localStorage.removeItem('ilbbaicnl');
	window.localStorage.removeItem('creativegroupscreativesFilterStore');
	window.localStorage.removeItem('campaigngroupscampaignsFilterStore');
	window.localStorage.removeItem('mf[UserStore');
	window.localStorage.removeItem('UserStore');
	window.localStorage.removeItem('campaignsFilterStore');
	window.localStorage.removeItem('creativesFilterStore');
	window.localStorage.removeItem('campaigngroupscreativesFilterStore');
	window.localStorage.removeItem('advertiserscreativesFilterStore');
	window.localStorage.removeItem('DateStore');
	window.localStorage.removeItem('mfpUserStore');
	window.localStorage.removeItem('mfp-cc-p');
};
