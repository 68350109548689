import { useState } from 'react';
import { IMetric } from '../chart';
import { PerformanceEnumarableLabels, PerformanceMetricsSymbols } from '@monorepo/tools/src/lib/enums/performance-labels';
import { Icon } from '@monorepo/base/src/components/icon/icon';
import { Select } from '../../select/select';
import { Menu } from '../../menu/menu';
import { Skeleton } from '../../skeleton/skeleton';
import { ChartColors } from '../chart';
import styles from './chart-legend.module.scss';
import { getTheme } from '@monorepo/tools/src/lib/get-config';
import { isStringInfinityOrNaN } from '@monorepo/tools/src/lib/utils/number';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';

const theme = getTheme();

interface ILegendItem {
	metric: IMetric;
	color: string;
	index: number;
	toggleMetric: (index: number) => void;
	replaceMetric: (index: number, afterMetric: PerformanceEnumarableLabels) => void;
	isLoading?: boolean;
	legendOptions?: string[];
}
const toFixedWithoutZeros = (num: number, precision: number) => `${Number.parseFloat(num.toFixed(precision))}`;

const LegendItem = (props: ILegendItem) => {
	const { isLoading, color, toggleMetric, metric, replaceMetric, index, legendOptions } = props;
	const { label, sum, selected, formatter } = metric;
	const [isMetricOpen, setIsMetricOpen] = useState<boolean>(false);
	const symbol = PerformanceMetricsSymbols.get(label as any);
	let parsedSum: string = sum + '';

	if (typeof sum !== 'undefined' && symbol === '%') {
		parsedSum = toFixedWithoutZeros(sum * 100, 2);
	}
	const sumIsNan = typeof sum === 'undefined' || typeof sum === 'object' ? true : isNaN(sum);

	return (
		<div className={styles.itemWrapper}>
			<div className={styles.item}>
				<div className={`${styles.marker} ${selected ? '' : styles.hide}`} style={{ backgroundColor: color }}></div>
				<div className={styles.content}>
					<div className={styles.labelWrapper} onClick={() => toggleMetric(index)}>
						<span className={styles.label}>{label}</span>
						{isLoading ? (
							<div className={styles.skeletonSum}>{<Skeleton is={isLoading} fullWidth />}</div>
						) : !sumIsNan ? (
							symbol ? (
								<div className={styles.sum}>
									{symbol === '$' ? symbol : ''}
									{formatter
										? !isStringInfinityOrNaN(Number.parseFloat(parsedSum))
											? formatter(Number.parseFloat(parsedSum))
											: 0
										: parsedSum}
									{symbol === '%' ? symbol : ''}
								</div>
							) : (
								<div className={styles.sum}>{formatter ? formatter(sum) : sum}</div>
							)
						) : (
							'-'
						)}
					</div>
					<Icon
						isMFP={true}
						onClick={() => setIsMetricOpen(!isMetricOpen)}
						color={theme.titleColor}
						radius={false}
						className={styles.arrow}
						debugProps={{ dataAttrs: [new DataAttribute('id', 'chevron-down')] }}>
						chevron-down
					</Icon>
				</div>
			</div>
			<Menu
				className={styles.menuMinWidth}
				unstyled={true}
				isOpen={isMetricOpen}
				alignToLeft={true}
				onClose={() => setIsMetricOpen(false)}>
				<Select
					isSearch={false}
					placeholder={'Search Metric'}
					values={legendOptions || []}
					onClick={value => {
						replaceMetric(index, value as PerformanceEnumarableLabels);
						setIsMetricOpen(false);
					}}
					defaultValue={label}
				/>
			</Menu>
		</div>
	);
};

interface ILegend {
	metrics: IMetric[];
	toggleMetric: (index: number) => void;
	replaceMetric: (index: number, afterMetric: PerformanceEnumarableLabels) => void;
	isLoading?: boolean;
	legendOptions?: string[];
}

export const Legend = (props: ILegend) => {
	const { metrics, toggleMetric, replaceMetric, isLoading, legendOptions } = props;
	return (
		<div className={`${styles.legend}`}>
			{Array.from(metrics.values()).map((metricValue, index) => {
				if (!metricValue.visible) {
					return null;
				}
				return (
					<LegendItem
						legendOptions={legendOptions}
						isLoading={isLoading}
						key={ChartColors[index]}
						metric={metricValue}
						index={index}
						color={ChartColors[index]}
						toggleMetric={toggleMetric}
						replaceMetric={replaceMetric}
					/>
				);
			})}
		</div>
	);
};
