import { Fragment, ReactNode, useEffect, useRef, useState } from 'react';
import { areSetsEqual } from '@monorepo/tools/src/lib/utils/set';
import { useDevices } from '@monorepo/tools/src/lib/hooks/tools/use-devices';
import { usePrevious } from '@monorepo/tools/src/lib/hooks/utils/use-previous';
import { useOnClickOutside } from '@monorepo/tools/src/lib/hooks/utils/use-on-click-outside';
import { Divider } from '../divider/divider';
import { FormError } from '../form/form-error/form-error';
import { Input } from '../form/input/input';
import { Icon, IconFonts } from '../icon/icon';
import { Tooltip } from '../tooltip/tooltip';
import styles from './dropdown.module.scss';
import { getTheme } from '@monorepo/tools/src/lib/get-config';
import { SearchIcon } from '../../assets/search.icon';
import { route } from '@monorepo/tools/src/lib/types/url';
import { SecondaryText } from '../buttons/buttons';
import { Link } from 'react-router-dom';
import { Skeleton } from '../skeleton/skeleton';
import { IDebugProps } from '@monorepo/tools/src/lib/interfaces/debug';
import { DataAttribute, generateDataAttrs } from '@monorepo/tools/src/lib/models/data-attr.model';

const theme = getTheme();

export type option = string;

interface IDropdown {
	label?: string;
	options: option[];
	onSelect: (option: Set<option> | undefined) => void;
	defaultOptions?: Set<option>;
	defaultOption?: option;
	disabled?: boolean;
	multi?: boolean;
	required?: boolean;
	requiredAsterisk?: boolean;
	error?: string;
	autocomplete?: boolean;
	autocompletePlaceholder?: string;
	onAutocompleteKeydown?: (e: React.KeyboardEvent<HTMLElement>) => void;
	tooltip?: boolean;
	className?: string;
	limitTags?: number;
	isExtraHeight?: boolean;
	id?: string;
	actions?: ReactNode;
	isLoading?: boolean;
	isCloseIcon?: boolean;
	debugProps?: IDebugProps;
}

interface IDropdownActions {
	children: ReactNode;
}

interface IDropdownAction {
	children: ReactNode;
	to: route;
	onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

export const DropdownActions = (props: IDropdownActions) => {
	const { children } = props;
	return <div className={styles.actions}>{children}</div>;
};

export const DropdownAction = (props: IDropdownAction) => {
	const { children, to, onClick } = props;
	return (
		<Link to={to} onClick={e => onClick && onClick(e)}>
			<SecondaryText
				style={{
					padding: '1px 0px 1px 12px',
					cursor: 'pointer',
					height: '40px',
					borderRadius: '0px 0px 6px 6px',
				}}
				icon={'plus'}
				iconColor={getTheme().primary600}>
				{children}
			</SecondaryText>
		</Link>
	);
};

/**
 * @param props
 * @returns
 */
export const Dropdown = (props: IDropdown) => {
	const {
		label,
		defaultOptions,
		defaultOption,
		options,
		onSelect,
		disabled = false,
		multi = false,
		required = false,
		requiredAsterisk = true,
		error,
		autocomplete = false,
		onAutocompleteKeydown,
		autocompletePlaceholder,
		tooltip,
		className,
		limitTags,
		isExtraHeight,
		id,
		actions,
		isLoading,
		isCloseIcon = true,
		debugProps,
	} = props;

	const [errorMsg, setErrorMsg] = useState<string | undefined>(error);
	const [isOpenDropdown, setDropdown] = useState<boolean>(false);
	const [activeOptions, setActiveOptions] = useState<Set<option>>(new Set());
	const [searchBarOption, setSearchBarOption] = useState<option | undefined>(defaultOption);
	const [filteredOptions, setFilteredOptions] = useState<string[]>(options);

	const { dataAttrs } = debugProps || {};
	const searchBarRef = useRef<HTMLInputElement | null>(null);
	const dropdownRef = useRef<HTMLDivElement>(null);
	const dropdownOptionsRef = useRef<HTMLDivElement>(null);
	const devices = useDevices();
	const prevOptions = useRef<option[]>();
	const prevDefaultOptions = useRef<Set<option>>();
	const prevDefaultOption = useRef<option>();
	const prevIsLoading = usePrevious<boolean>(Boolean(isLoading));
	const [positionClass, setPositionClass] = useState<string>(styles.fromBottom);

	useEffect(() => {
		if (dropdownOptionsRef.current) {
			const dropdownOptionsRefRect = dropdownOptionsRef.current.getBoundingClientRect();
			if (dropdownOptionsRefRect.bottom + 20 >= window.innerHeight) {
				// we are in the bottom
				setPositionClass(styles.fromTop);
			}
			// if (tooltipRect && tooltipRect.x + tooltipRect.width + 40 > window.innerWidth) {
			// 	setPositionClass(styles.alignToRight);
			// } else if (tooltipRect && tooltipRect.x < 0) {
			// 	setPositionClass(styles.alignToLeft);
			// } else {
			// 	setPositionClass(styles.normalAlight);
			// }
		}
	}, [isOpenDropdown]);

	useOnClickOutside(dropdownRef, () => setDropdown(false));

	useEffect(() => {
		setErrorMsg(error);
	}, [error]);

	const isDefaultOptionChanged = () => defaultOption !== prevDefaultOption?.current;

	const isDefaultOptionsChanged = () =>
		(defaultOptions && prevDefaultOptions?.current && areSetsEqual(defaultOptions, prevDefaultOptions.current)) ||
		defaultOptions !== prevDefaultOptions?.current;

	const isOptionsChanged = () =>
		Array.isArray(options) && Array.isArray(prevOptions.current) && options.join('') !== prevOptions.current.join('');

	const isLoadingChanged = () => Boolean(prevIsLoading) !== Boolean(isLoading);

	useEffect(() => {
		// Initalize active option in case of options or defaultOption or defaultOptions were changed from the last render
		// for example why we need this use case, see Create campaign page select an advertiser and select campaign group
		if (
			defaultOption ||
			defaultOptions ||
			isDefaultOptionChanged() ||
			isDefaultOptionsChanged() ||
			isOptionsChanged() ||
			isLoadingChanged()
		) {
			if (!autocomplete || (prevIsLoading && !isLoading)) {
				setFilteredOptions(options);
			}
			setActiveOptions(defaultOptions || new Set());
		}
		prevOptions.current = options;
		prevDefaultOptions.current = defaultOptions;
		prevDefaultOption.current = defaultOption;
	}, [options, defaultOptions, defaultOption, disabled, isLoading]);

	useEffect(() => {
		if (autocomplete) {
			setFilteredOptions(options);
		}
	}, []);

	const onOption = (option: option) => {
		let newSet = null;
		if (multi) {
			activeOptions.has(option) ? activeOptions.delete(option) : activeOptions.add(option);
			newSet = new Set(activeOptions);
		} else {
			newSet = new Set([option]);
			setDropdown(false);
		}
		setActiveOptions(newSet);
		onSelect && onSelect(newSet);
		if (searchBarOption) {
			onSearchBarChange(searchBarOption);
		}
	};

	const onDropdown = () => {
		if (disabled) {
			return;
		}
		if (!isOpenDropdown && autocomplete) {
			setSearchBarOption(undefined);
			setFilteredOptions(options);
		}
		setDropdown(!isOpenDropdown);
	};

	const getOptionKey = (option: option, index: number) => {
		return option + index;
	};

	const ActiveOption = ({ option, index }: { option: option; index: number }) => {
		return (
			<div key={getOptionKey(option, index)} className={`${multi ? styles.activeOption : ''} ${disabled ? styles.disabled : ''}`}>
				<span>{option}</span>
				{multi ? (
					<Icon
						font={IconFonts.Round}
						className={styles.closeIcon}
						isMFP={true}
						size={'15px'}
						color={'#98A2B3'}
						onClick={() => {
							if (!disabled) {
								onOption(option);
							}
						}}>
						x-close
					</Icon>
				) : null}
			</div>
		);
	};

	const DropdownBar = () => {
		if (activeOptions.size > 0) {
			const bar = Array.from(activeOptions).map((option, index) => {
				if (limitTags) {
					if (limitTags > index) {
						return <ActiveOption key={getOptionKey(option, index)} option={option} index={index} />;
					} else {
						return null;
					}
				}
				return <ActiveOption key={getOptionKey(option, index)} option={option} index={index} />;
			});
			return (
				<Fragment>
					{bar}
					{limitTags && limitTags < activeOptions.size ? (
						<div className={styles.activeOption}>+{activeOptions.size - limitTags}</div>
					) : null}
				</Fragment>
			);
		}
		return <span>{defaultOption || `${label || ''} ${required && requiredAsterisk ? '*' : ''}`}</span>;
	};

	const renderMultiOption = (option: option) => {
		return (
			<div className={styles.multiOption}>
				{option}
				{activeOptions.has(option) ? (
					<Icon isMFP={true} size={'14px'} color={theme.primaryColor}>
						check
					</Icon>
				) : null}
			</div>
		);
	};

	const onSearchBarChange = (value: string) => {
		if (autocomplete) {
			setSearchBarOption(value);
			setFilteredOptions(options.filter(option => (option || '').toLowerCase().includes(value.toLowerCase())));
		}
	};

	const DropdownOptions = () => {
		if (multi) {
			const selectedOptions: JSX.Element[] = [];
			const unselectedOptions: JSX.Element[] = [];

			filteredOptions.forEach((option, index) => {
				if (activeOptions.has(option)) {
					selectedOptions.push(
						<li className={styles.highlight} value={option} key={getOptionKey(option, index)} onClick={() => onOption(option)}>
							{multi ? renderMultiOption(option) : <div>{option}</div>}
						</li>
					);
				} else {
					unselectedOptions.push(
						<li value={option} key={getOptionKey(option, index)} onClick={() => onOption(option)}>
							{multi ? renderMultiOption(option) : <div>{option}</div>}
						</li>
					);
				}
			});
			return (
				<Fragment>
					{selectedOptions.map(option => option)}
					{selectedOptions.length > 0 ? (
						<div className={styles.separatorLine}>
							<Divider />
						</div>
					) : null}
					{unselectedOptions.map(option => option)}
				</Fragment>
			);
		}
		const FilteredOptions = filteredOptions.map((option, index) => (
			<li
				className={activeOptions.has(option) ? styles.highlight : ''}
				value={option}
				key={getOptionKey(option, index)}
				onClick={() => onOption(option)}>
				{multi ? renderMultiOption(option) : <div>{option}</div>}
			</li>
		));
		return <Fragment>{FilteredOptions}</Fragment>;
	};

	const DropdownBarWrapper = () => {
		return (
			<div className={`${styles.dropdown} ${isOpenDropdown ? styles.focused : ''}`} onClick={onDropdown}>
				<div className={styles.dropdownBar}>
					<DropdownBar />
				</div>
				{multi && activeOptions.size > 0 && !disabled && (
					<Icon
						className={styles.closeIcon}
						isMFP={true}
						size={'16px'}
						onClick={e => {
							const emptySet = new Set([]);
							e.stopPropagation();
							setActiveOptions(emptySet);
							if (onSelect) {
								onSelect(emptySet);
							}
						}}>
						x-close
					</Icon>
				)}
				{!multi && defaultOption && !required && onSelect && !disabled && isCloseIcon && (
					<Icon
						className={styles.closeIcon}
						isMFP={true}
						size={'16px'}
						onClick={e => {
							e.stopPropagation();
							onSelect(undefined);
						}}>
						x-close
					</Icon>
				)}
				<Icon
					className={`${disabled ? styles.arrowIcon : ''}`}
					isMFP={true}
					debugProps={{ dataAttrs: [new DataAttribute('id', 'chevron-down')] }}>
					chevron-down
				</Icon>
			</div>
		);
	};

	if (devices.isMobileDevice || devices.isTabletDevice || devices.largeTablet) {
		// In specific devices we prefer the native functionality of select
		return (
			<select onChange={e => onOption(e.target.value)} disabled={disabled}>
				<option value={''}>
					{label} {required && requiredAsterisk ? '*' : ''}
				</option>
				{options.map((option, index) => {
					return (
						<option value={option} key={getOptionKey(option, index)}>
							{option}
						</option>
					);
				})}
			</select>
		);
	}

	// ${activeOptions.size > 0 || defaultOption ? styles.active : ''}
	return (
		<div className={styles.dropdownAndError} id={id} {...generateDataAttrs(dataAttrs)}>
			<div
				ref={dropdownRef}
				className={`${styles.wrapper} ${disabled ? styles.disabled : ''} ${errorMsg ? styles.dropdownError : ''} ${className}`}>
				<label className={styles.label}>
					{label} {required && requiredAsterisk ? '*' : ''}
				</label>
				{defaultOption && tooltip ? (
					<Tooltip isFullWidth={true} content={<span>{defaultOption}</span>}>
						{<DropdownBarWrapper />}
					</Tooltip> // TODO - tooltip to multiselect
				) : (
					<DropdownBarWrapper />
				)}
				{disabled ? null : isOpenDropdown ? (
					<div
						className={`${styles.options} ${positionClass} ${isOpenDropdown ? styles.activeOptions : ''} ${
							multi || isExtraHeight ? styles.extraHeight : ''
						}`}
						ref={dropdownOptionsRef}>
						{autocomplete ? (
							<Fragment>
								<Input
									wrapperStyle={{ padding: '0px' }}
									inline={true}
									icon={'search-sm'}
									className={styles.searchbar}
									value={searchBarOption}
									placeholder={autocompletePlaceholder || label}
									onChange={e => onSearchBarChange(e.target.value)}
									onKeyDown={e => onAutocompleteKeydown && onAutocompleteKeydown(e)}
									ref={searchBarRef}
								/>
							</Fragment>
						) : null}
						<ul className={`${styles.listOptions}`}>
							{isLoading ? (
								<div className={styles.skeleton}>
									<Skeleton is={true} rectanglesAmount={6} />
								</div>
							) : (
								<DropdownOptions />
							)}
						</ul>
						{actions}
					</div>
				) : null}
			</div>
			<FormError msg={errorMsg} />
		</div>
	);
};
