import { url } from '@monorepo/tools/src/lib/types/url';
import { email } from '@monorepo/tools/src/lib/types/email';

export interface IUser {
	id: string;
	fullName: string;
	givenName: string;
	familyName: string;
	image: url;
	email: email;
	clientId: string;
}

export class UserModel implements IUser {
	id: string;
	fullName: string;
	givenName: string;
	familyName: string;
	image: url;
	email: email;
	clientId: string;

	constructor(user?: IUser);
	constructor(user: IUser) {
		this.id = user.id;
		this.fullName = user.fullName;
		this.givenName = user.givenName;
		this.familyName = user.familyName;
		this.image = user.image;
		this.email = user.email;
		this.clientId = user.clientId;
	}

	getEmail(): email {
		return this.email;
	}

	getFullName(): string {
		return this.fullName;
	}

	getGivenName(): string {
		return this.givenName;
	}

	getImage(): url {
		return this.image;
	}
}
