import { CampaignsEndpoint } from './campaigns.endpoint';
import { CreativesEndpoint } from './creatives.endpoint';
import { CrudEndpoint } from './crud.endpoint';
import { DealsEndpoint } from './deals.endpoint';
import { TestsEndpoint } from './tests.endpoint';

const endpoints = {
	campaigns: new CampaignsEndpoint(),
	advertisers: new CrudEndpoint('advertisers'),
	preferences: new CrudEndpoint('preferences'),
	creatives: new CreativesEndpoint(),
	campaignGroups: new CrudEndpoint('campaign-groups'),
	creativeGroups: new CrudEndpoint('creative-groups'),
	tests: new TestsEndpoint(),
	deals: new DealsEndpoint(),
	jobs: new CrudEndpoint('jobs'),
};

export default endpoints;
