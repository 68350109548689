import { makeAutoObservable } from "mobx";
import { stringAction } from "@monorepo/tools/src/lib/utils/string";

export enum FilterOptionsEnums {
  AccountName = "accountName",
  AdvertiserName = "advertiserName",
  CampaignName = "campaignName",
  ChannelName = "channelName",
  Status = "status",
  BrandName = "brandName",
  CreativeGroupDesign = "creativegroupDesign",
}

export type FilterOptionType =
  | FilterOptionsEnums.AdvertiserName
  | FilterOptionsEnums.AccountName
  | FilterOptionsEnums.CampaignName
  | FilterOptionsEnums.ChannelName
  | FilterOptionsEnums.Status
  | FilterOptionsEnums.BrandName
  | FilterOptionsEnums.CreativeGroupDesign;

export interface IFilterComponentProps {
  filter?: FilterModel;
  onCancel: () => void;
  onApply: () => void;
  addFilter: (arg0: FilterModel) => void;
  editFilter: (arg0: number, arg1: FilterModel) => void;
}

export type FilterComponent = (
  filterComponentProps: IFilterComponentProps
) => JSX.Element;

export interface IFilter {
  label: string;
  value: string | string[];
  action?: stringAction;
  prototype: IFilterProto;
  index?: number;
}

export interface IFilterProto {
  MenuComponent: string;
  FilterComponent: (props: IFilterComponentProps) => JSX.Element;
  prop: FilterOptionType;
  // eslint-disable-next-line
  filterFunc: (data: Array<any>, filter: FilterModel) => Array<any>;
  multi?: boolean;
  deletable?: boolean;
}

export class FilterModel implements IFilter {
  label: string;
  value: string | string[];
  action?: stringAction;
  prototype: IFilterProto;
  index?: number;

  constructor(filter: IFilter) {
    this.label = filter.label;
    this.value = filter.value;
    this.action = filter.action;
    this.index = filter.index;
    this.prototype = filter.prototype;
    makeAutoObservable(this);
  }

  setPrototype(proto: IFilterProto) {
    this.prototype = proto;
  }
}
