import { useNestedSidebar } from '@monorepo/controlled/src/hooks/use-nested-sidebar';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';
import { route } from '@monorepo/tools/src/lib/types/url';
import { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import routes from '../../../modules/routes/index.routes';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { IMFPStore } from '../../../modules/stores';
import { Page } from '@monorepo/base/src/components/page/page';
import { NestedNavPage, NestedNav, NestedNavItem, NestedNavGroup } from '../../../modules/components/base-wrappers/nested-nav/nested-nav';
import { Tooltip } from '@monorepo/base/src/components/tooltip/tooltip';
import { observer } from 'mobx-react';
import { Directions } from '@monorepo/base/src/components/buttons/buttons';

export const AccountView = observer(() => {
	const { userStore } = useStores<IMFPStore>();
	const { accountId } = useParams();
	const navigate = useNavigate();
	const location = useLocation();
	const { currentRoute } = useRoute();
	useNestedSidebar();

	useEffect(() => {
		if (!userStore?.getUser()?.isInternal) {
			navigate(`${routes.general.home()}`);
		}
	}, [userStore]);

	if (!accountId) {
		navigate(`${routes.accounts.index()}${location.search}`, { replace: true });
		return null;
	}

	const [activeRoute, setActiveRoute] = useState<route>(currentRoute);
	useEffect(() => {
		setActiveRoute(currentRoute);
	}, [currentRoute]);

	return (
		<NestedNavPage>
			<NestedNav onNavClick={({ to }) => to && setActiveRoute(to)} activeRoute={activeRoute}>
				{/* <NestedNavTitle>{accountName ? accountName : <Skeleton is={true} />}</NestedNavTitle> */}
				<NestedNavItem icon={'bar-chart-square-02'} to={accountId ? `${routes.accounts.view(accountId)}${location.search}` : ''}>
					Overview
				</NestedNavItem>
				<NestedNavItem icon={'building-07'} to={accountId ? `${routes.accounts.advertisers(accountId)}${location.search}` : ''}>
					Advertisers
				</NestedNavItem>
				<NestedNavItem icon={'announcement-01'} to={accountId ? `${routes.accounts.campaigns(accountId)}${location.search}` : ''}>
					Campaigns
				</NestedNavItem>
				<NestedNavItem icon={'globe-03'}>
					<Tooltip direction={Directions.FromLeft} content={'Coming Soon'}>
						<span>Landing Pages</span>
					</Tooltip>
				</NestedNavItem>
				<NestedNavItem icon={'timeline'}>
					<Tooltip direction={Directions.FromLeft} content={'Coming Soon'}>
						<span>Activity Log</span>
					</Tooltip>
				</NestedNavItem>
				<NestedNavItem icon={'settings-01'}>
					<Tooltip direction={Directions.FromLeft} content={'Coming Soon'}>
						<span>Settings</span>
					</Tooltip>
				</NestedNavItem>
				<NestedNavGroup title={'Placements'}>
					<NestedNavItem icon={'route'} to={accountId ? `${routes.accounts.channels(accountId)}${location.search}` : ''}>
						Channels
					</NestedNavItem>
					<NestedNavItem icon={'layout-alt-01'}>
						<Tooltip direction={Directions.FromLeft} content={'Coming Soon'}>
							<span>Sources</span>
						</Tooltip>
					</NestedNavItem>
				</NestedNavGroup>
			</NestedNav>
			<Page unstyled={true}>
				<Outlet />
			</Page>
		</NestedNavPage>
	);
});
