import dayjs from 'dayjs';
import { useDates } from '@monorepo/controlled/src/hooks/use-dates';
import {
	availablePerformanceToMetrics,
	PerformanceModel,
	PerformancePayload,
	TPerformanceEnumerable,
} from '../../models/performance.model';
import { isStringInfinityOrNaN, number } from '@monorepo/tools/src/lib/utils/number';
import { useMemo } from 'react';
import { DecimalData, PerformanceEnumarableLabels } from '@monorepo/tools/src/lib/enums/performance-labels';

// TODO - merge with adminx
type TMetrics = { [key: TPerformanceEnumerable]: number[] };

export const useMetrics = (performanceContent: PerformancePayload | null) => {
	const { startDate, endDate } = useDates();
	const performanceItems = performanceContent?.getData() || [];
	const performanceSummary = performanceContent?.getSummary() || {};
	let currentDate = dayjs(startDate);

	const generateMetrics = (items: PerformanceModel[], summary: PerformanceModel) => {
		let multipleMetrics: TMetrics = {};
		const _xLabels: string[] = [];
		let numOfDays = 0;
		const availablePerformanceSlugs = Object.values(availablePerformanceToMetrics);
		// loop by date and build the multipleMetrics object
		// the multiple metrics object will be object of arrays, each array will be array of numbers that will be displayed in the chart, each array will be one metric of availablePerformanceToMetrics
		while (currentDate.isBefore(dayjs(endDate)) || currentDate.isSame(dayjs(endDate))) {
			_xLabels.push(currentDate.format('MMM D, YYYY'));
			numOfDays += 1;
			if (items.length > 0) {
				const currentPerformanceDay = items.find(
					pItem => dayjs(pItem.date).format('YYYY-MM-DD') === currentDate.format('YYYY-MM-DD')
				);
				if (currentPerformanceDay) {
					// Set all available metrics in multipleMetrics
					Object.entries(currentPerformanceDay).forEach(([performanceName, performanceValue]) => {
						if (isStringInfinityOrNaN(performanceValue)) {
							performanceValue = 0;
						}

						if (availablePerformanceSlugs.includes(performanceName)) {
							if (!multipleMetrics[performanceName]) {
								multipleMetrics[performanceName] = [];
							}
							multipleMetrics[performanceName].push(performanceValue);
						}
					});
				}
			}

			currentDate = dayjs(currentDate).add(1, 'day');
		}

		// Fill multiple metrics to be until last day
		const arrayOfZeros = Array(numOfDays).fill(0);
		multipleMetrics = Object.entries(multipleMetrics).reduce((acc, currentMetric) => {
			const [currentMetricName, currentMetricValues] = currentMetric;
			const metricValues = currentMetricValues.filter(m => m).concat(arrayOfZeros);
			acc[currentMetricName] = metricValues;
			return acc;
		}, {} as TMetrics);

		const _metrics = Object.entries(availablePerformanceToMetrics).map(([performanceLabel, performanceSlug]) => {
			return {
				label: performanceLabel as string,
				dataset: {
					data: multipleMetrics[performanceSlug],
					label: performanceLabel as string,
				},
				sum: summary[performanceSlug as keyof PerformanceModel] as number,
				formatter: (val?: number) => number(val, DecimalData.includes(performanceLabel)) ?? 'N/A',
				selected:
					performanceSlug === availablePerformanceToMetrics[PerformanceEnumarableLabels.Cost] ||
					performanceSlug === availablePerformanceToMetrics[PerformanceEnumarableLabels.Revenue],
				visible:
					performanceSlug === availablePerformanceToMetrics[PerformanceEnumarableLabels.Cost] ||
					performanceSlug === availablePerformanceToMetrics[PerformanceEnumarableLabels.Revenue] ||
					performanceSlug === availablePerformanceToMetrics[PerformanceEnumarableLabels.ROAS] ||
					performanceSlug === availablePerformanceToMetrics[PerformanceEnumarableLabels.CPA],
			};
		});

		return {
			metrics: _metrics,
			xLabels: _xLabels,
		};
	};

	const { metrics, xLabels } = useMemo(
		() => generateMetrics(performanceItems, performanceSummary),
		[performanceItems, performanceSummary]
	);

	return { metrics, xLabels };
};
