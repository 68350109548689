import { FormStore } from '@monorepo/controlled/src/stores/form.store';
import { FormError } from '@monorepo/tools/src/lib/models/form-error.model';
import { makeAutoObservable } from 'mobx';
import { CampaignDomIds } from '../../hooks/toc/campaign.toc';

export interface IBasicStrategyModel {
	dailyBudget?: number;
	defaultBid?: number;
}

export interface IBasicStrategyForm {
	dailyBudget?: number;
	defaultBid?: number;
}

export class BasicStrategyModel implements IBasicStrategyModel {
	dailyBudget?: number;
	defaultBid?: number;

	constructor(strategy?: IBasicStrategyModel) {
		this.dailyBudget = strategy?.dailyBudget;
		this.defaultBid = strategy?.defaultBid;

		makeAutoObservable(this);
	}

	public getFormData(): IBasicStrategyForm {
		return {
			dailyBudget: this.dailyBudget,
			defaultBid: this.defaultBid,
		};
	}

	public isValid(formStore: FormStore) {
		if (!this.defaultBid) {
			formStore.addError(new FormError(CampaignDomIds.BasicDefaultBid, 'Please provide default bid'));
		}
	}

	public getDailyBudget(): number | undefined {
		return this.dailyBudget;
	}

	public setDailyBudget(dailyBudget: number): void {
		this.dailyBudget = dailyBudget;
	}

	public getDefaultBid(): number | undefined {
		return this.defaultBid;
	}

	public setDefaultBid(defaultBid: number): void {
		this.defaultBid = defaultBid;
	}
}
