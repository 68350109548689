import { CampaignDomIds } from '../../hooks/toc/campaign.toc';
import { FormStore } from '@monorepo/controlled/src/stores/form.store';
import { FormError } from '@monorepo/tools/src/lib/models/form-error.model';
import { mapToObject } from '@monorepo/tools/src/lib/utils/map';
import { makeObservable, observable, action } from 'mobx';
import { ChannelsEnums, ChannelModel } from '../channel.model';

export interface IAdvancedStrategyModel {
	channels?: Map<ChannelsEnums, ChannelModel>;
}

export interface IAdvancedStrategyForm {
	channels?: Record<ChannelsEnums, ChannelModel>;
}

export class AdvancedStrategyModel implements IAdvancedStrategyModel {
	channels?: Map<ChannelsEnums, ChannelModel>;

	constructor(strategy?: IAdvancedStrategyModel) {
		this.channels = strategy?.channels || new Map();

		makeObservable(this, {
			channels: observable,
			addChannel: action,
			removeChannel: action,
			hasChannel: action,
		});
	}

	public isValid(formStore: FormStore) {
		if (this.channels?.size === 0) {
			formStore.addError(new FormError(CampaignDomIds.Channels, 'Please add at least one channel'));
		}
		this.channels?.forEach(channel => {
			if (!channel?.getDefaultBid()) {
				formStore.addError(
					new FormError(`advanced_strategy_channel_${channel.getName()?.toLowerCase()}_bid`, 'Please provide default bid')
				);
			}
		});
	}

	public getFormData(): IAdvancedStrategyForm {
		return {
			channels: this.channels ? mapToObject<ChannelsEnums, ChannelModel>(this.channels) : {},
		};
	}

	public getChannels() {
		return this.channels;
	}

	public getChannel(channelType: ChannelsEnums) {
		return this.channels?.get(channelType);
	}

	public addChannel(channelType: ChannelsEnums, channel: ChannelModel) {
		this.channels?.set(channelType, channel);
	}

	public hasChannel(channelType: ChannelsEnums) {
		return this.channels?.has(channelType);
	}

	public removeChannel(channelType: ChannelsEnums) {
		return this.channels?.delete(channelType);
	}
}
