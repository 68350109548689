import { FormControl, FormTitle, FormDesc } from '@monorepo/base/src/components/form/form-control/form-control';
import { InputItem, InputInfo, InputTitle, InputDesc } from '@monorepo/base/src/components/form/input-item/input-item';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { CampaignDomIds } from '../../../../hooks/toc/campaign.toc';
import { IMFPStore } from '../../../../stores';
import styles from '../campaign-form.module.scss';
import { SpaceBetweenFormControls, SpaceBetweenInputControls, toolTipDisabledContent } from '../campaign.form';
import { ButtonGroup } from '@monorepo/base/src/components/buttons/group/button-group';
import { GButton } from '@monorepo/base/src/components/buttons/group/button/g-button';
import { Flex } from '@monorepo/base/src/components/flex/flex';
import { DaterCalendar } from '@monorepo/base/src/components/dater/Calendar/calendar';
import dayjs from 'dayjs';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';
import { StatusEnum } from '../../../../enums/status';
import { Tooltip } from '@monorepo/base/src/components/tooltip/tooltip';

export const CampaignDelivery = observer(() => {
	const { currentRoute } = useRoute();
	const isEditPage = currentRoute.includes('edit');
	const [isStartDaterOpen, setIsStartDaterOpen] = useState<boolean>(false);
	const [isEndDaterOpen, setIsEndDaterOpen] = useState<boolean>(false);
	const [isLater, setIsLater] = useState<boolean>(isEditPage);
	const { campaignStore } = useStores<IMFPStore>();

	const campaignCrud = campaignStore.getCrud();
	const formStore = campaignCrud.getFormStore();
	const campaign = campaignCrud.getData();
	const isDraftStatus = campaign.getStatus() === StatusEnum.DRAFT;
	const startDate = campaign.getStartTime();
	const endDate = campaign.getEndTime();
	const onStartApply = () => {
		setIsStartDaterOpen(false);
	};
	const onEndApply = () => {
		setIsEndDaterOpen(false);
	};

	return (
		<div>
			<FormControl>
				<FormTitle id={CampaignDomIds.Delivery}>Campaign Delivery</FormTitle>
				<FormDesc>Select whether you want your campaign to run continuously or set specific start and end dates</FormDesc>
			</FormControl>

			<SpaceBetweenFormControls />

			<InputItem id={CampaignDomIds.Dates}>
				<InputInfo className={styles.inputInfo}>
					<InputTitle>Campaign dates</InputTitle>
					<InputDesc>Enter the start and end dates of your campaign</InputDesc>
				</InputInfo>
				<Flex flexDirection={'column'} alignItems={'flex-start'} gap={'20px'} height={'50px'}>
					{isEditPage && !isDraftStatus ? null : (
						<ButtonGroup
							radio={true}
							defaultValue={'Now'}
							onChange={(setValues: Set<string>) => {
								setIsLater(setValues.values().next().value === 'Later');
							}}>
							<GButton text={'Start Now'} value={'Now'}></GButton>
							<GButton text={'Scheduled'} value={'Later'}></GButton>
						</ButtonGroup>
					)}

					{isLater ? (
						<Flex justifyContent={'flex-start'} gap={'12px'} height={'100px'}>
							<span style={{ flex: '50%' }}>
								<Tooltip is={isEditPage && !isDraftStatus} content={toolTipDisabledContent}>
									<DaterCalendar
										label={'Start Date'}
										disabled={isEditPage && !isDraftStatus}
										minDate={new Date()}
										// maxDate={startDate <= today ? startDate : ''}
										// maxDate={dayjs().isSame(dayjs(startDate)) || dayjs().isBefore(dayjs(startDate)) ? undefined : startDate}
										date={startDate}
										placeholder={'Select date'}
										isOpen={isStartDaterOpen}
										setOpen={setIsStartDaterOpen}
										onChange={(date: Date) => {
											campaign.setStartTime(date);
											if (dayjs(date).isAfter(campaign.getEndTime())) {
												campaign.setEndTime(undefined);
											}
											formStore.clearError(CampaignDomIds.Dates);
										}}
										onApply={onStartApply}
										error={formStore.getErrors().get(CampaignDomIds.Dates)?.getMsg()}
									/>
								</Tooltip>
							</span>
							<span style={{ flex: '50%' }}>
								<DaterCalendar
									label={'End Date'}
									minDate={startDate || new Date()}
									// maxDate={dayjs().isSame(endDate) || dayjs().isAfter(endDate) ? undefined : endDate}
									// maxDate={endDate >= today ? '' : endDate}
									date={endDate}
									placeholder={'Select date'}
									isOpen={isEndDaterOpen}
									setOpen={setIsEndDaterOpen}
									onChange={(date: Date) => {
										campaign.setEndTime(date);
										formStore.clearError(CampaignDomIds.Dates);
									}}
									onApply={onEndApply}
									error={formStore.getErrors().get(CampaignDomIds.Dates)?.getMsg()}
								/>
							</span>
						</Flex>
					) : null}
				</Flex>
			</InputItem>
			<SpaceBetweenInputControls />
			<SpaceBetweenInputControls />
		</div>
	);
});
