import { FC } from 'react';
import styles from './terms-and-condition.module.scss';

export const TermsAndCondition: FC = () => {
	return (
		<div className={styles.wrapper}>
			<div className={styles.date}>Last updated January 03, 2022</div>
			<div className={styles.paragraphsWrapper}>
				<div className={styles.paragraph}>
					<div className={styles.heading}>Terms & Conditions</div>
					<div className={styles.content}>
						These Terms and Conditions (the “Agreement”) are a legal contract between the individual accepting and agreeing to
						this Agreement (“you”, “your”, “user”) and Intango LTD. (“Intango”) who is the owner and operator of the “Mani-Festo
						Dashboard” website and services (the “Mani-Festo Dashboard” website and the services operated by Intango shall be
						collectively referred to as the “Website” and/or the “Services” as applicable). PLEASE READ THESE TERMS AND
						CONDITIONS CAREFULLY BEFORE USING THE WEBSITE AND THE SERVICE. THESE TERMS AND CONDITIONS GOVERN YOUR USE OF THE
						SITE AND SERVICES.
					</div>
				</div>
				<div className={styles.paragraph}>
					<div className={styles.heading}>Acceptance of Terms</div>
					<div className={styles.content}>
						By using the Website and the Services, you agree to comply with and be bound by this Agreement and all policies and
						guidelines incorporated by reference. If you do not wish to be bound by this Agreement, do not indicate your
						acceptance, and do not use the Website and Services. These Terms apply to all visitors, users, and others who access
						or use the Services.
					</div>
				</div>
				<div className={styles.paragraph}>
					<div className={styles.heading}>Third-Party Content</div>
					<div className={styles.content}>
						The Website may make available, links, content, or services created or made available by third parties
						(collectively, “Third-Party Content”). Any use by you of Third-Party Content that is available via the Website is
						subject to the terms and conditions of any such Third-Party Content. You hereby acknowledge that Intango has no
						control over and assumes no responsibility for Third- Party Content. When accessing or using Third-Party Content,
						you are interacting with the applicable Third-Party, not Intango, and you assume all responsibility and risk in
						connection with your use of Third-Party Content. Intango is not responsible for, and makes no warranties, express or
						implied, as to, the Third-Party Content (including without limitation the accuracy or completeness of the
						information provided by such Third-Party Content or the privacy practices of any third party) and it hereby
						disclaims all liability to you or any third party in connection thereto. Intango has no obligation to examine,
						review or monitor Third-Party Content and we are not responsible for the accuracy, completeness, appropriateness, or
						legality of Third-Party Content. Inclusion of any Third-Party Content on the Website does not imply approval or
						endorsement of such Third-Party Content. Intango is not responsible or liable for the Third-Party Content or
						practices of any Third-Party, even if such Third-Party Content links to or is linked by the Website.
					</div>
				</div>
				<div className={styles.paragraph}>
					<div className={styles.heading}>Requirements To Use</div>
					<div className={styles.content}>
						This Agreement and the Privacy Policy govern your use of the Website. In consideration of your use of the Website
						you represent and warrant that: (a) you are of legal age to form a binding contract and are not a person barred from
						receiving services under the laws of your state, province, or country of residence. and (b) your use of the Website
						does not violate any applicable law or regulation that are applicable to your use of the Website. If you use the
						Website, you represent that you can be bound by this Agreement. You represent that you are not under any sanctions
						or prohibited from receiving Services pursuant to these Terms and Conditions under applicable laws.
					</div>
				</div>
				<div className={styles.paragraph}>
					<div className={styles.heading}>Amendments to this Agreement</div>
					<div className={styles.content}>
						Intango may amend this Agreement or other policies that govern the Website from time to time. Any changes to this
						Agreement will be posted on the Website at <a href={'https://app.mani-festo.co/'}>https://app.mani-festo.co/</a> ,
						and the posted Agreement will indicate the date the Agreement was last revised. If you do not agree to the changes,
						you must stop using the Website. By continuing using the Website after any changes to this Agreement are posted, you
						agree to be bound by those changes. Privacy Please read the Website Privacy Policy (“Privacy Policy”) found at
						&nbsp;<a href={'https://app.mani-festo.co/'}>https://app.mani-festo.co/</a> to learn about Intango’s information
						collection, usage, and how such information may be used and/or shared with others. Your election to use the Website
						indicates your acceptance of the terms of the Privacy Policy. You are responsible for all activities that occur in
						your user account, and you agree to inform us immediately of any unauthorized use of your user account by email to
						privacy@mani-festo.co. We are not responsible for any loss or damage to you or to any third party incurred as a
						result of any unauthorized access and/or use of your user account, or otherwise.
					</div>
				</div>
				<div className={styles.paragraph}>
					<div className={styles.heading}>Privacy</div>
					<div className={styles.content}>
						Please read the Website Privacy Policy (“Privacy Policy”) found at&nbsp;
						<a href={'https://app.mani-festo.co/'}>https://app.mani-festo.co/</a> to learn about Intango’s information
						collection, usage, and how such information may be used and/or shared with others. Your election to use the Website
						indicates your acceptance of the terms of the Privacy Policy. You are responsible for all activities that occur in
						your user account, and you agree to inform us immediately of any unauthorized use of your user account by email to
						privacy@mani-festo.co. We are not responsible for any loss or damage to you or to any third party incurred as a
						result of any unauthorized access and/or use of your user account, or otherwise.
					</div>
				</div>
				<div className={styles.paragraph}>
					<div className={styles.heading}>Description of Service</div>
					<div className={styles.content}>
						The Website offers clients a way to gain clarity and accessibility to their digital advertising campaigns which are
						managed through Intango.
					</div>
				</div>
				<div className={styles.paragraph}>
					<div className={styles.heading}>Modifications to the service</div>
					<div className={styles.content}>
						Intango may at, its sole discretion, modify or discontinue, temporarily or permanently, the Website (or any part
						thereof) with or without notice when it deems necessary or useful to (i) maintain or enhance (a) the quality or
						delivery of the Services to you, (b) the Website’s performance, or (ii) to comply with applicable laws. You agree
						that Intango shall not be liable to you or to any third party for any modification or discontinuance of the Website.
					</div>
				</div>
				<div className={styles.paragraph}>
					<div className={styles.heading}>Proprietary Rights</div>
					<div className={styles.content}>
						You acknowledge and agree that the website and any necessary software used in connection with the Website
						(“Software”) contains proprietary and confidential information, including copyrights, trademarks, know-how, etc.,
						that is protected by applicable intellectual property and other applicable laws. You further acknowledge and agree
						that Content contained on the Websiteincluding any information and logos (the “Content”) presented to you through
						the Website is protected by copyrights, trademarks, service marks, patents, or other proprietary rights and laws.
						Except as expressly authorized by Intango, you agree not to use, modify, rent, lease, loan, sell, distribute, or
						create derivative works based on the Website, Software and/or Content, in whole or in part. Intango grants you a
						limited, revocable, non-sublicensable, non-transferable license to use and access the Website solely for your
						personal, non-commercial use, subject to the Terms and Conditions of this Agreement. You shall not (and shall not
						allow any third party to) copy, modify, create a derivative work from, reverse engineer, reverse assemble, or
						otherwise attempt to discover any source code, sell, assign, sublicense, grant a security interest in or otherwise
						transfer any right in the Software. You agree not to modify the Software in any manner or form, nor to use modified
						versions of the Software, including (without limitation) for the purpose of obtaining unauthorized access to the
						Website.
					</div>
				</div>
				<div className={styles.paragraph}>
					<div className={styles.heading}>Disclaimer of Warranties; Limitations of Liability</div>
					<div className={styles.content}>
						You expressly understand and agree that Intango and its subsidiaries, affiliates, officers, directors, shareholders,
						employees, and other representatives shall not be liable to you for any injury, loss, claim, or direct, indirect,
						incidental, punitive, special, consequential or exemplary damages, including, but not limited to, damages for loss
						of profits, business interruption, goodwill, use, data or other intangible losses (even if Intango has been advised
						of the possibility of such damages), whether based in contract, tort (including negligence) or otherwise, resulting
						from (i) the use or the inability to use the Website; (ii) any error or omissions, mistakes or inaccuracies in the
						Service or results that are obtained from the use of the Website; (iii) any loss or damage of any kind result of the
						use of the Website or any content posted, transmitted, or otherwise made available via the Website; or (iv) any
						other matter relating to the Website. Notwithstanding anything to the contrary contained herein, Intango maximum
						aggregate liability to you for any causes whatsoever, and regardless of the form of action, will at all times be
						limited to $20. The exclusions and limitations in this Section apply whether the alleged liability is based on
						contract, tort, negligence, strict liability, or any other basis, even if you have been advised of the possibility
						of such damage. Intango does not guarantee, represent, or warrant that your use of the Website will be
						uninterrupted, timely, secure, or error-free. Intango does not warrant that the data contained on the Website will
						be accurate or reliable. YOU EXPRESSLY UNDERSTAND AND AGREE THAT THE USE OF THE WEBSITE IS AT YOUR SOLE RISK. THE
						WEBSITE IS PROVIDED ON AN “AS-IS” AND “AS AVAILABLE” BASIS. INTANGO EXPRESSLY DISCLAIMS ALL WARRANTIES AND
						REPRESENTATIONS OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF
						MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. INTANGO MAKES NO WARRANTY THAT THE WEBSITE WILL BE
						UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE. INTANGO WILL NOT BE RESPONSIBLE FOR ANY DAMAGES YOU OR A THIRD PARTY
						MAY SUFFER AS A RESULT OF YOUR USE OF THE WEBSITE, INCLUDING WITHOUTLIMITATION ANY USE OF MATERIALS OR INFORMATION
						FOUND ON THE WEBSITE OTHER THAN AS EXPRESSLY AUTHORIZED BY THESE TERMS AND CONDITIONS.
					</div>
				</div>
				<div className={styles.paragraph}>
					<div className={styles.heading}>Indemnification</div>
					<div className={styles.content}>
						You agree to indemnify, defend and hold harmless Intango, its officers, directors, shareholders, subsidiaries,
						affiliates, and employees, from and against all claims, demands, and expenses, including but not limited to
						reasonable attorneys’ fees and other legal expenses, in whole or in part arising out of or attributable to your use
						of the Website, any breach of this Agreement by you, your violation of any law or violation of any rights of a third
						party when using the Website.
					</div>
				</div>
				<div className={styles.paragraph}>
					<div className={styles.heading}>Governing Law & Jurisdiction</div>
					<div className={styles.content}>
						This Agreement shall be construed and enforced in accordance with the laws of the state of Israel, without reference
						to its conflicts of law principles. The courts located in Tel Aviv, Israel shall have exclusive jurisdiction to
						adjudicate any dispute arising out of this Agreement, and each party hereby expressly consents to the personal
						jurisdiction of such courts. If for any reason Intango does not enforce or rely on a right which Intango has
						pursuant to this Agreement or to the general law, such nonenforcement shall not constitute a waiver or abandonment
						of that right. A determination that any term of this Agreement is invalid or unenforceable shall not affect the
						other terms thereof.
					</div>
				</div>
				<div className={styles.paragraph}>
					<div className={styles.heading}>Contact Us</div>
					<div className={styles.content}>
						You can contact Intango by submitting any inquiry or request via privacy@mani-festo.co.
					</div>
				</div>
			</div>
		</div>
	);
};
