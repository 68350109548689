import { CrudEndpoint } from './crud.endpoint';

export class AdvertisersEndpoint extends CrudEndpoint {
	constructor() {
		super('abdvertisers');
	}

	enableAdvertisers() {
		return `${this.baseUrl}/status/ACTIVE`;
	}
	pauseAdvertisers() {
		return `${this.baseUrl}/status/PAUSED`;
	}
}
