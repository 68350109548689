import styles from './sidebar.module.scss';
import sidebarButtonStyles from './sidebar-button.module.scss';
import { useHover } from '@monorepo/tools/src/lib/hooks/utils/use-hover';
import { useRef } from 'react';
import { useDebounce } from '@monorepo/tools/src/lib/hooks/utils/use-debounce';

export enum SidebarMode {
	HOVER = 'hover',
	LOCK = 'lock',
}

interface InjectedSidebarProps {
	isHover: boolean;
}

interface ISidebar {
	children(props: InjectedSidebarProps): JSX.Element;
	isOpen: boolean;
	isAnimate?: boolean;
	mode?: SidebarMode;
}

interface ISidebarButton {
	onClick?: () => void;
	isOpen: boolean;
}

export const SidebarButton = (props: ISidebarButton) => {
	const { onClick, isOpen } = props;

	return (
		<div className={`${sidebarButtonStyles.button} ${isOpen ? sidebarButtonStyles.lock : ''}`} onClick={onClick}>
			<div></div>
			<div></div>
			<div></div>
		</div>
	);
};

export const Sidebar = (props: ISidebar) => {
	const { children, isOpen, isAnimate, mode } = props;
	const hoverRef = useRef(null);
	const isHover = useHover(hoverRef);
	const debouncedIsHover = useDebounce<boolean>(isHover, 100);
	const renderCounter = useRef(0); //TODO - maybe change it later?
	renderCounter.current = renderCounter.current + 1;

	return (
		<aside
			ref={hoverRef}
			className={`${styles.wrapper} ${isOpen || (debouncedIsHover && mode === SidebarMode.HOVER) ? styles.lock : ''} 
			${isOpen || (debouncedIsHover && mode === SidebarMode.HOVER) ? styles.open : styles.close} 
			${renderCounter.current > 3 ? styles.triggerAnimation : styles.noAnmiation} 
			${isAnimate || (debouncedIsHover && mode === SidebarMode.HOVER) ? styles.animate : styles.static}`}>
			{children({ isHover: debouncedIsHover })}
		</aside>
	);
};
