import { AdvertiserModel, IAdvertiserCreateForm, IAdvertiserEditForm } from '../../models/advertiser.model';
import { AdvertisersApi } from '../../apis/advertisers.api';
import { BaseCrudStore } from '@monorepo/controlled/src/stores/base-crud.store';
import { FormError } from '@monorepo/tools/src/lib/models/form-error.model';
import { id } from '@monorepo/tools/src/lib/types/primitives';

export class AdvertiserCrudStore extends BaseCrudStore<AdvertiserModel, IAdvertiserCreateForm, IAdvertiserEditForm> {
	constructor() {
		super({
			apiLayer: AdvertisersApi,
			model: AdvertiserModel,
		});
	}

	/**
	 * Must call isValidAdvertiser before calling this function
	 * @returns
	 */
	public getCreateFormData(): IAdvertiserCreateForm {
		return {
			id: this.getData().getId(),
			name: this.getData().getName(),
			categoryId: this.getData().getCategory(),
			mainDomain: this.getData().getMainDomain(),
			accountId: this.getData().getAccount()?.getId(),
		};
	}

	/**
	 * Must call isValidAdvertiser before calling this function
	 * @returns
	 */
	public getEditFormData(): IAdvertiserEditForm {
		return {
			id: this.getData().getId(),
			name: this.getData().getName(),
			categoryId: this.getData().getCategory(),
			mainDomain: this.getData().getMainDomain(),
		};
	}

	public isValid(): boolean {
		this.formStore.reset();

		if (!this.getData().getName()) {
			this.formStore.addError(new FormError('name', 'Please provide a name'));
		}

		// if (!this.getData().getMainDomain()) {
		// 	this.formStore.addError(new FormError('domain', 'Please provide a domain'));
		// }

		if (!this.getData().getCategory()) {
			this.formStore.addError(new FormError('category', 'Please select a category'));
		}

		if (!this.getData().getAccount()) {
			this.formStore.addError(new FormError('account', 'Please select an account'));
		}

		return this.formStore.getIsValid();
	}

	enableAdvertisers(advertisersIds: id[]): Promise<void> {
		return AdvertisersApi.enableAdvertisers(advertisersIds)
			.then(() => {
				this.setIsLoading(false);
				this.setIsSuccess(true);
			})
			.catch(() => {
				this.setIsLoading(false);
				this.setIsSuccess(false);
			});
	}

	pauseAdvertisers(advertisersIds: id[]): Promise<void> {
		return AdvertisersApi.pauseAdvertisers(advertisersIds)
			.then(() => {
				this.setIsLoading(false);
				this.setIsSuccess(true);
			})
			.catch(() => {
				this.setIsLoading(false);
				this.setIsSuccess(false);
			});
	}
}
