import { Fragment } from 'react';
import { BarLoader } from '@monorepo/base/src/components/bar-loader/bar-loader';
import { Skeleton } from '@monorepo/base/src/components/skeleton/skeleton';
import { Select } from '../../select/select';

interface IFilterOptions {
	options: string[];
	value?: string;
	onFilterOption: (arg0: string | string[]) => void;
	isLoading?: boolean;
	placeholder?: string;
	onCancel?: () => void;
	isSelectAll?: boolean;
}

export const FilterOptions = (props: IFilterOptions) => {
	const { value, options, isLoading = false, onFilterOption, placeholder, onCancel, isSelectAll = false } = props;
	return (
		<Fragment>
			<BarLoader is={isLoading} />
			<Select
				placeholder={placeholder}
				values={options}
				onApply={_value => onFilterOption(_value)}
				disabled={isLoading}
				defaultValue={value}
				multiple={true}
				onCancel={onCancel}
				isSelectAll={isSelectAll}
			/>
			<Skeleton is={isLoading} rectanglesAmount={6} />
		</Fragment>
	);
};
