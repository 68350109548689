import { httpStatus } from '../enums/http-statuses';

export interface IHttpError extends Error {
	name: string;
	message: string;
	messages?: string[];
	httpStatus?: httpStatus;
}
export class HttpError implements IHttpError {
	name: string;
	message: string;
	messages?: string[];
	httpStatus?: httpStatus;

	constructor(props: IHttpError) {
		this.messages = props.messages || [props.message];
		this.httpStatus = props.httpStatus;
		this.name = props.name || props.message;
		this.message = props?.messages?.[0] || props.message;
	}

	getMessages(): string[] {
		return this.messages || [this.message];
	}

	getMessage(): string {
		return this.message;
	}
}
