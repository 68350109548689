import { id } from '@monorepo/tools/src/lib/types/primitives';
import { PerformancePayload } from '../../models/performance.model';
import { IPerformanceOptionsSortBy } from '../apis/use-reports-list';

export interface IInsight {
	name: string;
	value: string;
	id: id;
	advertiser?: {
		id: id;
		name: string;
	};
}

export const useInsights = (performancePayload: PerformancePayload | null, value: IPerformanceOptionsSortBy): IInsight[] => {
	const performanceItems = performancePayload?.getData() || [];

	const data: IInsight[] = performanceItems.map(item => {
		return {
			id: item.id,
			name: item.name,
			value: item[value],
			advertiser: {
				id: item.advertiser_id,
				name: item.advertiser_name,
			},
		} as IInsight;
	});

	return data;
};
