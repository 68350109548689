import { FacebookIcon } from '@monorepo/base/src/assets/facebook.icon';
import { GoogleIcon } from '@monorepo/base/src/assets/google.icon';
import { MicrosoftIcon } from '@monorepo/base/src/assets/microsoft.icon';
import { PrimaryButton } from '@monorepo/base/src/components/buttons/buttons';
import { useLoginWith } from '../../../modules/components/bouncer-login/login-with';
import { OAuthProviderEnum } from '../../enums/oauth-providers';
import styles from './third-party-auth.module.scss';

export const ThirdPartyAuth = () => {
	const { signInWith } = useLoginWith();

	return (
		<div className={styles.loginButtonsWrapper}>
			<PrimaryButton
				onClick={async e => await signInWith(e, OAuthProviderEnum.Google)}
				className={styles.loginButtonLink}
				icon={<GoogleIcon classes={styles.icon} />}>
				Continue with Google
			</PrimaryButton>
			<PrimaryButton
				onClick={async e => await signInWith(e, OAuthProviderEnum.Microsoft)}
				icon={<MicrosoftIcon classes={styles.icon} />}
				className={styles.loginButtonLink}>
				Continue with Microsoft
			</PrimaryButton>
			<PrimaryButton
				onClick={async e => await signInWith(e, OAuthProviderEnum.Facebook)}
				icon={<FacebookIcon classes={styles.icon} />}
				className={styles.loginButtonLink}>
				Continue with Facebook
			</PrimaryButton>
		</div>
	);
};
