import { Page, Pagebar, PagebarTitle } from '@monorepo/base/src/components/page/page';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { useEffect, useState } from 'react';
import { PrimaryButton, SecondaryButton } from '@monorepo/base/src/components/buttons/buttons';
import { observer } from 'mobx-react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Disable } from '@monorepo/base/src/components/disable/disable';
import { BottomBar, BottomBarActions } from '@monorepo/base/src/components/bottom-bar/bottom-bar';
import { useLogs } from '@monorepo/controlled/src/hooks/use-logs';
import routes from '../../../modules/routes/index.routes';
import { ErrorMsg } from '@monorepo/base/src/components/error-msg/error-msg';
import { IMFPStore } from '../../../modules/stores';
import { AdvertiserForm } from '../../../modules/components/forms/advertiser/advertiser.form';
import { useAdvertiserGet } from '../../../modules/hooks/apis/use-advertiser-get';
import { BetweenPagesStateProps } from '@monorepo/tools/src/lib/interfaces/global';
import { SiderSlider } from '@monorepo/base/src/components/sider-slider/sider-slider';
import styles from './edit-advertiser.module.scss';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';

export const EditAdvertiser = observer(() => {
	const { advertiserStore } = useStores<IMFPStore>();
	const { dispatchLog } = useLogs();
	const [isFormClicked, setFormClicked] = useState<boolean>(false);
	const advertiserCrudStore = advertiserStore.getCrud();
	const advertiser = advertiserCrudStore.getData();

	const navigate = useNavigate();
	const location = useLocation();
	const params = useParams();
	if (params.advertiserId) {
		useAdvertiserGet({ id: params.advertiserId });
	}

	useEffect(() => {
		advertiserCrudStore.reset();
	}, [location]);

	const name = advertiser.getName();
	const formStore = advertiserCrudStore.getFormStore();
	const isValidForm = formStore.getIsValid();
	const isLoading = advertiserCrudStore.getIsLoading();
	const isSuccess = advertiserCrudStore.getIsSuccess();
	const error = advertiserCrudStore.getHttpError();

	useEffect(() => {
		if (!isValidForm && isFormClicked) {
			dispatchLog({
				msg: 'Please complete all mandatory fields',
				type: 'error',
			});
		}
	}, [isFormClicked]);

	useEffect(() => {
		if (isSuccess) {
			navigate(`${routes.advertisers.index()}${location.search}`);
			dispatchLog({
				msg: `Advertiser ${name} was successfully edited`,
				type: 'success',
			});
			advertiserCrudStore.setIsSuccess(false);
		}
	}, [isSuccess]);

	const onEditAdvertiser = () => {
		setFormClicked(true);
		advertiserStore.getCrud().edit();
	};

	return (
		<SiderSlider
			onClose={() => navigate(routes.advertisers.index(), { state: { isBlockRequest: true } as BetweenPagesStateProps })}
			isOpen={true}>
			<Disable is={isLoading}>
				<Page unstyled className={styles.wrapper}>
					<Pagebar>
						<PagebarTitle classes={{ title: styles.title }} unstyled>
							Edit Advertiser
						</PagebarTitle>
					</Pagebar>
					<AdvertiserForm />
					<BottomBar classes={{ barClassses: styles.advertiserBottomBar, wrapperClassses: styles.advertiserBottomBarWrapper }}>
						{error ? <ErrorMsg error={error} /> : null}
						<BottomBarActions>
							<SecondaryButton
								onClick={() => navigate(-1)}
								debugProps={{ dataAttrs: [new DataAttribute('id', 'cancel_advertiser_button')] }}>
								Cancel
							</SecondaryButton>
							<PrimaryButton
								onClick={() => onEditAdvertiser()}
								debugProps={{ dataAttrs: [new DataAttribute('id', 'save_advertiser_button')] }}>
								Save
							</PrimaryButton>
						</BottomBarActions>
					</BottomBar>
				</Page>
			</Disable>
		</SiderSlider>
	);
});
