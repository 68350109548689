import { IInjectHeadItem } from '@monorepo/base/src/components/table-of-contents/table-of-contents';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { SubTocItem, TocItem } from '../../components/base-wrappers/toc-item/toc-item';
import { Strategies } from '../../enums/strategies';
import { IMFPStore } from '../../stores';
import { ChannelsEnums } from '../../models/channel.model';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';
import { StatusEnum } from '../../enums/status';

export enum CampaignDomIds {
	Profile = 'campaign-profile',
	Advertiser = 'campaign-advertiser',
	Name = 'campaign-name',
	LandingPage = 'campaign-landing-page',
	Delivery = 'campaign-delivery',
	Dates = 'campaign-dates',
	Targeting = 'campaign-targeting',
	Devices = 'campaign-devices',
	Geos = 'campaign-geos',
	BrowserLanguages = 'campaign-browser-languages',
	Strategy = 'campaign-strategy',
	TotalBudget = 'campaign-total-budget',

	// Smart
	SmartDailyBudget = 'campaign-smart-daily-budget',
	OptimizationMode = 'campaign-smart-optimization-mode',
	OptimizationBid = 'campaign-smart-optimization-bid',
	ConversionType = 'campaign-smart-conversion-type',

	// Basic
	BasicDailyBudget = 'campaign-basic-daily-budget',
	BasicDefaultBid = 'campaign-basic-default-bid',

	// Advanced
	Channels = 'campaign-advanced-channels',
}

export const useCampaignTableOfContents = () => {
	const [isTableOfContents, setIsTableOfContents] = useState<boolean>(false);
	const { campaignStore } = useStores<IMFPStore>();
	const { currentRoute } = useRoute();
	const isEditPage = currentRoute.includes('edit');
	campaignStore.getCrud(); // TODO - @RONT we need it?
	const campaign = campaignStore.getCrud().getData();
	const isDraftStatus = campaign.getStatus() === StatusEnum.DRAFT;
	const isDisabled = isEditPage && !isDraftStatus;
	const strategyType = campaign.getStrategyType();
	const location = useLocation();
	const formStore = campaignStore.getCrud().getFormStore();

	useEffect(() => {
		return () => setIsTableOfContents(false);
	}, [location]);

	const getChannelsErrors = () => {
		const insideChannelErrors = Object.values(ChannelsEnums)
			.map(channel => formStore.getErrors().get(`advanced_strategy_channel_${channel.toLowerCase()}_bid`)?.getMsg())
			.filter(channel => channel);
		return insideChannelErrors.length > 0 || Boolean(formStore.getErrors().get(CampaignDomIds.Channels)?.getMsg());
	};

	const getStrategySteps = () => {
		if (strategyType === Strategies.Smart) {
			return [
				{
					element: (subHeadProps: IInjectHeadItem) => (
						<SubTocItem {...subHeadProps} isError={Boolean(formStore.getErrors().get(CampaignDomIds.TotalBudget)?.getMsg())}>
							Total Budget
						</SubTocItem>
					),
					id: CampaignDomIds.TotalBudget,
					scroll: false,
				},
				{
					element: (subHeadProps: IInjectHeadItem) => <SubTocItem {...subHeadProps}>Daily Budget</SubTocItem>,
					id: CampaignDomIds.SmartDailyBudget,
					scroll: false,
				},
				{
					element: (subHeadProps: IInjectHeadItem) => (
						<SubTocItem
							{...subHeadProps}
							isError={Boolean(formStore.getErrors().get(CampaignDomIds.OptimizationMode)?.getMsg())}>
							Optimization Mode
						</SubTocItem>
					),
					id: CampaignDomIds.OptimizationMode,
					scroll: false,
				},
				{
					element: (subHeadProps: IInjectHeadItem) => (
						<SubTocItem
							{...subHeadProps}
							isError={Boolean(formStore.getErrors().get(CampaignDomIds.OptimizationBid)?.getMsg())}>
							Optimization Bid
						</SubTocItem>
					),
					id: CampaignDomIds.OptimizationBid,
					scroll: false,
				},
				{
					element: (subHeadProps: IInjectHeadItem) => (
						<SubTocItem {...subHeadProps} isError={Boolean(formStore.getErrors().get(CampaignDomIds.ConversionType)?.getMsg())}>
							Conversion Type
						</SubTocItem>
					),
					id: CampaignDomIds.ConversionType,
					scroll: false,
				},
			];
		}

		if (strategyType === Strategies.Advanced) {
			return [
				{
					element: (subHeadProps: IInjectHeadItem) => (
						<SubTocItem {...subHeadProps} isError={Boolean(formStore.getErrors().get(CampaignDomIds.TotalBudget)?.getMsg())}>
							Total Budget
						</SubTocItem>
					),
					id: CampaignDomIds.TotalBudget,
					scroll: false,
				},
				{
					element: (subHeadProps: IInjectHeadItem) => (
						<SubTocItem {...subHeadProps} isError={getChannelsErrors()}>
							Channels
						</SubTocItem>
					),
					id: CampaignDomIds.Channels,
					scroll: false,
				},
			];
		}

		if (strategyType === Strategies.Basic) {
			return [
				{
					element: (subHeadProps: IInjectHeadItem) => (
						<SubTocItem {...subHeadProps} isError={Boolean(formStore.getErrors().get(CampaignDomIds.TotalBudget)?.getMsg())}>
							Total Budget
						</SubTocItem>
					),
					id: CampaignDomIds.TotalBudget,
					scroll: false,
				},
				{
					element: (subHeadProps: IInjectHeadItem) => (
						<SubTocItem
							{...subHeadProps}
							isError={Boolean(formStore.getErrors().get(CampaignDomIds.BasicDefaultBid)?.getMsg())}>
							Bid
						</SubTocItem>
					),
					id: CampaignDomIds.BasicDefaultBid,
					scroll: false,
				},
				{
					element: (subHeadProps: IInjectHeadItem) => <SubTocItem {...subHeadProps}>Daily Budget</SubTocItem>,
					id: CampaignDomIds.BasicDailyBudget,
					scroll: false,
				},
			];
		}

		return []; // Will not get here
	};

	return {
		isTableOfContents,
		tableOfContents: [
			{
				id: CampaignDomIds.Profile,
				element: (headProps: IInjectHeadItem) => (
					<TocItem
						title={'Campaign Profile'}
						steps={[
							{
								element: (subHeadProps: IInjectHeadItem) => (
									<SubTocItem
										{...subHeadProps}
										isError={Boolean(formStore.getErrors().get(CampaignDomIds.Name)?.getMsg())}>
										Name
									</SubTocItem>
								),
								id: CampaignDomIds.Name,
								scroll: false,
							},
							{
								element: (subHeadProps: IInjectHeadItem) => (
									<SubTocItem
										{...subHeadProps}
										isDisabled={isDisabled}
										isError={Boolean(formStore.getErrors().get(CampaignDomIds.Advertiser)?.getMsg())}>
										Advertiser
									</SubTocItem>
								),
								id: CampaignDomIds.Advertiser,
								scroll: false,
							},
							{
								element: (subHeadProps: IInjectHeadItem) => (
									<SubTocItem
										{...subHeadProps}
										isError={Boolean(formStore.getErrors().get(CampaignDomIds.LandingPage)?.getMsg())}>
										Landing Page
									</SubTocItem>
								),
								id: CampaignDomIds.LandingPage,
								scroll: false,
							},
						]}
						{...headProps}
					/>
				),
			},
			{
				id: CampaignDomIds.Delivery,
				element: (headProps: IInjectHeadItem) => (
					<TocItem
						title={'Campaign Delivery'}
						steps={[
							{
								element: (subHeadProps: IInjectHeadItem) => (
									<SubTocItem {...subHeadProps} isDisabled={isDisabled}>
										Dates
									</SubTocItem>
								),
								id: CampaignDomIds.Dates,
								scroll: false,
							},
						]}
						{...headProps}
					/>
				),
			},
			{
				id: CampaignDomIds.Targeting,
				element: (headProps: IInjectHeadItem) => (
					<TocItem
						title={'Campaign Targeting'}
						steps={[
							{
								element: (subHeadProps: IInjectHeadItem) => (
									<SubTocItem
										{...subHeadProps}
										isDisabled={isDisabled}
										isError={Boolean(formStore.getErrors().get(CampaignDomIds.Devices)?.getMsg())}>
										Devices
									</SubTocItem>
								),
								id: CampaignDomIds.Devices,
								scroll: false,
							},
							{
								element: (subHeadProps: IInjectHeadItem) => (
									<SubTocItem
										{...subHeadProps}
										isDisabled={isDisabled}
										isError={Boolean(formStore.getErrors().get(CampaignDomIds.Geos)?.getMsg())}>
										Country
									</SubTocItem>
								),
								id: CampaignDomIds.Geos,
								scroll: false,
							},
						]}
						{...headProps}
					/>
				),
			},
			{
				id: CampaignDomIds.Strategy,
				element: (headProps: IInjectHeadItem) => (
					<TocItem title={'Budget and Bidding Strategy'} steps={getStrategySteps()} {...headProps} />
				),
			},
		],
		setIsTableOfContents,
	};
};
