import { IDebugProps } from '@monorepo/tools/src/lib/interfaces/debug';
import { generateDataAttrs } from '@monorepo/tools/src/lib/models/data-attr.model';
import styles from './icon.module.scss';

// !For now only outlined, filled and rounded are working, if you want sharp and two tone, need to add it in public/index.html
export enum IconFonts {
	Outlined = 'outlined',
	Filled = 'filled',
	Round = 'round',
	Sharp = 'sharp',
	TwoTone = 'two-tone',
	None = '',
}

interface IIcon {
	children: JSX.Element | string;
	onClick?: React.MouseEventHandler<HTMLDivElement>;
	wrapperStyles?: object;
	className?: string;
	color?: string;
	size?: string;
	radius?: boolean;
	font?: IconFonts;
	isMFP?: boolean;
	debugProps?: IDebugProps;
}

// material icons can be filled or outlined or round to sharp or two tone

export const Icon = (props: IIcon) => {
	const { children, onClick, wrapperStyles = {}, className = '', color, size, radius, font, isMFP, debugProps } = props;
	const { dataAttrs } = debugProps || {};

	if (isMFP) {
		const fontIcon = font || IconFonts.Outlined;
		return (
			<span
				className={`mfp-${fontIcon}-icons mfp-${fontIcon}-icons-${children}  ${className} ${
					onClick ? `${styles.iconOnClick}` : ''
				} ${radius ? `${styles.radius}` : ''}`}
				onClick={onClick}
				style={{ color, fontSize: size }}
				{...generateDataAttrs(dataAttrs)}
			/>
		);
	}

	// DEPRECATED
	// if (isMUI) {
	// 	return (
	// 		<span
	// 			onClick={onClick}
	// 			className={`material-icons material-icons${font ? `-${font}` : ''} ${className} ${onClick ? `${styles.iconOnClick}` : ''} ${
	// 				radius ? `${styles.radius}` : ''
	// 			}`}
	// 			style={{ color, fontSize: size }}>
	// 			{children}
	// 		</span>
	// 	);
	// }

	return (
		<div onClick={onClick} className={className} style={{ display: 'flex', alignItems: 'center', ...wrapperStyles }}>
			{children}
		</div>
	);
};
