import { Chart, IChart } from '@monorepo/base/src/components/chart/chart';
import styles from './manifesto-chart.module.scss';

export const ManifestoChart = (props: IChart) => {
	return (
		<div className={styles.chartWrapper}>
			<Chart {...props} />
		</div>
	);
};
