import {
	INav,
	INavGroup,
	INavItem,
	INavPage,
	INavTitle,
	Nav,
	NavGroup,
	NavItem,
	NavPage,
	NavTitle,
} from '@monorepo/base/src/components/nav/nav';
import styles from './nested-nav.module.scss';
import { Tooltip } from '@monorepo/base/src/components/tooltip/tooltip';
import { Directions } from '@monorepo/base/src/components/buttons/buttons';

export const NestedNavPage = (props: INavPage) => {
	const { children, ...rest } = props;
	return (
		<NavPage classes={{ navWrapper: styles.nestedNavPage }} {...rest}>
			{children}
		</NavPage>
	);
};

export const NestedNav = (props: INav) => {
	const { children, ...rest } = props;
	return (
		<Nav classes={{ nav: styles.nestedNav, activeNavItem: styles.nestedActiveNav }} unstyled={true} {...rest}>
			{children}
		</Nav>
	);
};
NestedNav.displayName = 'Nav'; // TODO - hate it

export const NestedNavTitle = (props: INavTitle) => {
	const { children, ...rest } = props;

	return (
		<Tooltip direction={Directions.FromLeft} content={<span>{children}</span>} bottom={'5px'}>
			<NavTitle classes={{ navTitle: styles.navTitle }} {...rest}>
				<span>{children}</span>
			</NavTitle>
		</Tooltip>
	);
};

export const NestedNavItem = (props: INavItem) => {
	const { children, ...rest } = props;

	return (
		<NavItem className={styles.navItem} linkClassName={styles.navLink} {...rest}>
			{children}
		</NavItem>
	);
};

export const NestedNavGroup = (props: INavGroup) => {
	const { children, ...rest } = props;

	return (
		<NavGroup
			classes={{ navGroup: styles.nestedNavGroup }}
			accordionProps={{
				classes: {
					wrapper: styles.navGroupAccordionWrapper,
					title: styles.navGroupAccordionTitle,
					toggler: styles.navGroupToggler,
					togglerIcon: styles.togglerIcon,
				},
			}}
			{...rest}>
			{children}
		</NavGroup>
	);
};
