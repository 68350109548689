import {
	SortingState,
	getSortedRowModel,
	getCoreRowModel,
	useReactTable,
	ColumnDef,
	flexRender,
	getPaginationRowModel,
	Row,
	ColumnSort,
	PaginationState,
	ExpandedState,
	VisibilityState,
	getFilteredRowModel,
	getExpandedRowModel,
	FilterFn,
} from '@tanstack/react-table';
import { useLocation } from 'react-router-dom';
import { ElementType, memo, useEffect, useState } from 'react';
import { BarLoader } from '@monorepo/base/src/components/bar-loader/bar-loader';
import { IControlledStore, useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { useDidMount } from '@monorepo/tools/src/lib/hooks/utils/use-didmount';
import { Icon } from '@monorepo/base/src/components/icon/icon';
import { ActionLiner } from './action-liner/action-liner';
import { PageSize } from './page-size/page-size';
import { Pagination } from './pagination/pagination';
import { TableInfo } from './table-info/table-info';
import { TableSkeleton } from './table-skeleton/table-skeleton';
import { Flex } from '@monorepo/base/src/components/flex/flex';
import styles from './table.module.scss';
import { IDebugProps } from '@monorepo/tools/src/lib/interfaces/debug';
import { generateDataAttrs, suffixToDataAttr } from '@monorepo/tools/src/lib/models/data-attr.model';

export interface IRowProps<T> {
	subRows?: T[];
}

export interface InjectedTableActionsProps<T> {
	rows: Row<T>[];
}

// TODO - change to ITableActions
export interface ITableLiners {
	IndexActions?: ElementType | null;
	SelectedActions?: ElementType | null;
	FiltersActions?: ElementType | null;
	SegmentActions?: ElementType | null;
}

export interface ISegmentActions {
	debugProps?: IDebugProps;
}

export interface ITableProps<T> {
	columns: ColumnDef<T, any>[]; // TODO - fix this any https://github.com/TanStack/table/issues/4382
	data: T[];
	// subComponent?: (args0: { row: Row }) => JSX.Element; // TODO
	children?(props: InjectedTableActionsProps<T>): ITableLiners;
	classes?: {
		wrapper?: string;
	};
	isLoading?: boolean;
	renderCell?: (row: Row<T>) => JSX.Element[];
	isBarLoader?: boolean;
	isSkeleton?: boolean;
	defaultSortBy?: ColumnSort[];
	columnVisibility?: VisibilityState;
	pagination?: PaginationState;
	isSummary?: boolean;
	filterTerm?: string;
	onPageChange?: (args: PaginationState) => void;
	totalRows?: string | number;
	label?: string;
	debugProps?: IDebugProps;
	emptyState?: JSX.Element;
}

export function isSubRow<T>(row: Row<T>) {
	return row.depth > 0;
}

// TODO! - when moving to reporting api do controlled pagination
// TODO - table store should be per table, the table store is for saving in sessionstorage part of the current state
// TODO - global filter should be from reporting api for the summary line
function _Table<T extends IRowProps<T>>(props: ITableProps<T>) {
	const {
		columns,
		data,
		isLoading,
		isBarLoader = true,
		isSkeleton = true,
		children,
		renderCell,
		columnVisibility: initialColumnVisibility,
		pagination: initialPagination,
		defaultSortBy,
		isSummary,
		filterTerm = '',
		onPageChange,
		totalRows,
		label = 'rows',
		debugProps,
		emptyState,
		classes,
	} = props;

	const { tableStore } = useStores<IControlledStore>(); // TODO - @MFP remove
	const didMount = useDidMount();
	const { dataAttrs } = debugProps || {};

	const [sorting, setSorting] = useState<SortingState>(defaultSortBy || []);
	const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({ ...initialColumnVisibility });
	const [globalFilter, setGlobalFilter] = useState<string>(filterTerm);
	const [pagination, setPagination] = useState<PaginationState>(initialPagination || { pageIndex: 0, pageSize: 10 });
	const [expanded, setExpanded] = useState<ExpandedState>({ expanded: true });

	const globalFilterFn: FilterFn<T> = (row, columnId, filterValue: string) => {
		const search = filterValue.toLowerCase();

		let value = row.getValue(columnId) as string;
		if (typeof value === 'number') {
			value = String(value);
		}

		return value?.toLowerCase().includes(search);
	};

	const tableProps = useReactTable<T>({
		data,
		columns,
		initialState: {
			expanded,
			pagination,
			columnVisibility,
			globalFilter,
			sorting,
		},
		state: {
			expanded,
			pagination,
			columnVisibility,
			globalFilter,
			sorting,
		},
		globalFilterFn,
		onExpandedChange: setExpanded,
		getSubRows: row => row.subRows,
		onPaginationChange: setPagination,
		onGlobalFilterChange: setGlobalFilter,
		onColumnVisibilityChange: setColumnVisibility,
		getFilteredRowModel: getFilteredRowModel(),
		onSortingChange: setSorting,
		getCoreRowModel: getCoreRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getExpandedRowModel: getExpandedRowModel(),
	});

	const {
		getHeaderGroups,
		getFooterGroups,
		getRowModel,
		getState,
		setPageSize,
		getVisibleFlatColumns,
		getSelectedRowModel,
		toggleAllRowsExpanded,

		getCanPreviousPage,
		getCanNextPage,
		getPageOptions,
		setPageIndex,
		//
	} = tableProps;

	const location = useLocation();

	useEffect(() => {
		toggleAllRowsExpanded(true);
		return () => {
			// TODO - @MFP need eventually to remove this, we want to save the table page size in the url.
			onSetPageIndex(0);
			onSetGlobalFilter('');
		};
	}, [location]);

	const { rows } = getRowModel();
	const { flatRows: selectedRows } = getSelectedRowModel();

	const tableState = getState();
	const statePageSize = tableState.pagination.pageSize;
	const statePageIndex = tableState.pagination.pageIndex;
	// totalRows = totalRows || data.length;
	// const min = statePageIndex === 0 ? 1 : statePageSize * statePageIndex + 1;
	// const max = total < statePageSize * (statePageIndex + 1) ? total : statePageSize * (statePageIndex + 1);

	const stateGlobalFilter = tableState.globalFilter;
	const visibleColumns = getVisibleFlatColumns();

	const onSetPageIndex = (_pageIndex: number) => {
		setPageIndex(_pageIndex);
		if (onPageChange) {
			onPageChange({ pageIndex: _pageIndex, pageSize: statePageSize });
		}
		// tableStore.setPageIndex(_pageIndex); // TODO - @MFP remove
	};

	const onSetPageSize = (_pageSize: number) => {
		setPageSize(_pageSize);
		tableStore.setPageSize(_pageSize); // TODO - @MFP remove
	};

	const onSetGlobalFilter = (_filterTerm: string) => {
		onSetPageIndex(0);
		// tableStore.setFilterTerm(_filterTerm || ''); // TODO - @MFP need to be from the server
		setGlobalFilter(_filterTerm);
	};

	const isEmptyResults = !isLoading && rows?.length === 0 && data?.length === 0 && didMount;
	const _isSummary = (() => {
		if (stateGlobalFilter) {
			return false;
		}
		if (isLoading) {
			return false;
		}
		if (isEmptyResults) {
			return false;
		}
		return isSummary;
	})();

	let actions: ITableLiners = {};
	if (children) {
		actions = children({ rows: selectedRows });
	}
	const { SelectedActions } = actions;

	const _renderCell = (row: Row<T>) => {
		if (renderCell) {
			return renderCell(row);
		}

		return row.getVisibleCells().map(cell => <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>);
	};

	const TableHead = () => {
		return (
			<thead>
				{getHeaderGroups().map(headerGroup => (
					<tr key={headerGroup.id}>
						{headerGroup.headers.map(header => (
							<th key={header.id} colSpan={header.colSpan}>
								{header.isPlaceholder ? null : (
									<div className={styles.thContent} onClick={header.column.getToggleSortingHandler()}>
										{flexRender(header.column.columnDef.header, header.getContext())}
										{{
											asc: (
												<Icon className={styles.arrowDirection} isMFP={true} size={'14px'}>
													arrow-up
												</Icon>
											),
											desc: (
												<Icon className={styles.arrowDirection} isMFP={true} size={'14px'}>
													arrow-down
												</Icon>
											),
										}[header.column.getIsSorted() as string] ?? null}
									</div>
								)}
							</th>
						))}
					</tr>
				))}
			</thead>
		);
	};

	const TableBody = () => {
		return (
			<tbody>
				{isSkeleton ? (
					<TableSkeleton isLoading={isLoading || isEmptyResults || false} columnsAmount={visibleColumns.length} />
				) : null}
				{_isSummary ? TotalLine() : null}
				{rows.map(row => {
					// TODO - need to move it to headers files, we want to take the name column and put it in data-id on the tr
					// i map all cells because row.getValue('name') when there is no column name is just crashing everything
					const cell = row.getAllCells().filter(c => c.id.includes('name'))?.[0];
					let _dataAttrs = {};
					if (cell) {
						_dataAttrs = { ...generateDataAttrs(suffixToDataAttr(`_${cell.getValue()}`, dataAttrs)) };
					}
					return (
						<tr
							key={row.id}
							className={`${row.getIsSelected() ? styles.selectedRow : ''} ${isSubRow(row) ? styles.subRow : styles.row}`}
							{..._dataAttrs}>
							{_renderCell(row)}
						</tr>
					);
				})}
			</tbody>
		);
	};

	const TotalLine = () => {
		return getFooterGroups().map(group => {
			return (
				<tr key={group.id} className={_isSummary ? styles.summary : ''}>
					{group.headers.map((header, index) => {
						if (index === 1) {
							return (
								<td className={styles.summaryOverall} key={header.id}>
									<div className={styles.tdContent}>
										<span>
											Overall total {totalRows || data.length.toLocaleString()} {label}
										</span>
									</div>
									<div className={styles.absoluteBorder}></div>
								</td>
							);
						}

						return (
							<td key={header.id}>
								<div className={styles.tdContent}>{flexRender(header.column.columnDef.footer, header.getContext())}</div>
								{/* <div className={styles.absoluteBorder}></div> */}
							</td>
						);
					})}
				</tr>
			);
		});
	};

	return (
		<div
			className={`${styles.wrapper} ${SelectedActions ? styles.withSelections : ''} ${classes?.wrapper}`}
			{...generateDataAttrs(dataAttrs)}>
			<ActionLiner<T> tableProps={tableProps} tableActions={actions} onSetGlobalFilter={onSetGlobalFilter} debugProps={debugProps} />
			<div className={styles.tableWrapper}>
				<div className={styles.tableResponsive}>
					{isEmptyResults && !emptyState ? <div className={styles.noRows}>{'No Results Found'}</div> : null}
					<div className={styles.barLoader}>
						<BarLoader is={isBarLoader || false} />
					</div>
					<table className={styles.table}>
						<TableHead />
						{!(isEmptyResults && emptyState) ? <TableBody /> : null}
					</table>
					{isEmptyResults && emptyState ? emptyState : null}
				</div>
			</div>
			<div className={styles.belowTable}>
				<Flex justifyContent={'flex-start'} gap={'20px'}>
					<TableInfo total={totalRows || data.length} label={label} />
					<PageSize pageSize={statePageSize} setPageSize={onSetPageSize} />
				</Flex>
				{/* TODO - when subrows calc total */}
				<Pagination
					pageSize={statePageSize}
					total={totalRows || data.length}
					canPreviousPage={getCanPreviousPage()}
					canNextPage={getCanNextPage()}
					// canNextPage={getCanNextPage() || state}
					pageOptions={getPageOptions()}
					gotoPage={onSetPageIndex}
					pageIndex={statePageIndex}
				/>
			</div>
		</div>
	);
}

export const Table = memo(_Table) as typeof _Table;
