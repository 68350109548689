import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { useEffect } from 'react';
import { ICrudGetProps } from '@monorepo/tools/src/lib/interfaces/crud';
import { useLocation } from 'react-router-dom';
import { IMFPStore } from '../../stores';

export function useCampaignGet(props: ICrudGetProps) {
	const { id, options } = props;
	const { campaignStore } = useStores<IMFPStore>();
	const location = useLocation();

	useEffect(() => {
		if (id) {
			campaignStore.getCrud().get(id, options);
		}
		// TODO - think maybe when unmount reset to crud model
	}, [location]);

	if (!id) {
		return null;
	}

	return { error: campaignStore.getCrud().getHttpError(), isLoading: campaignStore.getCrud().getIsLoading() };
}
