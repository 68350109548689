import { AccountsEndpoint } from './accounts.endpoint';
import { AdvertisersEndpoint } from './advertisers.endpoint';
import { CampaignsEndpoint } from './campaigns.endpoint';
import { ChannelsEndpoint } from './channels.endpoint';
import { UsersEndpoint } from './users.endpoint';

const endpoints = {
	campaigns: new CampaignsEndpoint(),
	advertisers: new AdvertisersEndpoint(),
	accounts: new AccountsEndpoint(),
	channels: new ChannelsEndpoint(),
	users: new UsersEndpoint(),
};

export default endpoints;
