import { useLocation, useParams } from 'react-router-dom';
// import { CampaignPageBreadcrumbs } from './campaign-page.breadcrumbs';
import { AdvertiserBreadcrumb, AdvertiserPageBreadcrumbs } from './advertiser-page.breadcrumbs';
import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { Skeleton } from '@monorepo/base/src/components/skeleton/skeleton';
import styles from './main-breadcrumbs.module.scss';
import { IMFPStore } from '../../stores';
import { CampaignPageBreadcrumbs } from './campaign-page.breadcrumbs';
import { useList } from '../../hooks/use-list';
import { AccountBreadcrumb, AccountPageBreadcrumbs } from './account-page.breadcrumbs';

interface ISkeletonBreadCrumb {
	isNext?: boolean;
}

// TODO - @MFP remove from controlled
export const SkeletonBreadCrumb = (props: ISkeletonBreadCrumb) => {
	const { isNext } = props;

	return (
		<div className={styles.breadcrumbWrapper}>
			<div className={styles.skeletons}>
				<div className={styles.topSkeleton}>
					<Skeleton is={true} rectanglesAmount={1} />
				</div>
				<div className={styles.bottomSkeleton}>
					<Skeleton is={true} rectanglesAmount={1} />
				</div>
			</div>
			{isNext && <div className={styles.next}>/</div>}
		</div>
	);
};

export const MainBreadcrumbs = observer(() => {
	const { campaignId, advertiserId, accountId } = useParams();
	const { advertiserStore, accountStore, userStore } = useStores<IMFPStore>();
	const user = userStore.getUser();

	useList(accountStore.getListStore());

	const location = useLocation();

	useEffect(() => {
		if (!advertiserId) {
			// In manager view reset all crud stores
			advertiserStore.getCrud().reset();
			// campaignStore.getCrud().reset();
		}
	}, [location]);

	if (campaignId) {
		return <CampaignPageBreadcrumbs campaignId={campaignId} />;
	}

	if (advertiserId) {
		return <AdvertiserPageBreadcrumbs advertiserId={advertiserId} />;
	}

	if (!user?.isInternal) {
		return <AdvertiserBreadcrumb />;
	}

	if (accountId) {
		return <AccountPageBreadcrumbs accountId={accountId} />;
	}

	return <AccountBreadcrumb />;
});
