import { BaseRoutes } from '@monorepo/tools/src/lib/routes/base.routes';
import { AuthRoutes } from './auth.routes';
import { GeneralRoutes } from './general.routes';

const routes = {
	campaigns: new BaseRoutes('campaigns'),
	preferences: new BaseRoutes('preferences'),
	creatives: new BaseRoutes('creatives'),
	creativeGroups: new BaseRoutes('creative-groups'),
	advertisers: new BaseRoutes('advertisers'),
	campaignGroups: new BaseRoutes('campaign-groups'),
	tests: new BaseRoutes('tests'),
	deals: new BaseRoutes('deals'),
	jobs: new BaseRoutes('jobs'),
	auth: new AuthRoutes(),
	general: new GeneralRoutes(),
};

export default routes;
