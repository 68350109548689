import { observer } from 'mobx-react';
import { FC, useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { useLogs } from '@monorepo/controlled/src/hooks/use-logs';
import { IMFPStore } from '../../../stores';
import { ILayoutContext } from '../../../hooks/useLayoutContext';

export const AuthLayout: FC = observer(() => {
	const { tableStore, toastsStore } = useStores<IMFPStore>();
	const location = useLocation();
	const { dispatchLog } = useLogs();
	const toastsOnNav = toastsStore.getToastOnNav();
	const [, setIsTableOfContents] = useState<boolean>(false);

	// When you want to add data between parent to child components put it here
	const layoutContext: ILayoutContext = {
		setIsTableOfContents,
	};

	useEffect(() => {
		if (toastsOnNav.length > 0) {
			toastsOnNav.forEach(toastOnNav => {
				dispatchLog(toastOnNav);
			});
			toastsStore.resetToastsOnNav();
		}

		return () => {
			tableStore.resetTable();
		};
	}, [location]);

	return <Outlet context={layoutContext} />;
});
