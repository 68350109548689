import { HttpError } from '@monorepo/tools/src/lib/models/http-error.model';
import { List, ListItem } from '../list/list';
import { Icon } from '@monorepo/base/src/components/icon/icon';
import styles from './error-msg.module.scss';

interface IErrorMsg {
	error: HttpError;
	classes?: { error: string };
	isIcon?: boolean;
}

export const ErrorMsg = (props: IErrorMsg) => {
	const { error, classes, isIcon = true } = props;
	return (
		<div className={`${styles.error} ${classes?.error}`}>
			{isIcon ? <Icon isMFP={true}>alert-circle</Icon> : null}
			<List>
				{error.getMessages().map(msg => (
					<ListItem key={msg}>{msg}</ListItem>
				))}
			</List>
		</div>
	);
};
