import { Page, Pagebar, PagebarTitle } from '@monorepo/base/src/components/page/page';
import { Soon } from '@monorepo/base/src/components/soon/soon';
import styles from './../../../../styles/pages.module.scss';

export const CampaignSources = () => {
	return (
		<Page unstyled={true} className={styles.article}>
			<Pagebar classes={{ pagebar: styles.padding }}>
				<PagebarTitle>Sources</PagebarTitle>
			</Pagebar>
			<Soon />
		</Page>
	);
};
