import { ReactNode } from 'react';
import styles from './form-control.module.scss';

interface IFormControl {
	children: ReactNode;
}
export const FormControl = (props: IFormControl) => {
	const { children } = props;
	return <div className={styles.formControl}>{children}</div>;
};

interface IFormTitle {
	children: ReactNode;
	id?: string;
}
export const FormTitle = (props: IFormTitle) => {
	const { children, id } = props;
	return (
		<div className={styles.formTitle} id={id}>
			{children}
		</div>
	);
};

interface IFormDesc {
	children: ReactNode;
}
export const FormDesc = (props: IFormDesc) => {
	const { children } = props;
	return <div className={styles.formDesc}>{children}</div>;
};
