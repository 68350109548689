import { id } from '@monorepo/tools/src/lib/types/primitives';
import { ICrudEndpoints } from '@monorepo/tools/src/lib/interfaces/crud';
import { getConfig } from '@monorepo/tools/src/lib/get-config';

export class CrudEndpoint implements ICrudEndpoints {
	public rootApi: string;
	public component: string;
	public baseUrl: string;
	public reportsUrl: string;
	public categoriesUrl: string;

	constructor(component: string) {
		this.rootApi = getConfig().env.rootApi || 'invalid root api';
		this.component = component;
		this.baseUrl = `${this.rootApi}/${this.component}`;
		this.reportsUrl = `${this.rootApi}/reports`;
		this.categoriesUrl = `${this.baseUrl}/categories`;
	}

	breadcrumbs() {
		return this.baseUrl;
	}

	list() {
		return this.baseUrl;
	}

	reports() {
		return this.reportsUrl;
	}

	categories() {
		return this.categoriesUrl;
	}

	create() {
		return `${this.rootApi}/${this.component}`;
	}

	get(id: id) {
		return `${this.baseUrl}/${id}`;
	}

	edit(id: id) {
		return `${this.baseUrl}/${id}`;
	}

	delete(id: id) {
		return `${this.baseUrl}/${id}`;
	}
}
