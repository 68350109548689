import { ICampaignEditForm, ICampaignTargetingListing } from '../models/campaign.model';
import { ICampaignCreateForm, CampaignModel } from '../models/campaign.model';
import { ask } from '@monorepo/tools/src/lib/tools/ask/ask';
import endpoints from './endpoints/index.endpoints';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import { URLSearchParams } from 'url';
import { IRequestOptions } from '@monorepo/tools/src/lib/interfaces/url';

export const CampaignsApi = {
	list: (params?: URLSearchParams): Promise<CampaignModel[]> => {
		return ask.get(endpoints.campaigns.list(), params);
	},
	create: (campaign: ICampaignCreateForm & ICampaignTargetingListing, options?: IRequestOptions): Promise<{ id: id }> => {
		return ask.post<ICampaignCreateForm & ICampaignTargetingListing>(endpoints.campaigns.create(), campaign, options);
	},
	edit: (campaignId: id, campaign: ICampaignEditForm, options?: IRequestOptions): Promise<null> => {
		return ask.patch<ICampaignEditForm>(endpoints.campaigns.edit(campaignId), campaign, options);
	},
	editTargetListings: (campaignId: id, targetingListing: ICampaignTargetingListing, options?: IRequestOptions): Promise<null> => {
		return ask.put<ICampaignTargetingListing>(endpoints.campaigns.targetListings(campaignId), targetingListing, options);
	},
	get: (campaignId: id, params?: URLSearchParams): Promise<CampaignModel> => {
		return ask.get(endpoints.campaigns.get(campaignId), params);
	},
	delete: (campaignId: id) => {
		return ask.delete(endpoints.campaigns.delete(campaignId));
	},
	addCreatives: (campaignId: id, creativeIds: id[], options?: IRequestOptions): Promise<null> => {
		return ask.post(endpoints.campaigns.addAndEditCreatives(campaignId), creativeIds, options);
	},
	editCreatives: (campaignId: id, creativeIds: id[], options?: IRequestOptions): Promise<null> => {
		return ask.put(endpoints.campaigns.addAndEditCreatives(campaignId), creativeIds, options);
	},
	enableCampaigns(campaignIds: id[]): Promise<null> {
		return ask.patch(endpoints.campaigns.enableCampaigns(), campaignIds);
	},
	pauseCampaigns(campaignIds: id[]): Promise<null> {
		return ask.patch(endpoints.campaigns.pauseCampaigns(), campaignIds);
	},
	attachDeals(campaignId: id, dealIds: id[]): Promise<null> {
		return ask.put(endpoints.campaigns.attachDeals(campaignId), dealIds);
	},
	getTargets(): Promise<null> {
		return ask.get(endpoints.campaigns.targetList());
	},
	getAmountOfSharedTarget(id: id): Promise<CampaignModel[]> {
		return ask.get(endpoints.campaigns.sharedTargetAmount(id));
	},
};
