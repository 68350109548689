import { makeAutoObservable, observable } from 'mobx';
import { action } from 'mobx';
import { CreativesApi } from '../../apis/creatives.api';
import { PerformancesColumns } from '../../hooks/apis/use-performance';
import { CreativeModel } from '../../models/creative.model';
import { BaseListStore } from '@monorepo/controlled/src/stores/base-list.store';
import { PerformanceStore } from '../performance.store';
import { CreativeCrudStore } from './creative-crud.store';
import { filterPrototypesByProp } from '../../components/filters-menu/filters/index';
import { FilterStore } from '@monorepo/controlled/src/stores/filter.store';

export enum CreativeFiltersStoreKeys {
	Creatives = 'creatives',
	Campaign = 'campaignscreatives',
	CampaignGroup = 'campaigngroupscreatives',
	Advertiser = 'advertiserscreatives',
	CreativeGroup = 'creativegroupscreatives',
}

export class CreativeStore {
	crud = new CreativeCrudStore();
	list = new BaseListStore<CreativeModel>({
		listFunc: CreativesApi.list,
		model: CreativeModel,
	});
	performance = new PerformanceStore<CreativeModel>({
		keys: new Set([PerformancesColumns.CreativeId]),
	});
	filters: Map<string, FilterStore>;

	constructor() {
		makeAutoObservable(this, {
			crud: observable,
			list: observable,
			resetCrud: action, // TODO - RONT no need more for resetCrud, see campaign crud store reset function
			filters: observable,
			performance: observable,
		});

		const creativesPageFilterStore = new FilterStore({
			key: CreativeFiltersStoreKeys.Creatives,
			filterPrototypesByProp,
		});
		const campaignCreativesPageFilterStore = new FilterStore({
			key: CreativeFiltersStoreKeys.Campaign,
			filterPrototypesByProp,
		});
		const advertiserCreativesPageFilterStore = new FilterStore({
			key: CreativeFiltersStoreKeys.Advertiser,
			filterPrototypesByProp,
		});
		const campaignGroupCreativesPageFilterStore = new FilterStore({
			key: CreativeFiltersStoreKeys.CampaignGroup,
			filterPrototypesByProp,
		});
		const creativeGroupCreativesPageFilterStore = new FilterStore({
			key: CreativeFiltersStoreKeys.CreativeGroup,
			filterPrototypesByProp,
		});

		this.filters = new Map([
			[CreativeFiltersStoreKeys.Creatives, creativesPageFilterStore],
			[CreativeFiltersStoreKeys.Campaign, campaignCreativesPageFilterStore],
			[CreativeFiltersStoreKeys.Advertiser, advertiserCreativesPageFilterStore],
			[CreativeFiltersStoreKeys.CampaignGroup, campaignGroupCreativesPageFilterStore],
			[CreativeFiltersStoreKeys.CreativeGroup, creativeGroupCreativesPageFilterStore],
		]);
	}

	getFiltersStore(key: CreativeFiltersStoreKeys) {
		return this.filters.get(key);
	}

	getCrud() {
		return this.crud;
	}

	resetCrud() {
		this.crud = new CreativeCrudStore();
	}

	getListStore() {
		return this.list;
	}

	getPerformance() {
		return this.performance;
	}
}
