import { useSearchParams } from 'react-router-dom';

const DEBUG_KEY = 'maze';
const DEBUG_VALUE = '1';

export const isDebug = () => {
	if (typeof window.location !== 'undefined') {
		return window.location.href.indexOf(DEBUG_KEY + '=' + DEBUG_VALUE) > -1;
	}
	return false;
};

export function useDebug() {
	const [searchParams] = useSearchParams();
	return searchParams.get(DEBUG_KEY) === DEBUG_VALUE;
}
