import { action, makeObservable, observable } from 'mobx';
import { PerformanceApi } from '../apis/performance.api';
import { ISegment } from '@monorepo/base/src/components/segments/segments';
import { PerformanceModel, PerformanceResponse } from '../models/performance.model';
import { IPerformanceOptionsV2, PerformancesColumns } from '../hooks/apis/use-performance';
import { HttpStore } from '@monorepo/controlled/src/stores/http.store';

interface IPerformanceStoreOptions<TModel> {
	keys?: Set<PerformancesColumns>;
	segments?: Set<ISegment<TModel, PerformanceModel, PerformancesColumns>>;
}

export class PerformanceStore<TModel>
	extends HttpStore<IPerformanceOptionsV2, PerformanceResponse>
	implements IPerformanceStoreOptions<TModel>
{
	keys: Set<PerformancesColumns>;
	segments: Set<ISegment<TModel, PerformanceModel, PerformancesColumns>>;

	constructor(props: IPerformanceStoreOptions<TModel>) {
		super({
			httpFunc: PerformanceApi.performance,
			model: PerformanceResponse,
		});

		this.segments = props.segments || new Set();
		this.keys = props.keys || new Set();

		makeObservable(this, {
			segments: observable,
			setSegments: action,
		});

		// makePersistable
		// makePersistable(this, {
		// 	name: 'PerformanceStore',
		// 	properties: [{
		// 		key: 'segments',
		// 		deserialize: (value) => {
		// 			return
		// 		}
		// 	}],
		// 	storage: window.localStorage,
		// });
	}

	setSegments(segments: Set<ISegment<TModel, PerformanceModel, PerformancesColumns>>) {
		this.segments = segments;
	}

	getSegments(): Set<ISegment<TModel, PerformanceModel, PerformancesColumns>> | undefined {
		return this.segments;
	}

	setKeys(keys: Set<PerformancesColumns>) {
		this.keys = keys;
	}

	getGroupBys(): Set<PerformancesColumns> {
		return this.keys;
	}
}
