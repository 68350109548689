import { Soon } from '@monorepo/base/src/components/soon/soon';
import { Accounts } from '../../pages/accounts/index/accounts.page';
import { AccountView } from '../../pages/accounts/view/account-view.page';
import { AccountAdvertisers } from '../../pages/accounts/view/advertisers/advertisers.account';
import { FullLayout } from '../components/layouts/full-layout/full-layout';
import { PageLayout } from '../components/layouts/page-layout/page-layout';
import routes from '../routes/index.routes';
import { CreateAdvertiser } from '../../pages/advertisers/create/create-advertiser.page';
import { EditAccount } from '../../pages/accounts/edit/edit-account.page';
import { AccountCampaigns } from '../../pages/accounts/view/campaigns/campaigns.account';
import { AccountChannels } from '../../pages/accounts/view/channels/channels.account';

export const AccountsRouter = [
	{
		element: <FullLayout />,
		children: [
			{
				path: routes.accounts.create(),
				element: <Soon />,
			},
		],
	},
	{
		element: <PageLayout />,
		children: [
			{
				path: routes.accounts.index(),
				element: <Accounts />,
			},
			{
				path: routes.accounts.createAdvertiser(':accountId'),
				element: <CreateAdvertiser />,
			},
			{
				path: routes.accounts.edit(':accountId'),
				element: <EditAccount />,
			},
			{
				element: <AccountView />,
				children: [
					{
						path: routes.accounts.view(':accountId'),
						element: <Soon />,
					},
					{
						path: routes.accounts.advertisers(':accountId'),
						element: <AccountAdvertisers />,
					},
					{
						path: routes.accounts.campaigns(':accountId'),
						element: <AccountCampaigns />,
					},
					{
						path: routes.accounts.channels(':accountId'),
						element: <AccountChannels />,
					},
				],
			},
		],
	},
];
