import dayjs from 'dayjs';
import { TableLink } from '@monorepo/controlled/src/components/table/table-link/table-link';
import { PerformanceModel } from '../../models/performance.model';
import { TestModel } from '../../models/test.model';
import routes from '../../routes/index.routes';
import { PerformanceStore } from '../../stores/performance.store';
import { PerformancesColumns } from '../apis/use-performance';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { ISegment, ISegmentCell } from '@monorepo/base/src/components/segments/segments';
import { IAdminxStore } from '../../stores';

interface IUseSegments<T> {
	performanceStore: PerformanceStore<T>;
	disableSegments?: PerformancesColumns[];
}
// TODO - maybe take all segment out to external objects like TsetSegment

export function TestSegment<T>(): ISegment<T, PerformanceModel, PerformancesColumns> {
	return {
		title: 'Test Group',
		performanceGroupBy: PerformancesColumns.TestGroupName as PerformancesColumns,
		renderCell: (props: ISegmentCell<T | TestModel, PerformanceModel>) => {
			const { performanceItem, model } = props;

			const isTestModel = Boolean((model as TestModel)?.test_groups);

			// if (!performanceItem.testName) {
			// 	return <span>N/A</span>;
			// }

			if (isTestModel) {
				// test.headers.tsx render the group name so we dont need to render here test name and group
				return <span></span>;
			}

			return (
				<span>
					{performanceItem.testName}- {performanceItem.testGroupName}
				</span>
			);
			// TODO - when there will be view for test return this
			// return (
			// 	<TableLink
			// 		to={performanceItem.testId ? routes.tests.view(performanceItem.testId) : undefined}
			// 		onClick={() => {
			// 			sidebarStore.setSidebarOpen(false);
			// 		}}>
			// 		{performanceItem.test_name} {performanceItem.testGroupName}
			// 	</TableLink>
			// );
		},
		sortFn: (a: PerformanceModel, b: PerformanceModel) => {
			const testNameGroupA = (a?.testName || '') + (a?.testGroupName || '');
			const testNameGroupB = (b?.testName || '') + (b?.testGroupName || '');
			const nameA = testNameGroupA.toUpperCase(); // ignore upper and lowercase
			const nameB = testNameGroupB.toUpperCase(); // ignore upper and lowercase
			if (nameA < nameB) {
				return -1;
			}
			if (nameA > nameB) {
				return 1;
			}

			// names must be equal
			return 0;
		},
	};
}

export function DateSegment<T>(): ISegment<T, PerformanceModel, PerformancesColumns> {
	return {
		title: 'Day',
		performanceGroupBy: PerformancesColumns.Date,
		renderCell: (props: ISegmentCell<T, PerformanceModel>) => {
			const { performanceItem } = props;
			return <span style={{ whiteSpace: 'pre' }}>{dayjs(performanceItem.date).format('MMMM D, YYYY')}</span>;
		},
		sortFn: (a: PerformanceModel, b: PerformanceModel) => {
			return dayjs(a.date).isBefore(dayjs(b.date)) ? -1 : 1;
		},
	};
}

export function useSegments<T>(props: IUseSegments<T>) {
	const { performanceStore, disableSegments } = props;
	const { sidebarStore } = useStores<IAdminxStore>();

	const onSegment = (segment: ISegment<T, PerformanceModel, PerformancesColumns> | null) => {
		if (!segment) {
			performanceStore.setSegments(new Set<ISegment<T, PerformanceModel, PerformancesColumns>>([]));
			return;
		}
		performanceStore.setSegments(new Set<ISegment<T, PerformanceModel, PerformancesColumns>>([segment]));
	};

	let segments: ISegment<T, PerformanceModel, PerformancesColumns>[] = [
		{
			title: 'Auction Type',
			performanceGroupBy: PerformancesColumns.AuctionType,
		},
		{
			title: 'Browser',
			performanceGroupBy: PerformancesColumns.BrowserName,
		},
		// {
		// 	title: 'Bid Keys',
		// 	performanceGroupBy: PerformancesColumns.BidKeys,
		// },
		// {
		// 	title: 'City',
		// 	performanceGroupBy: PerformancesColumns.City,
		// },
		{
			title: 'Creative',
			performanceGroupBy: PerformancesColumns.CreativeId,
			renderCell: (props: ISegmentCell<T, PerformanceModel>) => {
				const { performanceItem } = props;
				const { creativeBrandName, creativeImageUrl, creativeSize } = performanceItem;

				return (
					<div>
						<div style={{ whiteSpace: 'pre' }}>
							{creativeBrandName} {creativeSize}
						</div>
						<img style={{ maxHeight: '60px' }} src={creativeImageUrl} />
					</div>
				);
			},
		},
		{
			title: 'Creative Group',
			performanceGroupBy: PerformancesColumns.CreativeGroupId,
			renderCell: (props: ISegmentCell<T, PerformanceModel>) => {
				const { performanceItem } = props;
				return (
					<TableLink
						to={performanceItem.creativeGroupId ? routes.creativeGroups.creatives(performanceItem.creativeGroupId) : undefined}
						onClick={() => {
							sidebarStore.setSidebarOpen(false);
						}}>
						{performanceItem.creativeGroupDesign || 'N/A'}
					</TableLink>
				);
			},
		},
		DateSegment<T>(),
		// {
		// 	title: 'Domain',
		// 	performanceGroupBy: PerformancesColumns.Domain,
		// },
		{
			title: 'Geo',
			performanceGroupBy: PerformancesColumns.Geo,
		},
		{
			title: 'Operating System',
			performanceGroupBy: PerformancesColumns.OsName,
		},
		// {
		// 	title: 'Page',
		// 	performanceGroupBy: PerformancesColumns.Page,
		// },
		{
			title: 'Preferred Deal',
			performanceGroupBy: PerformancesColumns.PreferredDealId,
			renderCell: (props: ISegmentCell<T, PerformanceModel>) => {
				const { performanceItem } = props;
				return <span>{performanceItem.preferredDealName}</span>;
			},
		},
		// {
		// 	title: 'Publisher',
		// 	performanceGroupBy: PerformancesColumns.PublisherId,
		// },
		{
			title: 'State',
			performanceGroupBy: PerformancesColumns.State,
			renderCell: (props: ISegmentCell<T, PerformanceModel>) => {
				const { performanceItem } = props;
				if (!performanceItem.state) {
					return <span>N/A</span>;
				}
				return <span>{performanceItem.state?.toUpperCase()}</span>;
			},
		},
		TestSegment<T>(),
	];

	if (disableSegments) {
		segments = segments.filter(segment => !disableSegments.includes(segment.performanceGroupBy as PerformancesColumns));
	}

	return { segments, onSegment };
}
