import { FilterOptionsEnums, IFilterProto } from '@monorepo/controlled/src/models/filter.model';
import { AdvertiserNameFilterPrototype } from './advertiser-name.filter';
import { BrandNameFilterPrototype } from './brand-name.filter';
import { CampaignGroupNameFilterPrototype } from './campaign-group-name.filter';
import { CreativeGroupDesignFilterPrototype } from './creative-group-design.filter';
import { StatusFilterPrototype } from './status.filter';

export {
	StatusFilterPrototype as StatusFilter,
	AdvertiserNameFilterPrototype as AdvertiserNameFilter,
	CampaignGroupNameFilterPrototype as CampaignGroupNameFilter,
	CreativeGroupDesignFilterPrototype as CreativeGroupDesignFilter,
	BrandNameFilterPrototype as BrandNameFilter,
};
export const filterPrototypesByProp: Record<string, IFilterProto> = {
	[FilterOptionsEnums.Status]: StatusFilterPrototype,
	[FilterOptionsEnums.AdvertiserName]: AdvertiserNameFilterPrototype,
	[FilterOptionsEnums.CampaignName]: CampaignGroupNameFilterPrototype,
	[FilterOptionsEnums.BrandName]: BrandNameFilterPrototype,
	[FilterOptionsEnums.CreativeGroupDesign]: CreativeGroupDesignFilterPrototype,
};
