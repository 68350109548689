import { BetweenPagesStateProps } from '@monorepo/tools/src/lib/interfaces/global';
import { Link, To, useLocation } from 'react-router-dom';
import { route } from '@monorepo/tools/src/lib/types/url';
import { Icon, IconFonts } from '@monorepo/base/src/components/icon/icon';
import styles from './table-link.module.scss';
import { ReactNode } from 'react';

interface ITableLink {
	toEdit?: route;
	toEditState?: BetweenPagesStateProps;
	to?: To;
	onClick?: () => void;
	children: ReactNode;
}

export const TableLink = (props: ITableLink) => {
	const { children, to, toEdit, toEditState, ...rest } = props;
	const { search } = useLocation();

	return (
		<div className={styles.wrapper} style={{ whiteSpace: 'pre' }}>
			{to ? (
				<Link className={`tableLink ${styles.link}`} to={`${to}${search}`} {...rest}>
					{children}
				</Link>
			) : (
				children
			)}
			{toEdit ? (
				<Link className={`tableLinkEditIcon ${styles.editLink}`} to={`${toEdit}${search}`} state={toEditState} {...rest}>
					<Icon isMFP={true} size={'14px'} font={IconFonts.Filled}>
						edit-02
					</Icon>
				</Link>
			) : null}
		</div>
	);
};
