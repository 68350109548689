import { ReactNode } from 'react';
import { url } from '@monorepo/tools/src/lib/types/url';
import { Avatar } from '../avatar/avatar';
import styles from './avatar-details.module.scss';

interface IAvatarDetails {
	url?: url;
	children: ReactNode;
	className?: string;
}

interface IAvatarTitle {
	children: ReactNode;
	className?: string;
}

interface IAvatarSubtitle {
	children: ReactNode;
	className?: string;
}

export const AvatarDetails = (props: IAvatarDetails) => {
	const { children, url, className = '' } = props;
	return (
		<div className={`${styles.avatar} ${className}`}>
			<Avatar url={url} />
			<div className={styles.info}>{children}</div>
		</div>
	);
};

export const AvatarTitle = (props: IAvatarTitle) => {
	const { children, className = '' } = props;
	return <div className={`${styles.title} ${className}`}>{children}</div>;
};

export const AvatarSubtitle = (props: IAvatarSubtitle) => {
	const { children, className = '' } = props;
	return <div className={`${styles.subtitle} ${className}`}>{children}</div>;
};
