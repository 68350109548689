// TODO - contact, faq, about, etc.. will be here
export class GeneralRoutes {
	home() {
		return '/';
	}

	termsAndCondition() {
		return '/terms-and-condition';
	}
}
