interface ITableStore {
	filterTerm: string;
	pageIndex: number;
	pageSize: number;
}

export class TableStore implements ITableStore {
	filterTerm = '';
	pageIndex = 0;
	pageSize = 20;

	constructor() {
		this.updateInStorage();
	}

	updateInStorage(tableStoreProps: Partial<ITableStore> = {}) {
		const tableStoreString = window.sessionStorage.getItem('TableStore');
		const tableStore = (tableStoreString ? JSON.parse(tableStoreString) : {}) as ITableStore;

		this.filterTerm = (() => {
			if (tableStoreProps.filterTerm === '') {
				return tableStoreProps.filterTerm;
			}
			return tableStoreProps.filterTerm || tableStore.filterTerm || this.filterTerm || '';
		})();
		this.pageIndex = (() => {
			if (tableStoreProps.pageIndex === 0) {
				return tableStoreProps.pageIndex;
			}
			return tableStoreProps.pageIndex || tableStore.pageIndex || this.pageIndex || 0;
		})();
		this.pageSize = tableStoreProps.pageSize || tableStore.pageSize || this.pageSize || 20;

		window.sessionStorage.setItem(
			'TableStore',
			JSON.stringify({
				filterTerm: this.filterTerm,
				pageIndex: this.pageIndex,
				pageSize: this.pageSize,
			})
		);
	}

	setFilterTerm(filterTerm: string): void {
		this.filterTerm = filterTerm;
		this.updateInStorage({ filterTerm });
	}

	getFilterTerm(): string {
		return this.filterTerm;
	}

	setPageIndex(pageIndex: number): void {
		this.pageIndex = pageIndex;
		this.updateInStorage({ pageIndex });
	}

	getPageIndex(): number {
		return this.pageIndex;
	}

	resetTable(): void {
		this.filterTerm = '';
		this.pageIndex = 0;
		this.pageSize = 20;
		this.updateInStorage({ pageIndex: 0, filterTerm: '', pageSize: 20 });
	}

	setPageSize(pageSize: number): void {
		this.pageSize = pageSize;
		this.updateInStorage({ pageSize });
	}

	getPageSize(): number {
		return this.pageSize;
	}
}
