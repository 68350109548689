import { CSSProperties, ReactNode } from 'react';

// https://medium.com/@ttennant/react-inline-styles-and-media-queries-using-a-custom-react-hook-e76fa9ec89f6

interface IFlex {
	children: ReactNode;
	className?: string;
	container?: boolean;
	justifyContent?: string;
	flexDirection?: string;
	flexGrow?: number;
	flexBasis?: string;
	flexShrink?: number;
	flexWrap?: string;
	flex?: string;
	alignItems?: string;
	margin?: string;
	padding?: string;
	width?: string;
	height?: string;
	maxWidth?: string;
	alignSelf?: string;
	gap?: string;
}

export const Flex = (props: IFlex) => {
	const {
		className,
		justifyContent,
		flexDirection,
		flexGrow,
		flexBasis,
		flexShrink,
		flexWrap,
		flex,
		alignItems,
		margin,
		padding,
		width,
		height,
		maxWidth,
		children,
		gap,
		alignSelf,
	} = props;

	return (
		<div
			className={className}
			style={
				{
					display: 'flex',
					justifyContent: justifyContent || 'space-between',
					flexDirection: flexDirection || 'row',
					flexGrow: flexGrow || 0,
					flexBasis: flexBasis || 'auto',
					flexShrink: flexShrink || 1,
					flexWrap: flexWrap || 'nowrap',
					flex: flex || '0 1 auto',
					alignItems: alignItems || 'center',
					margin: margin || '0',
					padding: padding || '0',
					width: width || '100%',
					height: height || 'auto',
					maxWidth: maxWidth || 'none',
					gap: gap || '0px',
					alignSelf: alignSelf || 'flex-start',
				} as CSSProperties
			}>
			{children}
		</div>
	);
};
