import { Fragment } from 'react';
import { SelectedLinerButton } from '@monorepo/controlled/src/components/table/action-liner/liner-buttons';
import { QuickActionButton } from '@monorepo/controlled/src/components/table/quick-actions-menu/quick-actions-menu';
import { IFilterProto } from '@monorepo/controlled/src/models/filter.model';
import routes from '../../routes/index.routes';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { IEntityActions, ITableActions, useDefaultActions } from './default.actions';
import { CampaignModel } from '../../models/campaign.model';
import { getTheme } from '@monorepo/tools/src/lib/get-config';
import { IMFPStore } from '../../stores/index';
import { ITableFiltersActions, TableFilters } from '@monorepo/controlled/src/components/table/table-filters/table-filters';
import { CampaignFiltersStoreKeys } from '../../stores/campaigns/campaign.store';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';
import { AdvertiserNameFilter, StatusFilter } from '../../components/filters';
import { ISegment, Segments } from '@monorepo/base/src/components/segments/segments';
import { PerformanceModel } from '../../models/performance.model';
import { PerformancesColumns } from '../apis/use-reports-list';
import { useSegments } from '../tools/use-segments';
import { ActionModal, ModalTypes } from '@monorepo/controlled/src/components/modal/modals/action-modal/action-modal';
import { useNavigate } from 'react-router-dom';
import { EnablePauseCampaignsModal } from '../../components/modals/enable-pause-campaigns';
import { SecondaryText } from '@monorepo/base/src/components/buttons/buttons';
import { IconFonts } from '@monorepo/base/src/components/icon/icon';
import { ISegmentActions } from '@monorepo/controlled/src/components/table/table';

const theme = getTheme();

interface ICampaignActions {
	availableFilters?: IFilterProto[];
	currentSegments?: Set<ISegment<CampaignModel, PerformanceModel, PerformancesColumns>>;
}

export const useCampaignActions = (props: ICampaignActions = {}) => {
	const { availableFilters, currentSegments } = props;
	const { campaignStore, modalsStore, toastsStore } = useStores<IMFPStore>();
	const campaignsCrudStore = campaignStore.getCrud();
	const { currentRouteWithoutSpecialChars } = useRoute();
	const navigate = useNavigate();

	const filterStore = campaignStore.getFiltersStore(currentRouteWithoutSpecialChars as CampaignFiltersStoreKeys);
	const currentFilters = filterStore?.getCurrentFilters();

	const DefaultAvailableFilters = [AdvertiserNameFilter, StatusFilter];

	const onEditRoute = (id: id) => routes.campaigns.edit(id);
	const onDelete = (id: id) => campaignStore.getCrud().delete(id);
	const onDuplicateRoute = (id: id) => routes.campaigns.duplicate(id);

	const funcActions = {
		onEditRoute,
		onDelete,
		onDuplicateRoute,
	};

	// TODO - row should be converted to CampaignModel
	const SelectedActions = (props: ITableActions<CampaignModel>) => {
		const { rows } = props;
		const entities = rows.map(row => new CampaignModel(row.original));
		const { EditAction, DeleteAction, DuplicateAction } = useDefaultActions({ entities, ...funcActions });
		const textColor = theme.actionLinerIconTextColor;
		const iconColor = theme.actionLinerIconColor;
		const entitiesIds: id[] = [];
		entities.forEach(e => {
			const id = e.getId();
			if (id) {
				entitiesIds.push(id);
			}
		});

		return (
			<Fragment>
				<SelectedLinerButton>
					<SecondaryText
						icon={'play'}
						onClick={() => enableCampaigns(entitiesIds)}
						textColor={textColor}
						iconColor={iconColor}
						font={IconFonts.Outlined}>
						Enable
					</SecondaryText>
				</SelectedLinerButton>
				<SelectedLinerButton>
					<SecondaryText
						icon={'pause-square'}
						onClick={() => pauseCampaigns(entitiesIds)}
						textColor={textColor}
						iconColor={iconColor}
						font={IconFonts.Outlined}>
						Pause
					</SecondaryText>
				</SelectedLinerButton>
				<SelectedLinerButton>
					<EditAction />
				</SelectedLinerButton>
				<SelectedLinerButton>
					<DuplicateAction />
				</SelectedLinerButton>
				<SelectedLinerButton>
					<DeleteAction />
				</SelectedLinerButton>
			</Fragment>
		);
	};

	const EntityActions = (props: IEntityActions<CampaignModel>) => {
		const { entity } = props;
		const { EditAction, DeleteAction, DuplicateAction, buttonClassName } = useDefaultActions({ entities: [entity], ...funcActions });
		const entityId = entity.getId();
		if (!entityId) {
			// log error
			return null;
		}

		return (
			<Fragment>
				<QuickActionButton>
					<SecondaryText
						icon={'play'}
						onClick={() => enableCampaigns([entityId])}
						textColor={theme.titleColor}
						iconColor={theme.titleColor}
						className={buttonClassName}
						font={IconFonts.Outlined}>
						Enable
					</SecondaryText>
				</QuickActionButton>
				<QuickActionButton>
					<SecondaryText
						icon={'pause-square'}
						onClick={() => pauseCampaigns([entityId])}
						textColor={theme.titleColor}
						iconColor={theme.titleColor}
						className={buttonClassName}
						font={IconFonts.Outlined}>
						Pause
					</SecondaryText>
				</QuickActionButton>
				<QuickActionButton>
					<EditAction className={buttonClassName} iconColor={theme.titleColor} textColor={theme.titleColor} />
				</QuickActionButton>
				<QuickActionButton>
					<DuplicateAction className={buttonClassName} iconColor={theme.titleColor} textColor={theme.titleColor} />
				</QuickActionButton>
				<QuickActionButton>
					<DeleteAction className={buttonClassName} iconColor={theme.titleColor} textColor={theme.titleColor} />
				</QuickActionButton>
			</Fragment>
		);
	};

	const FiltersActions = (props: ITableFiltersActions) => {
		if (!currentFilters) {
			return null;
		}

		return (
			<TableFilters
				{...props}
				availableFilters={availableFilters || DefaultAvailableFilters}
				currentFilters={currentFilters}
				clearAll={() => filterStore?.clearAll()}
				clearFilter={index => filterStore?.clearFilter(index)}
				addFilter={filter => filterStore?.addFilter(filter)}
				editFilter={(index, filter) => filterStore?.editFilter(index, filter)}
			/>
		);
	};

	const SegmentActions = (props: ISegmentActions) => {
		const { segments, onSegment } = useSegments<CampaignModel>({ setSegments: campaignStore.setSegments });

		return (
			<Segments<CampaignModel, PerformanceModel, PerformancesColumns>
				currentSegments={currentSegments}
				availableSegments={segments}
				isComingSoon={segments}
				onClick={segment => onSegment(segment)}
				{...props}
			/>
		);
	};

	const enableCampaigns = (campaignIds: id[]) => {
		modalsStore.addModal({
			children: (
				<ActionModal
					type={ModalTypes.Info}
					primaryBtnTitle={'Enable'}
					onAction={() => {
						campaignsCrudStore.enableCampaigns(campaignIds).then(() => {
							toastsStore.addToastOnNav({ msg: `Campaigns ${campaignIds} Enabled`, type: 'success' });
							navigate(0);
						});
					}}>
					<EnablePauseCampaignsModal action={'Enable'} campaignIds={campaignIds} />
				</ActionModal>
			),
		});
	};

	const pauseCampaigns = (campaignIds: id[]) => {
		modalsStore.addModal({
			children: (
				<ActionModal
					type={ModalTypes.Info}
					primaryBtnTitle={'Paused'}
					onAction={() => {
						campaignsCrudStore.pauseCampaigns(campaignIds).then(() => {
							toastsStore.addToastOnNav({ msg: `Campaigns ${campaignIds} Paused`, type: 'success' });
							navigate(0);
						});
					}}>
					<EnablePauseCampaignsModal action={'Paused'} campaignIds={campaignIds} />
				</ActionModal>
			),
		});
	};

	return {
		SelectedActions,
		EntityActions,
		FiltersActions,
		SegmentActions,
	};
};
