import { CampaignGroupModel } from '../../models/campaign-group.model';
import { makeAutoObservable, observable } from 'mobx';
import { CampaignGroupsCrudStore } from './campaign-groups-crud.store';
import { BaseListStore } from '@monorepo/controlled/src/stores/base-list.store';
import { CampaignGroupsApi } from '../../apis/campaign-groups.api';
import { PerformanceStore } from '../performance.store';
import { PerformancesColumns } from '../../hooks/apis/use-performance';
import { DateSegment } from '../../hooks/tools/use-segments';
import { ISegment } from '@monorepo/base/src/components/segments/segments';
import { PerformanceModel } from '../../models/performance.model';

export class CampaignGroupsStore {
	crud = new CampaignGroupsCrudStore();
	list = new BaseListStore<CampaignGroupModel>({
		listFunc: CampaignGroupsApi.list,
		model: CampaignGroupModel,
	});
	performance = new PerformanceStore<CampaignGroupModel>({
		keys: new Set([PerformancesColumns.CampaignGroupId]),
	});
	metrics = new PerformanceStore<CampaignGroupModel>({
		segments: new Set<ISegment<CampaignGroupModel, PerformanceModel, PerformancesColumns>>([DateSegment<CampaignGroupModel>()]),
	});

	constructor() {
		makeAutoObservable(this, {
			crud: observable,
			list: observable,
			performance: observable,
		});
	}

	getCrud() {
		return this.crud;
	}

	getListStore() {
		return this.list;
	}

	getPerformance() {
		return this.performance;
	}

	getMetrics() {
		return this.metrics;
	}
}
