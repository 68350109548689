// import { spy } from 'mobx';
import { ModalsContainer } from '@monorepo/controlled/src/components/modal/modals-container/modals-container';
import { ToastsContainer } from '@monorepo/controlled/src/components/toast/toasts-container/toasts-container';
import { HelmetProvider } from 'react-helmet-async';
import * as Sentry from '@sentry/react';
import './app.scss';
import { Envs } from '@monorepo/tools/src/lib/enums/env';
import { useEffect } from 'react';
import packageJson from '../../package.json';
import { getConfig } from '@monorepo/tools/src/lib/get-config';
import { ErrorsContainer } from '../modules/components/errors-container/errors-container';
import { MfpRouter } from '../modules/router/router';
import { removeOldPerssistableStores } from '@monorepo/controlled/src/stores/helper';
import { Head } from '../modules/components/head/head';

// Mobx Event listener
// spy(event => {
// 	if (event.type === 'action') {
// console.log(`TCL ~ ${event.name} with args: ${event.arguments}`);
// 	}
// });

if (getConfig().env.projectEnv === Envs.Production) {
	Sentry.init({
		dsn: 'https://43614f611f5e4d35a69efefbbd797917@o206854.ingest.sentry.io/6616704',
	});
}

function App() {
	useEffect(() => {
		console.log('v' + packageJson.version);
		removeOldPerssistableStores();
	}, []);

	return (
		<Sentry.ErrorBoundary>
			<HelmetProvider>
				<Head />
				<ErrorsContainer />
				<ModalsContainer />
				<ToastsContainer />
				<MfpRouter />
			</HelmetProvider>
		</Sentry.ErrorBoundary>
	);
}

export default App;
