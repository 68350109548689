import { PrimaryButton } from '@monorepo/base/src/components/buttons/buttons';
import { Dropdown } from '@monorepo/base/src/components/dropdown/dropdown';
import { Input } from '@monorepo/base/src/components/form/input/input';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { observer } from 'mobx-react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import routes from '../../../modules/routes/index.routes';
import { IMFPStore } from '../../../modules/stores';
import styles from './create-account.module.scss';
import { Icon } from '@monorepo/base/src/components/icon/icon';
import { getTheme } from '@monorepo/tools/src/lib/get-config';
import { capitalCase } from 'change-case';

export const CreateAccount = observer(() => {
	const { userStore, authStore, accountStore } = useStores<IMFPStore>();
	const crudStore = accountStore.getCrud();
	const formStore = crudStore.getFormStore();
	const account = crudStore.getData();
	const user = userStore.getUser();
	const navigate = useNavigate();
	const location = useLocation();

	const onCreateAccount = async () => {
		const token = authStore.getToken();

		if (!token) {
			return;
		}

		accountStore
			.getCrud()
			.create({
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.then(account => {
				if (account) {
					navigate(user?.isVerified() ? routes.general.home() : routes.auth.verify());
				}
			});
	};

	const onSwitchUser = () => {
		userStore.removeUser();
		authStore.removeToken();
	};

	if (!user) {
		return <Navigate to={`${routes.auth.login()}${location.search}`} replace />;
	}

	if (user.hasAccounts()) {
		return <Navigate to={`${routes.general.home()}${location.search}`} replace />;
	}

	return (
		<div className={styles.wrapper}>
			<div className={styles.title}>Hey {capitalCase(user?.firstName || '')},</div>
			<div className={styles.subtitle}>Let’s create your account</div>
			<div className={styles.formWrapper}>
				<div className={styles.inputWrapper}>
					<div className={styles.formText}>Company Name</div>
					<Input
						className={styles.formInput}
						placeholder={'Enter your company'}
						onValue={value => {
							account.setName(value);
						}}
						value={account.getName()}
						required={false}
						error={formStore.getErrors().get('name')?.getMsg()}
					/>
				</div>
				<div className={styles.inputWrapper}>
					<div className={styles.formText}>Company Size</div>
					<Dropdown
						className={styles.formInput}
						label={'Select you company size'}
						options={['1-10', '11-50', '51-200', '201-500', '500+']}
						defaultOption={account.getCompanySize()}
						required={false}
						onSelect={companySize => account.setCompanySize(companySize?.values().next().value)}
						error={formStore.getErrors().get('company_size')?.getMsg()}
					/>
				</div>
				<div className={styles.inputWrapper}>
					<div className={styles.formText}>Website</div>
					<Input
						before={
							<Icon isMFP={true} color={getTheme().subtitleColor}>
								link-02
							</Icon>
						}
						className={styles.formInput}
						placeholder={'www.intango.com'}
						onValue={value => {
							account.setWebsite(value);
						}}
						value={account.getWebsite()}
						required={false}
						error={formStore.getErrors().get('website')?.getMsg()}
					/>
				</div>
				{/* Here check if verified go to overview and if not go to verify page */}
				<PrimaryButton className={styles.letsGoBtn} onClick={onCreateAccount}>
					Let's Go
				</PrimaryButton>
				<div className={styles.switchUserWrapper}>
					Not <span className={styles.email}>{`${user.getEmail()}?`} </span>
					<span onClick={() => onSwitchUser()} className={styles.switchUser}>
						Switch User
					</span>
				</div>
			</div>
		</div>
	);
});
