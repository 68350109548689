import { objectToMap } from '@monorepo/tools/src/lib/utils/map';
import { ChannelsEnums, ChannelModel } from './channel.model';
import { makeAutoObservable } from 'mobx';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import { AdvertiserModel } from './advertiser.model';
import { Strategies } from '../enums/strategies';
import { Devices } from '../enums/devices';
import { IPerformanceItem } from './performance.model';
import { OptimizationModes } from '../enums/optimization-modes';
import { AccountModel } from './account.model';
import { AdvancedStrategyModel } from './strategies/advanced-strategy.model';
import { BasicStrategyModel } from './strategies/basic-strategy.model';
import { SmartStrategyModel } from './strategies/smart-strategy.model';
import { StatusType } from '../enums/status';
import { sentenceCase } from 'change-case';
import { ConversionModel } from './conversion.model';

export type StrategyType = BasicStrategyModel | AdvancedStrategyModel | SmartStrategyModel;

export const strategyTypesToModel = {
	[Strategies.Basic]: BasicStrategyModel,
	[Strategies.Advanced]: AdvancedStrategyModel,
	[Strategies.Smart]: SmartStrategyModel,
};

/**
 * The campaign the campaign crud page uses
 */
export interface ICampaign extends IPerformanceItem {
	id?: id;
	status?: StatusType;
	advertiser?: AdvertiserModel;
	advertiser_id?: id;
	advertiser_name?: string;

	name?: string;
	landingPage?: string;
	startTime?: Date;
	endTime?: Date;
	devices?: Set<Devices>;
	geo?: string;
	// languages?: Set<string>;
	strategyType?: Strategies;
	totalBudget?: number;
	strategy?: StrategyType;
	conversionActions?: ConversionModel[];

	defaultBid?: number;
	dailyBudget?: number;
	optimizationMode?: OptimizationModes;
	channelsByName?: Map<ChannelsEnums, ChannelModel>;
	isDeepLink?: boolean;
}

/**
 * The campaign to send to server
 */
export interface ICampaignCreateForm {
	name?: string;
	advertiser?: { id: id; name?: string };
	landingPage?: string;
	startTime?: string;
	endTime?: string;
	devices?: string[];
	geo?: string;
	// languages?: string[];
	strategyType?: Strategies;
	totalBudget?: number;
	optimizationMode?: OptimizationModes;
	// conversions
	defaultBid?: number;
	dailyBudget?: number;
	channelsByName?: Record<ChannelsEnums, ChannelModel>;
	conversionActions?: ConversionModel[];
	isDeepLink?: boolean;
}

export interface ICampaignEditForm {
	id?: id;
	name?: string;
	status?: StatusType;
	advertiser?: { id: id; name?: string };
	landingPage?: string;
	startTime?: string;
	endTime?: string;
	devices?: string[];
	geo?: string;
	// languages?: string[];
	strategyType?: Strategies;
	totalBudget?: number;
	optimizationMode?: OptimizationModes;
	// conversions
	defaultBid?: number;
	dailyBudget?: number;
	channelsByName?: Record<ChannelsEnums, ChannelModel>;
	conversionActions?: ConversionModel[];
	isDeepLink?: boolean;
}

export class CampaignModel implements ICampaign {
	id?: id;
	name?: string;
	landingPage?: string;
	startTime?: Date;
	endTime?: Date;
	devices?: Set<Devices>;
	geo?: string;
	// languages?: Set<string>;
	strategyType?: Strategies;
	totalBudget?: number;
	optimizationMode?: OptimizationModes;
	conversionActions: ConversionModel[];
	// conversions
	defaultBid?: number;
	dailyBudget?: number;
	channelsByName?: Map<ChannelsEnums, ChannelModel>;

	advertiser?: AdvertiserModel;
	status?: StatusType;
	budget?: number;
	daily_cap?: number;
	clicks?: number;
	conversions?: number;
	cost?: number;
	revenue?: number;
	impressions?: number;
	roas?: number;
	cpc?: number;
	cpa?: number;
	cpm?: number;
	cvr?: number;
	account?: AccountModel;
	suggestedBid?: number;
	isDeepLink?: boolean;

	constructor(campaign?: ICampaign) {
		this.id = campaign?.id;
		this.name = campaign?.name;
		this.landingPage = this.replaceToBrackets(campaign?.landingPage);
		this.devices = new Set(campaign?.devices || [Devices.Desktop, Devices.Mobile]);
		this.geo = campaign?.geo || 'United States';
		// this.languages = new Set(campaign?.languages || ['All']);
		this.startTime = campaign?.startTime ? new Date(campaign?.startTime) : new Date();
		this.endTime = campaign?.endTime ? new Date(campaign?.endTime) : undefined;
		this.advertiser =
			campaign?.advertiser || campaign?.advertiser_id
				? new AdvertiserModel({
						id: campaign?.advertiser?.id || campaign?.advertiser_id,
						name: campaign?.advertiser?.name || campaign?.advertiser_name,
						account: campaign?.advertiser?.account,
				  })
				: undefined;
		this.strategyType = this.initStrategyType(campaign?.strategyType || Strategies.Smart);
		this.totalBudget = campaign?.totalBudget === null ? undefined : campaign?.totalBudget;
		this.defaultBid = campaign?.defaultBid === null ? undefined : campaign?.defaultBid;
		this.dailyBudget = campaign?.dailyBudget === null ? undefined : campaign?.dailyBudget;
		this.optimizationMode = campaign?.optimizationMode || OptimizationModes.TargetCPA;
		this.isDeepLink = Boolean(campaign?.isDeepLink);
		this.channelsByName = (() => {
			if (!campaign?.channelsByName) {
				return new Map();
			}
			if (campaign?.channelsByName instanceof Map) {
				return campaign?.channelsByName;
			}
			const channelsByName = campaign?.channelsByName as Record<ChannelsEnums, ChannelModel>;
			const channelsMap = objectToMap<ChannelsEnums, ChannelModel>(channelsByName);
			channelsMap.forEach(channel => {
				if (channel?.name) {
					channelsMap.set(channel.name, new ChannelModel(channel));
				}
			});
			return channelsMap;
		})();
		this.conversions = campaign?.conversions;
		this.conversionActions = campaign?.conversionActions ? campaign.conversionActions : [];

		this.clicks = campaign?.clicks;
		this.status = campaign?.status;
		this.daily_cap = campaign?.daily_cap;
		this.budget = campaign?.budget;
		this.conversions = campaign?.conversions;
		this.cost = campaign?.cost;
		this.revenue = campaign?.revenue;
		this.impressions = campaign?.impressions;
		this.roas = campaign?.roas;
		this.cpc = campaign?.cpc;
		this.cpa = campaign?.cpa;
		this.cpm = campaign?.cpm;
		this.cvr = campaign?.cvr;

		makeAutoObservable(this);

		//do not uncomment
		// makeObservable(this, {
		// 	id: observable,
		// 	name: observable,
		// 	landingPage: observable,
		// 	devices: observable,
		// 	geo: observable,
		// 	defaultBid: observable,
		// 	languages: observable,
		// 	startTime: observable,
		// 	endTime: observable,
		// 	advertiser: observable,
		// 	strategyType: observable,
		// 	totalBudget: observable,
		// 	dailyBudget: observable,
		// 	optimizationMode: observable,
		// 	setId: action,
		// 	setName: action,
		// 	setLandingPage: action,
		// 	setDevices: action,
		// 	setGeo: action,
		// 	setDefaultBid: action,
		// 	setLanguages: action,
		// 	setStartTime: action,
		// 	setEndTime: action,
		// 	setAdvertiser: action,
		// 	setStrategyType: action,
		// 	setTotalBudget: action,
		// 	setDailyBudget: action,
		// 	setOptimizationMode: action,
		// });
	}

	public getId(): id | undefined {
		return this.id;
	}

	public setId(id: id) {
		this.id = id;
	}

	public getStatus(): StatusType | undefined {
		return this.status;
	}

	public setStatus(status: StatusType): void {
		this.status = status;
	}

	public getAdvertiser(): AdvertiserModel | undefined {
		return this.advertiser;
	}

	public setAdvertiser(advertiser: AdvertiserModel): void {
		this.advertiser = advertiser;
	}

	//
	public getName() {
		return this.name;
	}

	public setName(name: string) {
		this.name = name;
	}

	public getLandingPage() {
		return this.landingPage;
	}

	public setLandingPage(landingPage: string) {
		this.landingPage = landingPage;
	}

	public getStartTime() {
		return this.startTime;
	}

	public setStartTime(startTime: Date) {
		this.startTime = startTime;
	}

	public getEndTime() {
		return this.endTime;
	}

	public setEndTime(endTime?: Date) {
		this.endTime = endTime;
	}

	public getDevices() {
		return this.devices;
	}

	public setDevices(devices: Set<Devices>) {
		this.devices = devices;
	}

	public getGeo() {
		return this.geo;
	}

	public setGeo(geo: string | undefined) {
		this.geo = geo;
	}

	public getStrategyType() {
		return this.strategyType;
	}

	public setStrategyType(strategyType: Strategies) {
		this.strategyType = strategyType;
	}

	public getTotalBudget() {
		return this.totalBudget;
	}

	public setTotalBudget(totalBudget?: number) {
		this.totalBudget = totalBudget;
	}

	public getDailyBudget(): number | undefined {
		return this.dailyBudget;
	}

	public setDailyBudget(dailyBudget: number): void {
		this.dailyBudget = dailyBudget;
	}

	public getDefaultBid(): number | undefined {
		return this.defaultBid;
	}

	public setDefaultBid(defaultBid?: number): void {
		this.defaultBid = defaultBid;
	}

	public getSuggestedBid(): number | undefined {
		return this.suggestedBid;
	}

	public setSuggestedBid(suggestedBid?: number): void {
		this.suggestedBid = suggestedBid;
	}

	public getChannels() {
		return this.channelsByName;
	}

	public getChannel(channelType: ChannelsEnums) {
		return this.channelsByName?.get(channelType);
	}

	public addChannel(channelType: ChannelsEnums, channel: ChannelModel) {
		this.channelsByName?.set(channelType, channel);
	}

	public hasChannel(channelType: ChannelsEnums) {
		return this.channelsByName?.has(channelType);
	}

	public removeChannel(channelType: ChannelsEnums) {
		return this.channelsByName?.delete(channelType);
	}

	public getOptimizationMode() {
		return this.optimizationMode;
	}

	public setOptimizationMode(optimizationMode?: OptimizationModes) {
		this.optimizationMode = optimizationMode;
	}

	private initStrategyType(strategyType: Strategies) {
		if (!Object.keys(Strategies).includes(sentenceCase(strategyType))) {
			return Strategies.Smart;
		}

		return strategyType;
	}

	public getConversionActions(): ConversionModel[] {
		return this.conversionActions;
	}

	public setConversionActions(conversions: ConversionModel[]) {
		this.conversionActions = conversions;
	}

	public getIsDeepLink(): boolean {
		return Boolean(this.isDeepLink);
	}

	public setIsDeepLink(isDeepLink: boolean) {
		this.isDeepLink = Boolean(isDeepLink);
	}

	private replaceToBrackets(url: string | undefined) {
		let output = '';
		let open = false;

		if (!url) {
			return '';
		}

		for (let i = 0; i < url.length; i++) {
			if (url[i] === '@' && url[i + 1] === '@') {
				if (!open) {
					output += '{{';
					open = true;
				} else {
					output += '}}';
					open = false;
				}
				i++;
			} else {
				output += url[i];
			}
		}
		return output;
	}
}
