import { isDebug } from '../hooks/tools/use-debug';

export interface IDataAttr {
	key: string;
	value: string;
}

export const suffixToDataAttr = (suffix: string, dataAttrs?: DataAttribute[]): DataAttribute[] => {
	return (dataAttrs || []).map(a => {
		const linkDataAttr = new DataAttribute(a.getKey(), a.getValue() + suffix);
		return linkDataAttr;
	});
};

export const generateDataAttrs = (dataAttrs?: DataAttribute[]) => {
	if (!isDebug()) {
		return {};
	}
	if (!dataAttrs) {
		return {};
	}
	return dataAttrs?.reduce((agg, attr) => ({ ...agg, [attr.key]: attr.value }), {});
};

export class DataAttribute implements IDataAttr {
	key: string;
	value: string;

	constructor(key: string, value: string) {
		this.key = 'data-' + key;
		this.value = value;
	}

	setKey(key: string) {
		this.key = key;
	}

	getKey(): string {
		return this.key.replace('data-', '');
	}

	setValue(value: string) {
		this.value = value;
	}

	getValue(): string {
		return this.value;
	}
}
