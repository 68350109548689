import { Fragment, ReactNode } from 'react';
import styles from './page.module.scss';

interface IPage {
	children: ReactNode;
	className?: string;
	unstyled?: boolean;
	direction?: string; // row
}
export const Page = (props: IPage) => {
	const { children, className, unstyled, direction } = props;
	return (
		<Fragment>
			<article className={`${unstyled ? '' : styles.article} ${className} ${direction === 'row' ? styles.rowDirection : ''}`}>
				{children}
			</article>
			{/* {isDivider ? <Divider classNames={styles.divider} /> : null} */}
		</Fragment>
	);
};
Page.displayName = 'Page'; // for use in children.map loop

export { Pagebar, PagebarActions, PagebarTitle } from './pagebar/pagebar';
