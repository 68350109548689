import { HttpError } from '../../models/http-error.model';

export function isAbortError(error: Error): error is DOMException {
	return error && error.name === 'AbortError';
}

export function isUnauthorizedError(error: HttpError): error is HttpError {
	return error && error.httpStatus === 401;
}

export function isForbiddenError(error: HttpError): error is HttpError {
	return error && error.httpStatus === 403;
}

export function isBadRequest(error: HttpError): error is HttpError {
	return error && error.httpStatus === 400;
}
