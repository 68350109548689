import { BrowserRouter, useRoutes } from 'react-router-dom';
import routes from '../routes/index.routes';
import { BarLoader } from '@monorepo/base/src/components/bar-loader/bar-loader';
import { useDidMount } from '@monorepo/tools/src/lib/hooks/utils/use-didmount';
import { RequireAuth } from '../components/require-auth/require-auth';
import { Home } from '../../pages/home/home.page';
import { AuthLayout } from '../components/layouts/auth-layout/auth-layout';
import { CampaignsRouter } from './campaigns.router';
import { PageLayout } from '../components/layouts/page-layout/page-layout';
import { AccountsRouter } from './accounts.router';
import { AdvertisersRouter } from './advertisers.router';
import { RedirectAuth } from '../components/redirect-auth/redirect-auth';
import { SignIn } from '../../pages/signin-signup/signin/signin.page';
import { OAuthLoginRedirect } from '../../pages/signin-signup/signin/signin-redirect';
import { OAuthProviderEnum } from '../enums/oauth-providers';
import { SignUp } from '../../pages/signin-signup/signup/signup.page';
import { TermsAndCondition } from '../../pages/terms-and-condition/terms-and-condition';
import { CreateAccount } from '../../pages/signin-signup/create-account/create-account';
import { VerifyUser } from '../../pages/verify-user/verify-user.page';
import { VerifiedUser } from '../../pages/verified-user/verified-user.page';
import { NotFoundPage } from '../../pages/404/404';
import { SplitLayout } from '../components/layouts/split-layout/split-layout';

const Router = () => {
	const appRoutes = [
		{
			path: routes.auth.verified(),
			element: <VerifiedUser />,
		},
		{
			path: routes.auth.verify(),
			element: <VerifyUser />,
		},
		{
			element: <SplitLayout />,
			children: [
				{
					path: routes.accounts.createAccount(),
					element: <CreateAccount />,
				},
			],
		},
		{
			path: routes.general.termsAndCondition(),
			element: <TermsAndCondition />,
		},
		// Unauthorized Routes
		{
			element: (
				<RedirectAuth>
					<AuthLayout />
				</RedirectAuth>
			),
			children: [
				{
					element: <SplitLayout isSticky={true} />,
					children: [
						{
							path: routes.auth.login(),
							element: <SignIn />,
						},
					],
				},
				{
					element: <SplitLayout isSticky={false} />,
					children: [
						{
							path: routes.auth.signUp(),
							element: <SignUp />,
						},
					],
				},
				{
					path: routes.auth.redirect(OAuthProviderEnum.Google),
					element: <OAuthLoginRedirect provider={OAuthProviderEnum.Google} />,
				},
				{
					path: routes.auth.redirect(OAuthProviderEnum.Microsoft),
					element: <OAuthLoginRedirect provider={OAuthProviderEnum.Microsoft} />,
				},
				{
					path: routes.auth.redirect(OAuthProviderEnum.Facebook),
					element: <OAuthLoginRedirect provider={OAuthProviderEnum.Facebook} />,
				},
			],
		},
		// Authorized Routes
		{
			element: (
				<RequireAuth>
					<AuthLayout />
				</RequireAuth>
			),
			children: [
				{
					element: <PageLayout />,
					children: [
						{
							path: routes.general.home(),
							element: <Home />,
						},
					],
				},
				...CampaignsRouter,
				...AccountsRouter,
				...AdvertisersRouter,
			],
		},
		// Wildcard
		{
			path: '*',
			element: <SplitLayout />,
			children: [
				{
					path: '*',
					element: <NotFoundPage />,
				},
			],
		},
	];

	return useRoutes(appRoutes);
};
export const MfpRouter = () => {
	const didMount = useDidMount();
	if (!didMount) {
		return <BarLoader is={true} />;
	}

	return (
		<BrowserRouter>
			<Router />
		</BrowserRouter>
	);
};
