import { BaseCrudStore } from '@monorepo/controlled/src/stores/base-crud.store';
import { FormError } from '@monorepo/tools/src/lib/models/form-error.model';
import { DealsApi } from '../../apis/deals.api';
import { DealModel, IDealCreateForm, IDealEditForm } from '../../models/deal.model';

export class DealCrudStore extends BaseCrudStore<DealModel, IDealCreateForm, IDealEditForm> {
	constructor() {
		super({
			apiLayer: DealsApi,
			model: DealModel,
		});
	}

	/**
	 * Must call isValidDeal before calling this function
	 * @returns
	 */
	public getCreateFormData(): IDealCreateForm {
		return {
			id: this.getData().getId(),
			deal_id: this.getData().getDealId(),
			deal_name: this.getData().getDealName(),
			formats: this.getData().getFormats(),
			proposal_id: this.getData().getProposalId(),
			proposal_name: this.getData().getProposalName(),
			publisher_profile: this.getData().getPublisherProfile(),
			serving_status: this.getData().getServingStatus(),
		};
	}

	/**
	 * Must call isValidDeal before calling this function
	 * @returns
	 */
	public getEditFormData(): IDealEditForm {
		return {
			id: this.getData().getId(),
			deal_id: this.getData().getDealId(),
			deal_name: this.getData().getDealName(),
			formats: this.getData().getFormats(),
			proposal_id: this.getData().getProposalId(),
			proposal_name: this.getData().getProposalName(),
			publisher_profile: this.getData().getPublisherProfile(),
			serving_status: this.getData().getServingStatus(),
		};
	}

	public isValid(): boolean {
		const dealName = this.getData().getDealName();
		if (!dealName) {
			this.formStore.addError(new FormError('name', 'Please provide a deal name'));
		}

		return this.formStore.getIsValid();
	}
}
