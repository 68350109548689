import dayjs from 'dayjs';
import { useDates } from '@monorepo/controlled/src/hooks/use-dates';
import { IPerformanceOptions, preparePerformanceRequest } from '../apis/use-reports-list';
import { BetweenPagesStateProps } from '@monorepo/tools/src/lib/interfaces/global';
import { useLocation } from 'react-router-dom';
import { useDidMount } from '@monorepo/tools/src/lib/hooks/utils/use-didmount';
import { useEffect } from 'react';
import { HttpStore } from '@monorepo/controlled/src/stores/http.store';
import { IPerformancePayload } from '../../models/performance.model';

export const useMetricsGet = (
	httpStore: HttpStore<IPerformanceOptions, IPerformancePayload>,
	options: IPerformanceOptions = {},
	hookOptions: BetweenPagesStateProps = {}
) => {
	const isBlockRequest = hookOptions.isBlockRequest || false;
	const location = useLocation();
	const { startDate, endDate } = useDates();
	const primaryFromDate = dayjs(startDate).format('YYYY-MM-DD');
	const primaryToDate = dayjs(endDate).format('YYYY-MM-DD');
	const didMount = useDidMount(); // fetch data after did mount, solve problem of navigating between performance pages before fetch is resolved
	const orderByColumns = options.sortBys ? options.sortBys[0].column : 'date';

	useEffect(() => {
		const { periodStart, periodEnd } = options;
		if (isBlockRequest || !didMount) {
			return;
		}

		const params = preparePerformanceRequest({
			...options,
			periodStart: periodStart || primaryFromDate,
			periodEnd: periodEnd || primaryToDate,
		});

		httpStore.reset();
		httpStore.fetch(params);

		return () => {
			if (!isBlockRequest) {
				// in case of block request no request happend so no need to abort
				httpStore.abort();
			}
		};
	}, [location, primaryFromDate, primaryToDate, didMount, orderByColumns]);

	return { error: httpStore.getHttpError(), isLoading: httpStore.getIsLoading() };
};
