import { WorkingSVG } from '@monorepo/base/src/assets/working';
import { Icon } from '@monorepo/base/src/components/icon/icon';
import styles from './soon.module.scss';

export const Soon = () => {
	return (
		<div className={styles.wrapper}>
			<div className={styles.title}>Coming Soon</div>
			<Icon className={styles.workingIcon}>
				<WorkingSVG />
			</Icon>
		</div>
	);
};
