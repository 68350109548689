import styles from './bar-loader.module.scss';

interface IBarLoader {
	is: boolean;
}

export const BarLoader = (props: IBarLoader) => {
	const { is } = props;
	if (!is) {
		return null;
	}

	return (
		<div className={styles.loadingLine}>
			<div className={`${styles.loadingLineInner} ${styles.loadingLineInnerTransparent}`}></div>
			<div className={`${styles.loadingLineInner} ${styles.loadingLineInnerColor}`}></div>
		</div>
	);
};
