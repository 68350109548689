import { DealModel } from '../../models/deal.model';
import { DealsApi } from '../../apis/deals.api';
import { makeAutoObservable } from 'mobx';
import { BaseListStore } from '@monorepo/controlled/src/stores/base-list.store';
import { DealCrudStore } from './deal-crud.store';
import { PerformanceStore } from '../performance.store';
import { PerformancesColumns } from '../../hooks/apis/use-performance';

export class DealStore {
	private crud = new DealCrudStore();
	private list = new BaseListStore<DealModel>({
		listFunc: DealsApi.list,
		model: DealModel,
	});
	performance = new PerformanceStore<DealModel>({
		keys: new Set([PerformancesColumns.PreferredDealId]),
	});

	constructor() {
		makeAutoObservable(this);
	}

	getCrud() {
		return this.crud;
	}

	getListStore() {
		return this.list;
	}

	getPerformance() {
		return this.performance;
	}
}
