import { useEffect, useState } from 'react';
import { ask, IResponseInterceptor } from '@monorepo/tools/src/lib/tools/ask/ask';
import { HttpStatuses } from '@monorepo/tools/src/lib/enums/http-statuses';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { HttpError, IHttpError } from '@monorepo/tools/src/lib/models/http-error.model';
import { sessionStorageHelper } from '@monorepo/tools/src/lib/tools/storage';
import { ActionModal, ModalTypes } from '@monorepo/controlled/src/components/modal/modals/action-modal/action-modal';
import { HttpErrorModal } from '@monorepo/controlled/src/components/modal/modals/http-error-modal/http-error-modal';
import { NetworkErrorModal } from '@monorepo/controlled/src/components/modal/modals/network-error-modal/network-error-modal';
import { IMFPStore } from '../../stores';
import { URL_TO_RETURN_AFTER_LOGOUT } from '../../../lib/consts/storage';
import { isUnauthorizedError } from '@monorepo/tools/src/lib/tools/ask/guards';

// TODO - probably will need to change this functionality
const httpErrorModalStatuses = [
	HttpStatuses.NOT_ACCEPTABLE,
	HttpStatuses.METHOD_NOT_ALLOWED,
	HttpStatuses.NOT_IMPLEMENTED, // TODO- new modal for this?
	HttpStatuses.NOT_FOUND, // TODO- new modal for this?
	HttpStatuses.INTERNAL_SERVER_ERROR,
	HttpStatuses.UNAUTHORIZED,
];

const inlineErrorStatuses = [HttpStatuses.BAD_REQUEST];

export const ErrorsContainer = () => {
	const { modalsStore, authStore, userStore } = useStores<IMFPStore>();
	const [isErrorModal, setErrorModal] = useState<boolean>(false);
	const [error, setError] = useState<HttpError | null>(null);

	const getErrorModal = () => {
		if (!isErrorModal) {
			return null;
		}

		const httpStatus = error?.httpStatus;
		if (httpStatus) {
			if (httpErrorModalStatuses.includes(httpStatus)) {
				return <HttpErrorModal error={error} />;
			}
			if (inlineErrorStatuses.includes(httpStatus)) {
				return null;
			}
		}
		return <NetworkErrorModal />;
	};

	useEffect(() => {
		if (isErrorModal) {
			const ErrorModal = getErrorModal();
			if (!ErrorModal) {
				return;
			}
			modalsStore.addModal({
				children: (
					<ActionModal type={ModalTypes.Error} primaryBtnTitle={'Ok'} onClose={() => setErrorModal(false)}>
						{ErrorModal}
					</ActionModal>
				),
			});
		}
	}, [isErrorModal]);

	useEffect(() => {
		return () => setErrorModal(false);
	}, []);

	const serverInterceptor: IResponseInterceptor = {
		error: (response, data) => {
			const httpError = new HttpError({
				httpStatus: response?.status,
				message: data?.payload?.message || data?.message,
			} as IHttpError);

			// if (isAbortError(err) || isBadRequest(err)) {
			// 	return;
			// }

			if (isUnauthorizedError(httpError)) {
				authStore.removeToken();
				userStore.removeUser();
				sessionStorageHelper.setItem(URL_TO_RETURN_AFTER_LOGOUT, window?.location?.pathname);

				if (!isErrorModal) {
					setErrorModal(true);
					setError(httpError);
				}
			}
		},
	};
	ask.interceptors.responses.set('Server Error', serverInterceptor);

	return null;
};
