import { makeAutoObservable, observable } from 'mobx';
import { AdvertisersApi } from '../../apis/advertisers.api';
import { PerformancesColumns } from '../../hooks/apis/use-performance';
import { AdvertiserModel } from '../../models/advertiser.model';
import { BaseListStore } from '@monorepo/controlled/src/stores/base-list.store';
import { PerformanceStore } from '../performance.store';
import { AdvertiserCrudStore } from './advertiser-crud.store';
import { DateSegment } from '../../hooks/tools/use-segments';
import { ISegment } from '@monorepo/base/src/components/segments/segments';
import { PerformanceModel } from '../../models/performance.model';

export class AdvertiserStore {
	crud = new AdvertiserCrudStore();
	list = new BaseListStore<AdvertiserModel>({
		listFunc: AdvertisersApi.list,
		model: AdvertiserModel,
	});
	performance = new PerformanceStore<AdvertiserModel>({
		keys: new Set([PerformancesColumns.AdvertiserId]),
	});
	metrics = new PerformanceStore<AdvertiserModel>({
		segments: new Set<ISegment<AdvertiserModel, PerformanceModel, PerformancesColumns>>([DateSegment<AdvertiserModel>()]),
	});

	constructor() {
		makeAutoObservable(this, {
			crud: observable,
			list: observable,
			performance: observable,
		});
	}

	getListStore() {
		return this.list;
	}

	getCrud() {
		return this.crud;
	}

	getPerformance() {
		return this.performance;
	}

	getMetrics() {
		return this.metrics;
	}
}
