import { useEffect } from 'react';
import { SidebarMode } from '@monorepo/base/src/components/sidebar/sidebar';
import { IControlledStore, useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { useLocation } from 'react-router-dom';

export function useNestedSidebar() {
	const { sidebarStore } = useStores<IControlledStore>();
	const location = useLocation();

	useEffect(() => {
		sidebarStore.setSidebarMode(SidebarMode.HOVER);
		sidebarStore.setSidebarOpen(false);

		return () => {
			sidebarStore.setSidebarMode(SidebarMode.LOCK);
			sidebarStore.setSidebarOpen(true);
		};
	}, [location]);
}
