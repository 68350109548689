import { makeObservable, observable, action } from 'mobx';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import { IPerformanceItem } from './performance.model';

export enum ChannelsEnums {
	Coupons = 'Coupon/Deal sites',
	BNPL = 'BNPL',
	Contextual = 'Contextual',
	Push = 'Push',
	Tiles = 'Tiles',
}

export const ChannelIds = {
	[ChannelsEnums.Coupons]: 8,
	[ChannelsEnums.BNPL]: 15,
	[ChannelsEnums.Contextual]: 23,
	[ChannelsEnums.Push]: 4,
	[ChannelsEnums.Tiles]: 10,
};

export interface IChannel extends IPerformanceItem {
	channelId?: id;
	id?: id;
	name?: ChannelsEnums;
	channel?: string;
	clickCap?: number;
	convCap?: number;
	dailyCap?: number;
	defaultBid?: number;
	suggestedBid?: number;
}

export class ChannelModel implements IChannel {
	channelId?: id;
	id?: id; // need id to extends Id interface, TODO check
	name?: ChannelsEnums;
	channel?: string;
	clickCap?: number;
	convCap?: number;
	dailyCap?: number;
	defaultBid?: number;
	suggestedBid?: number;
	daily_cap?: number;
	clicks?: number;
	conversions?: number;
	cost?: number;
	revenue?: number;
	impressions?: number;
	roas?: number;
	cpc?: number;
	cpa?: number;
	cpm?: number;
	cvr?: number;

	constructor(channel?: IChannel) {
		this.id = channel?.channelId;
		this.channelId = channel?.channelId;
		this.name = channel?.name;
		this.channel = channel?.channel;
		this.clickCap = channel?.clickCap;
		this.convCap = channel?.convCap;
		this.dailyCap = channel?.dailyCap;
		this.defaultBid = channel?.defaultBid;
		this.suggestedBid = channel?.suggestedBid;
		this.conversions = channel?.conversions;

		this.clicks = channel?.clicks;
		this.daily_cap = channel?.daily_cap;
		this.conversions = channel?.conversions;
		this.cost = channel?.cost;
		this.revenue = channel?.revenue;
		this.impressions = channel?.impressions;
		this.roas = channel?.roas;
		this.cpc = channel?.cpc;
		this.cpa = channel?.cpa;
		this.cpm = channel?.cpm;
		this.cvr = channel?.cvr;

		makeObservable(this, {
			channelId: observable,
			name: observable,
			clickCap: observable,
			convCap: observable,
			dailyCap: observable,
			defaultBid: observable,
			suggestedBid: observable,
			setChannelId: action,
			setName: action,
			setClickCap: action,
			setConvCap: action,
			setDailyCap: action,
			setDefaultBid: action,
			setSuggestedBid: action,
		});
	}

	public getId(): id | undefined {
		return this.id;
	}

	public getChannelId(): id | undefined {
		return this.channelId;
	}

	public setChannelId(channelId: id): void {
		this.channelId = channelId;
	}

	public setId(channelId: id): void {
		this.channelId = channelId;
	}

	public getName(): ChannelsEnums | undefined {
		return this.name;
	}

	public setName(name: ChannelsEnums): void {
		this.name = name;
	}

	public getClickCap(): number | undefined {
		return this.clickCap;
	}

	public setClickCap(clickCap: number): void {
		this.clickCap = clickCap;
	}

	public getConvCap(): number | undefined {
		return this.convCap;
	}

	public setConvCap(convCap: number): void {
		this.convCap = convCap;
	}

	public getDailyCap(): number | undefined {
		return this.dailyCap;
	}

	public setDailyCap(dailyCap: number): void {
		this.dailyCap = dailyCap;
	}

	public getDefaultBid(): number | undefined {
		return this.defaultBid;
	}

	public setDefaultBid(defaultBid: number): void {
		this.defaultBid = defaultBid;
	}

	public getSuggestedBid(): number | undefined {
		return this.suggestedBid;
	}

	public setSuggestedBid(suggestedBid: number): void {
		this.suggestedBid = suggestedBid;
	}
}
