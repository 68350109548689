import { CampaignGroupsApi } from '../../apis/campaign-groups.api';
import { Status } from '../../enums/status';
import { CampaignGroupModel, ICampaignGroupCreateForm, ICampaignGroupEditForm } from '../../models/campaign-group.model';
import { BaseCrudStore } from '@monorepo/controlled/src/stores/base-crud.store';
import { constantCase } from 'change-case';

export class CampaignGroupsCrudStore extends BaseCrudStore<CampaignGroupModel, ICampaignGroupCreateForm, ICampaignGroupEditForm> {
	constructor() {
		super({
			apiLayer: CampaignGroupsApi,
			model: CampaignGroupModel,
		});
	}

	/**
	 * Must call isValidCampaignGroup before calling this function
	 * @returns
	 */
	public getCreateFormData(): ICampaignGroupCreateForm {
		const advertiser = this.getData()?.getAdvertiser();
		const advertiserId = advertiser?.getId();
		const status = this.getData().getStatus();

		return {
			id: this.getData().getId(),
			name: this.getData().getName(),
			status: status ? (constantCase(status) as Status) : undefined,
			advertiser: advertiserId ? { id: advertiserId } : undefined,
		};
	}

	/**
	 * Must call isValidCampaignGroup before calling this function
	 * @returns
	 */
	public getEditFormData(): ICampaignGroupEditForm {
		const advertiser = this.getData()?.getAdvertiser();
		const advertiserId = advertiser?.getId();
		const status = this.getData().getStatus();

		return {
			id: this.getData().getId(),
			name: this.getData().getName(),
			status: status ? (constantCase(status) as Status) : undefined,
			advertiser: advertiserId ? { id: advertiserId } : undefined,
		};
	}

	public isValid(): boolean {
		return this.formStore.getIsValid();
	}
}
