import { ReactNode, useEffect, useState } from 'react';
import { Icon } from '../../icon/icon';
import styles from './radio.module.scss';

interface IRadio {
	isActive?: boolean;
	multi?: boolean;
	iconColor?: string;
	onChecked?: (isChecked: boolean) => void;
	children?: ReactNode;
	disabled?: boolean;
}

export const Radio = (props: IRadio) => {
	const { isActive, multi, iconColor, onChecked, children = null, disabled } = props;
	// TODO - iconBackgroundColor #F9F5FF
	const [isChecked, setIsChecked] = useState<boolean>(Boolean(isActive));

	useEffect(() => {
		setIsChecked(Boolean(isActive));
	}, [isActive]);

	const onClick = () => {
		setIsChecked(!isChecked);
		if (onChecked) {
			onChecked(!isChecked);
		}
	};

	return (
		<div className={styles.wrapper}>
			<div
				onClick={() => {
					if (!disabled) {
						onClick();
					}
				}}
				className={`${styles.circle} ${isChecked ? styles.active : ''} ${multi ? styles.multi : ''} ${
					disabled ? styles.disabled : ''
				}`}>
				{multi ? (
					isChecked ? (
						<Icon isMFP={true} color={disabled ? '#d0d5dd' : iconColor} size={'14px'}>
							check
						</Icon>
					) : null
				) : (
					<div className={styles.innerCircle}></div>
				)}
			</div>
			{children ? <div className={styles.children}>{children}</div> : null}
		</div>
	);
};
