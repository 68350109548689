import { makeAutoObservable } from 'mobx';
import { FormError } from '@monorepo/tools/src/lib/models/form-error.model';

export class FormStore {
	private errors: Map<string, FormError>;
	private isValid: boolean;

	constructor() {
		this.isValid = true;
		this.errors = new Map();
		makeAutoObservable(this);
	}

	public reset(): void {
		this.setIsValid(true);
		this.errors = new Map();
	}

	public clearError(propery: string): void {
		this.errors.delete(propery);
		if (this.errors.size === 0) {
			this.setIsValid(true);
		}
	}

	public getErrors(): Map<string, FormError> {
		return this.errors;
	}

	public addError(formError: FormError): void {
		if (!formError.getMsg() || !formError.getProperty()) {
			throw new Error('Must have msg and property in FormError');
		}
		this.setIsValid(false);
		this.errors.set(formError.property, formError);
	}

	public getIsValid(): boolean {
		return this.isValid;
	}

	public setIsValid(isValid: boolean): void {
		this.isValid = isValid;
	}
}
