import { Environment, Environments } from '../enums/environment';
import { capitalCase, constantCase } from 'change-case';
import { makeAutoObservable } from 'mobx';
import { objectFlip } from '@monorepo/tools/src/lib/utils/object';
import { DeviceType } from '@monorepo/tools/src/lib/enums/device-types';
import countriesJson from '@monorepo/tools/src/lib/consts/geos.json';
import { id } from '@monorepo/tools/src/lib/types/primitives';
const flippedCountriesJson = objectFlip(countriesJson);

export interface ITargeting {
	id?: id;
	environment?: Environment;
	device_types?: Set<DeviceType>;
	op_systems?: Set<string>;
	browsers?: Set<string>;
	geos?: Set<string>;
}

export interface ITargetingForm {
	id?: id;
	environment?: Environment;
	device_types?: DeviceType[];
	op_systems?: string[];
	browsers?: string[];
	geos?: string[];
}

const convertTwoDigitsCountriesToFullCountries = (twoDigitsCountries: string[]) => {
	const fullNameCountries: string[] = [];
	twoDigitsCountries.forEach(country => {
		if (country) {
			const fullNameCountry = countriesJson[country as keyof typeof countriesJson];
			fullNameCountries.push(fullNameCountry);
		}
	});
	return fullNameCountries;
};

const convertFullCountryToTwoDigitsCountries = (fullNameCountries: Set<string>) => {
	const twoDigitsCountries = new Set<string>();
	fullNameCountries.forEach(country => {
		if (country) {
			twoDigitsCountries.add(flippedCountriesJson[country]);
		}
	});
	return twoDigitsCountries;
};

export class TargetingModel implements ITargeting {
	id?: id;
	environment?: Environment;
	device_types?: Set<DeviceType>;
	op_systems?: Set<string>;
	browsers?: Set<string>;
	geos?: Set<string>;
	isSharedTargetingModified?: boolean;

	constructor(targeting?: ITargeting) {
		this.id = targeting?.id;
		this.environment = targeting?.environment ? (capitalCase(targeting?.environment) as Environment) : Environments.All;
		this.device_types =
			targeting && Array.isArray(targeting?.device_types) ? (this.arrayToSet(targeting.device_types) as Set<DeviceType>) : undefined;
		this.op_systems = targeting && Array.isArray(targeting?.op_systems) ? this.arrayToSet(targeting.op_systems) : undefined;
		this.browsers = targeting && Array.isArray(targeting?.browsers) ? this.arrayToSet(targeting.browsers) : undefined;
		this.geos =
			targeting && Array.isArray(targeting?.geos)
				? this.arrayToSet(convertTwoDigitsCountriesToFullCountries(targeting.geos))
				: undefined;
		makeAutoObservable(this);
	}

	private setToArray(model: Set<string>): string[] {
		return Array.from(model).map(value => constantCase(value));
	}

	private arrayToSet(data: string[]): Set<string> {
		data = data.filter(d => d);
		return new Set(data.map(d => capitalCase(d)));
	}

	public getFormData(): ITargetingForm {
		return {
			id: this.id ? this.id : undefined,
			environment: this.environment ? (this.environment.toUpperCase() as Environment) : undefined,
			device_types: this.device_types ? (this.setToArray(this.device_types) as DeviceType[]) : undefined,
			op_systems: this.op_systems ? this.setToArray(this.op_systems) : undefined,
			browsers: this.browsers ? this.setToArray(this.browsers) : undefined,
			geos: this.geos ? this.setToArray(convertFullCountryToTwoDigitsCountries(this.geos)) : undefined,
		};
	}

	public getId(): id | undefined {
		return this.id;
	}

	public setId(id: id): void {
		this.id = id;
	}

	public getEnvironment(): Environment | undefined {
		return this.environment;
	}

	public setEnvironment(environment: Environment): void {
		this.checkIsModified();
		this.environment = environment;
	}

	public getDeviceTypes(): Set<string> | undefined {
		return this.device_types;
	}

	public setDeviceTypes(device_types: Set<DeviceType>): void {
		this.checkIsModified();
		this.device_types = device_types;
	}

	public getOperatingSystems(): Set<string> | undefined {
		return this.op_systems;
	}

	public setOperatingSystems(op_systems: Set<string> | undefined): void {
		this.checkIsModified();
		this.op_systems = op_systems;
	}

	public getBrowsers(): Set<string> | undefined {
		return this.browsers;
	}

	public setBrowsers(browsers: Set<string> | undefined): void {
		this.browsers = browsers;
	}

	public getGeos(): Set<string> | undefined {
		return this.geos;
	}

	public setGeos(geos: Set<string> | undefined): void {
		this.checkIsModified();
		this.geos = geos;
	}

	private checkIsModified() {
		if (this?.id) {
			this.isSharedTargetingModified = true;
		}
	}

	public getIsSharedTargetingModified(): undefined | boolean {
		return this.isSharedTargetingModified;
	}
}
