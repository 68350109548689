import { Fragment } from 'react';
import { id } from '@monorepo/tools/src/lib/types/primitives';

type IToggleAdvertisersStatuses = {
	advertiserIds: id[];
	action: string;
};
export const EnablePauseAdvertisersModal = (props: IToggleAdvertisersStatuses) => {
	const { advertiserIds, action } = props;
	return (
		<Fragment>
			<div>{action} the following advertisers:</div>
			<span style={{ color: '#4f46e5' }}>
				{advertiserIds.length < 6
					? advertiserIds.map(
							(advertiserId, index) => `${index !== advertiserIds.length - 1 ? advertiserId + ', ' : advertiserId + '.'}`
					  )
					: `${advertiserIds.length} advertisers`}
			</span>
		</Fragment>
	);
};
