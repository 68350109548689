import { CreativeModel, ICreativeCreateForm, ICreativeEditForm } from '../models/creative.model';
import { ask } from '@monorepo/tools/src/lib/tools/ask/ask';
import { IRequestOptions } from '@monorepo/tools/src/lib/interfaces/url';
import endpoints from './endpoints/index.endpoints';
import { URLSearchParams } from 'url';
import { id } from '@monorepo/tools/src/lib/types/primitives';

export type ids = {
	id: id;
}[];

export const CreativesApi = {
	list: (options?: URLSearchParams): Promise<CreativeModel[]> => {
		return ask.get(endpoints.creatives.list(), options);
	},
	create: (creative: ICreativeCreateForm, options?: IRequestOptions): Promise<{ id: id }> => {
		return ask.post<ICreativeCreateForm>(endpoints.creatives.create(), creative, options);
	},
	edit: (creativeId: id, creative: ICreativeEditForm, options?: IRequestOptions): Promise<null> => {
		return ask.patch<ICreativeEditForm>(`${endpoints.creatives.edit(creativeId.toString())}`, creative, options);
	},
	get: (creativeId: id, params?: URLSearchParams): Promise<CreativeModel> => {
		return ask.get(`${endpoints.creatives.get(creativeId)}`, params);
	},
	delete: (creativeId: id) => {
		return ask.delete(`${endpoints.creatives.delete(creativeId)}`);
	},
	postBanner: (banners: FormData): Promise<null> => {
		return ask.postForms(endpoints.creatives.postBanner(), banners);
	},
	sendToReview(creativeIds: id[]) {
		return ask.post(endpoints.creatives.sendToReview(), creativeIds);
	},
	attachCampaigns(creativeId: id, campaignIds: (id | undefined)[]): Promise<null> {
		return ask.post(endpoints.creatives.attachCampaigns(creativeId), campaignIds);
	},
	multiCreate: (creatives: ICreativeCreateForm[], options?: IRequestOptions): Promise<ids> => {
		return ask.post<ICreativeCreateForm[]>(endpoints.creatives.multiCreate(), creatives, options);
	},
	detachCampaignCreatives: (creativeIds: id[], campaignId: id): Promise<null> => {
		if (!campaignId) {
			throw new TypeError('Campaign id cannot be undefined');
		}
		return ask.delete(`${endpoints.creatives.getCampaignCreatives(campaignId, creativeIds)}`);
	},
};
