import { AdvertiserModel, IAdvertiserCreateForm, IAdvertiserEditForm } from '../../models/advertiser.model';
import { AdvertisersApi } from '../../apis/advertisers.api';
import { constantCase } from 'change-case';
import { BaseCrudStore } from '@monorepo/controlled/src/stores/base-crud.store';
import { FormError } from '@monorepo/tools/src/lib/models/form-error.model';
import { Statuses, Status } from '../../enums/status';

export class AdvertiserCrudStore extends BaseCrudStore<AdvertiserModel, IAdvertiserCreateForm, IAdvertiserEditForm> {
	constructor() {
		super({
			apiLayer: AdvertisersApi,
			model: AdvertiserModel,
		});
	}

	/**
	 * Must call isValidAdvertiser before calling this function
	 * @returns
	 */
	public getCreateFormData(): IAdvertiserCreateForm {
		const status = this.getData().getStatus();

		return {
			id: this.getData().getId(),
			name: this.getData().getName(),
			status: (status ? constantCase(status) : constantCase(Statuses.Paused)) as Status,
			total_budget: this.getData().getTotalBudget(),
			user_cap_enabled: this.getData().getUserCapEnabled(),
		};
	}

	/**
	 * Must call isValidAdvertiser before calling this function
	 * @returns
	 */
	public getEditFormData(): IAdvertiserEditForm {
		const status = this.getData().getStatus();

		return {
			id: this.getData().getId(),
			name: this.getData().getName(),
			status: (status ? constantCase(status) : constantCase(Statuses.Paused)) as Status,
			total_budget: this.getData().getTotalBudget(),
			user_cap_enabled: this.getData().getUserCapEnabled(),
		};
	}

	public isValid(): boolean {
		this.formStore.reset();

		if (!this.getData().getName()) {
			this.formStore.addError(new FormError('name', 'Please provide a name'));
		}

		if (!this.getData().getTotalBudget()?.limit) {
			this.formStore.addError(new FormError('total_budget', 'Please provide a total budget'));
		}

		return this.formStore.getIsValid();
	}
}
