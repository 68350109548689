import { observer } from 'mobx-react';
import { Outlet } from 'react-router-dom';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import styles from './page-layout.module.scss';
import { MainNavbar } from '../../main-navbar/main-navbar';
import { IMFPStore } from '../../../stores';
import { ControlledSidebar } from '../../sidebar/sidebar';

export const PageLayout = observer(() => {
	const { sidebarStore } = useStores<IMFPStore>();

	return (
		<main className={styles.main}>
			<ControlledSidebar />
			<div className={sidebarStore.getIsSideBarOpen() ? styles.minimize : styles.maximize}>
				<MainNavbar />
				<Outlet />
			</div>
		</main>
	);
});
