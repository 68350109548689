import { makeAutoObservable } from 'mobx';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import { CampaignGroupModel } from './campaign-group.model';
import { CreativeGroupModel } from './creative-group.model';
import { Status } from '../enums/status';

export interface IStats {
	daily_spent?: number;
	daily_earn?: number;
	total_spent?: number;
	total_earn?: number;
}

export interface IBudget {
	limit?: number;
	balance?: number;
}

export interface IAdvertiser {
	id?: id;
	name?: string;
	status?: Status;
	user_cap_enabled?: boolean;
	campaign_groups?: CampaignGroupModel[];
	stats?: IStats;
	total_budget?: IBudget;
}

export interface IAdvertiserCreateForm {
	id?: id;
	name?: string;
	status?: Status;
	total_budget?: IBudget;
	user_cap_enabled?: boolean;
}

export interface IAdvertiserEditForm {
	id?: id;
	name?: string;
	status?: Status;
	total_budget?: IBudget;
	user_cap_enabled?: boolean;
}

export class AdvertiserModel implements IAdvertiser {
	id?: id;
	name?: string;
	status?: Status;
	user_cap_enabled?: boolean;
	campaign_groups?: CampaignGroupModel[];
	creative_groups?: CreativeGroupModel[];
	stats?: IStats;
	total_budget?: IBudget;

	constructor(advertiser?: IAdvertiser) {
		this.id = advertiser?.id;
		this.name = advertiser?.name;
		this.status = advertiser?.status;
		this.campaign_groups = advertiser?.campaign_groups
			? advertiser?.campaign_groups.map(campaignGroup => new CampaignGroupModel(campaignGroup))
			: undefined;
		this.stats = advertiser?.stats;
		this.total_budget = advertiser?.total_budget;
		this.user_cap_enabled = advertiser?.user_cap_enabled;

		makeAutoObservable(this);
	}

	setCampaignGroups(campaignGroups: CampaignGroupModel[]) {
		this.campaign_groups = campaignGroups;
	}

	getCampaignGroups(): CampaignGroupModel[] | undefined {
		return this.campaign_groups;
	}

	setCreativeGroups(creativeGroups: CreativeGroupModel[]) {
		this.creative_groups = creativeGroups;
	}

	getCreativeGroups(): CreativeGroupModel[] | undefined {
		return this.creative_groups;
	}

	getId(): id | undefined {
		return this.id;
	}

	getName(): string | undefined {
		return this.name;
	}

	setName(name: string) {
		this.name = name;
	}

	getStatus(): Status | undefined {
		return this.status;
	}

	setStatus(status: Status): void {
		this.status = status;
	}

	setTotalBudget(totalBudget: IBudget) {
		this.total_budget = totalBudget;
	}

	getTotalBudget(): IBudget | undefined {
		return this.total_budget;
	}

	setUserCapEnabled(userCapEnabled: boolean) {
		this.user_cap_enabled = userCapEnabled;
	}

	getUserCapEnabled(): boolean | undefined {
		return this.user_cap_enabled;
	}
}
