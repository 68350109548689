import { PreferenceModel } from '../../models/preference.model';
import { PreferencesApi } from '../../apis/preferences.api';
import { makeAutoObservable } from 'mobx';
import { BaseListStore } from '@monorepo/controlled/src/stores/base-list.store';
import { PreferenceCrudStore } from './preference-crud.store';

export class PreferenceStore {
	private crud = new PreferenceCrudStore();
	private list = new BaseListStore<PreferenceModel>({
		listFunc: PreferencesApi.list,
		model: PreferenceModel,
	});

	constructor() {
		makeAutoObservable(this);
	}

	getCrud() {
		return this.crud;
	}

	getListStore() {
		return this.list;
	}
}
