import { CreateCampaign } from '../../pages/campaigns/create/create-campaign.page';
import { FullLayout } from '../components/layouts/full-layout/full-layout';
import { PageLayout } from '../components/layouts/page-layout/page-layout';
import { Campaigns } from '../../pages/campaigns/index/campaigns.page';
import routes from '../routes/index.routes';
import { CampaignView } from '../../pages/campaigns/view/campaign-view.page';
import { EditCampaign } from '../../pages/campaigns/edit/edit-campaign.page';
import { CampaignChannels } from '../../pages/campaigns/view/channels/channels.campaign';
import { CampaignSources } from '../../pages/campaigns/view/sources/sources.campaign';
import { CampaignOverview } from '../../pages/campaigns/view/overview/overview.campaign';
import { DuplicateCampaign } from '../../pages/campaigns/duplicate/duplicate-campaign.page';

export const CampaignsRouter = [
	{
		element: <FullLayout />,
		children: [
			{
				path: routes.campaigns.create(),
				element: <CreateCampaign />,
			},
			{
				path: routes.campaigns.edit(':campaignId'),
				element: <EditCampaign />,
			},
			{
				path: routes.campaigns.duplicate(':campaignId'),
				element: <DuplicateCampaign />,
			},
		],
	},
	{
		element: <PageLayout />,
		children: [
			{
				path: routes.campaigns.index(),
				element: <Campaigns />,
			},
			{
				element: <CampaignView />,
				children: [
					{
						path: routes.campaigns.view(':campaignId'),
						element: <CampaignOverview />,
					},
					{
						path: routes.campaigns.channels(':campaignId'),
						element: <CampaignChannels />,
					},
					{
						path: routes.campaigns.sources(':campaignId'),
						element: <CampaignSources />,
					},
				],
			},
		],
	},
];
