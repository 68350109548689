import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import dayjs from 'dayjs';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { useDates } from '@monorepo/controlled/src/hooks/use-dates';
import { BetweenPagesStateProps } from '@monorepo/tools/src/lib/interfaces/global';
import { useDidMount } from '@monorepo/tools/src/lib/hooks/utils/use-didmount';
import { url } from '@monorepo/tools/src/lib/types/url';
import { IAdminxStore } from '../../stores';

export enum SideEffectKeys {
	DealId = 'deal_id',
	Id = 'id',
}

export enum PerformancesColumns {
	Domain = 'domain',
	Page = 'page',
	BidKeys = 'bid_key',
	CampaignId = 'campaign_id',
	CampaignName = 'campaign_name',
	CampaignGroupId = 'campaign_group_id',
	AdvertiserId = 'advertiser_id',
	CreativeId = 'creative_id',
	CreativeGroupId = 'creative_group_id',
	TestId = 'test_id',
	Date = 'date',
	PublisherId = 'publisher_id',
	BrowserName = 'browser_name',
	OsName = 'os_name',
	Geo = 'geo',
	State = 'state',
	City = 'city',
	AuctionType = 'auction_type',
	PreferredDealId = 'preferred_deal_id',
	CreativeSize = 'creative_size',
	TestGroupName = 'test_group_name',
	PreferredDeaName = 'preferred_deal_name',
	BidIsEditable = 'bid_is_editable',
	CreativeBrandName = 'creative_brand_name',
	CreativeGroupDesign = 'creative_group_design',
	StrategyId = 'strategy_id',
	CreativeImageUrl = 'creative_image_url',
	TestName = 'test_name',
	Bid = 'bid',
}

// TODO - @RONT explain why we need it in comment
export type ForeignKeys =
	| PerformancesColumns.CampaignId
	| PerformancesColumns.CampaignGroupId
	| PerformancesColumns.AdvertiserId
	| PerformancesColumns.CreativeId
	| PerformancesColumns.CreativeGroupId
	| PerformancesColumns.TestId
	| PerformancesColumns.PublisherId
	| PerformancesColumns.PreferredDealId
	| SideEffectKeys.DealId
	| SideEffectKeys.Id;

// DEPRECATED
export interface IPerformanceOptions {
	keys?: PerformancesColumns[];
	fromDate?: string;
	toDate?: string;
	campaignIds?: id | id[];
	campaignGroupIds?: id | id[];
	advertiserIds?: id | id[];
	publisherIds?: id | id[];
	creativeIds?: id | id[];
	testIds?: id | id[];
	geos?: string | string[]; // 2 digits
	states?: string | string[]; // 2 digits
	domains?: url | url[];
	osNames?: string | string[];
	cities?: string | string[];
	pages?: url | url[];
	bidKeys?: string | string[];
	preferredDealIds?: string | string[];
	auctionTypes?: string | string[];
}

interface IPerformanceOptionsPeriod {
	periodStart: string; // YYYY-MM-DD
	periodEnd: string; // YYYY-MM-DD
}

export enum IPerformanceOptionsFilterType {
	Include = 'INCLUDE',
	AutocompleteInclue = 'AUTOCOMPLETE_INCLUDE',
}

interface IPerformanceOptionsFilter {
	column: string;
	filterType: string;
	value: string[];
	inverted?: boolean;
}

enum IPerformanceOptionsDateSegments {
	Daily = 'DAILY',
	None = 'NONE',
}

enum IPerformanceOptionsSortBy {
	Date = 'date',
}

enum IPerformanceOptionsSortOrder {
	Asc = 0,
	Desc = 1,
}

export interface IPerformanceOptionsExternal extends IPerformanceOptionsV2 {
	reportName: string;
	type: string;
	submit: boolean;
}

export interface IPerformanceOptionsV2 {
	start?: number;
	limit?: number;
	periodStart?: string; // YYYY-MM-DD
	periodEnd?: string; // YYYY-MM-DD
	multipleTimePeriods?: IPerformanceOptionsPeriod[];
	groupBys?: (PerformancesColumns | null)[];
	filters?: IPerformanceOptionsFilter[];
	dateSegment?: IPerformanceOptionsDateSegments;
	sortBy?: IPerformanceOptionsSortBy;
	sortOrder?: IPerformanceOptionsSortOrder;
	inResultsSort?: string | null;
	inResultsSortOrder?: IPerformanceOptionsSortOrder;
	inResultsFilters?: string[][];
	graphYaxis?: string[];
	graphLines?: string[]; // ?
	additionalColumns?: string[]; // ?
}

export const preparePerformanceRequest = (options: IPerformanceOptionsV2): IPerformanceOptionsExternal & IPerformanceOptionsV2 => {
	const {
		start,
		limit,
		periodStart,
		periodEnd,
		multipleTimePeriods,
		groupBys,
		filters,
		dateSegment,
		sortBy,
		sortOrder,
		inResultsSort,
		inResultsSortOrder,
		inResultsFilters,
		graphYaxis,
		graphLines,
		additionalColumns,
	} = options;

	const externalOptions: IPerformanceOptionsExternal & IPerformanceOptionsV2 = {
		reportName: 'adx',
		type: 'STATISTICS',
		submit: true,
		start: start || 0,
		limit: limit || 100000,
		periodStart,
		periodEnd,
		multipleTimePeriods: multipleTimePeriods || [],
		groupBys: (() => {
			if (!groupBys) {
				return [];
			}
			// Default is by date, if we add date it ruins the results so remove it for now until fixed in reporting service (probably never blat)
			return groupBys
				?.filter(groupBy => groupBy !== PerformancesColumns.Date)
				.filter((value, index, self) => self.indexOf(value) === index); // remove duplicates
		})(),
		filters: filters || [],
		dateSegment: (() => {
			if (dateSegment) {
				return dateSegment;
			}

			if (!groupBys?.includes(PerformancesColumns.Date)) {
				return IPerformanceOptionsDateSegments.None;
			}
			return IPerformanceOptionsDateSegments.Daily;
		})(),
		sortBy: sortBy || IPerformanceOptionsSortBy.Date,
		inResultsSort: inResultsSort || null,
		inResultsSortOrder: inResultsSortOrder || IPerformanceOptionsSortOrder.Desc,
		sortOrder: sortOrder || IPerformanceOptionsSortOrder.Desc,
		inResultsFilters: inResultsFilters || [],
		graphYaxis: graphYaxis || [],
		graphLines: graphLines || [],
		additionalColumns: additionalColumns || [],
	};

	// In case periodStart or periodEnd is from the props and not from the global state take it
	// if (groupBys) {
	// 	returnAsArray(groupBys).map(groupBy => params.append('groupBys', groupBy));
	// }

	// TODO - filters
	// if (campaignIds) {
	// 	returnAsArray(campaignIds).map(campaign_id => params.append('campaign_ids', campaign_id.toString()));
	// }

	// if (campaignGroupIds) {
	// 	returnAsArray(campaignGroupIds).map(campaign_group_id => params.append('campaign_group_ids', campaign_group_id.toString()));
	// }

	// if (advertiserIds) {
	// 	returnAsArray(advertiserIds).map(advertiserId => params.append('advertiserIds', advertiserId.toString()));
	// }

	// if (publisherIds) {
	// 	returnAsArray(publisherIds).map(publisher_id => params.append('publisher_ids', publisher_id.toString()));
	// }

	// if (creativeIds) {
	// 	returnAsArray(creativeIds).map(creativeId => params.append('creative_ids', creativeId.toString()));
	// }

	// if (testIds) {
	// 	returnAsArray(testIds).map(test_id => params.append('test_ids', test_id.toString()));
	// }

	// if (geos) {
	// 	returnAsArray(geos).map(geo => params.append('geos', geo.toString()));
	// }

	// if (states) {
	// 	returnAsArray(states).map(state => params.append('states', state.toString()));
	// }

	// if (domains) {
	// 	returnAsArray(domains).map(domain => params.append('domains', domain.toString()));
	// }

	// if (osNames) {
	// 	returnAsArray(osNames).map(os_name => params.append('os_names', os_name.toString()));
	// }

	// if (cities) {
	// 	returnAsArray(cities).map(city => params.append('cities', city.toString()));
	// }

	// if (pages) {
	// 	returnAsArray(pages).map(page => params.append('pages', page.toString()));
	// }

	// if (bidKeys) {
	// 	returnAsArray(bidKeys).map(bidKey => params.append('bid_keys', bidKey.toString()));
	// }

	// if (preferredDealIds) {
	// 	returnAsArray(preferredDealIds).map(preferred_deal_id => params.append('preferred_deal_ids', preferred_deal_id.toString()));
	// }

	// if (auctionTypes) {
	// 	returnAsArray(auctionTypes).map(auction_type => params.append('auction_types', auction_type.toString()));
	// }

	return externalOptions;
};

export const usePerformance = (options: IPerformanceOptionsV2, hookOptions: BetweenPagesStateProps = {}) => {
	const isBlockRequest = hookOptions.isBlockRequest || false;
	const { performanceStore } = useStores<IAdminxStore>();
	const location = useLocation();
	const { startDate, endDate } = useDates();
	const primaryFromDate = dayjs(startDate).format('YYYY-MM-DD');
	const primaryToDate = dayjs(endDate).format('YYYY-MM-DD');
	const didMount = useDidMount(); // fetch data after did mount, solve problem of navigating between performance pages before fetch is resolved

	useEffect(() => {
		const { periodStart, periodEnd } = options;
		if (isBlockRequest || !didMount) {
			return;
		}

		const params = preparePerformanceRequest({
			...options,
			periodStart: periodStart || primaryFromDate,
			periodEnd: periodEnd || primaryToDate,
		});

		performanceStore.reset();
		performanceStore.fetch(params);

		return () => {
			if (!isBlockRequest) {
				// in case of block request no request happend so no need to abort
				performanceStore.abort();
			}
		};
	}, [location, primaryFromDate, primaryToDate, didMount]);

	return { error: performanceStore.getHttpError(), isLoading: performanceStore.getIsLoading() };
};
