// import { Icon } from '@monorepo/base/src/components/icon/icon';
import styles from './settings-menu.module.scss';
import { observer } from 'mobx-react';
import { Menu } from '@monorepo/base/src/components/menu/menu';
import { useState } from 'react';
import { SettingsIcon } from '@monorepo/base/src/assets/calendar.icon';
import { Soon } from '@monorepo/base/src/components/soon/soon';

export const SettingsMenu = observer(() => {
	const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false);

	const SettingsBar = () => {
		return (
			<div className={styles.settingsBar} onClick={() => setIsOpenMenu(!isOpenMenu)}>
				<SettingsIcon className={styles.settingsIcon} />
			</div>
		);
	};

	return (
		<div className={styles.wrapper}>
			<SettingsBar />
			<Menu alignToRight={true} isOpen={isOpenMenu} onClose={() => setIsOpenMenu(false)}>
				<Soon />
			</Menu>
		</div>
	);
});
