import { CreativeGroupModel, ICreativeGroupCreateForm, ICreativeGroupEditForm } from '../../models/creative-group.model';
import { CreativeGroupsApi } from '../../apis/creative-groups.api';
import { BaseCrudStore } from '@monorepo/controlled/src/stores/base-crud.store';
import { FormError } from '@monorepo/tools/src/lib/models/form-error.model';

export class CreativeGroupsCrudStore extends BaseCrudStore<CreativeGroupModel, ICreativeGroupCreateForm, ICreativeGroupEditForm> {
	constructor() {
		super({
			apiLayer: CreativeGroupsApi,
			model: CreativeGroupModel,
		});
	}

	/**
	 * Must call isValidCreativeGroup before calling this function
	 * @returns
	 */
	public getCreateFormData(): ICreativeGroupCreateForm {
		const advertiser = this.getData().getAdvertiser();
		const advertiserId = advertiser?.getId();
		return {
			id: this.getData().getId(),
			design: this.getData().getDesign(),
			advertiser: advertiserId ? { id: advertiserId } : undefined,
		};
	}

	/**
	 * Must call isValidCreativeGroup before calling this function
	 * @returns
	 */
	public getEditFormData(): ICreativeGroupEditForm {
		const advertiser = this.getData().getAdvertiser();
		const advertiserId = advertiser?.getId();
		return {
			id: this.getData().getId(),
			design: this.getData().getDesign(),
			advertiser: advertiserId ? { id: advertiserId } : undefined,
		};
	}

	isValid(): boolean {
		const creativeGroupDesign = this.getData().getDesign();
		if (!creativeGroupDesign) {
			this.formStore.addError(new FormError('design', 'Please provide a creative group name'));
		}

		const advertiser = this.getData().getAdvertiser();
		if (!advertiser) {
			this.formStore.addError(new FormError('advertiser', 'Please select an advertiser'));
		}

		return this.formStore.getIsValid();
	}
}
