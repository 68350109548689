import { observer } from 'mobx-react';
import { stringAction, stringFuncs } from '@monorepo/tools/src/lib/utils/string';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { FilterModel, FilterOptionsEnums, IFilterComponentProps, IFilterProto } from '@monorepo/controlled/src/models/filter.model';
import { FilterStringMenu } from '@monorepo/base/src/components/filters/filter-string-menu/filter-string-menu';
import { IMFPStore } from '../../stores';
import { AdvertiserFiltersStoreKeys } from '../../stores/advertisers/advertiser.store';
import { AdvertiserModel } from '../../models/advertiser.model';
import { AccountFiltersStoreKeys } from '../../stores/accounts/account.store';

// TODO - change to add filter and edit filter from props
const AccountNameFilter = observer((props: IFilterComponentProps) => {
	const { filter, onCancel, onApply } = props;
	const { advertiserStore, accountStore } = useStores<IMFPStore>();
	const { currentRouteWithoutSpecialChars } = useRoute();

	const onAddAccountNameFilter = (_action: stringAction, _value: string) => {
		const filterLabel = `Account Name ${_action} ${_value}`;
		const accountNameFilter = new FilterModel({
			label: filterLabel,
			action: _action,
			value: _value,
			prototype: AccountNameFilterPrototype,
		});
		const filterStore = advertiserStore.getFiltersStore(currentRouteWithoutSpecialChars as AdvertiserFiltersStoreKeys);
		const accountFilterStore = accountStore.getFiltersStore(currentRouteWithoutSpecialChars as AccountFiltersStoreKeys);
		if (filterStore) {
			if (filter?.index && filterStore?.getCurrentFilters().has(filter?.index)) {
				filterStore.editFilter(filter.index, accountNameFilter);
			} else {
				filterStore?.addFilter(accountNameFilter);
			}
		}

		if (accountFilterStore) {
			if (filter?.index && accountFilterStore?.getCurrentFilters().has(filter?.index)) {
				accountFilterStore.editFilter(filter.index, accountNameFilter);
			} else {
				accountFilterStore?.addFilter(accountNameFilter);
			}
		}
		onApply();
	};

	return (
		<FilterStringMenu
			title={'Account Name'}
			defaultValue={typeof filter?.value === 'string' ? filter?.value : ''}
			defaultAction={filter?.action}
			onApply={(_action: stringAction, _value: string) => onAddAccountNameFilter(_action, _value)}
			onCancel={onCancel}
		/>
	);
});

export const AccountNameFilterPrototype: IFilterProto = {
	MenuComponent: 'Account Name',
	FilterComponent: AccountNameFilter,
	prop: FilterOptionsEnums.AccountName,
	deletable: true,
	multi: true,
	filterFunc<T extends AdvertiserModel>(models: Array<T>, filter: FilterModel): Array<T> {
		const { value, action } = filter;
		return models.filter(model => {
			if (!action) {
				return true;
			}
			const actionFunc = stringFuncs[action];
			if (!actionFunc) {
				return true;
			}
			return actionFunc(model?.account?.name || model.name || '', typeof value === 'string' ? value : '');
		});
	},
};
