import { Dropdown, DropdownAction, DropdownActions, option } from '@monorepo/base/src/components/dropdown/dropdown';
import { InputItem, InputInfo, InputTitle, InputDesc } from '@monorepo/base/src/components/form/input-item/input-item';
import { Input } from '@monorepo/base/src/components/form/input/input';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { observer } from 'mobx-react';
import { OptimizationModes, OptimizationModesLabels } from '../../../../enums/optimization-modes';
import { IMFPStore } from '../../../../stores';
import { BudgetInput } from '../campaign-common.form';
import styles from '../campaign-form.module.scss';
import { SpaceBetweenInputControls } from '../campaign.form';
import { CampaignDomIds } from '../../../../hooks/toc/campaign.toc';
import {
	EmptyState,
	EmptyStateActions,
	EmptyStateDescription,
	EmptyStateIcon,
	EmptyStateTitle,
} from '@monorepo/base/src/components/empty-state/empty-state';
import { PrimaryLink } from '@monorepo/base/src/components/buttons/buttons';
import { ButtonGroup, GButton } from '@monorepo/base/src/components/buttons/group/button-group';
import { Flex } from '@monorepo/base/src/components/flex/flex';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';
import { ConversionModel } from '../../../../models/conversion.model';
import { useEffect } from 'react';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';

export const SmartStrategy = observer(() => {
	const { campaignStore, advertiserStore } = useStores<IMFPStore>();
	const routes = useRoute();
	const isEditPage = routes.currentRoute.includes('edit');
	const campaignCrudStore = campaignStore.getCrud();
	const campaign = campaignCrudStore.getData();
	const formStore = campaignCrudStore.getFormStore();
	const advertiser = advertiserStore.getCrud().getData();
	const conversions = advertiser.getConversions();

	useEffect(() => {
		if (isEditPage && campaign?.getAdvertiser()) {
			advertiserStore.getCrud().get(campaign?.getAdvertiser()?.id || -1);
		}
	}, [campaign?.getAdvertiser()]);

	const selectedConversion = campaign?.getConversionActions();

	const conversionNames: string[] = conversions?.map(c => c.name || '') || [];
	const onConversionSelect = (conversionName: string[]) => {
		const conversionActions: ConversionModel[] = [];
		conversionName.map(c => {
			const _conversion = conversions?.find(_conversion => _conversion.getName() === c);
			if (_conversion) {
				conversionActions.push(_conversion);
			}
		});
		campaign.setConversionActions(conversionActions);
	};

	const renderConversionsActions = () => {
		if (!conversionNames || conversionNames.length === 0) {
			return (
				<EmptyState>
					<EmptyStateIcon>line-chart-up-02</EmptyStateIcon>
					<EmptyStateTitle>No conversions in your account</EmptyStateTitle>
					<EmptyStateDescription>
						Set up conversions to track campaign performance and optimize for better results and higher ROI
					</EmptyStateDescription>
					<EmptyStateActions>
						<PrimaryLink to={'#'} icon={'plus'} style={{ maxWidth: '250px', margin: '0 auto' }}>
							Add a new conversion
						</PrimaryLink>
					</EmptyStateActions>
				</EmptyState>
			);
		}

		if (conversionNames.length > 1) {
			return (
				<DropdownActions>
					<DropdownAction to={'#'}>Add a new conversion</DropdownAction>
				</DropdownActions>
			);
		}
	};

	return (
		<div>
			<InputItem id={CampaignDomIds.TotalBudget} maxWidth={'620px'}>
				<InputInfo className={styles.inputInfo}>
					<InputTitle>Daily Budget</InputTitle>
					<InputDesc>Enter the maximum amount you can spend per day</InputDesc>
				</InputInfo>
				<BudgetInput
					value={campaign.getDailyBudget()?.toString()}
					setValue={value => {
						campaign.setDailyBudget(parseFloat(value));
					}}
				/>
			</InputItem>

			<SpaceBetweenInputControls />

			<InputItem maxWidth={'944px'}>
				<InputInfo className={`${styles.inputInfo} ${styles.stickToTop}`}>
					<InputTitle>Optimization Mode</InputTitle>
					<InputDesc>Select the optimization mode that best suits your goals</InputDesc>
				</InputInfo>
				{/* TODO - think about gbutton? */}
				<Flex flexDirection={'column'} alignItems={'flex-start'} width={'320px'} gap={'10px'}>
					<ButtonGroup
						radio={true}
						defaultValue={
							campaign?.getOptimizationMode() === OptimizationModes.TargetCPA ||
							campaign?.getOptimizationMode() === OptimizationModes.ManualCPC
								? OptimizationModesLabels.TargetCPA
								: OptimizationModesLabels.TargetRoas
						}
						onChange={(_optimizationModeLabel: Set<option> | undefined) => {
							const optimizationModeLabel = _optimizationModeLabel?.values().next().value as OptimizationModesLabels;
							// TODO - hash from mode to label
							const optimizationModeToChange = (() => {
								if (optimizationModeLabel === OptimizationModesLabels.TargetCPA) {
									return OptimizationModes.TargetCPA;
								}
								if (optimizationModeLabel === OptimizationModesLabels.TargetRoas) {
									return OptimizationModes.TargetRoas;
								}
							})();

							if (campaign.getOptimizationMode() !== optimizationModeToChange) {
								campaign.setDefaultBid(undefined);
							}
							campaign.setOptimizationMode(optimizationModeToChange);
							formStore.clearError(CampaignDomIds.OptimizationMode);
						}}>
						<GButton text={OptimizationModesLabels.TargetCPA} value={OptimizationModesLabels.TargetCPA} />
						<GButton text={OptimizationModesLabels.TargetRoas} value={OptimizationModesLabels.TargetRoas} />
					</ButtonGroup>
					{/* <SpacerX x={2} /> */}
					{campaign.getOptimizationMode() === OptimizationModes.TargetCPA ? (
						<BudgetInput
							placeholder={''}
							id={CampaignDomIds.OptimizationBid}
							value={campaign.getDefaultBid()?.toString()}
							setValue={value => {
								campaign.setDefaultBid(parseFloat(value));
								formStore.clearError(CampaignDomIds.OptimizationBid);
							}}
							errorMsg={formStore.getErrors().get(CampaignDomIds.OptimizationBid)?.getMsg()}
						/>
					) : (
						<Input
							id={CampaignDomIds.OptimizationBid}
							after={'%'}
							type={'positiveNumber'}
							className={styles.inputWrapper}
							value={campaign?.getDefaultBid()?.toString()}
							onValue={value => {
								campaign.setDefaultBid(parseInt(value));
								formStore.clearError(CampaignDomIds.OptimizationBid);
							}}
							required={true}
							requiredAsterisk={false}
							error={formStore.getErrors().get(CampaignDomIds.OptimizationBid)?.getMsg()}
							debugProps={{ dataAttrs: [new DataAttribute('id', 'default_bid')] }}
						/>
					)}
				</Flex>
			</InputItem>

			<SpaceBetweenInputControls />

			<InputItem id={CampaignDomIds.ConversionType} maxWidth={'620px'}>
				<InputInfo className={styles.inputInfo}>
					<InputTitle>Conversion Type</InputTitle>
					<InputDesc>Select the type of conversion you want to track or you can add and create a new pixel</InputDesc>
				</InputInfo>

				<Dropdown
					disabled={!campaign?.getAdvertiser()}
					tooltip={!campaign?.getAdvertiser()}
					defaultOption={!campaign?.getAdvertiser() ? 'Please select an advertiser' : selectedConversion?.[0]?.name}
					required={true}
					requiredAsterisk={false}
					label={'Select conversion'}
					autocomplete={conversionNames && conversionNames.length > 0}
					autocompletePlaceholder={'Search Conversion'}
					options={conversionNames}
					onSelect={(conversionName: Set<option> | undefined) => {
						onConversionSelect(Array.from(conversionName?.values() || []));
						formStore.clearError(CampaignDomIds.ConversionType);
					}}
					error={formStore.getErrors().get(CampaignDomIds.ConversionType)?.getMsg()}
					actions={renderConversionsActions()}
					debugProps={{ dataAttrs: [new DataAttribute('id', 'conversion_type')] }}
				/>
			</InputItem>

			<SpaceBetweenInputControls />
		</div>
	);
});
