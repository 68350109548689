import { Fragment } from 'react';
import styles from './skeleton.module.scss';

interface ISkeleton {
	is?: boolean;
	rectanglesAmount?: number;
	fullWidth?: boolean;
}
export const Skeleton = (props: ISkeleton) => {
	const { is = false, rectanglesAmount = 1, fullWidth } = props;

	if (!is) {
		return null;
	}

	const rectangles = Array.from({ length: rectanglesAmount });

	return (
		<Fragment>
			{rectangles.map((rectangle, recatngleIndex) => {
				return <div key={recatngleIndex} className={`${styles.rectangle} ${fullWidth ? styles.full : ''}`}></div>;
			})}
		</Fragment>
	);
};
