import { getConfig } from '@monorepo/tools/src/lib/get-config';
import React, { createContext, useContext } from 'react';
import { SidebarStore } from '../stores/sidebar.store';
import { AuthStore } from '../stores/auth.store';
import { FormStore } from '../stores/form.store';
import { ToastsStore } from '../stores/toasts.store';
import { ModalsStore } from '../stores/modals.store';
import { TableStore } from '../stores/table.store';
import { DateStore } from '../stores/date.store';
import { NotificationsStore } from '../stores/notifications.store';
const config = getConfig();

// TODO - Change this functionality to init store
let StoreContext: unknown;
if (config.env.projectName === 'adminx') {
	// TODO - change this require to import
	// eslint-disable-next-line
	const { AdminxStore, IAdminxStore } = require('../../../adminx/src/modules/stores');
	StoreContext = createContext<typeof IAdminxStore>(new AdminxStore()) as React.Context<typeof IAdminxStore>;
} else {
	// TODO - change this require to import
	// eslint-disable-next-line
	const { IMFPStore, MFPStore } = require('../../../manifesto-platform/src/modules/stores');
	StoreContext = createContext<typeof IMFPStore>(new MFPStore()) as React.Context<typeof IMFPStore>;
}

export function useStores<T>() {
	// type IStoreContext = T extends IAdminxStore ? IAdminxStore : IMFPStore;
	// eslint-disable-next-line
	// @ts-ignore
	return useContext<T>(StoreContext);
}

export interface IControlledStore {
	sidebarStore: SidebarStore;
	authStore: AuthStore;
	formStore: FormStore;
	toastsStore: ToastsStore;
	modalsStore: ModalsStore;
	tableStore: TableStore;
	dateStore: DateStore;
	notificationsStore: NotificationsStore;
}
