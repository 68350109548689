import { getConfig } from '@monorepo/tools/src/lib/get-config';
import { CrudEndpoint } from './crud.endpoint';

export class UsersEndpoint extends CrudEndpoint {
	sherrifRoute: string;

	constructor() {
		super('users');
		this.sherrifRoute = getConfig().env.sheriffRoot;
	}

	create() {
		return `${this.sherrifRoute}/bouncer/${this.component}`;
	}

	signUp() {
		return `${this.sherrifRoute}/bouncer/${this.component}/signup`;
	}

	signIn() {
		return `${this.sherrifRoute}/bouncer/${this.component}/signin`;
	}

	verify(token: string) {
		return `${this.sherrifRoute}/bouncer/${this.component}/verify/${token}`;
	}

	sendVerifyEmail() {
		return `${this.sherrifRoute}/bouncer/${this.component}/send-verify-email`;
	}
}
