import { CrudEndpoint } from './crud.endpoint';

export class AccountsEndpoint extends CrudEndpoint {
	constructor() {
		super('accounts');
	}

	enableAccounts() {
		return `${this.baseUrl}/status/ACTIVE`;
	}
	pauseAccounts() {
		return `${this.baseUrl}/status/PAUSED`;
	}
}
