export const currencyFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 });
export const currencyFormatterWithDecimals = new Intl.NumberFormat('en-US', {
	style: 'currency',
	currency: 'USD',
	maximumFractionDigits: 2,
});
export const numberFormatter = new Intl.NumberFormat('en', { notation: 'compact' });

export const numberFormatterWithDecimals = new Intl.NumberFormat('en', { maximumFractionDigits: 2 });

export const numberFormatterWithDecimalsCompacted = new Intl.NumberFormat('en', { notation: 'compact', maximumFractionDigits: 2 });

export const currencyFormat = (value?: number, currencyWithDecimal?: boolean): string | null => {
	if (value === undefined || value === null) {
		return null;
	}
	return currencyWithDecimal ? currencyFormatterWithDecimals.format(value) : currencyFormatter.format(value);
};

export const percents = (value?: number): string | null => {
	if (!value) {
		return null;
	}
	return `${parseInt((value * 100).toLocaleString()) || 0}%`;
};

export const number = (value?: number, decimal?: boolean, compact: boolean = true): string | null => {
	if (decimal) {
		if (compact) {
			return value !== null && value !== undefined ? numberFormatterWithDecimalsCompacted.format(value) : null;
		} else {
			return value !== null && value !== undefined ? numberFormatterWithDecimals.format(value) : null;
		}
	}

	return value !== null && value !== undefined ? numberFormatter.format(value) : null;
};

export const randomNumber = (min: number, max: number, places = 2): string => {
	let value = Math.random() * (max - min) + min;
	return Number.parseFloat(`${value}`).toFixed(places);
};

export const isStringInfinityOrNaN = (val: string | number) => {
	if (val === 'NaN' || val === 'Infinity') {
		return true;
	}

	return false;
};
