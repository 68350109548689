import { IPerformanceOptionsV2 } from '../hooks/apis/use-performance';
import { ask } from '@monorepo/tools/src/lib/tools/ask/ask';
import { PerformanceResponse } from '../models/performance.model';
import { hash } from '@monorepo/tools/src/lib/tools/hash';
import { getConfig } from '@monorepo/tools/src/lib/get-config';

export const PerformanceApi = {
	performance: (params: IPerformanceOptionsV2): Promise<PerformanceResponse> => {
		const paramsHash = hash.encrypt(JSON.stringify(params));
		let queryParams = '';
		if (paramsHash) {
			queryParams = `?ck=${paramsHash}`;
		}
		return ask.post<IPerformanceOptionsV2>(`${getConfig().env.sheriffRootApi}/reporting/reportsApi/api/rp/repr${queryParams}`, params);
	},
};
