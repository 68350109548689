import { IconButton } from '@monorepo/base/src/components/buttons/buttons';
import { ToggleMenu } from '@monorepo/base/src/components/toggle-menu/toggle-menu';
import { getTheme } from '@monorepo/tools/src/lib/get-config';
import styles from './entity-actions-menu.module.scss';
import { ReactNode } from 'react';
import { Text } from '@monorepo/base/src/components/text/text';

interface IEntityActionsMenu {
	children: ReactNode;
	title?: string;
}

export const EntityActionsMenu = (props: IEntityActionsMenu) => {
	const { children, title } = props;

	return (
		<ToggleMenu
			alignToRight={true}
			toggler={
				<IconButton className={styles.toggler} size={'28px'}>
					dots-vertical
				</IconButton>
			}>
			<div className={styles.actionsMenu}>
				{title ? (
					<Text color={getTheme().titleColor} className={styles.actionsTitle}>
						{title}
					</Text>
				) : null}
				{children}
			</div>
		</ToggleMenu>
	);
};
