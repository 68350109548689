import { List, ListItem } from '@monorepo/base/src/components/list/list';
import styles from './network-error-modal.module.scss';

export const NetworkErrorModal = () => {
	return (
		<div className={styles.wrapper}>
			<div className={styles.title}>Network Error</div>
			<div className={styles.subtitle}>Please check one of the following:</div>
			<List className={styles.list}>
				<ListItem>VPN Connected</ListItem>
				<ListItem>Internet Connection</ListItem>
			</List>
		</div>
	);
};
