import { CrudEndpoint } from './crud.endpoint';

export class CampaignsEndpoint extends CrudEndpoint {
	constructor() {
		super('campaigns');
	}

	saveDraft() {
		return `${this.rootApi}/${this.component}/draft`;
	}

	enableCampaigns() {
		return `${this.baseUrl}/status/ACTIVE`;
	}
	pauseCampaigns() {
		return `${this.baseUrl}/status/PAUSED`;
	}
}
