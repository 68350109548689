import { id } from '@monorepo/tools/src/lib/types/primitives';
import { CrudEndpoint } from './crud.endpoint';

export class CampaignsEndpoint extends CrudEndpoint {
	constructor() {
		super('campaigns');
	}

	targetListings(id: id) {
		return `${this.baseUrl}/${id}/target-listings`;
	}

	inputOptions() {
		return `${this.rootApi}/input-options`;
	}

	addAndEditCreatives(id: id) {
		return `${this.baseUrl}/${id}/creatives/bulk`;
	}

	enableCampaigns() {
		return `${this.baseUrl}/status/ACTIVE`;
	}
	pauseCampaigns() {
		return `${this.baseUrl}/status/PAUSED`;
	}
	attachDeals(id: id) {
		return `${this.baseUrl}/${id}/deals/set-deals-related-to-campaign`;
	}
	targetList() {
		return `${this.baseUrl}/shared-targets`;
	}

	sharedTargetAmount(id: id) {
		return `${this.baseUrl}/shared-targets/${id}`;
	}
}
