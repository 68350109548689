import { makeAutoObservable } from 'mobx';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import { AccountModel } from './account.model';
import { IPerformanceItem } from './performance.model';
import { StatusType } from '../enums/status';
import { CampaignModel } from './campaign.model';
import { ConversionModel, IConversion } from './conversion.model';

export interface IAdvertiser extends IPerformanceItem {
	id?: id;
	name?: string;
	status?: StatusType;
	account?: AccountModel;
	account_id?: id;
	account_name?: string;
	category?: string;
	mainDomain?: string;
	campaigns?: CampaignModel[];
	conversionActions?: IConversion[];
}

export interface IAdvertiserCreateForm {
	id?: id;
	name?: string;
	categoryId?: id;
	mainDomain?: string;
	accountId?: id;
}

export interface IAdvertiserEditForm {
	id?: id;
	name?: string;
	categoryId?: id;
	mainDomain?: string;
	accountId?: id;
}

export class AdvertiserModel implements IAdvertiser {
	id?: id;
	name?: string;
	status?: StatusType;
	account?: AccountModel;
	advertiser?: AdvertiserModel;
	budget?: number;
	daily_cap?: number;
	clicks?: number;
	conversions?: number;
	cost?: number;
	revenue?: number;
	impressions?: number;
	roas?: number;
	cpc?: number;
	cpa?: number;
	cpm?: number;
	cvr?: number;
	category?: string;
	mainDomain?: string;
	campaigns?: CampaignModel[];
	conversionActions?: ConversionModel[];

	constructor(advertiser?: IAdvertiser) {
		this.id = advertiser?.id;
		this.name = advertiser?.name;
		this.status = advertiser?.status;
		this.account = new AccountModel({
			id: advertiser?.account?.id || advertiser?.account_id,
			name: advertiser?.account?.name || advertiser?.account_name,
		});
		this.clicks = advertiser?.clicks;
		this.conversions = advertiser?.conversions;
		this.cost = advertiser?.cost;
		this.revenue = advertiser?.revenue;
		this.impressions = advertiser?.impressions;
		this.roas = advertiser?.roas;
		this.cpc = advertiser?.cpc;
		this.cpa = advertiser?.cpa;
		this.cpm = advertiser?.cpm;
		this.cvr = advertiser?.cvr;
		this.category = advertiser?.category;
		this.mainDomain = advertiser?.mainDomain;
		this.campaigns = advertiser?.campaigns ? advertiser?.campaigns.map(campaign => new CampaignModel(campaign)) : [];
		this.conversionActions = advertiser?.conversionActions?.map(c => new ConversionModel(c));

		makeAutoObservable(this);
	}

	getId(): id | undefined {
		return this.id;
	}

	getName(): string | undefined {
		return this.name;
	}

	setName(name: string) {
		this.name = name;
	}

	getCategory(): string | undefined {
		return this.category;
	}

	setCategory(category: string) {
		this.category = category;
	}

	getMainDomain(): string | undefined {
		return this.mainDomain;
	}

	setMainDomain(mainDomain: string) {
		this.mainDomain = mainDomain;
	}

	getAccount(): AccountModel | undefined {
		return this.account;
	}

	setAccount(account: AccountModel): void {
		this.account = account;
	}

	getCampaigns(): CampaignModel[] | undefined {
		return this.campaigns;
	}

	setCampaigns(campaigns: CampaignModel[]): void {
		this.campaigns = campaigns;
	}

	getConversions(): ConversionModel[] | undefined {
		return this.conversionActions;
	}
	setConversions(conversions: ConversionModel[] | undefined) {
		this.conversionActions = conversions;
	}
}
