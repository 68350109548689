import { PrimaryLink } from '@monorepo/base/src/components/buttons/buttons';
import { Page, Pagebar, PagebarActions, PagebarTitle } from '@monorepo/base/src/components/page/page';
import { ControlledDater } from '@monorepo/controlled/src/components/controlled-dater/controlled-dater';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { observer } from 'mobx-react';
import { Entities, IPerformanceOptionsSortBy, PerformancesColumns } from '../../hooks/apis/use-reports-list';
import { useMetrics } from '../../hooks/tools/use-metrics';
import { useMetricsGet } from '../../hooks/tools/use-metrics-get';
import { availablePerformanceToMetrics } from '../../models/performance.model';
import routes from '../../routes/index.routes';
import { IMFPStore } from '../../stores';
import { Insights } from '../insights/insights';
import { PerformanceOptionsSortByStrings } from '../../hooks/apis/use-reports-list';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { ManifestoChart } from '../chart/manifesto-chart';
import styles from './../../../styles/pages.module.scss';
import { PagebarContent, PagebarSubtitle } from '@monorepo/base/src/components/page/pagebar/pagebar';

export const Overview = observer(() => {
	const { campaignStore } = useStores<IMFPStore>();
	const metricsStore = campaignStore.getMetricsStore();
	const excludedSortBys = ['Date', 'CPC', 'Revenue', 'CPM', 'CVR', 'Impressions'];

	useMetricsGet(metricsStore, {
		entity: Entities.Chart,
		groupBys: [PerformancesColumns.Date],
	});

	const { metrics, xLabels } = useMetrics(metricsStore.getData());

	return (
		<Page unstyled={true} className={styles.article}>
			<Pagebar classes={{ pagebar: styles.padding }}>
				<PagebarContent>
					<PagebarTitle>Overview</PagebarTitle>
					<PagebarSubtitle>Track and review your overall activity</PagebarSubtitle>
				</PagebarContent>
				<PagebarActions>
					<ControlledDater />
					<PrimaryLink
						icon={'plus'}
						debugProps={{ dataAttrs: [new DataAttribute('id', 'create_campaign')] }}
						to={routes.campaigns.create()}
						width={'148px'}>
						New Campaign
					</PrimaryLink>
				</PagebarActions>
			</Pagebar>
			<ManifestoChart
				isLoading={metricsStore.getIsLoading()}
				metrics={metrics}
				labels={xLabels}
				legendOptions={Object.keys(availablePerformanceToMetrics)}
			/>
			<Insights
				options={
					Object.keys(IPerformanceOptionsSortBy).filter(
						key => !excludedSortBys.includes(key as IPerformanceOptionsSortBy)
					) as PerformanceOptionsSortByStrings[]
				}
			/>
		</Page>
	);
});
