import { action, makeObservable, observable } from 'mobx';
import { AccountsApi } from '../../apis/accounts.api';
import { AccountModel } from '../../models/account.model';
import { BaseListStore } from '@monorepo/controlled/src/stores/base-list.store';
import { AccountCrudStore } from './account-crud.store';
import { HttpStore } from '@monorepo/controlled/src/stores/http.store';
import { IPerformanceOptions, PerformancesColumns } from '../../hooks/apis/use-reports-list';
import { PerformancePayload, PerformanceModel } from '../../models/performance.model';
import { FilterModel } from '@monorepo/controlled/src/models/filter.model';
import { capitalCase } from 'change-case';
import { FilterStore } from '@monorepo/controlled/src/stores/filter.store';
import { StatusFilter, filterPrototypesByProp } from '../../components/filters';
import { ISegment } from '@monorepo/base/src/components/segments/segments';
import { StatusEnum } from '../../enums/status';

export enum AccountFiltersStoreKeys {
	Accounts = 'accounts',
}

export class AccountStore {
	crud = new AccountCrudStore();
	list = new BaseListStore<AccountModel>({
		listFunc: AccountsApi.list,
		model: AccountModel,
	});
	reports = new HttpStore<IPerformanceOptions, PerformancePayload>({
		httpFunc: AccountsApi.reports,
		model: PerformancePayload,
	});
	metrics = new HttpStore<IPerformanceOptions, PerformancePayload>({
		httpFunc: AccountsApi.metrics,
		model: PerformancePayload,
	});
	filters: Map<string, FilterStore>;
	segments: Set<ISegment<AccountModel, PerformanceModel, PerformancesColumns>>;

	constructor() {
		makeObservable(this, {
			crud: observable,
			list: observable,
			segments: observable,
			setSegments: action.bound, // https://mobx.js.org/actions.html#actionbound prevents cannot set property of undefined
		});

		this.segments = new Set<ISegment<AccountModel, PerformanceModel, PerformancesColumns>>([]);
		this.filters = this.createDefaultFilters();
	}

	createDefaultFilters() {
		// set filters for all campaigns pages
		const defaultFilters = new Map([
			[
				1,
				new FilterModel({
					index: 1,
					label: `Status is ${capitalCase(StatusEnum.ACTIVE)}`,
					value: StatusEnum.ACTIVE,
					prototype: StatusFilter,
				}),
			],
		]);

		const AccountsPageFilterStore = new FilterStore({
			key: AccountFiltersStoreKeys.Accounts,
			currentFilters: defaultFilters,
			filterPrototypesByProp,
		});

		return new Map([[AccountFiltersStoreKeys.Accounts, AccountsPageFilterStore]]);
		//
	}

	getFiltersStore(key: AccountFiltersStoreKeys) {
		return this.filters.get(key);
	}

	getListStore() {
		return this.list;
	}

	getCrud() {
		return this.crud;
	}

	getReportsStore() {
		return this.reports;
	}

	getMetricsStore() {
		return this.metrics;
	}

	setSegments(segments: Set<ISegment<AccountModel, PerformanceModel, PerformancesColumns>>): void {
		this.segments = segments;
	}

	getSegments(): Set<ISegment<AccountModel, PerformanceModel, PerformancesColumns>> {
		return this.segments;
	}
}
