import { makeAutoObservable } from 'mobx';

export interface IConversion {
	id: number;
	name: string;
}

export class ConversionModel implements IConversion {
	id: number;
	name: string;

	constructor(conversion: IConversion) {
		this.id = conversion?.id;
		this.name = conversion.name;

		makeAutoObservable(this);
	}

	getId(): number | undefined {
		return this.id;
	}

	getName(): string | undefined {
		return this.name;
	}
}
