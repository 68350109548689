import { OAuthProviderEnum } from '../enums/oauth-providers';

export class AuthRoutes {
	login() {
		return '/login';
	}
	signUp() {
		return '/signup';
	}
	verify() {
		return '/verify';
	}
	verified() {
		return '/verified';
	}
	redirect(provider: OAuthProviderEnum) {
		return `redirect/${provider}`;
	}
}
