import { Fragment } from 'react';
import { PrimaryLink, SecondaryText } from '@monorepo/base/src/components/buttons/buttons';
import { SelectedLinerButton } from '@monorepo/controlled/src/components/table/action-liner/liner-buttons';
import { QuickActionButton } from '@monorepo/controlled/src/components/table/quick-actions-menu/quick-actions-menu';
import { BetweenPagesStateProps } from '@monorepo/tools/src/lib/interfaces/global';
import { AccountModel } from '../../models/account.model';
import routes from '../../routes/index.routes';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { IEntityActions, ITableActions, IUseEntityActions, useDefaultActions } from './default.actions';
import { getTheme } from '@monorepo/tools/src/lib/get-config';
import { IMFPStore } from '../../stores';
import { AccountFiltersStoreKeys } from '../../stores/accounts/account.store';
import { StatusFilter, AccountNameFilter } from '../../components/filters';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';
import { ITableFiltersActions, TableFilters } from '@monorepo/controlled/src/components/table/table-filters/table-filters';
import { Segments } from '@monorepo/base/src/components/segments/segments';
import { PerformanceModel } from '../../models/performance.model';
import { PerformancesColumns } from '../apis/use-reports-list';
import { useSegments } from '../tools/use-segments';
import { ActionModal, ModalTypes } from '@monorepo/controlled/src/components/modal/modals/action-modal/action-modal';
import { useNavigate } from 'react-router-dom';
import { EnablePauseAccountsModal } from '../../components/modals/enable-pause-accounts';
import { IconFonts } from '@monorepo/base/src/components/icon/icon';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { ISegmentActions } from '@monorepo/controlled/src/components/table/table';

const theme = getTheme();

export function useAccountActions(props: IUseEntityActions<AccountModel> = {}) {
	const { createRoute, onEdit, availableFilters, currentSegments } = props;
	const { accountStore, modalsStore, toastsStore } = useStores<IMFPStore>();
	const accountsCrudStore = accountStore.getCrud();
	const { currentRouteWithoutSpecialChars } = useRoute();

	const filterStore = accountStore.getFiltersStore(currentRouteWithoutSpecialChars as AccountFiltersStoreKeys);
	const currentFilters = filterStore?.getCurrentFilters();
	const DefaultAvailableFilters = [AccountNameFilter, StatusFilter];

	const onEditRoute = (accountId: id) => (onEdit ? onEdit(accountId) : routes.accounts.edit(accountId));
	const onDelete = (id: id) => accountStore.getCrud().delete(id);
	const navigate = useNavigate();

	const funcActions = {
		onEditRoute,
		onDelete,
	};

	const SelectedActions = (props: ITableActions<AccountModel>) => {
		const { rows } = props;
		const entities = rows.map(row => new AccountModel(row.original));
		const { EditAction, DeleteAction } = useDefaultActions({ entities, ...funcActions });
		const textColor = theme.actionLinerIconTextColor;
		const iconColor = theme.actionLinerIconColor;
		const entitiesIds: id[] = [];
		entities.forEach(e => {
			const id = e.getId();
			if (id) {
				entitiesIds.push(id);
			}
		});

		return (
			<Fragment>
				<SelectedLinerButton>
					<SecondaryText
						icon={'play'}
						onClick={() => enableAccounts(entitiesIds)}
						textColor={textColor}
						iconColor={iconColor}
						font={IconFonts.Outlined}>
						Enable
					</SecondaryText>
				</SelectedLinerButton>
				<SelectedLinerButton>
					<SecondaryText
						icon={'pause-square'}
						onClick={() => pauseAccounts(entitiesIds)}
						textColor={textColor}
						iconColor={iconColor}
						font={IconFonts.Outlined}>
						Pause
					</SecondaryText>
				</SelectedLinerButton>
				<SelectedLinerButton>
					<EditAction state={{ isBlockRequest: true } as BetweenPagesStateProps} />
				</SelectedLinerButton>
				<SelectedLinerButton>
					<DeleteAction />
				</SelectedLinerButton>
			</Fragment>
		);
	};

	const EntityActions = (props: IEntityActions<AccountModel>) => {
		const { entity } = props;
		const { EditAction, DeleteAction, buttonClassName } = useDefaultActions({ entities: [entity], ...funcActions });
		const entityId = entity.getId();
		if (!entityId) {
			// log error
			return null;
		}

		return (
			<Fragment>
				<QuickActionButton>
					<SecondaryText
						icon={'play'}
						onClick={() => enableAccounts([entityId])}
						textColor={theme.titleColor}
						className={buttonClassName}
						iconColor={theme.titleColor}
						font={IconFonts.Outlined}>
						Enable
					</SecondaryText>
				</QuickActionButton>
				<QuickActionButton>
					<SecondaryText
						icon={'pause-square'}
						onClick={() => pauseAccounts([entityId])}
						textColor={theme.titleColor}
						className={buttonClassName}
						iconColor={theme.titleColor}
						font={IconFonts.Outlined}>
						Pause
					</SecondaryText>
				</QuickActionButton>
				<QuickActionButton>
					<EditAction
						iconColor={theme.titleColor}
						className={buttonClassName}
						textColor={theme.titleColor}
						state={{ isBlockRequest: true } as BetweenPagesStateProps}
					/>
				</QuickActionButton>
				<QuickActionButton>
					<DeleteAction iconColor={theme.titleColor} className={buttonClassName} textColor={theme.titleColor} />
				</QuickActionButton>
			</Fragment>
		);
	};

	const IndexActions = () => {
		return (
			<PrimaryLink
				icon={'plus'}
				debugProps={{ dataAttrs: [new DataAttribute('id', 'create_account')] }}
				to={`${createRoute || routes.accounts.create()}${location.search}`}
				width={'100px'}
				state={{ isBlockRequest: true } as BetweenPagesStateProps}>
				Create
			</PrimaryLink>
		);
	};

	const FiltersActions = (props: ITableFiltersActions) => {
		if (!currentFilters) {
			return null;
		}

		return (
			<TableFilters
				{...props}
				availableFilters={availableFilters || DefaultAvailableFilters}
				currentFilters={currentFilters}
				clearAll={() => filterStore?.clearAll()}
				clearFilter={index => filterStore?.clearFilter(index)}
				addFilter={filter => filterStore?.addFilter(filter)}
				editFilter={(index, filter) => filterStore?.editFilter(index, filter)}
			/>
		);
	};

	const SegmentActions = (props: ISegmentActions) => {
		const { segments, onSegment } = useSegments<AccountModel>({ setSegments: accountStore.setSegments });

		return (
			<Segments<AccountModel, PerformanceModel, PerformancesColumns>
				isComingSoon={segments}
				currentSegments={currentSegments}
				availableSegments={segments}
				onClick={segment => onSegment(segment)}
				{...props}
			/>
		);
	};

	const enableAccounts = (accountIds: id[]) => {
		modalsStore.addModal({
			children: (
				<ActionModal
					type={ModalTypes.Info}
					primaryBtnTitle={'Enable'}
					onAction={() => {
						accountsCrudStore.enableAccounts(accountIds).then(() => {
							toastsStore.addToastOnNav({ msg: `Accounts ${accountIds} Enabled`, type: 'success' });
							navigate(0);
						});
					}}>
					<EnablePauseAccountsModal action={'Enable'} accountIds={accountIds} />
				</ActionModal>
			),
		});
	};

	const pauseAccounts = (accountIds: id[]) => {
		modalsStore.addModal({
			children: (
				<ActionModal
					type={ModalTypes.Info}
					primaryBtnTitle={'Paused'}
					onAction={() => {
						accountsCrudStore.pauseAccounts(accountIds).then(() => {
							toastsStore.addToastOnNav({ msg: `Accounts ${accountIds} Paused`, type: 'success' });
							navigate(0);
						});
					}}>
					<EnablePauseAccountsModal action={'Paused'} accountIds={accountIds} />
				</ActionModal>
			),
		});
	};

	return {
		SelectedActions,
		EntityActions,
		IndexActions,
		FiltersActions,
		SegmentActions,
	};
}
