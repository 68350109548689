import styles from './logo.module.scss';
import { Icon } from '@monorepo/base/src/components/icon/icon';
import { Link } from 'react-router-dom';
import routes from '../../../../adminx/src/modules/routes/index.routes'; // TODO - need to be dynamic

interface logoProps {
	text?: string;
	children: JSX.Element;
}

export function Logo(props: logoProps) {
	const { text, children } = props;

	return (
		<Link to={routes.general.home()}>
			<div className={styles.logoWrapper}>
				<Icon>{children}</Icon>
				<span>{text}</span>
			</div>
		</Link>
	);
}
