import { Icon } from '@monorepo/base/src/components/icon/icon';
import styles from './avatar.module.scss';
import { url } from '@monorepo/tools/src/lib/types/url';
import { getTheme } from '@monorepo/tools/src/lib/get-config';

const theme = getTheme();

interface IAvatar {
	url?: url;
	alt?: string;
}

export const Avatar = (props: IAvatar) => {
	const { url, alt } = props;
	if (!url) {
		return (
			<Icon isMFP={true} color={theme.primaryColor} size={'40px'}>
				user-circle
			</Icon>
		);
	}
	return <img alt={alt} className={styles.avatar} src={url} />;
};
