import { Fragment } from 'react';
import styles from './table-skeleton.module.scss';

interface ITableSkeleton {
	isLoading: boolean;
	columnsAmount?: number;
}

export const SkeletonCell = () => {
	return <div className={styles.rectangle}></div>;
};

export const TableSkeleton = (props: ITableSkeleton) => {
	const { isLoading, columnsAmount = 5 } = props;

	if (!isLoading) {
		return null;
	}

	const rows = Array.from([1, 2, 3, 4, 5]);
	const columns = Array.from({ length: columnsAmount });
	return (
		<Fragment>
			{rows.map((row, rowIndex) => {
				return (
					<tr key={rowIndex}>
						{columns.map((column, columnIndex) => {
							return (
								<td key={columnIndex}>
									<SkeletonCell />
								</td>
							);
						})}
					</tr>
				);
			})}
		</Fragment>
	);
};
