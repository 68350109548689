import { FormStore } from '@monorepo/controlled/src/stores/form.store';
import { FormError } from '@monorepo/tools/src/lib/models/form-error.model';
import { makeAutoObservable } from 'mobx';
import { OptimizationModes } from '../../enums/optimization-modes';
import { CampaignDomIds } from '../../hooks/toc/campaign.toc';

export interface ISmartStrategyModel {
	dailyBudget?: number;
	optimizationMode?: OptimizationModes;
	defaultBid?: number;
	conversionType?: string;
}

export interface ISmartStrategyForm {
	dailyBudget?: number;
	optimizationMode?: OptimizationModes;
	defaultBid?: number;
	conversionType?: string;
}

export class SmartStrategyModel implements ISmartStrategyModel {
	dailyBudget?: number;
	optimizationMode?: OptimizationModes;
	defaultBid?: number;
	conversionType?: string;

	constructor(strategy?: ISmartStrategyModel) {
		this.dailyBudget = strategy?.dailyBudget;
		this.optimizationMode = strategy?.optimizationMode || OptimizationModes.TargetCPA;
		this.defaultBid = strategy?.defaultBid;
		this.conversionType = strategy?.conversionType;

		makeAutoObservable(this);
	}

	public isValid(formStore: FormStore) {
		if (!this.optimizationMode) {
			formStore.addError(new FormError(CampaignDomIds.OptimizationMode, 'Please provide optimization mode'));
		}
		if (!this.defaultBid) {
			formStore.addError(new FormError(CampaignDomIds.OptimizationBid, 'Please provide optimization bid'));
		}
	}

	public getFormData(): ISmartStrategyForm {
		return {
			dailyBudget: this.dailyBudget,
			optimizationMode: this.optimizationMode,
			defaultBid: this.defaultBid,
		};
	}

	public getDailyBudget(): number | undefined {
		return this.dailyBudget;
	}

	public setDailyBudget(dailyBudget: number): void {
		this.dailyBudget = dailyBudget;
	}

	public getOptimizationMode() {
		return this.optimizationMode;
	}

	public setOptimizationMode(optimizationMode?: OptimizationModes) {
		this.optimizationMode = optimizationMode;
	}

	public getDefaultBid() {
		return this.defaultBid;
	}

	public setDefaultBid(defaultBid?: number) {
		this.defaultBid = defaultBid;
	}

	public getConversionType() {
		return this.conversionType;
	}

	public setConversionType(conversionType?: string) {
		this.conversionType = conversionType;
	}
}
