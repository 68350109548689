import { observer } from 'mobx-react';
import { stringFuncs, stringAction } from '@monorepo/tools/src/lib/utils/string';
import { useRoute } from '../../../hooks/tools/use-route';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { CreativeModel } from '../../../models/creative.model';
import { FilterModel, FilterOptionsEnums, IFilterComponentProps, IFilterProto } from '@monorepo/controlled/src/models/filter.model';
import { CreativeFiltersStoreKeys } from '../../../stores/creatives/creative.store';
import { FilterStringMenu } from '@monorepo/base/src/components/filters/filter-string-menu/filter-string-menu';
import { IAdminxStore } from '../../../stores';

// TODO - change to add filter and edit filter from props
const CreativeGroupDesignFilter = observer((props: IFilterComponentProps) => {
	const { filter, onCancel, onApply } = props;
	const { creativeStore } = useStores<IAdminxStore>();
	const { currentRouteWithoutSpecialChars } = useRoute();

	const onAddCreativeGroupDesignFilter = (_action: stringAction, _value: string) => {
		const filterLabel = `Creative Group Design ${_action} ${_value}`;
		const creativeGroupDesignFilter = new FilterModel({
			label: filterLabel,
			action: _action,
			value: _value,
			prototype: CreativeGroupDesignFilterPrototype,
		});

		const filterStore = creativeStore.getFiltersStore(currentRouteWithoutSpecialChars as CreativeFiltersStoreKeys);
		if (filter?.index && filterStore?.getCurrentFilters().has(filter?.index)) {
			filterStore.editFilter(filter.index, creativeGroupDesignFilter);
		} else {
			filterStore?.addFilter(creativeGroupDesignFilter);
		}
		onApply();
	};

	return (
		<FilterStringMenu
			title={'Creative Group Design'}
			defaultValue={typeof filter?.value === 'string' ? filter?.value : ''}
			defaultAction={filter?.action}
			onApply={(_action: stringAction, _value: string) => onAddCreativeGroupDesignFilter(_action, _value)}
			onCancel={onCancel}
		/>
	);
});

export const CreativeGroupDesignFilterPrototype: IFilterProto = {
	MenuComponent: 'Creative Group Design',
	FilterComponent: CreativeGroupDesignFilter,
	prop: FilterOptionsEnums.BrandName,
	multi: true,
	deletable: true,
	filterFunc<T extends CreativeModel>(models: Array<T>, filter: FilterModel): Array<T> {
		const { value, action } = filter;
		return models.filter(model => {
			if (!action) {
				return true;
			}
			const actionFunc = stringFuncs[action];
			if (!actionFunc) {
				return true;
			}
			return actionFunc(model?.creative_group?.design || '', typeof value === 'string' ? value : '');
		});
	},
};
