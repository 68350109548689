import { ReactNode, useEffect, useState } from 'react';
import { useHeadsObserver } from '@monorepo/tools/src/lib/hooks/tools/use-heads-observer';
import { List, ListItem } from '@monorepo/base/src/components/list/list';
import styles from './table-of-contents.module.scss';

export interface IInjectHeadItem {
	onClick: (arg0: React.MouseEvent<HTMLElement>) => void;
	isActive: boolean;
	clicked: string;
}

export interface IHead {
	id: string;
	element: string | ((args: IInjectHeadItem) => ReactNode);
	scroll?: boolean; // if scroll is false, the instersection observer will ignore this element
}

interface ITableOfContents {
	heads: IHead[];
	title?: string;
	divider?: boolean;
	className?: { [key: string]: string };
	offset?: number;
	observerOptions?: { threshold?: number; rootMargin?: string; intersectionRatio?: number };
}

export const TableOfContents = (props: ITableOfContents) => {
	const { heads, title, divider, className, offset = 0, observerOptions = {} } = props;
	const [contentClicked, setContentClicked] = useState<string>('');
	const { activeId } = useHeadsObserver({
		heads: heads.map((head, i) => ({ id: head.id, index: i, scroll: head.scroll })),
		contentClicked,
		options: observerOptions,
	});

	useEffect(() => {
		document.addEventListener('wheel', handleWheelScroll, { passive: false });

		return () => document.removeEventListener('wheel', handleWheelScroll);
	}, [contentClicked]);

	const handleWheelScroll = () => {
		if (contentClicked !== '') {
			setContentClicked('');
		}
	};

	const getTopPosition = (linkElement: HTMLElement) => {
		return linkElement.getBoundingClientRect().top - document.body.getBoundingClientRect().top - (50 + offset);
	};

	const onClick = (e: React.MouseEvent<HTMLElement>, head: IHead) => {
		e.preventDefault();
		e.stopPropagation();
		setContentClicked(head.id);
		if (!head) {
			return;
		}

		const linkElement = document.getElementById(head.id);
		if (!linkElement) {
			return null;
		}

		window.scrollTo({
			behavior: 'smooth',
			top: getTopPosition(linkElement),
		});
	};

	return (
		<div className={`${styles.wrapper} ${divider ? styles.divider : ''} ${className?.wrapper}`}>
			{title ? <div className={styles.title}>{title}</div> : null}
			<List className={`${styles.list} ${className?.list}`}>
				{heads.map(head => {
					return (
						<ListItem className={`${styles.item} ${className?.item}`} key={head.id}>
							<div
								onClick={e => onClick(e, head)}
								// to={`#${head.id}`}
								className={`${styles.link} ${className?.link} ${activeId === head.id ? styles.active : ''}`}>
								{typeof head.element === 'string'
									? head.element
									: head.element({ isActive: activeId === head.id, clicked: contentClicked, onClick: e => onClick(e, head) })}
							</div>
						</ListItem>
					);
				})}
			</List>
		</div>
	);
};
