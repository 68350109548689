// must be the same values as availablePerformanceToMetrics
export enum PerformanceEnumarableLabels {
  Auctions = "Auctions",
  Budget = "Budget",
  DailyCap = "Daily Cap",
  Wins = "Wins",
  Impressions = "Impressions",
  Clicks = "Clicks",
  Conversions = "Conversions",
  Cost = "Cost",
  Revenue = "Revenue",
  WinRate = "Win rate",
  ROAS = "ROAS",
  ROI = "ROI",
  CTR = "CTR",
  CVR = "CVR",
  Gain = "Gain",
  OptimalGain = "Optimal Gain",
  OptimalCost = "Optimal Cost",
  CPA = "CPA",
  AvgBidFloor = "Avg. Bid Floor",
  AvgBidToWin = "Avg. Bid To Win",
  CPC = "CPC",
  CPM = "CPM",
  MaxBidToWin = "Max Bid To Win",
  MinBidToWin = "Min Bid To Win",
  MaxBidFloor = "Max Bid Floor",
  MinBidFloor = "Min Bid Floor",
}

export const PerformanceMetricsSymbols = new Map([
	[PerformanceEnumarableLabels.WinRate, '%'],
	[PerformanceEnumarableLabels.CTR, '%'],
	[PerformanceEnumarableLabels.CVR, '%'],
	[PerformanceEnumarableLabels.ROI, '%'],
	[PerformanceEnumarableLabels.ROAS, '%'],
	[PerformanceEnumarableLabels.Cost, '$'],
	[PerformanceEnumarableLabels.OptimalCost, '$'],
	[PerformanceEnumarableLabels.CPA, '$'],
	[PerformanceEnumarableLabels.AvgBidFloor, '$'],
	[PerformanceEnumarableLabels.AvgBidToWin, '$'],
	[PerformanceEnumarableLabels.CPC, '$'],
	[PerformanceEnumarableLabels.CPM, '$'],
	[PerformanceEnumarableLabels.MaxBidToWin, '$'],
	[PerformanceEnumarableLabels.MinBidToWin, '$'],
	[PerformanceEnumarableLabels.MinBidFloor, '$'],
	[PerformanceEnumarableLabels.MaxBidFloor, '$'],
	[PerformanceEnumarableLabels.Revenue, '$'],
]);

export const DecimalData = ['CPA', 'CPC', 'CPM'];

