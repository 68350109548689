import { ButtonGroup } from '@monorepo/base/src/components/buttons/group/button-group';
import { FormControl, FormTitle, FormDesc } from '@monorepo/base/src/components/form/form-control/form-control';
import { InputItem, InputInfo, InputTitle, InputDesc } from '@monorepo/base/src/components/form/input-item/input-item';
import { SpacerY } from '@monorepo/base/src/components/spacer/spacer';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { observer } from 'mobx-react';
import { Strategies } from '../../../../enums/strategies';
import { CampaignDomIds } from '../../../../hooks/toc/campaign.toc';
import { IMFPStore } from '../../../../stores';
import { ManifestoGButton } from '../../../base-wrappers/g-button/g-button';
import { BudgetInput } from '../campaign-common.form';
import styles from '../campaign-form.module.scss';
import { SpaceBetweenFormControls, SpaceBetweenInputControls } from '../campaign.form';
import { AdvancedStrategy } from './advanced-strategy';
import { SmartStrategy } from './smart-strategy';
import { BasicStrategy } from './basic-strategy';
import { Tag } from '@monorepo/base/src/components/tag/tag';
import { getTheme } from '@monorepo/tools/src/lib/get-config';

export const CampaignStrategy = observer(() => {
	const { campaignStore } = useStores<IMFPStore>();
	const campaignCrud = campaignStore.getCrud();
	const campaign = campaignCrud.getData();
	const formStore = campaignCrud.getFormStore();

	const campaignStrategy = campaign.getStrategyType();

	const CampaignStrategiesOptions = {
		[Strategies.Basic]: <BasicStrategy />,
		[Strategies.Advanced]: <AdvancedStrategy />,
		[Strategies.Smart]: <SmartStrategy />,
	};
	return (
		<div>
			<FormControl>
				<FormTitle id={CampaignDomIds.Strategy}>Budget and Bidding Strategy</FormTitle>
				<FormDesc>Select the budget and bidding strategy that best suits your goals</FormDesc>
			</FormControl>

			<SpaceBetweenFormControls />

			<InputItem flex={false} max={true}>
				<InputInfo className={styles.inputInfo}>
					<InputTitle>Choose your strategy</InputTitle>
					<InputDesc>Select whether you want to use Basic, Advanced, or Smart Bidding</InputDesc>
				</InputInfo>
				<SpacerY />
				<ButtonGroup
					required={true}
					defaultValue={campaign.getStrategyType()}
					error={formStore.getErrors().get(CampaignDomIds.Strategy)?.getMsg()}
					onChange={setValues => {
						const strategyType = setValues.values().next().value as Strategies;
						campaign.setStrategyType(strategyType);
						// campaign.setStrategy(new strategyTypesToModel[strategyType]());
						formStore.clearError(CampaignDomIds.Strategy);
					}}>
					<ManifestoGButton
						value={Strategies.Basic}
						title={'Basic'}
						description={'One bid & budget for all our channels'}
						icon={'settings-01'}
					/>
					<ManifestoGButton
						value={Strategies.Smart}
						title={'Smart'}
						description={'Define your goals and use our enhanced optimized auto-pilot'}
						icon={'magic-wand-01'}
						badge={
							<Tag textColor={getTheme().primary700} backgroundColor={'#F9F5FF'} fontWeight={'500'}>
								Recommended
							</Tag>
						}
					/>
					<ManifestoGButton
						value={Strategies.Advanced}
						title={'Advanced'}
						description={'Custom bid and budget per a selected channel'}
						icon={'sliders-01'}
					/>
				</ButtonGroup>
			</InputItem>

			<SpaceBetweenInputControls />

			<InputItem maxWidth={'620px'}>
				<InputInfo className={styles.inputInfo}>
					<InputTitle>Total Budget</InputTitle>
					<InputDesc>Enter the total amount you have to spend</InputDesc>
				</InputInfo>
				<BudgetInput
					value={campaign.getTotalBudget()?.toString()}
					setValue={value => {
						campaign.setTotalBudget(parseFloat(value));
						formStore.clearError(CampaignDomIds.TotalBudget);
					}}
					error={formStore.getErrors().get(CampaignDomIds.TotalBudget)?.getMsg()}
				/>
			</InputItem>

			<SpaceBetweenInputControls />

			{campaignStrategy ? CampaignStrategiesOptions[campaignStrategy] : null}
		</div>
	);
});
