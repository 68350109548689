import { action, makeObservable, observable } from 'mobx';

export interface IModalAdd {
	children: JSX.Element;
}

export interface IModalItem {
	children: JSX.Element;
	id: number;
}

interface IModals {
	modals: IModalAdd[];
}

export class ModalsStore implements IModals {
	modals: IModalItem[] = [];
	id = 1;
	type = '';

	constructor() {
		makeObservable(this, {
			modals: observable,
			setModals: action,
			removeModal: action,
		});
	}

	getModals(): IModalItem[] {
		return this.modals;
	}

	addModal(modal: IModalAdd) {
		if (!modal) {
			throw new Error('No modal added to addModal function');
		}
		// Render modals in the next render cycle
		setTimeout(() => {
			const modals = [
				...this.modals,
				{
					id: this.id++,
					...modal,
				},
			];
			this.setModals(modals);
		});
	}

	setModals(modals: IModalItem[]) {
		this.modals = modals;
	}

	removeModal(id: number) {
		this.modals = this.modals.filter(modal => modal.id !== id);
	}

	getId(): number {
		return this.id;
	}
}
