import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { Page, Pagebar, PagebarActions, PagebarTitle } from '@monorepo/base/src/components/page/page';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { AdvertiserModel } from '../../../../modules/models/advertiser.model';
import routes from '../../../../modules/routes/index.routes';
import { useAdvertiserActions } from '../../../../modules/hooks/actions/advertiser.actions';
import { ControlledTable } from '@monorepo/controlled/src/components/table-wrapper/table-wrapper';
import { useAdvertiserHeaders } from '../../../../modules/hooks/theaders/advertisers.headers';
import { ControlledDater } from '@monorepo/controlled/src/components/controlled-dater/controlled-dater';
import { IMFPStore } from '../../../../modules/stores';
import {
	Entities,
	IPerformanceOptionsFilterType,
	PerformancesColumns,
	useReportsList,
} from '../../../../modules/hooks/apis/use-reports-list';
import { PrimaryLink } from '@monorepo/base/src/components/buttons/buttons';
import { useMetricsGet } from '../../../../modules/hooks/tools/use-metrics-get';
import { availablePerformanceToMetrics } from '../../../../modules/models/performance.model';
import { useMetrics } from '../../../../modules/hooks/tools/use-metrics';
import { SpacerY } from '@monorepo/base/src/components/spacer/spacer';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { ManifestoChart } from '../../../../modules/components/chart/manifesto-chart';
import styles from './../../../../styles/pages.module.scss';

export const AccountAdvertisers = observer(() => {
	const { advertiserStore } = useStores<IMFPStore>();
	const metricsStore = advertiserStore.getMetricsStore();
	const reportsStore = advertiserStore.getReportsStore();
	const reportData = reportsStore.getData();
	const { accountId } = useParams();

	if (accountId) {
		useReportsList<AdvertiserModel>(
			reportsStore,
			{
				entity: Entities.Advertisers,
				filters: [
					{
						column: PerformancesColumns.AccountId,
						filterType: IPerformanceOptionsFilterType.Include,
						value: [accountId],
					},
				],
				groupBys: [PerformancesColumns.AdvertiserId],
			},
			undefined,
			advertiserStore.getSegments()
		);
	}

	useMetricsGet(metricsStore, {
		entity: Entities.Chart,
		groupBys: [PerformancesColumns.Date],
		filters: [
			{
				column: PerformancesColumns.AccountId,
				filterType: IPerformanceOptionsFilterType.Include,
				value: [accountId ? accountId : '1'],
			},
		],
	});

	const { columns, columnVisibility } = useAdvertiserHeaders({ summary: reportData?.getSummary() });
	const { SelectedActions, SegmentActions } = useAdvertiserActions({
		currentSegments: advertiserStore.getSegments(),
	});

	const { metrics, xLabels } = useMetrics(metricsStore.getData());

	return (
		<Page unstyled={true} className={styles.article}>
			<Pagebar classes={{ pagebar: styles.padding }}>
				<PagebarTitle>Advertisers</PagebarTitle>
				<PagebarActions>
					<ControlledDater />
					<PrimaryLink icon={'plus'} to={accountId ? routes.accounts.createAdvertiser(accountId) : ''} width={'148px'}>
						New Advertiser
					</PrimaryLink>
				</PagebarActions>
			</Pagebar>
			<ManifestoChart
				isLoading={metricsStore.getIsLoading()}
				metrics={metrics}
				labels={xLabels}
				legendOptions={Object.keys(availablePerformanceToMetrics)}
			/>
			<SpacerY y={2} />
			<ControlledTable
				columns={columns}
				columnVisibility={columnVisibility}
				classes={{ wrapper: styles.padding }}
				data={reportData?.getData() || []}
				isLoading={reportsStore.getIsLoading()}
				debugProps={{ dataAttrs: [new DataAttribute('id', 'advertisers_account_table')] }}
				isWithPerformance={true}
				isLoadingPerformance={reportsStore.getIsLoading()}
				defaultSortBy={[{ id: 'revenue', desc: true }]}>
				{() => {
					return {
						SelectedActions,
						SegmentActions,
					};
				}}
			</ControlledTable>
		</Page>
	);
});
