import { CampaignStore } from './campaigns/campaign.store';
import { AdvertiserStore } from './advertisers/advertiser.store';
import { PreferenceStore } from './preferences/preference.store';
import { CreativeStore } from './creatives/creative.store';
import { CampaignGroupsStore } from './campaign-groups/campaign-groups.store';
import { CreativeGroupsStore } from './creative-groups/creative-groups.store';
import { UserStore } from './user.store'; // TODO - move to controlled?
import { PerformanceResponse } from '../models/performance.model';
import { PerformanceApi } from '../apis/performance.api';
import { TestStore } from './tests/test.store';
import { DealStore } from './deals/deal.store';
import { IPerformanceOptionsV2 } from '../hooks/apis/use-performance';
import { JobStore } from './jobs/job.store';
import { NotificationsStore } from '@monorepo/controlled/src/stores/notifications.store';
import { AuthStore } from '@monorepo/controlled/src/stores/auth.store';
import { FormStore } from '@monorepo/controlled/src/stores/form.store';
import { DateStore } from '@monorepo/controlled/src/stores/date.store';
import { HttpStore } from '@monorepo/controlled/src/stores/http.store';
import { ModalsStore } from '@monorepo/controlled/src/stores/modals.store';
import { ToastsStore } from '@monorepo/controlled/src/stores/toasts.store';
import { SidebarStore } from '@monorepo/controlled/src/stores/sidebar.store';
import { TableStore } from '@monorepo/controlled/src/stores/table.store';

export interface IAdminxStore {
	// Data Stores
	campaignStore: CampaignStore;
	advertiserStore: AdvertiserStore;
	preferenceStore: PreferenceStore;
	creativeStore: CreativeStore;
	campaignGroupsStore: CampaignGroupsStore;
	creativeGroupsStore: CreativeGroupsStore;
	performanceStore: HttpStore<IPerformanceOptionsV2, PerformanceResponse>;
	testStore: TestStore;
	dealStore: DealStore;
	jobStore: JobStore;

	// Functionality Stores
	authStore: AuthStore;
	userStore: UserStore;
	sidebarStore: SidebarStore;
	formStore: FormStore;
	toastsStore: ToastsStore;
	modalsStore: ModalsStore;
	tableStore: TableStore;
	dateStore: DateStore;
	notificationsStore: NotificationsStore;
	platform: 'adminx';
}

export class AdminxStore implements IAdminxStore {
	public advertiserStore: AdvertiserStore;
	public authStore: AuthStore;
	public campaignGroupsStore: CampaignGroupsStore;
	public campaignStore: CampaignStore;
	public creativeGroupsStore: CreativeGroupsStore;
	public creativeStore: CreativeStore;
	public dateStore: DateStore;
	public dealStore: DealStore;
	public formStore: FormStore;
	public jobStore: JobStore;
	public modalsStore: ModalsStore;
	public notificationsStore: NotificationsStore;
	public performanceStore: HttpStore<IPerformanceOptionsV2, PerformanceResponse>;
	public platform = 'adminx' as const;
	public preferenceStore: PreferenceStore;
	public sidebarStore: SidebarStore;
	public tableStore: TableStore;
	public testStore: TestStore;
	public toastsStore: ToastsStore;
	public userStore: UserStore;

	constructor() {
		this.formStore = new FormStore();
		this.authStore = new AuthStore();
		this.userStore = new UserStore();
		this.sidebarStore = new SidebarStore();
		this.advertiserStore = new AdvertiserStore();
		this.campaignStore = new CampaignStore();
		this.preferenceStore = new PreferenceStore();
		this.toastsStore = new ToastsStore();
		this.modalsStore = new ModalsStore();
		this.tableStore = new TableStore();
		this.creativeStore = new CreativeStore();
		this.campaignGroupsStore = new CampaignGroupsStore();
		this.creativeGroupsStore = new CreativeGroupsStore();
		this.dateStore = new DateStore();
		this.testStore = new TestStore();
		this.dealStore = new DealStore();
		this.jobStore = new JobStore();
		this.notificationsStore = new NotificationsStore();
		this.performanceStore = new HttpStore<IPerformanceOptionsV2, PerformanceResponse>({
			httpFunc: PerformanceApi.performance,
			model: PerformanceResponse,
		});
	}
}
