import { UserModel, IUserCreateForm, IUserEditForm } from '../../models/user.model';
import { ICredentials, UsersApi } from '../../apis/users.api';
import { BaseCrudStore } from '@monorepo/controlled/src/stores/base-crud.store';
import { FormError } from '@monorepo/tools/src/lib/models/form-error.model';
import { runInAction } from 'mobx';
import { HttpError, IHttpError } from '@monorepo/tools/src/lib/models/http-error.model';
import { validateEmail } from '@monorepo/tools/src/lib/utils/string';
import { FullResponse } from '@monorepo/tools/src/lib/interfaces/url';

export interface IUserRes {
	payload: { user: UserModel };
}

export class UserCrudStore extends BaseCrudStore<UserModel, IUserCreateForm, IUserEditForm> {
	constructor() {
		super({
			apiLayer: UsersApi,
			model: UserModel,
		});
	}

	/**
	 * Must call isValidUser before calling this function
	 * @returns
	 */
	public getCreateFormData(): IUserCreateForm {
		const company = this.getData()
			.getEmail()
			?.match(/@([^.]+)/);

		return {
			id: this.getData().getId(),
			firstName: this.getData().getFirstName(),
			lastName: this.getData().getLastName(),
			email: this.getData().getEmail(),
			password: this.getData().getPassword(),
			company: company ? company[0].slice(1) : '',
		};
	}

	/**
	 * Must call isValidUser before calling this function
	 * @returns
	 */
	public getEditFormData(): IUserEditForm {
		return {
			id: this.getData().getId(),
			firstName: this.getData().getFirstName(),
			lastName: this.getData().getLastName(),
			email: this.getData().getEmail(),
			password: this.getData().getPassword(),
		};
	}

	public isValid(): boolean {
		this.formStore.reset();
		this.clearHttpError();
		const firstName = this.getData().getFirstName();
		const lastName = this.getData().getLastName();
		const email = this.getData().getEmail();
		const password = this.getData().getPassword();
		if (!firstName) {
			this.formStore.addError(new FormError('first_name', 'Please enter first name'));
		}

		if (!lastName) {
			this.formStore.addError(new FormError('last_name', 'Please enter last name'));
		}

		if (!email) {
			this.formStore.addError(new FormError('email', 'Please enter email'));
		}

		if (email && !validateEmail(email)) {
			this.formStore.addError(new FormError('email', 'Please enter valid email'));
		}

		if (!password) {
			this.formStore.addError(new FormError('password', 'Please enter password'));
		}

		return this.formStore.getIsValid();
	}

	public signInValidation(): boolean {
		this.formStore.reset();
		this.clearHttpError();
		const email = this.getData().getEmail();
		const password = this.getData().getPassword();
		if (!email) {
			this.formStore.addError(new FormError('email', 'Please enter email'));
		}

		if (email && !validateEmail(email)) {
			this.formStore.addError(new FormError('email', 'Please enter valid email'));
		}

		if (!password) {
			this.formStore.addError(new FormError('password', 'Please enter password'));
		}

		return this.formStore.getIsValid();
	}

	public signIn(): Promise<FullResponse<IUserRes> | void> {
		const isValid = this.signInValidation();
		if (!isValid) {
			this.setHttpError(new HttpError({ message: 'Please fix the issues above' } as IHttpError));
			return Promise.resolve();
		}

		this.setIsLoading(true);
		this.setIsSuccess(false);

		const credentials: ICredentials = {
			// after validation
			email: this.getData().getEmail() as string,
			password: this.getData().getPassword() as string,
		};

		return UsersApi.signIn(credentials)
			.then(res => {
				runInAction(() => {
					this.setIsLoading(false);
					this.setIsSuccess(true);
				});
				return res;
			})
			.catch(err => {
				this.setHttpError(new HttpError({ message: err?.payload?.message } as IHttpError));
				this.setIsLoading(false);
				this.setIsSuccess(false);
				return err;
			});
	}

	public signUp(): Promise<FullResponse<IUserRes> | void> {
		const isValid = this.isValid();
		if (!isValid) {
			this.setHttpError(new HttpError({ message: 'Please fix the issues above' } as IHttpError));
			return Promise.resolve();
		}
		this.setIsLoading(true);
		this.setIsSuccess(false);

		return UsersApi.signUp(this.getCreateFormData())
			.then(res => {
				runInAction(() => {
					this.setIsLoading(false);
					this.setIsSuccess(true);
				});
				return res;
			})
			.catch(err => {
				this.setHttpError(new HttpError({ message: err?.payload?.message } as IHttpError));
				this.setIsLoading(false);
				this.setIsSuccess(false);
				return err;
			});
	}

	public verify(verifyToken: string): Promise<FullResponse<IUserRes> | void> {
		if (!verifyToken) {
			return Promise.resolve();
		}

		this.setIsLoading(true);
		this.setIsSuccess(false);

		return UsersApi.verify(verifyToken)
			.then(res => {
				const { body } = res;
				runInAction(() => {
					if (body?.payload?.item) {
						this.setData(body.payload.item);
					}
					this.setIsLoading(false);
					this.setIsSuccess(true);
				});
				return res;
			})
			.catch(err => {
				runInAction(() => {
					this.setHttpError(new HttpError({ message: err?.payload?.message } as IHttpError));
					this.setIsLoading(false);
					this.setIsSuccess(false);
				});
				return err;
			});
	}
}
