import { TagTypes } from '@monorepo/base/src/components/tag/tag';

export enum StatusEnum {
	PAUSED = 'PAUSED',
	ACTIVE = 'ACTIVE',
	DRAFT = 'DRAFT',
	DELETED = 'DELETED',
}

export type StatusType = StatusEnum.PAUSED | StatusEnum.ACTIVE | StatusEnum.DRAFT | StatusEnum.DELETED;

export interface IStatus {
	status?: StatusType;
}

export const mapToIcon = (status?: StatusType) => {
	if (status === StatusEnum.ACTIVE) {
		return { icon: 'play', color: '#12B76A', className: 'active' };
	}
	if (status === StatusEnum.PAUSED) {
		return { icon: 'pause-square', color: '#667085', className: 'paused' };
	}
	if (status === StatusEnum.DRAFT) {
		return { icon: 'package', color: '#667085', className: 'draft' };
	}
	if (status === StatusEnum.DELETED) {
		return { icon: 'x-close', color: '#EA4337', className: 'deleted' };
	}

	return { icon: 'play', color: '#12B76A', className: 'active' };
};

export const mapToTagStatus = (status?: StatusType) => {
	if (status === StatusEnum.ACTIVE) {
		return TagTypes.Success;
	}
	if (status === StatusEnum.PAUSED) {
		return TagTypes.Paused;
	}
	if (status === StatusEnum.DRAFT) {
		return TagTypes.Draft;
	}
	if (status === StatusEnum.DELETED) {
		return TagTypes.Deleted;
	}
	return TagTypes.Info;
};
