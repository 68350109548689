import { FC } from 'react';
import styles from './home.module.scss';
import { Overview } from '../../modules/components/overview/overview';

export const Home: FC = () => {
	return (
		<article className={styles.wrapper}>
			<Overview />
		</article>
	);
};
