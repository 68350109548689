import { TestModel } from '../../models/test.model';
import { TestsApi } from '../../apis/tests-api';
import { makeObservable, observable } from 'mobx';
import { TestCrudStore } from './test-crud.store';
import { PerformancesColumns } from '../../hooks/apis/use-performance';
import { PerformanceStore } from '../performance.store';
import { TestSegment } from '../../hooks/tools/use-segments';
import { capitalCase } from 'change-case';
import { StatusFilter } from '../../components/filters-menu/filters';
import { FilterModel } from '@monorepo/controlled/src/models/filter.model';
import { ISegment } from '@monorepo/base/src/components/segments/segments';
import { filterPrototypesByProp } from '../../components/filters-menu/filters/index';
import { BaseListStore } from '@monorepo/controlled/src/stores/base-list.store';
import { FilterStore } from '@monorepo/controlled/src/stores/filter.store';
import { PerformanceModel } from '../../models/performance.model';
import { Statuses } from '../../enums/status';

export enum TestFiltersStoreKeys {
	Tests = 'tests',
}
export class TestStore {
	crud = new TestCrudStore();
	list = new BaseListStore<TestModel>({
		listFunc: TestsApi.list,
		model: TestModel,
	});
	performance = new PerformanceStore<TestModel>({
		keys: new Set([PerformancesColumns.TestGroupName]),
		segments: new Set<ISegment<TestModel, PerformanceModel, PerformancesColumns>>([TestSegment<TestModel>()]),
	});
	filters: Map<string, FilterStore>;

	constructor() {
		makeObservable(this, {
			crud: observable,
			list: observable,
			filters: observable,
			performance: observable,
		});

		const defaultFilters = new Map([
			[
				1,
				new FilterModel({
					index: 1,
					label: `Status is ${capitalCase(Statuses.Active)}`,
					value: Statuses.Active,
					prototype: StatusFilter,
				}),
			],
		]);
		const testsPageFilterStore = new FilterStore({
			key: TestFiltersStoreKeys.Tests,
			currentFilters: defaultFilters,
			filterPrototypesByProp,
		});
		this.filters = new Map([[TestFiltersStoreKeys.Tests, testsPageFilterStore]]);
	}

	getCrud() {
		return this.crud;
	}

	getListStore() {
		return this.list;
	}

	getPerformance() {
		return this.performance;
	}

	getFiltersStore(key: TestFiltersStoreKeys) {
		return this.filters.get(key);
	}
}
