import { Checkbox } from '@monorepo/base/src/components/form/checkbox/checkbox';
import { Icon } from '@monorepo/base/src/components/icon/icon';
import { Constructable } from '@monorepo/tools/src/lib/interfaces/class';
import styles from './selection-column.module.scss';
import { ElementType } from 'react';
import { QuickActionsMenu } from './../../table/quick-actions-menu/quick-actions-menu';
import { ColumnDef } from '@tanstack/react-table';
import { isSubRow } from '../../table/table';

export const SELECTION_COLUM = 'SELECTION_COLUMN';
export const SELECTION_STATUS_COLUMN = 'SELECTION_WITH_STATUS_COLUMN';

interface ISelectionColumn<T> {
	model: Constructable<T>;
	EntityActions?: ElementType;
}

// interface IColumnBuilder {
// 	id: string,
// 	Header: HeaderProps
// }

// export function columnBuilder<T>(props: IColumnBuilder): Column {
// 	return {
// 		id,
// 		Header,
// 		Cell
// 	} as Column;
// }

// TODO Dont like it so much, think of other way to do this Cell shit
export function selectionWithStatusColumn<T>(props: ISelectionColumn<T>): ColumnDef<T> {
	const { model, EntityActions } = props;

	return {
		id: SELECTION_STATUS_COLUMN,
		header: ({ table }) => {
			const checkboxProps = {
				checked: table.getIsAllRowsSelected(),
				// indeterminate: table.getIsSomeRowsSelected(),
				onChange: table.getToggleAllRowsSelectedHandler(),
			};
			// delete checkboxProps.indeterminate; // TODO
			return (
				<div className={styles.selectionWithStatusColumn}>
					<Checkbox {...checkboxProps} className={styles.checkbox} />
					<Icon size={'14px'} isMFP={true} color={'#c5c5c5'}>
						circle
					</Icon>
				</div>
			);
		},
		cell: ({ row }) => {
			const checkboxProps = {
				checked: row.getIsSelected(),
				// indeterminate: row.getIsSomeSelected(),
				onChange: row.getToggleSelectedHandler(),
			};

			// delete checkboxProps.indeterminate; // TODO
			if (isSubRow(row)) {
				return <span></span>;
			}
			return (
				<div className={styles.selectionWithStatusColumn}>
					<Checkbox {...checkboxProps} className={styles.checkbox} />
					{EntityActions ? (
						<QuickActionsMenu<T> entity={new model(row.original)} isStatus={true}>
							<EntityActions entity={new model(row.original)} />
						</QuickActionsMenu>
					) : null}
				</div>
			);
		},
	};
}

export function selectionColumn<T>(props: ISelectionColumn<T>): ColumnDef<T> {
	const { model, EntityActions } = props;

	return {
		id: SELECTION_COLUM,
		header: ({ table }) => {
			const checkboxProps = {
				checked: table.getIsAllRowsSelected(),
				// indeterminate: table.getIsSomeRowsSelected(),
				onChange: table.getToggleAllRowsSelectedHandler(),
			};
			// delete checkboxProps.indeterminate; // TODO
			return (
				<div className={styles.selectionColumn}>
					<Checkbox {...checkboxProps} className={styles.checkbox} />
				</div>
			);
		},
		cell: ({ row }) => {
			const checkboxProps = {
				checked: row.getIsSelected(),
				// indeterminate: row.getIsSomeSelected(),
				onChange: row.getToggleSelectedHandler(),
			};

			// delete checkboxProps.indeterminate; // TODO
			if (isSubRow(row)) {
				return <span></span>;
			}
			return (
				<div className={styles.selectionColumn}>
					<Checkbox {...checkboxProps} className={styles.checkbox} />
					{EntityActions ? (
						<QuickActionsMenu<T> entity={new model(row.original)}>
							<EntityActions entity={new model(row.original)} />
						</QuickActionsMenu>
					) : null}
				</div>
			);
		},
	};
}
