import { ask } from '@monorepo/tools/src/lib/tools/ask/ask';
import endpoints from './endpoints/index.endpoints';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import { URLSearchParams } from 'url';
import { ICampaignCreateForm, ICampaignEditForm, CampaignModel } from '../models/campaign.model';
import { PerformancePayload } from '../models/performance.model';
import { IPerformanceOptions } from '../hooks/apis/use-reports-list';
import { queryParamsToSearchParams } from '@monorepo/tools/src/lib/utils/url';
import { IRequestOptions } from '@monorepo/tools/src/lib/interfaces/url';

export const CampaignsApi = {
	list: (params?: URLSearchParams): Promise<CampaignModel[]> => {
		return ask.get(endpoints.campaigns.list(), params).then(res => res?.payload?.list);
	},
	create: (campaign: ICampaignCreateForm, options?: IRequestOptions): Promise<CampaignModel> => {
		return ask.post<ICampaignCreateForm>(endpoints.campaigns.create(), campaign, options).then(res => res?.payload?.item);
	},
	edit: (campaignId: id, campaign: ICampaignEditForm, options?: IRequestOptions): Promise<CampaignModel> => {
		return ask.put<ICampaignEditForm>(endpoints.campaigns.edit(campaignId), campaign, options).then(res => res?.payload?.item);
	},
	saveDraft: (campaign: ICampaignEditForm, options?: IRequestOptions): Promise<CampaignModel> => {
		return ask.post<ICampaignEditForm>(endpoints.campaigns.saveDraft(), campaign, options).then(res => res?.payload?.item);
	},
	get: (campaignId: id, params?: URLSearchParams): Promise<CampaignModel> => {
		return ask.get(endpoints.campaigns.get(campaignId), params).then(res => res?.payload?.item);
	},
	delete: (campaignId: id) => {
		return ask.delete(endpoints.campaigns.delete(campaignId)).then(res => res?.payload?.ids);
	},
	reports: (params?: IPerformanceOptions, options?: IRequestOptions): Promise<PerformancePayload> => {
		const hashParams = btoa(JSON.stringify(params));
		return ask
			.get(endpoints.campaigns.reports(), queryParamsToSearchParams({ p: hashParams, ...(options?.queryParams || {}) }))
			.then(res => res?.payload?.report);
	},
	metrics: (params?: IPerformanceOptions): Promise<PerformancePayload> => {
		const hashParams = btoa(JSON.stringify(params));
		return ask.get(endpoints.campaigns.reports(), queryParamsToSearchParams({ p: hashParams })).then(res => res?.payload?.report);
	},
	insights: (params?: IPerformanceOptions): Promise<PerformancePayload> => {
		const hashParams = btoa(JSON.stringify(params));
		return ask.get(endpoints.campaigns.reports(), queryParamsToSearchParams({ p: hashParams })).then(res => res?.payload?.report);
	},
	enableCampaigns(campaignIds: id[]): Promise<id[]> {
		return ask.patch(endpoints.campaigns.enableCampaigns(), campaignIds).then(res => res?.payload?.ids);
	},
	pauseCampaigns(campaignIds: id[]): Promise<id[]> {
		return ask.patch(endpoints.campaigns.pauseCampaigns(), campaignIds).then(res => res?.payload?.ids);
	},
};
