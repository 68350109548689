import { ReactElement, useEffect, useRef, useState } from 'react';
import styles from './tooltip.module.scss';
import { useDebounce } from '@monorepo/tools/src/lib/hooks/utils/use-debounce';
import { useHover } from '@monorepo/tools/src/lib/hooks/utils/use-hover';
import { Directions } from '../buttons/buttons';

enum TooltipThemes {
	Dark = 'dark',
	Light = 'light',
}

interface ITooltip {
	children: ReactElement;
	content: ReactElement | string;
	theme?: TooltipThemes;
	delay?: number;
	className?: string;
	contentClassName?: string;
	is?: boolean;
	isFullWidth?: boolean;
	align?: Alignments;
	direction?: Directions;
	top?: string; // TODO - fix a bug where someting the tooltip is not align to center
	bottom?: string; // TODO - fix a bug where someting the tooltip is not align to center
}

// TODO - take it outside
export enum Alignments {
	FromTop = 'alignToTop',
	FromBottom = 'alignToBottom',
	FromLeft = 'alignToLeft',
	FromRight = 'alignToRight',
}

export const Tooltip = (props: ITooltip) => {
	const {
		children,
		content,
		theme = TooltipThemes.Dark,
		delay = 250,
		className,
		is = true,
		contentClassName,
		isFullWidth = false,
		align = undefined,
		direction = undefined,
		top = '',
		bottom = '',
	} = props;
	const hoverRef = useRef(null);
	const isHover = useHover(hoverRef);
	const tooltipRef = useRef<HTMLDivElement>(null);
	const [alignClass, setAlignClass] = useState<string>(align ? styles[align] : '');
	const [directionClass] = useState<string>(direction ? styles[direction] : '');
	const debouncedIsHover = useDebounce(isHover, delay);

	useEffect(() => {
		if (!alignClass && tooltipRef.current) {
			const tooltipRect = tooltipRef.current.getBoundingClientRect();
			if (tooltipRect && tooltipRect.x + tooltipRect.width + 40 > window.innerWidth) {
				setAlignClass(styles.alignToRight);
			} else if (tooltipRect && tooltipRect.x < 0) {
				setAlignClass(styles.alignToLeft);
			} else {
				setAlignClass(styles.normalAlign);
			}
		}
	}, [tooltipRef]);

	const themeStyles = {
		[TooltipThemes.Dark]: styles.dark,
		[TooltipThemes.Light]: styles.light,
	};
	if (!is) {
		return children;
	}

	return (
		<span className={`${styles.wrapper} ${className ? className : ''} ${isFullWidth ? styles.fullWidth : ''}`} ref={hoverRef}>
			<div
				ref={tooltipRef}
				style={{ top, bottom }}
				className={`${styles.tooltip} ${debouncedIsHover ? styles.active : ''} ${alignClass} ${directionClass} ${
					themeStyles[theme]
				} ${contentClassName}`}>
				{content}
			</div>
			{children}
		</span>
	);
};
