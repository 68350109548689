import { ManifestoLogoSVG } from '../../modules/components/logo/logo';
import { PrimaryButton, SecondaryButton } from '@monorepo/base/src/components/buttons/buttons';
import { Image } from '@monorepo/base/src/components/img/img';
import styles from './verify-user.module.scss';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { IMFPStore } from '../../modules/stores';
import { Navigate, useLocation } from 'react-router-dom';
import routes from '../../modules/routes/index.routes';
import { observer } from 'mobx-react';
import { useState } from 'react';

export const VerifyUser = observer(() => {
	const { userStore, authStore } = useStores<IMFPStore>();
	const user = userStore.getUser();
	const verifyEmailStore = userStore.getVerifyEmailHttpStore();
	const isLoading = verifyEmailStore.getIsLoading();
	const location = useLocation();
	const [isEvery10Sec, setIsEvery10Sec] = useState<boolean>(false);

	if (!user) {
		return <Navigate to={`${routes.auth.login()}${location.search}`} state={{ from: location }} replace />;
	}

	const email = user.getEmail();
	if (!email) {
		return <Navigate to={`${routes.auth.login()}${location.search}`} state={{ from: location }} replace />;
	}

	if (user?.isVerified()) {
		return <Navigate to={`${routes.general.home()}${location.search}`} state={{ from: location }} replace />;
	}

	const resendEmail = () => {
		const token = authStore.getToken();
		setIsEvery10Sec(true);
		setTimeout(() => {
			setIsEvery10Sec(false);
		}, 10000);
		userStore.getVerifyEmailHttpStore().fetch(undefined, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
	};

	return (
		<div className={styles.wrapper}>
			<ManifestoLogoSVG width={'173'} height={'36'} />
			<div className={styles.contentWrapper}>
				<div className={styles.imageWrapper}>
					<Image className={styles.img} source={'/assets/verify-email.png'} alt={'email photo'} />
				</div>
				<div className={styles.heading}>Verify Email</div>
				<div className={styles.text}>
					An email with a link to verify your account has been sent to {email}. If you aren't able to locate the email after a
					short time, please look into your spam folder.
				</div>
				<div className={styles.actions}>
					<SecondaryButton className={styles.btn}>Contact Support</SecondaryButton>
					<PrimaryButton
						disabled={isEvery10Sec}
						onClick={resendEmail}
						className={styles.btn}
						isRotateIcon={isLoading}
						icon={isLoading ? 'loading-01' : 'send-03'}>
						Resend email
					</PrimaryButton>
				</div>
			</div>
		</div>
	);
});
