import { PerformanceOptionsSortByStrings } from '../../../hooks/apis/use-reports-list';
import styles from './insights-tabs.module.scss';

interface ITopTabs {
	options: PerformanceOptionsSortByStrings[];
	defaultValue: string;
	onTabClick?: (option: PerformanceOptionsSortByStrings) => void;
}

export const InsightsTabs = (props: ITopTabs) => {
	const { options, defaultValue = 'Impressions', onTabClick } = props;

	return (
		<div className={styles.wrapper}>
			{options?.map(option => (
				<div
					key={option}
					className={`${styles.tab} ${defaultValue === option ? styles.active : ''}`}
					onClick={() => onTabClick && onTabClick(option)}>
					{option}
				</div>
			))}
		</div>
	);
};
