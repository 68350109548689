import { generateStoreName } from './helper';
import { makePersistable } from 'mobx-persist-store';
import { makeAutoObservable, action } from 'mobx';
// import { cookies } from '@monorepo/tools/src/lib/tools/cookies';
import { jwt } from '@monorepo/tools/src/lib/types/jwt';

export class AuthStore {
	token?: jwt;

	constructor() {
		makeAutoObservable(this, {
			removeToken: action,
		});

		makePersistable(this, {
			name: generateStoreName('ilbbaicnl'),
			properties: ['token'],
			// storage: {
			// 	setItem: (key, data) => cookies.setCookie(key, data),
			// 	removeItem: key => cookies.deleteCookie(key),
			// 	getItem: key => {
			// 		const data = JSON.parse(cookies.getCookie(key) || '{}');

			// 		return {
			// 			...data,
			// 		};
			// 	},
			// },
			//!TODO: Cookies currently does not save properly, we dont need cookie because the token is encrypted

			storage: {
				setItem: (key, data) => window.localStorage.setItem(key, data),
				removeItem: key => window.localStorage.removeItem(key),
				getItem: key => {
					const data = JSON.parse(window.localStorage.getItem(key) || '{}');

					return {
						...data,
					};
				},
			},
		});
	}

	setToken(token?: jwt) {
		this.token = token;
	}

	getToken(): jwt | undefined {
		return this.token;
	}

	removeToken() {
		this.token = undefined;
	}
}
