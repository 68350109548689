import dayjs from 'dayjs';
import { PerformanceModel } from '../../models/performance.model';
import { PerformancesColumns } from '../apis/use-reports-list';
import { ISegment, ISegmentCell } from '@monorepo/base/src/components/segments/segments';

interface IUseSegments<T> {
	setSegments: (segment: Set<ISegment<T, PerformanceModel, PerformancesColumns>>) => void;
	disableSegments?: PerformancesColumns[];
}
// TODO - maybe take all segment out to external objects like TsetSegment

export function DateSegment<T>(): ISegment<T, PerformanceModel, PerformancesColumns> {
	return {
		title: 'Day',
		performanceGroupBy: PerformancesColumns.Date,
		renderCell: (props: ISegmentCell<T, PerformanceModel>) => {
			const { performanceItem } = props;
			return <span style={{ whiteSpace: 'pre' }}>{dayjs(performanceItem.date).format('MMMM D, YYYY')}</span>;
		},
		sortFn: (a: PerformanceModel, b: PerformanceModel) => {
			return dayjs(a.date).isBefore(dayjs(b.date)) ? -1 : 1;
		},
	};
}

export function useSegments<T>(props: IUseSegments<T>) {
	const { setSegments, disableSegments } = props;

	const onSegment = (segment: ISegment<T, PerformanceModel, PerformancesColumns> | null) => {
		if (!segment) {
			setSegments(new Set<ISegment<T, PerformanceModel, PerformancesColumns>>([]));
			return;
		}
		setSegments(new Set<ISegment<T, PerformanceModel, PerformancesColumns>>([segment]));
	};

	let segments: ISegment<T, PerformanceModel, PerformancesColumns>[] = [
		// {
		// 	title: 'Auction Type',
		// 	performanceGroupBy: PerformancesColumns.AuctionType,
		// },
		// {
		// 	title: 'Browser',
		// 	performanceGroupBy: PerformancesColumns.BrowserName,
		// },
		DateSegment<T>(),
		{
			title: 'Geo',
			performanceGroupBy: PerformancesColumns.Geo,
		},
		{
			title: 'Operating System',
			performanceGroupBy: PerformancesColumns.OsName,
		},
		{
			title: 'Conversion',
			performanceGroupBy: PerformancesColumns.Conversion,
		},
	];

	if (disableSegments) {
		segments = segments.filter(segment => {
			return !disableSegments.includes(segment.performanceGroupBy as PerformancesColumns);
		});
	}

	return { segments, onSegment };
}
