import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useNestedSidebar } from '@monorepo/controlled/src/hooks/use-nested-sidebar';
import routes from '../../../modules/routes/index.routes';
import { Page } from '@monorepo/base/src/components/page/page';
import { useEffect, useState } from 'react';
import { route } from '@monorepo/tools/src/lib/types/url';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';
import { NestedNav, NestedNavGroup, NestedNavItem, NestedNavPage } from '../../../modules/components/base-wrappers/nested-nav/nested-nav';
import { Tooltip } from '@monorepo/base/src/components/tooltip/tooltip';
import { observer } from 'mobx-react';
import { Directions } from '@monorepo/base/src/components/buttons/buttons';

export const CampaignView = observer(() => {
	const { campaignId } = useParams();
	const navigate = useNavigate();
	const { currentRoute } = useRoute();

	const [activeRoute, setActiveRoute] = useState<route>(currentRoute);
	useEffect(() => {
		setActiveRoute(currentRoute);
	}, [currentRoute]);

	const location = useLocation();
	useNestedSidebar();

	if (!campaignId) {
		navigate(`${routes.campaigns.index()}${location.search}`, { replace: true });
		return null;
	}

	return (
		<NestedNavPage>
			<NestedNav onNavClick={({ to }) => to && setActiveRoute(to)} activeRoute={activeRoute}>
				<NestedNavItem icon={'bar-chart-square-02'}>
					<Tooltip direction={Directions.FromLeft} content={'Coming Soon'}>
						<span>Overview</span>
					</Tooltip>
				</NestedNavItem>
				<NestedNavItem icon={'globe-03'}>
					<Tooltip direction={Directions.FromLeft} content={'Coming Soon'}>
						<span>Landing Pages</span>
					</Tooltip>
				</NestedNavItem>
				<NestedNavItem icon={'timeline'}>
					<Tooltip direction={Directions.FromLeft} content={'Coming Soon'}>
						<span>Activity Log</span>
					</Tooltip>
				</NestedNavItem>
				<NestedNavItem icon={'settings-01'}>
					<Tooltip direction={Directions.FromLeft} content={'Coming Soon'}>
						<span>Settings</span>
					</Tooltip>
				</NestedNavItem>
				<NestedNavGroup title={'Placements'}>
					<NestedNavItem icon={'route'} to={campaignId ? `${routes.campaigns.channels(campaignId)}${location.search}` : ''}>
						Channels
					</NestedNavItem>
					<NestedNavItem
						icon={'layout-alt-01'}
						to={campaignId ? `${routes.campaigns.sources(campaignId)}${location.search}` : ''}>
						Sources
					</NestedNavItem>
				</NestedNavGroup>
			</NestedNav>
			<Page unstyled={true}>
				<Outlet />
			</Page>
		</NestedNavPage>
	);
});
