import { useRef, useState } from 'react';
import { useOnClickOutside } from '@monorepo/tools/src/lib/hooks/utils/use-on-click-outside';
import { SELECTION_COLUM, SELECTION_STATUS_COLUMN } from '@monorepo/tools/src/lib/consts/global';
import { PrimaryText, SecondaryText } from '@monorepo/base/src/components/buttons/buttons';
import { Divider } from '@monorepo/base/src/components/divider/divider';
import { Checkbox } from '@monorepo/base/src/components/form/checkbox/checkbox';
import { List, ListItem } from '@monorepo/base/src/components/list/list';
import { Menu } from '@monorepo/base/src/components/menu/menu';
import { IndexLinerButton } from '../action-liner/liner-buttons';
import styles from './columns-picker.module.scss';
import { Column } from '@tanstack/react-table';
import { getTheme } from '@monorepo/tools/src/lib/get-config';
import { IconFonts } from '@monorepo/base/src/components/icon/icon';
import { suffixToDataAttr } from '@monorepo/tools/src/lib/models/data-attr.model';
import { IDebugProps } from '@monorepo/tools/src/lib/interfaces/debug';

const theme = getTheme();

interface IColumnsPicker<T> {
	allColumns: Column<T>[];
	toggleAllColumnsVisible: (arg0: boolean) => void;
	debugProps?: IDebugProps;
}

export function ColumnsPicker<T>(props: IColumnsPicker<T>) {
	const { allColumns, toggleAllColumnsVisible, debugProps } = props;
	const [isColumnPickerOpen, setColumnPickerOpen] = useState<boolean>(false);
	const columnsPickerRef = useRef<HTMLDivElement>(null);
	const { dataAttrs } = debugProps || {};

	const onColumnPicker = () => {
		setColumnPickerOpen(!isColumnPickerOpen);
	};

	useOnClickOutside(columnsPickerRef, () => {
		setColumnPickerOpen(false);
	});

	return (
		<div className={styles.wrapper} ref={columnsPickerRef}>
			<IndexLinerButton>
				<SecondaryText
					debugProps={{ dataAttrs: suffixToDataAttr('_columns', dataAttrs) }}
					icon={'columns-03'}
					font={IconFonts.Outlined}
					iconColor={theme.actionLinerActionIconsColor}
					iconSize={'18px'}
					onClick={onColumnPicker}>
					Columns
				</SecondaryText>
			</IndexLinerButton>
			{isColumnPickerOpen ? (
				<Menu unstyled={true} isOpen={isColumnPickerOpen} alignToRight={true}>
					<div className={styles.columnPickerMenu}>
						<div className={styles.subtitle}>Column settings:</div>
						<List className={styles.list}>
							{allColumns
								.filter(column => column.id !== SELECTION_COLUM && column.id !== SELECTION_STATUS_COLUMN)
								.map(column => {
									const title = column.columnDef.header?.toString();
									return (
										<ListItem
											debugProps={
												title ? { dataAttrs: suffixToDataAttr(`_${title.toLowerCase()}`, dataAttrs) } : undefined
											}
											className={styles.item}
											key={column.id}
											onClick={() => {
												column.toggleVisibility();
											}}>
											<div className={styles.content}>
												<Checkbox
													className={styles.checkbox}
													checked={column.getIsVisible()}
													onChange={column.getToggleVisibilityHandler()}
												/>
												<span className={styles.title}>{title}</span>
											</div>
										</ListItem>
									);
								})}
						</List>
						<Divider />
						<div className={styles.actions}>
							<PrimaryText onClick={() => toggleAllColumnsVisible(true)}>Select all</PrimaryText>
							<SecondaryText width={'45px'} onClick={() => toggleAllColumnsVisible(false)}>
								Clear
							</SecondaryText>
						</div>
					</div>
				</Menu>
			) : null}
		</div>
	);
}
