import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { ControlledDater } from '@monorepo/controlled/src/components/controlled-dater/controlled-dater';
import { Page, Pagebar, PagebarActions, PagebarTitle } from '@monorepo/base/src/components/page/page';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { IMFPStore } from '../../../../modules/stores';
import {
	Entities,
	IPerformanceOptionsFilterType,
	PerformancesColumns,
	useReportsList,
} from '../../../../modules/hooks/apis/use-reports-list';
import styles from './../../../../styles/pages.module.scss';
import { SpacerY } from '@monorepo/base/src/components/spacer/spacer';
import { ControlledTable } from '@monorepo/controlled/src/components/table-wrapper/table-wrapper';
import { useMetricsGet } from '../../../../modules/hooks/tools/use-metrics-get';
import { useMetrics } from '../../../../modules/hooks/tools/use-metrics';
import { useChannelHeaders } from '../../../../modules/hooks/theaders/channel.headers';
import { availablePerformanceToMetrics } from '../../../../modules/models/performance.model';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';
import { ChannelFiltersStoreKeys } from '../../../../modules/stores/channels/channel.store';
import { useCampaignActions } from '../../../../modules/hooks/actions/campaign.actions';
import { EntityActionsMenu } from '../../../../modules/components/entity-actions-menu/entity-actions-menu';
import { useChannelActions } from '../../../../modules/hooks/actions/channel.actions';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { ManifestoChart } from '../../../../modules/components/chart/manifesto-chart';

export const CampaignChannels = observer(() => {
	const { channelStore, campaignStore } = useStores<IMFPStore>();
	const { campaignId } = useParams();
	const { currentRouteWithoutSpecialChars } = useRoute();

	const reportsStore = channelStore.getReportsStore();
	const reportData = reportsStore.getData();
	const metricsStore = channelStore.getMetricsStore();
	const currentSegments = channelStore.getSegments();

	if (campaignId) {
		useReportsList(
			reportsStore,
			{
				entity: Entities.Channels,
				groupBys: [PerformancesColumns.Channel],
				filters: [
					{
						column: PerformancesColumns.CampaignId,
						filterType: IPerformanceOptionsFilterType.Include,
						value: [campaignId],
					},
				],
			},
			undefined,
			currentSegments
		);

		useMetricsGet(metricsStore, {
			entity: Entities.Chart,
			groupBys: [PerformancesColumns.Date],
			filters: [
				{
					column: PerformancesColumns.CampaignId,
					filterType: IPerformanceOptionsFilterType.Include,
					value: [campaignId],
				},
			],
		});
	}

	const { columns, columnVisibility } = useChannelHeaders({ summary: reportData?.getSummary() });
	const { FiltersActions, SegmentActions } = useChannelActions({ currentSegments });
	const { metrics, xLabels } = useMetrics(metricsStore.getData());
	const currentFilters = channelStore
		.getFiltersStore(currentRouteWithoutSpecialChars as ChannelFiltersStoreKeys.Campaign)
		?.getCurrentFilters();
	const { EntityActions } = useCampaignActions();
	const campaign = campaignStore.getCrud().getData(); // This campaign will be stored from breadcrumbs

	return (
		<Page unstyled={true} className={styles.article}>
			<Pagebar classes={{ pagebar: styles.padding }}>
				<PagebarTitle>Channels</PagebarTitle>
				<PagebarActions>
					<ControlledDater />
					<EntityActionsMenu title={'Campaign Actions'}>
						<EntityActions entity={campaign} />
					</EntityActionsMenu>
				</PagebarActions>
			</Pagebar>
			<ManifestoChart
				isLoading={metricsStore.getIsLoading()}
				metrics={metrics}
				labels={xLabels}
				legendOptions={Object.keys(availablePerformanceToMetrics)}
			/>
			<SpacerY y={2} />
			<ControlledTable
				totalRows={reportData?.getTotalRows() ? reportData?.getTotalRows()?.toLocaleString() : undefined}
				columns={columns}
				classes={{ wrapper: styles.padding }}
				debugProps={{ dataAttrs: [new DataAttribute('id', 'channels_campaign_table')] }}
				columnVisibility={columnVisibility}
				data={reportData?.getData() || []}
				isLoading={reportsStore.getIsLoading()}
				filters={currentFilters}
				isWithPerformance={true}
				isLoadingPerformance={reportsStore.getIsLoading()}
				defaultSortBy={[{ id: 'revenue', desc: true }]}>
				{() => {
					return {
						FiltersActions,
						SegmentActions,
					};
				}}
			</ControlledTable>
		</Page>
	);
});
