import { makeAutoObservable } from 'mobx';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import { IPerformanceItem } from './performance.model';
import { StatusType } from '../enums/status';

export interface IAccount extends IPerformanceItem {
	id?: id;
	name?: string;
	status?: StatusType;
	company_size?: string;
	website?: string;
}

export interface IAccountCreateForm {
	id?: id;
	name?: string;
	company_size?: string;
	website?: string;
}

export interface IAccountEditForm {
	id?: id;
	name?: string;
	company_size?: string;
	website?: string;
}

export class AccountModel implements IAccount {
	id?: id;
	name?: string;
	status?: StatusType;
	company_size?: string;
	website?: string;
	clicks?: number;
	conversions?: number;
	cost?: number;
	revenue?: number;
	impressions?: number;
	roas?: number;
	cpc?: number;
	cpa?: number;
	cpm?: number;
	cvr?: number;

	constructor(account?: IAccount) {
		this.id = account?.id;
		this.name = account?.name;
		this.status = account?.status;
		this.company_size = account?.company_size;
		this.website = account?.website;
		this.clicks = account?.clicks;
		this.status = account?.status;
		this.conversions = account?.conversions;
		this.cost = account?.cost;
		this.revenue = account?.revenue;
		this.impressions = account?.impressions;
		this.roas = account?.roas;
		this.cpc = account?.cpc;
		this.cpa = account?.cpa;
		this.cpm = account?.cpm;
		this.cvr = account?.cvr;

		makeAutoObservable(this);
	}

	getId(): id | undefined {
		return this.id;
	}

	getName(): string | undefined {
		return this.name;
	}

	setName(name: string) {
		this.name = name;
	}

	getCompanySize(): string | undefined {
		return this.company_size;
	}

	setCompanySize(company_size: string) {
		this.company_size = company_size;
	}

	getWebsite(): string | undefined {
		return this.website;
	}

	setWebsite(website: string) {
		this.website = website;
	}
}
