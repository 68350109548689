import { StatusFilter } from '../../components/filters-menu/filters';
import { CampaignModel } from '../../models/campaign.model';
import { BaseListStore } from '@monorepo/controlled/src/stores/base-list.store';
import { makeObservable, observable } from 'mobx';
import { CampaignInputOptionsStore } from './campaign-input-options.store';
import { CampaignCrudStore } from './campaign-crud.store';
import { CampaignsApi } from '../../apis/campaigns.api';
import { capitalCase } from 'change-case';
import { PerformanceStore } from '../performance.store';
import { PerformancesColumns } from '../../hooks/apis/use-performance';
import { DateSegment } from '../../hooks/tools/use-segments';
import { FilterModel } from '@monorepo/controlled/src/models/filter.model';
import { FilterStore } from '@monorepo/controlled/src/stores/filter.store';
import { ISegment } from '@monorepo/base/src/components/segments/segments';
import { filterPrototypesByProp } from '../../components/filters-menu/filters/index';
import { PerformanceModel } from '../../models/performance.model';
import { Statuses } from '../../enums/status';

// every enum here is the path withtout / and ids
// for example, advertiser/1/campaigns will transform to CampaignFiltersStoreKeys.Advertiser
export enum CampaignFiltersStoreKeys {
	Campaigns = 'campaigns',
	Advertiser = 'advertiserscampaigns',
	CampaignGroup = 'campaigngroupscampaigns',
}

export class CampaignStore {
	crud = new CampaignCrudStore();
	list = new BaseListStore<CampaignModel>({
		listFunc: CampaignsApi.list,
		model: CampaignModel,
	});
	inputOptions = new CampaignInputOptionsStore();
	performance = new PerformanceStore<CampaignModel>({
		keys: new Set([PerformancesColumns.CampaignId]),
	});
	metrics = new PerformanceStore<CampaignModel>({
		segments: new Set<ISegment<CampaignModel, PerformanceModel, PerformancesColumns>>([DateSegment<CampaignModel>()]),
	});
	filters: Map<string, FilterStore>;

	constructor() {
		makeObservable(this, {
			crud: observable,
			list: observable,
			filters: observable,
			performance: observable,
			metrics: observable,
		});

		this.filters = this.createDefaultFilters();
	}

	createDefaultFilters() {
		// set filters for all campaigns pages
		const defaultFilters = new Map([
			[
				1,
				new FilterModel({
					index: 1,
					label: `Status is ${capitalCase(Statuses.Active)}`,
					value: Statuses.Active,
					prototype: StatusFilter,
				}),
			],
		]);
		const campaignsPageFilterStore = new FilterStore({
			key: CampaignFiltersStoreKeys.Campaigns,
			currentFilters: defaultFilters,
			filterPrototypesByProp,
		});
		const campaignsAdvertiserPageFilterStore = new FilterStore({
			key: CampaignFiltersStoreKeys.Advertiser,
			currentFilters: defaultFilters,
			filterPrototypesByProp,
		});
		const campaignsCampaignGroupPageFilterStore = new FilterStore({
			key: CampaignFiltersStoreKeys.CampaignGroup,
			currentFilters: defaultFilters,
			filterPrototypesByProp,
		});

		return new Map([
			[CampaignFiltersStoreKeys.Campaigns, campaignsPageFilterStore],
			[CampaignFiltersStoreKeys.Advertiser, campaignsAdvertiserPageFilterStore],
			[CampaignFiltersStoreKeys.CampaignGroup, campaignsCampaignGroupPageFilterStore],
		]);
		//
	}

	getCrud() {
		return this.crud;
	}

	getListStore() {
		return this.list;
	}

	getFiltersStore(key: CampaignFiltersStoreKeys) {
		return this.filters.get(key);
	}

	getInputOptions() {
		return this.inputOptions;
	}

	getPerformance() {
		return this.performance;
	}

	getMetrics() {
		return this.metrics;
	}
}
