import { BaseAndCrudRoutes } from '@monorepo/tools/src/lib/routes/base.routes';
import { id } from '@monorepo/tools/src/lib/types/primitives';

export class CampaignsRoutes extends BaseAndCrudRoutes {
	constructor() {
		super('campaigns');
	}

	overview(id: id) {
		return `/${this.baseRoute}/${id}/overview`;
	}

	channels(id: id) {
		return `/${this.baseRoute}/${id}/channels`;
	}

	sources(id: id) {
		return `/${this.baseRoute}/${id}/sources`;
	}

	settings(id: id) {
		return `/${this.baseRoute}/${id}/settings`;
	}

	// landing pages
	pages(id: id) {
		return `/${this.baseRoute}/${id}/pages`;
	}

	activityLog(id: id) {
		return `/${this.baseRoute}/${id}/activity-log`;
	}

	duplicate(id: id) {
		return `/${this.baseRoute}/${id}/duplicate`;
	}
}
