import { AccountModel, IAccountCreateForm, IAccountEditForm } from '../../models/account.model';
import { AccountsApi } from '../../apis/accounts.api';
import { BaseCrudStore } from '@monorepo/controlled/src/stores/base-crud.store';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import { FormError } from '@monorepo/tools/src/lib/models/form-error.model';
import { isValidUrl } from '@monorepo/tools/src/lib/utils/url';

export class AccountCrudStore extends BaseCrudStore<AccountModel, IAccountCreateForm, IAccountEditForm> {
	constructor() {
		super({
			apiLayer: AccountsApi,
			model: AccountModel,
		});
	}

	/**
	 * Must call isValidAccount before calling this function
	 * @returns
	 */
	public getCreateFormData(): IAccountCreateForm {
		return {
			id: this.getData().getId(),
			name: this.getData().getName(),
			company_size: this.getData().getCompanySize(),
			website: this.getData().getWebsite(),
		};
	}

	/**
	 * Must call isValidAccount before calling this function
	 * @returns
	 */
	public getEditFormData(): IAccountEditForm {
		return {
			id: this.getData().getId(),
			name: this.getData().getName(),
			company_size: this.getData().getCompanySize(),
			website: this.getData().getWebsite(),
		};
	}

	public isValid(): boolean {
		this.formStore.reset();
		const name = this.getData().getName();
		const company_size = this.getData().getCompanySize();
		const website = this.getData().getWebsite();

		if (!name) {
			this.formStore.addError(new FormError('name', 'Please enter name'));
		}

		if (!company_size) {
			this.formStore.addError(new FormError('company_size', 'Please select company size'));
		}

		if (!website) {
			this.formStore.addError(new FormError('website', 'Please enter website'));
		}

		if (website) {
			if (!isValidUrl(website)) {
				this.formStore.addError(new FormError('website', 'Invalid URL'));
			}
		}

		return this.formStore.getIsValid();
	}

	enableAccounts(accountsIds: id[]): Promise<void> {
		return AccountsApi.enableAccounts(accountsIds)
			.then(() => {
				this.setIsLoading(false);
				this.setIsSuccess(true);
			})
			.catch(() => {
				this.setIsLoading(false);
				this.setIsSuccess(false);
			});
	}

	pauseAccounts(accountsIds: id[]): Promise<void> {
		return AccountsApi.pauseAccounts(accountsIds)
			.then(() => {
				this.setIsLoading(false);
				this.setIsSuccess(true);
			})
			.catch(() => {
				this.setIsLoading(false);
				this.setIsSuccess(false);
			});
	}
}
