import { makeAutoObservable } from 'mobx';
import { url } from '@monorepo/tools/src/lib/types/url';
import { CampaignGroupModel } from './campaign-group.model';
import { AdvertiserModel } from './advertiser.model';
import { IStrategy, StrategyModel } from './strategy.model';
import { ITargetingForm, TargetingModel } from './targeting.model';
import { DailyBudgetModel, IDailyBudget } from './daily-budget.model';
import { IRules, RulesModel } from './rules.model';
import { capitalCase } from 'change-case';
import { CreativeSelectionMode } from '../enums/creative-selection-modes';
import { CampaignType } from '../enums/campaign-types';
import { DealType } from '../enums/deal-types';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import { DealModel } from './deal.model';
import { CreativeModel } from './creative.model';
import { Status } from '../enums/status';

/**
 * The campaign the campaign crud page uses
 */
export interface ICampaign {
	id?: id;
	name?: string;
	advertiser?: AdvertiserModel;
	campaign_group?: CampaignGroupModel;
	status?: Status;
	parent?: CampaignModel;
	priority?: number;
	secondary_priority?: number;
	bid_key_generator?: string;
	cache_key_generator?: string;
	campaign_type?: CampaignType;
	deal_type?: DealType;
	preferred_deals?: DealModel[];
	billing_id?: id;
	strategy?: StrategyModel;
	dest_url?: url;
	targeting?: TargetingModel;
	daily_budget?: DailyBudgetModel;
	rules?: RulesModel;
	black_listed_domains?: Set<string>;
	white_listed_domains?: Set<string>;
	black_listed_keys?: Set<string>;
	white_listed_keys?: Set<string>;
	black_listed_pages?: Set<string>;
	white_listed_pages?: Set<string>;
	creatives?: CreativeModel[];
	creative_selection_mode?: CreativeSelectionMode;
}

/**
 * The campaign the campaign edit page uses
 */
export interface ICampaignEdit {
	id?: id;
	name?: string;
	advertiser?: AdvertiserModel;
	campaign_group?: CampaignGroupModel;
	status?: Status;
	parent?: CampaignModel;
	priority?: number;
	secondary_priority?: number;
	bid_key_generator?: string;
	cache_key_generator?: string;
	campaign_type?: CampaignType;
	deal_type?: DealType;
	preferred_deals?: DealModel[];
	billing_id?: id;
	strategy?: IStrategy;
	dest_url?: url;
	targeting?: TargetingModel;
	daily_budget?: DailyBudgetModel;
	rules?: RulesModel;
	creative_selection_mode?: CreativeSelectionMode;
}

/**
 * The campaign to send to server
 */
export interface ICampaignCreateForm {
	name?: string;
	advertiser?: { id?: id }; // Advertiser id
	campaign_group?: { id?: id } | null; // Campaign group id
	status?: Status;
	parent?: { id?: id };
	priority?: number;
	secondary_priority?: number;
	bid_key_generator?: string | null;
	cache_key_generator?: string;
	campaign_type?: CampaignType;

	deal_type?: DealType;
	preferred_deals?: DealModel[];
	billing_id?: id;
	strategy?: { id: id } | null;
	dest_url?: url;

	targeting?: ITargetingForm;
	daily_budget?: IDailyBudget;
	rules?: IRules;
	creatives?: number[];
	creative_selection_mode?: CreativeSelectionMode;
}

export interface ICampaignEditForm {
	id?: id;
	name?: string;
	advertiser?: { id?: id }; // Advertiser id
	campaign_group?: { id?: id } | null; // Campaign group id
	status?: Status;
	parent?: { id?: id };
	priority?: number;
	secondary_priority?: number;
	bid_key_generator?: string | null;
	cache_key_generator?: string;
	campaign_type?: CampaignType;

	deal_type?: DealType;
	preferred_deals?: DealModel[];
	billing_id?: id;
	strategy?: { id?: id } | null;
	dest_url?: url;

	targeting?: ITargetingForm;
	daily_budget?: IDailyBudget;
	rules?: IRules;
	creatives?: number[];
	creative_selection_mode?: CreativeSelectionMode;
}

export interface ICampaignTargetingListing {
	black_listed_domains?: string[];
	white_listed_domains?: string[];
	black_listed_pages?: url[];
	white_listed_pages?: url[];
	black_listed_keys?: string[];
	white_listed_keys?: string[];
}

export class CampaignModel implements ICampaign {
	id?: id;
	name?: string;
	advertiser?: AdvertiserModel;
	campaign_group?: CampaignGroupModel;
	status?: Status;
	parent?: CampaignModel;
	priority?: number;
	secondary_priority?: number;
	bid_key_generator?: string;
	cache_key_generator?: string;
	campaign_type?: CampaignType;
	deal_type?: DealType;
	preferred_deals?: DealModel[];
	billing_id?: id;
	strategy?: StrategyModel;
	dest_url?: url;
	targeting?: TargetingModel;
	daily_budget?: DailyBudgetModel;
	rules?: RulesModel;
	black_listed_domains?: Set<string>;
	white_listed_domains?: Set<string>;
	black_listed_keys?: Set<string>;
	white_listed_keys?: Set<string>;
	black_listed_pages?: Set<string>;
	white_listed_pages?: Set<string>;
	creatives?: CreativeModel[];
	creative_selection_mode?: CreativeSelectionMode;

	constructor(campaign?: ICampaign) {
		this.id = campaign?.id;
		this.name = campaign?.name;
		this.advertiser = campaign?.advertiser ? new AdvertiserModel(campaign.advertiser) : undefined;
		this.campaign_group = campaign?.campaign_group ? new CampaignGroupModel(campaign.campaign_group) : undefined;
		this.status = campaign?.status;
		this.parent = campaign?.parent ? new CampaignModel(campaign?.parent) : undefined;
		this.priority = campaign?.priority;
		this.secondary_priority = campaign?.secondary_priority;
		this.bid_key_generator = campaign?.bid_key_generator;
		this.cache_key_generator = campaign?.cache_key_generator;
		this.campaign_type = campaign?.campaign_type;
		this.deal_type = campaign?.deal_type ? (capitalCase(campaign?.deal_type) as DealType) : undefined;
		this.preferred_deals = campaign?.preferred_deals?.map(deal => new DealModel(deal));
		this.billing_id = campaign?.billing_id;
		this.strategy = campaign?.strategy ? new StrategyModel(campaign.strategy) : undefined;
		this.dest_url = campaign?.dest_url;
		this.targeting = campaign?.targeting ? new TargetingModel(campaign.targeting) : undefined;
		this.daily_budget = campaign?.daily_budget ? new DailyBudgetModel(campaign.daily_budget) : undefined;
		this.rules = campaign?.rules ? new RulesModel(campaign.rules) : undefined;
		this.black_listed_domains = new Set<string>(campaign?.black_listed_domains ?? []);
		this.white_listed_domains = new Set<string>(campaign?.white_listed_domains ?? []);
		this.black_listed_keys = new Set<string>(campaign?.black_listed_keys ?? []);
		this.white_listed_keys = new Set<string>(campaign?.white_listed_keys ?? []);
		this.black_listed_pages = new Set<string>(campaign?.black_listed_pages ?? []);
		this.white_listed_pages = new Set<string>(campaign?.white_listed_pages ?? []);
		this.creatives = campaign?.creatives?.map(campaignCreative => {
			// happens because it returns the pivot table instead of creative
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore: Unreachable code error
			return new CreativeModel(campaignCreative.creative);
		});
		this.creative_selection_mode = campaign?.creative_selection_mode;

		makeAutoObservable(this);
	}

	public getId(): id | undefined {
		return this.id;
	}

	public setId(id: id) {
		this.id = id;
	}

	public getName() {
		return this.name;
	}

	public setName(name: string) {
		this.name = name;
	}

	public getAdvertiser(): AdvertiserModel | undefined {
		return this.advertiser;
	}

	public setAdvertiser(advertiser: AdvertiserModel): void {
		this.advertiser = advertiser;
	}

	public getCampaignGroup(): CampaignGroupModel | undefined {
		return this.campaign_group;
	}

	public setCampaignGroup(campaignGroup: CampaignGroupModel | undefined): void {
		this.campaign_group = campaignGroup;
	}

	public getStatus(): Status | undefined {
		return this.status;
	}

	public setParentCampaign(parent: CampaignModel | undefined): void {
		this.parent = parent;
	}

	public getParentCampaign(): CampaignModel | undefined {
		return this.parent;
	}

	public setStatus(status: Status): void {
		this.status = status;
	}

	public getPriority(): number | undefined {
		return this.priority;
	}

	public setPriority(priority: number): void {
		this.priority = priority;
	}

	public getSecondaryPriority(): number | undefined {
		return this.secondary_priority;
	}

	public setSecondaryPriority(secondary_priority: number): void {
		this.secondary_priority = secondary_priority;
	}

	public getBidKeyGenerator(): string | undefined {
		return this.bid_key_generator;
	}

	public setBidKeyGenerator(bid_key_generator: string): void {
		this.bid_key_generator = bid_key_generator;
	}

	public getCacheKeyGenerator(): string | undefined {
		return this.cache_key_generator;
	}

	public setCacheKeyGenerator(cache_key_generator: string): void {
		this.cache_key_generator = cache_key_generator;
	}

	public getCampaignType(): CampaignType | undefined {
		return this.campaign_type;
	}

	public setCampaignType(campaign_type: CampaignType): void {
		this.campaign_type = campaign_type;
	}

	public getDealType(): DealType | undefined {
		return this.deal_type;
	}

	public setDealType(deal_type: DealType): void {
		this.deal_type = deal_type;
	}

	public setBillingId(billingId: id | undefined) {
		this.billing_id = billingId;
	}

	public getBillingId(): id | undefined {
		return this.billing_id;
	}

	public getStrategy(): StrategyModel | undefined {
		return this.strategy;
	}

	public setStrategy(strategy: StrategyModel | undefined): void {
		this.strategy = strategy;
	}

	public getDestinationUrl(): url | undefined {
		return this.dest_url;
	}

	public setDestinationUrl(dest_url: url): void {
		this.dest_url = dest_url;
	}

	public getTargeting(): TargetingModel | undefined {
		return this.targeting;
	}

	public setTargeting(targeting: TargetingModel): void {
		this.targeting = targeting;
	}

	public getDailyBudget(): DailyBudgetModel | undefined {
		return this.daily_budget;
	}

	public setDailyBudget(dailyBudget: DailyBudgetModel): void {
		this.daily_budget = dailyBudget;
	}

	public getRules(): RulesModel | undefined {
		return this.rules;
	}

	public setRules(rules: RulesModel): void {
		this.rules = rules;
	}

	public getBlacklistedDomains(): Set<string> | undefined {
		return this.black_listed_domains;
	}
	public getWhitelistedDomains(): Set<string> | undefined {
		return this.white_listed_domains;
	}
	public getBlacklistedKeys(): Set<string> | undefined {
		return this.black_listed_keys;
	}
	public getWhitelistedKeys(): Set<string> | undefined {
		return this.white_listed_keys;
	}
	public getBlacklistedPages(): Set<string> | undefined {
		return this.black_listed_pages;
	}
	public getWhitelistedPages(): Set<string> | undefined {
		return this.white_listed_pages;
	}

	public getCreatives(): CreativeModel[] | undefined {
		return this.creatives;
	}
	public setCreatives(creative: CreativeModel[]): void {
		this.creatives = creative;
	}
	public getCreativeSelectionMode(): CreativeSelectionMode | undefined {
		return this.creative_selection_mode;
	}
	public setCreativeSelectionMode(creativeSelectionMode: CreativeSelectionMode): void {
		this.creative_selection_mode = creativeSelectionMode;
	}
	public getPreferredDeals(): DealModel[] | undefined {
		return this.preferred_deals;
	}
	public setPreferredDeals(preferred_deals: DealModel[]): void {
		this.preferred_deals = preferred_deals;
	}
}
