import { ReactNode, useRef, useState } from 'react';
import { useOnClickOutside } from '@monorepo/tools/src/lib/hooks/utils/use-on-click-outside';
import { IMenu, Menu } from '@monorepo/base/src/components/menu/menu';
import styles from './toggle-menu.module.scss';

interface IToggleMenu extends Partial<IMenu> {
	children: ReactNode;
	toggler: ReactNode;
}

export function ToggleMenu(props: IToggleMenu): JSX.Element {
	const { children, toggler, ...rest } = props;
	const [isToggleMenu, setToggleMenu] = useState<boolean>(false);
	const entityActionsRef = useRef(null);

	useOnClickOutside(entityActionsRef, () => {
		setToggleMenu(false);
	});

	return (
		<div ref={entityActionsRef} className={styles.wrapper}>
			<div onClick={() => setToggleMenu(!isToggleMenu)} className={styles.togglerWrapper}>
				{toggler}
			</div>
			<Menu isOpen={isToggleMenu} unstyled={true} {...rest}>
				{children}
			</Menu>
		</div>
	);
}
