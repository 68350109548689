import { TableLink } from '@monorepo/controlled/src/components/table/table-link/table-link';
import routes from '../../routes/index.routes';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { AccountModel, IAccount } from '../../models/account.model';
import { IEntityHeaders, IMergedHeadersReturn, renderSubRow } from './index.headers';
import { BetweenPagesStateProps } from '@monorepo/tools/src/lib/interfaces/global';
import { selectionColumn } from '@monorepo/controlled/src/components/table-wrapper/selection-column/selection-column';
import { isSubRow } from '@monorepo/controlled/src/components/table/table';
import { IPerformanceItem } from '../../models/performance.model';
import { createColumnHelper } from '@tanstack/react-table';
import { IMFPStore } from '../../stores';
import { useWithPerformanceHeaders } from './with-performance.headers';
import { Tag } from '@monorepo/base/src/components/tag/tag';
import { StatusType, mapToTagStatus, mapToIcon } from '../../enums/status';
import { Icon, IconFonts } from '@monorepo/base/src/components/icon/icon';
import { sentenceCase } from 'change-case';
import styles from './headers.module.scss';

const columnHelper = createColumnHelper<IAccount & IPerformanceItem>();

export const useAccountHeaders = (props: IEntityHeaders = {}): IMergedHeadersReturn<AccountModel> => {
	const { EntityActions, onEdit, summary } = props;
	const { sidebarStore } = useStores<IMFPStore>();
	const mergedPerforamnceHeaders = useWithPerformanceHeaders({ summaryItem: summary }).columns;

	return {
		columns: [
			selectionColumn<AccountModel>({ model: AccountModel, EntityActions }),
			columnHelper.accessor('name', {
				header: 'Name',
				cell: info => {
					const { row } = info;
					if (isSubRow(row)) {
						return renderSubRow({ row });
					}
					const toEdit = (() => {
						if (onEdit) {
							return onEdit(row.getValue('id'));
						}
						return routes.accounts.edit(row.getValue('id'));
					})();
					return (
						<TableLink
							to={routes.accounts.advertisers(row.getValue('id'))}
							toEdit={toEdit}
							toEditState={{ isBlockRequest: true } as BetweenPagesStateProps}
							onClick={() => {
								if (!toEdit) {
									sidebarStore.setSidebarOpen(false);
								}
							}}>
							{row.getValue('name')}
						</TableLink>
					);
				},
			}),
			columnHelper.accessor('status', {
				header: 'Status',
				cell: info => {
					const { row } = info;
					if (isSubRow(row)) {
						return <span></span>;
					}
					const status = row.getValue('status') as string;
					const mappedStatus = mapToIcon(status?.toUpperCase() as StatusType);
					return (
						<Tag type={mapToTagStatus(status?.toUpperCase() as StatusType)}>
							<Icon
								className={styles[mappedStatus.className]}
								size={'13px'}
								color={mappedStatus.color}
								font={IconFonts.Outlined}
								isMFP={true}>
								{mappedStatus.icon}
							</Icon>
							{`${sentenceCase(row.getValue('status'))}`}
						</Tag>
					);
				},
			}),
			columnHelper.accessor('id', {
				header: 'ID',
			}),
			...mergedPerforamnceHeaders,
		],
		columnVisibility: { id: false, impressions: false },
	};
};
