import { observer } from 'mobx-react';
import { flexRender, ColumnDef, Row, ColumnSort, RowData } from '@tanstack/react-table';
import { route } from '@monorepo/tools/src/lib/types/url';
import { IControlledStore, useStores } from '../../hooks/use-stores';
import { InjectedTableActionsProps, IRowProps, ITableLiners, ITableProps, Table } from '../table/table';
import { PrimaryLink } from '@monorepo/base/src/components/buttons/buttons';
import { SkeletonCell } from '../table/table-skeleton/table-skeleton';
import { FilterModel } from '../../models/filter.model';
import { memo } from 'react';
import { IDebugProps } from '@monorepo/tools/src/lib/interfaces/debug';
import { suffixToDataAttr } from '@monorepo/tools/src/lib/models/data-attr.model';

declare module '@tanstack/table-core' {
	// eslint-disable-next-line
	interface ColumnMeta<TData extends RowData, TValue> {
		isPerformance: boolean;
		// TODO add total line here
	}
}

export interface IServerPagination {
	offset: number;
	limit: number;
}

interface IControlledTableProps<T> extends ITableProps<T> {
	// TODO - fix this any
	// https://github.com/TanStack/table/issues/4382
	columns: ColumnDef<T, unknown>[];
	data: Array<T>;
	children?(props: InjectedTableActionsProps<T>): ITableLiners;
	createRoute?: route;
	isLoading?: boolean;
	isLoadingPerformance?: boolean;
	isWithPerformance?: boolean; // TODO - remove this and use isLoadingPerformance ??
	columnVisibility?: Record<string, boolean>; // string is the id of the column
	defaultSortBy?: ColumnSort[];
	filters?: Map<number, FilterModel>;
	debugProps?: IDebugProps;
	// fetchReports?: (pagination: IServerPagination) => void;
}

function theFilter<T>(data: Array<T>, filters?: Map<number, FilterModel>) {
	if (!filters) {
		return data;
	}

	let filteredData = data;
	Array.from(filters).forEach(([, filter]) => {
		const { prototype } = filter;
		const { filterFunc } = prototype;
		if (!filterFunc) {
			return filteredData;
		}
		filteredData = filterFunc(filteredData, filter);
	});

	return filteredData;
}

export const _ControlledTable = observer(<T extends IRowProps<T>>(props: IControlledTableProps<T>) => {
	const {
		columns,
		data,
		children,
		isLoading,
		isLoadingPerformance,
		createRoute,
		isWithPerformance,
		columnVisibility,
		filters,
		debugProps,
		...rest
	} = props;
	const { dataAttrs } = debugProps || {};

	let { defaultSortBy } = props;
	const { tableStore } = useStores<IControlledStore>();
	// const [pagination, setPagination] = useState<{ offset: number; limit?: number }>({ offset: 0, limit: undefined });

	let TableActions: ITableLiners = {};
	let performanceProps = {};

	const filteredData = theFilter(data, filters);

	const renderWithPerformance = (row: Row<T>): JSX.Element[] => {
		return row.getVisibleCells().map(cell => {
			return (
				<td key={cell.id}>
					{isLoadingPerformance && cell.column.columnDef.meta?.isPerformance ? (
						<SkeletonCell />
					) : (
						flexRender(cell.column.columnDef.cell, cell.getContext())
					)}
				</td>
			);
		});
	};

	if (isWithPerformance) {
		performanceProps = {
			renderCell: renderWithPerformance,
			isBarLoader: isLoading || isLoadingPerformance,
			defaultSortBy: defaultSortBy || [{ id: 'wins', desc: true }],
		};
	}

	if (!defaultSortBy) {
		defaultSortBy = [{ id: 'id', desc: false }];
	}

	return (
		<Table<T>
			data={filteredData}
			columns={columns}
			isLoading={isLoading}
			isBarLoader={isLoading}
			columnVisibility={columnVisibility}
			defaultSortBy={defaultSortBy}
			debugProps={debugProps}
			isSummary={isWithPerformance}
			filterTerm={tableStore.getFilterTerm()}
			pagination={{
				pageIndex: tableStore.getPageIndex(),
				pageSize: tableStore.getPageSize(),
			}}
			{...performanceProps}
			{...rest}>
			{(props: { rows: Row<T>[] }) => {
				const { rows } = props;

				if (children) {
					TableActions = children({ rows });
				}
				const DefaultIndexActions = () => {
					if (!createRoute) {
						return null;
					}
					return (
						<PrimaryLink
							icon={'plus'}
							to={`${createRoute}${window.location.search}`}
							width={'100px'}
							debugProps={{ dataAttrs: suffixToDataAttr('_create', dataAttrs) }}>
							Create
						</PrimaryLink>
					);
				};

				return {
					IndexActions: (() => {
						if (TableActions.IndexActions) {
							return TableActions.IndexActions;
						}
						if (!createRoute) {
							return null;
						}
						return DefaultIndexActions;
					})(),
					...TableActions,
				};
			}}
		</Table>
	);
});

export const ControlledTable = memo(_ControlledTable) as typeof _ControlledTable;
