import { ChangeEvent, LegacyRef } from 'react';
interface ICheckboxProps {
	onChange?: ((e: ChangeEvent) => void) | undefined;
	checked?: boolean | undefined;
	title?: string | undefined;
	indeterminate?: string | boolean | undefined;
	ref?: LegacyRef<HTMLInputElement> | undefined;
	className?: string;
	id?: string;
	style?: object;
	disabled?: boolean;
}

export const Checkbox = (props: ICheckboxProps) => {
	return <input type={'checkbox'} {...props} />;
};
