import { IControlledStore, useStores } from '@monorepo/controlled/src/hooks/use-stores';
import styles from './page-size.module.scss';
import { Dropdown, option } from '@monorepo/base/src/components/dropdown/dropdown';

interface IPageSize {
	pageSize: number;
	setPageSize: (args0: number) => void;
}

// TODO - check without tableStore

export const PageSize = (props: IPageSize) => {
	const { pageSize, setPageSize } = props;
	const { tableStore } = useStores<IControlledStore>();
	return (
		<div className={styles.wrapper}>
			<Dropdown
				defaultOption={`Show ${pageSize}`}
				isCloseIcon={false}
				label={'Show'}
				options={['Show 20', 'Show 50', 'Show 100', 'Show 200']}
				onSelect={(size: Set<option> | undefined) => {
					const sizeNumber = Number(size?.values().next().value.replace('Show ', ''));
					tableStore.setPageSize(sizeNumber);
					setPageSize(sizeNumber);
				}}
			/>
		</div>
	);
};
