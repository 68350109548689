import { ElementType, ReactNode } from 'react';

interface IText {
	children: ReactNode;
	Element?: ElementType;
	className?: string;
	color?: string;
}
export const Text = (props: IText) => {
	const { Element = 'div', children, className, color } = props;
	return (
		<Element className={className} style={{ color }}>
			{children}
		</Element>
	);
};
