import { IAdvertiserCreateForm, IAdvertiserEditForm } from '../models/advertiser.model';
import { AdvertiserModel } from '../models/advertiser.model';
import { ask } from '@monorepo/tools/src/lib/tools/ask/ask';
import endpoints from './endpoints/index.endpoints';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import { IRequestOptions } from '@monorepo/tools/src/lib/interfaces/url';
import { IPerformanceOptions } from '../hooks/apis/use-reports-list';
import { PerformancePayload } from '../models/performance.model';
import { queryParamsToSearchParams } from '@monorepo/tools/src/lib/utils/url';

export const AdvertisersApi = {
	list: (params?: URLSearchParams): Promise<AdvertiserModel[]> => {
		return ask.get(endpoints.advertisers.list(), params).then(res => res?.payload?.list);
	},
	create: (advertiser: IAdvertiserCreateForm, options?: IRequestOptions): Promise<AdvertiserModel> => {
		return ask.post<IAdvertiserCreateForm>(endpoints.advertisers.create(), advertiser, options).then(res => res?.payload?.item);
	},
	edit: (advertiserId: id, advertiser: IAdvertiserEditForm, options?: IRequestOptions): Promise<AdvertiserModel> => {
		return ask
			.patch<IAdvertiserEditForm>(endpoints.advertisers.edit(advertiserId), advertiser, options)
			.then(res => res?.payload?.item);
	},
	get: (advertiserId: id, options?: URLSearchParams): Promise<AdvertiserModel> => {
		return ask.get(endpoints.advertisers.get(advertiserId), options).then(res => res?.payload?.item);
	},
	delete: (advertiserId: id) => {
		return ask.delete(endpoints.advertisers.delete(advertiserId)).then(res => res?.payload?.ids);
	},
	reports: (params?: IPerformanceOptions, options?: IRequestOptions): Promise<PerformancePayload> => {
		const hashParams = btoa(JSON.stringify(params));
		return ask
			.get(endpoints.advertisers.reports(), queryParamsToSearchParams({ p: hashParams, ...(options?.queryParams || {}) }))
			.then(res => res?.payload?.report);
	},
	metrics: (params?: IPerformanceOptions): Promise<PerformancePayload> => {
		const hashParams = btoa(JSON.stringify(params));
		return ask.get(endpoints.advertisers.reports(), queryParamsToSearchParams({ p: hashParams })).then(res => res?.payload?.report);
	},
	categories: (): Promise<string[]> => {
		return ask.get(endpoints.advertisers.categories()).then(res => res?.payload?.list);
	},
	enableAdvertisers(advertiserIds: id[]): Promise<null> {
		return ask.patch(endpoints.advertisers.enableAdvertisers(), advertiserIds).then(res => res?.payload?.ids);
	},
	pauseAdvertisers(advertiserIds: id[]): Promise<null> {
		return ask.patch(endpoints.advertisers.pauseAdvertisers(), advertiserIds).then(res => res?.payload?.ids);
	},
};
