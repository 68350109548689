import { Flex } from '@monorepo/base/src/components/flex/flex';
import { InputDesc, InputInfo, InputItem, InputTitle, InputTooltip } from '@monorepo/base/src/components/form/input-item/input-item';
import { Input } from '@monorepo/base/src/components/form/input/input';
import { SpacerX } from '@monorepo/base/src/components/spacer/spacer';
import { Text } from '@monorepo/base/src/components/text/text';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { getTheme } from '@monorepo/tools/src/lib/get-config';
import { observer } from 'mobx-react';
import { IMFPStore } from '../../../../stores';
import styles from '../campaign-form.module.scss';
import { SpaceBetweenInputControls } from '../campaign.form';
import { CampaignDomIds } from '../../../../hooks/toc/campaign.toc';
import { randomNumber } from '@monorepo/tools/src/lib/utils/number';
import { useEffect } from 'react';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';

const randomSuggestedBid = parseFloat(randomNumber(0.05, 0.5));

export const BasicStrategy = observer(() => {
	const { campaignStore } = useStores<IMFPStore>();
	const campaignCrudStore = campaignStore.getCrud();
	const campaign = campaignCrudStore.getData();
	const formStore = campaignCrudStore.getFormStore();

	useEffect(() => {
		campaign.setSuggestedBid(randomSuggestedBid);
	}, []);

	return (
		<div>
			<InputItem maxWidth={'944px'} alignItems={'flex-start'}>
				<InputInfo className={styles.inputInfo}>
					<InputTitle>Daily Budget</InputTitle>
					<InputDesc>Enter the maximum amount you can spend per day</InputDesc>
				</InputInfo>
				<Input
					id={CampaignDomIds.BasicDefaultBid}
					before={'$'}
					topLabel={'Bid'}
					after={
						<Flex>
							<Text color={getTheme().titleColor}>CPC</Text>
							<SpacerX />
							<InputTooltip content={'This is a tooltip'} />
						</Flex>
					}
					className={styles.inputWrapper}
					type={'positiveNumber'}
					step={'0.1'}
					value={campaign?.getDefaultBid()?.toString()}
					onValue={value => {
						campaign.setDefaultBid(parseFloat(value));
						formStore.clearError(CampaignDomIds.BasicDefaultBid);
					}}
					required={true}
					placeholder={randomSuggestedBid.toString()}
					bottomLabel={`Suggested Bid: ${randomSuggestedBid}`}
					requiredAsterisk={false}
					error={formStore.getErrors().get(CampaignDomIds.BasicDefaultBid)?.getMsg()}
					debugProps={{ dataAttrs: [new DataAttribute('id', `default_bid`)] }}
				/>
				<SpacerX x={2} />
				<Input
					id={CampaignDomIds.BasicDailyBudget}
					before={'$'}
					topLabel={'Daily budget'}
					after={
						<Flex>
							<Text color={getTheme().titleColor}>USD</Text>
							<SpacerX />
							<InputTooltip content={'This is a tooltip'} />
						</Flex>
					}
					type={'positiveNumber'}
					className={styles.inputWrapper}
					placeholder={'Unlimited'}
					value={campaign.getDailyBudget()?.toString()}
					onValue={value => {
						campaign.setDailyBudget(parseInt(value));
					}}
					required={true}
					requiredAsterisk={false}
					debugProps={{ dataAttrs: [new DataAttribute('id', `daily_budget`)] }}
				/>
			</InputItem>

			<SpaceBetweenInputControls />
		</div>
	);
});
