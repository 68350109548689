import { IControlledStore, useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { Icon } from '@monorepo/base/src/components/icon/icon';
import styles from './pagination.module.scss';

interface IPagination {
	// previousPage: () => void;
	// nextPage: () => void;
	gotoPage: (arg0: number) => void;
	canPreviousPage: boolean;
	canNextPage: boolean;
	pageOptions: number[];
	pageIndex: number;
	pageSize: number;
	total: string | number;
}

export const Pagination = (props: IPagination) => {
	const { canPreviousPage, canNextPage, gotoPage, pageIndex = 0, total, pageSize } = props;
	const { tableStore } = useStores<IControlledStore>();

	const min = pageIndex === 0 ? 1 : pageSize * pageIndex + 1;
	const max = parseInt(`${total}`) < pageSize * (pageIndex + 1) ? total : pageSize * (pageIndex + 1);
	// {min} - {max} of {total}

	return (
		<div className={styles.pagination}>
			{/* <Icon
				isMFP={true}
				className={`${styles.start} ${pageIndex === 0 ? styles.disable : ''}`}
				onClick={() => {
					tableStore.setPageIndex(0);
					gotoPage(0);
				}}>
				keyboard_double_arrow_left
			</Icon> */}
			<Icon
				isMFP={true}
				className={`${styles.prev} ${!canPreviousPage ? styles.disable : ''}`}
				onClick={() => {
					tableStore.setPageIndex(pageIndex - 1);
					gotoPage(pageIndex - 1);
					// previousPage();
				}}>
				chevron-left
			</Icon>
			<div className={styles.info}>
				{min} - {max} of {total}
			</div>
			<Icon
				isMFP={true}
				className={`${styles.next} ${!canNextPage ? styles.disable : ''}`}
				onClick={() => {
					tableStore.setPageIndex(pageIndex + 1);
					gotoPage(pageIndex + 1);
					// nextPage();
				}}>
				chevron-right
			</Icon>
			{/* <Icon
				isMFP={true}
				className={`${styles.end} ${pageOptions.length - 1 === pageIndex ? styles.disable : ''}`}
				onClick={() => {
					tableStore.setPageIndex(pageOptions.length - 1);
					gotoPage(pageOptions.length - 1);
				}}>
				keyboard_double_arrow_right
			</Icon> */}
		</div>
	);
};
